.l-region--widescreen {
  position: relative;
}

#block-views-frontpage-featured-block {
  min-height: 200px;
  box-shadow: 0 0 30px rgba(1, 1, 1, 0.07);
  background-color: #ffffff;
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  // width: span(8 of 12);  
  width: 100%;
  max-width: 1057px;
  // padding: 50px;
  position: relative;
  margin-top: -460px;
  margin-bottom: 180px;

  @include breakpoint(1080px) {
    position: relative;
    top: unset;
    left: unset;
    bottom: unset;
    transform: none;
    width: 95%;
    margin: 0 auto;
    margin-top: -40px;
    margin-bottom: 60px;
  }

  .box {
    width: 100%;
    display: flex;
    align-items: center;

    @include breakpoint(700px) {
      flex-direction: column;
    }
  }

  .custom-title {
    color: #100a0f;
    font-family: "Libre Baskerville";
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 15px;

    @include breakpoint(1080px) {
      line-height: 35px;      
    }
    @include breakpoint(700px) {
      font-size: 24px;
    }
  }

  .field--name-body {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;

    @include breakpoint(1080px) {
      br {
        display: none;
      }
    }
  }
  
  .required-fields {
    // margin-right: span(1 wide);
    width: span(5 of 8);
    padding: 50px 0 50px 50px;
    @include breakpoint(1080px) {
      padding: 25px 0 25px 25px;
    }
    @include breakpoint(700px) {
      width: 100%;
      padding: 25px;
    }
  }
  .button {
    margin-left: auto;
    margin-right: 50px;
    margin-left: 50px;
    @include breakpoint(1080px) {
      margin-right: 25px;
    }
    @include breakpoint(700px) {
      margin-right: auto;
      margin-bottom: 25px;
      width: 100%;
      padding: 0 25px;
    }
  }
  .field--name-field-cta {       
    a {
      color: #100a0f;
      font-size: 14px;
      padding: 20px 40px;
      border: 1px solid #e1e1e1;
      text-decoration: none;

      @include breakpoint(780px) {
        padding: 20px 0;
      }
      @include breakpoint(700px) {
        width: 100%;
        min-width: 100%;        
      }

      &:after {
        content: "";
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
        // background-image: url(../images/sprites/build/sprites.png?v=1515416562526);
        // background-position: -337px -268px;
        @include sprite($sprite-read-more-black);
        width: 37px;
        height: 12px;
      }
      &:visited {
        color: #100a0f;
      }
    }
  }
}

.terms-text {
  font-size: 14px;

  a {
    position: relative;
    z-index: 99999;
  }
  
  @include breakpoint(700px) {
    font-size: 12px;
  }
  @include breakpoint(425px) {
    width: 200px;
    display: inline-block;
    text-align: left;
    margin-left: 40px;
  }
}

.activew-bubble {
  display: none;
}




#sliding-popup {
  box-shadow: 0 0 30px rgba(1, 1, 1, 0.07);
  & > div {
    padding: 20px;
    display: flex;

    &:before {
      content: "";
      width: 56px;
      height: 68px;
      background: url("/sites/all/themes/theuxprodigy/images/cookies.png");            
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 30px;
    }
  }
  &.sliding-popup-top {
    top: 100px !important;
    max-width: 500px !important;
    width: 100% !important;
    right: 20px !important;
    left: auto !important;
    background: white;

    @include breakpoint(540px) {
      right: 0 !important;
    }
  }
  .popup-content {    
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    

    #popup-text {
      color: black;
      max-width: 100% !important;
      line-height: 18px;
      @include breakpoint(600px) {
        br {
          display: none;
        }
      }
    
    }
    #popup-buttons {
      width: 100%;
      float: none;
      display: flex;
      max-width: 100% !important;
      margin-top: 30px;

      button {
        padding:0;
        &.agree-button {
          font-weight: bold;
        }
        &:hover {
          background: none !important;
          color: black !important;
        }
      }
    }
  }
}

// body {
//   &.popup-open {
//     &:after {
//       content: '';
//       background: 
//     }
//   }
// }

#block-gtweaks-what-we-do {
  .block-title-top {
    font-size: 2.5rem;
    margin-bottom: 40px;
    max-width: 760px;
    @include breakpoint(1024px) {
      font-size: 1.815rem;
    }
  }
}



.block--gtweaks-front-main-banner .block__content {
  @include breakpoint(700px) {
    min-height: 680px !important;
    max-height: unset !important;
  }
}


.newsletter-popup {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: white;
  padding: 20px;
  z-index: 999;
  width: 750px;
  height: 400px;

  .left-column {
    width: calc(100% - 377px);
    position: relative;
    h2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
    .continue-browsing {
      font-size: 14px;
      margin-top: 40px;
      border: 1px solid #e1e1e1;
      padding: 20px 12px;
      &:hover {
        cursor: pointer;
      }
    }    
  }  
  .popup-footer {
    position: absolute;
    bottom: 0;
    left: 37%;
    transform: translateX(-50%);
    font-size: 12px;
  }
  .right-column {
    position: relative;
    width: 377px;
  }
  .bg-image {
    background: url(/sites/all/themes/theuxprodigy/images/newsletter.png);
    background-repeat: repeat;
    height: 463px;
    width: 377px;
    position: absolute;
    background-repeat: no-repeat;
    bottom: -20px;
    right: -20px;
  }
  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 230px;
  }

  @include breakpoint(670px) {
    transform: translate(-50%,-50%) scale(0.75);
  }
}

body.popup-open {
  overflow: hidden;
  &:after {
    content: "";
    background: rgba(0,0,0,0.45);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
  }
}


.find-more-button {
  font-size: 14px;
}



.pgwloaderModal img {
  margin: 0 auto;
}



.node-type-plain-page {
  table {
    width: 100%;
    td, tr {
      border: 1px solid;
      padding: 10px;
    }
  }
  u {
    text-decoration: none;
  }
}