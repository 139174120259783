//Checkout summary

.page-checkout {
  .l-main {
    max-width: 100%;
    @include breakpoint(1160px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .l-header-wrapper {
    border-bottom: 1px solid #d8d8d8;
  }
  .messages {
    font-size: 14px;
  }
  .l-region--header {
    height: 100%;
    @include breakpoint(850px) {
      width: 100%;
      position: relative;
      right: 0;
      left: 0;
      justify-content: center;
    }
  }

  .block-gtweaks-notification-bar-top {
    display: none;
  }

  .l-header {
    @include breakpoint(850px) {
      display: flex;
      flex-direction: column;
      height: 100px;

      .l-branding {
        float: none;
        margin: 0 auto;
      }
    }
  }
}

.commerce-checkout-form-complete,
.commerce-checkout-form-checkout {
  &>div {
    display: flex;    
    @include breakpoint(1160px) {
      flex-direction: column-reverse;
    }
    .grouper {
      // max-width: 660px;
      // max-width: 510px;
      // width: 100%;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 200px;

      @include breakpoint(1160px) {
        width: 100%;
        margin-right: 0;
      }
      @include breakpoint(500px) {
        margin-bottom: 60px;
      }

      .content {
        max-width: 510px;
        margin: 0 auto;

        @include breakpoint(550px) {
          padding: 0 30px;
        }
      } 

      .messages.error {
        position: absolute;
        max-width: 512px;
      }

      .account {
        legend {
          padding-top: 120px;
          font-size: 24px;
          margin-bottom: 30px;

          @include breakpoint(500px) {
            padding-top: 100px;
          }
        }

        .fieldset-wrapper {
          #account-login-container {
            .form-item {
              margin-bottom: 0;

              label {
                font-size: 14px;
                font-weight: normal;
                margin-bottom: 8px;
              }
  
              input {
                max-width: 512px;
                height: 50px;
                width: 100%;
                margin-bottom: 20px;
                font-size: 14px;
              }
            }
          }

          #edit-account-consent {
            margin-bottom: 45px;
            .form-item {
              // input {
              //   // width: auto;
              //   // margin: 0;
              //   // height: auto;
              // }

              label {
                font-size: 14px;
              }
            }
          }
        }
      }

      .commerce-payment-title {
        font-family: "Libre Baskerville";
        font-size: 24px;
      }
      #edit-account {
        .fieldset-legend {
          font-family: "Libre Baskerville";
          font-size: 24px;
        }
      }
      .customer_profile_billing {
        legend {
          font-size: 24px;
          margin-bottom: 35px;          
        }

        // .field-type-addressfield {
        //   .form-item {
                      
        //   }
        // }
        .fieldset-legend {
          font-family: "Libre Baskerville";
          font-size: 24px;
        }
        label {
          font-weight: normal;
          font-size: 14px;
          margin-bottom: 8px;
          color: #000000;
          font-family: Montserrat;
        } 
        input {
          max-width: 512px;
          height: 50px;
          width: 100%;
          padding: 0 15px;
          font-size: 14px;
        }

        // .edit-customer-profile-billing-field-customer-last-name
        .field-name-field-customer-phone {
          .form-item {
            margin-bottom: 0;

            label {
              font-weight: normal;
              font-size: 14px;
              margin-bottom: 8px;
            }
  
            input {
              max-width: 512px;
              height: 50px;
              width: 100%;
            }
          }
        }

        .addressfield-container-inline {
          display: flex;
          flex-direction: column;

          .form-item {
            width: 100%;
            max-width: 512px;
          }
        }
      }

      .phone-helper {
        font-size: 12px;
        margin-bottom: 65px;
        margin-top: 10px;
      }

      .commerce-payment-title {
        font-size: 24px;
        margin-bottom: 35px;
        margin-top: 45px;
      }

      #commerce-payment-method-ajax-wrapper {
        margin-top: 40px;
        max-width: 512px;
        width: 100%;
        border: 1px solid #cccccc;
        
        #edit-commerce-payment-payment-method {
          min-height: 88px;
          display: flex;
          align-items: center;

          .checkmark {
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
          }

          .form-item {
            display: flex;
            align-items: center;
            margin-bottom: 0;
  
            input {
              margin-left: 20px;
            }
  
            label {
              font-size: 14px;
              margin-left: 30px;
              span {
                margin-bottom: 17px;
                display: block;
              }
            }
          }
        }

        legend {
          display: none;
        }

        &:after {
          content: "";
          background-image: url(../images/payment_instructions.png);
          display: block;
          width: 100%;
          height: 300px;
          border-top: 1px solid #cccccc;
        }

        .accepted-cards-payment {
          li {
            margin-right: 8px;
          }
          @include breakpoint(850px) {
            margin: 0 auto;
          }
        }
      }

      .accept_terms {
        margin-top: 100px;

        .form-item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
        .option {
          display: none;
        }
      }

      .checkout-buttons {
        .fieldset-wrapper {
          display: flex;
          flex-direction: column;
        }

        .checkout-processing,
        .button-operator {
          display: none;
        }

        .checkout-continue {
          width: 100%;
          max-width: 512px;
          height: 64px;
          background-color: #fbc31e;
          border: none;
          font-size: 16px;

          cursor: pointer;
          background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
          background-size: 200% 100%;
          background-position: 100% 0;
          background-repeat: no-repeat;
          -webkit-transition: 0.3s background-position ease-out;
          transition: 0.3s background-position ease-out;
          color: black;

          &:hover {
            background-position: 0 0;
            color: #fbc31e;
          }
        }

        .checkout-cancel {
          width: 100%;
          max-width: 512px;
          height: 64px;
          border: none;
          font-size: 16px;
          color: black;
          cursor: pointer;
        }
      }
    }

    &> .cart_contents {
      // max-width: 660px;
      // max-width: 510px;
      // width: 100%;
      width: 50%;
      margin-right: auto;
      margin-left: auto;
      background-color: #f6f6f6;
      z-index: 99;
      position: relative;
      // padding-left: 150px;

      @include breakpoint(1160px) {
        width: 100%;
        margin-right: 0;
        padding-bottom: 90px;
      }

      .fieldset-legend {
        color: #000000;
        font-family: "Libre Baskerville";
        font-size: 24px;
        font-weight: 400;
      }
      legend {
        padding-top: 115px;
        font-size: 26px;
        font-family: serif;
        margin-bottom: 40px;
      }
      .content {
        max-width: 510px;
        margin: 0 auto;
        position: sticky;
        top: 0;

        @include breakpoint(550px) {
          padding: 0 30px;
          position: relative;
        }
      }

      .view-header {
        width: 100%;
        float: none;
        display: none;
      }

      .view-content {
        min-height: 105px;

        .views-row {
          display: flex;
          align-items: center;

          @include breakpoint(440px) {
            flex-direction: column;
          }

          .views-field-field-product-image {
            float: left;
          }
  
          .field--type-commerce-price {
            clear: both;
          }

          .views-field-title {
            margin-left: 20px;
            font-weight: bold;
            font-size: 14px;
            padding-right: 10px;
            @include breakpoint(450px) {
              margin-left: 0;
            }
          }

          .views-field-commerce-unit-price {
            margin-left: auto;
            font-weight: bold;
            @include breakpoint(450px) {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
      
      &:after {
        content: "";
        display: block;
        width: 50vw;
        height: 100%;
        background-color: #f6f6f6;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;              
      }
    }
  }
}




.view-commerce-cart-summary {
  img {
    display: block;
  }
  .views-row {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}

fieldset[id^="edit-commerce-coupon"] {
  label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 10px;
  }
  .messages {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
  .fieldset-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;
    border-bottom: 1px solid #d9d9d9;
    @include breakpoint(440px) {
      flex-direction: column;
      // padding-top: 25px;
    }
  }
  input[type="text"] {
    height: 50px;
    border: 1px solid #cccccc;
    padding: 20px;
    background: none;
    font-size: 14px;
  }
}

.form-item-commerce-coupon-coupon-code {
  width: calc(100% - 105px);
  
  @include breakpoint(440px) {
    width: 100%;
  }

  input {
    width: 100%;
  }
}
input[id^="edit-commerce-coupon-coupon-add"] {
  width: 105px;
  height: 50px;
  background-color: #d7d7d7;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  top: 3px;
  &:hover {
    cursor: pointer;
  }

  @include breakpoint(1160px) {
    top: 4px;
  }
  @include breakpoint(440px) {
    width: 100%;
  }
}


#edit-account-login-mail {
  padding: 0 15px;
}




// Custom checkout checkboxes.
/* The container */
.form-type-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 16px;
}

/* Hide the browser's default checkbox */
.form-type-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #b7b7b7;
}

/* On mouse-over, add a grey background color */
.form-type-checkbox:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.form-type-checkbox input:checked ~ .checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-type-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-type-checkbox .checkmark:after {
  left: 7px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#co-order-total-checkout-ajax-wrapper {
  margin-top: 30px;
}
.co-order--row {
  display: flex;
  &:last-child {
    margin-top: 20px;
    .co-order--value,
    .co-order--label {
      color: #000000;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.co-order--value,
.co-order--label {
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
}
.co-order--value {
  margin-left: auto;
}


.form-item-accept-terms-terms-of-use {
  .description {
    font-size: 14px;
    font-weight: 300;
  }
}


#edit-accept-terms {
  .description {
    height: 22px;
    line-height: 22px;
  }
  a {
    color: black;
    position: relative;
    z-index: 100;

    &:hover,
    &:active,
    &:visited {
      color: black;
    }
  }
}


#commerce-alphabank-redirect-form {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 150px 0;
  
  #edit-submit {
    display: inline-block;
    padding: 22px;
    color: #100a0f;
    font-size: 13.99986px;
    font-size: 0.77777rem;
    text-align: center;
    text-decoration: none;
    font-weight: 300;
    min-width: 225px;
    background-image: -webkit-gradient(linear, left top, right top, from(#100a0f), color-stop(50%, #100a0f), color-stop(50%, #fbc31e), to(#fbc31e));
    background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    border: none;
    margin-top: 30px;
    
    &:hover {
      cursor: pointer;
      background-position: 0 0;
      color: #fbc31e;
    }
  }
}


#edit-continue {
  // background: #fbc31e;
  // background-position: 0% 100%;
  // &:hover {
  //   background-image: linear-gradient(to right,#100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
  //   background-size: 200% 100%;
  //   background-position: 100% 100%;
  //   background-repeat: no-repeat;
  //   -webkit-transition: 0.3s background-position ease-out;
  //   transition: 0.3s background-position ease-out;
  //   color: #fbc31e;
  // }
}


.messages.error {
  display: none;
}


#block-gtweaks-accepted-cards-payment {
  margin: 0;
  display: flex;
  .block__content {
    display: flex;
  }
}

.accepted-cards-payment {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 160px;
  li {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}


.views-field-uxwebinar-image {
  @include breakpoint(440px) {
    margin-bottom: 20px;
    margin-right: auto;
  }
}


.accepted-cards-payment {
  @include breakpoint(850px) {
    margin: 0 auto;
  }
  li {
    @include breakpoint(350px) {
      margin-right: 10px;
    }
  }
}

//From here

.views-form {
  .form-actions {
    .ajax-progress-throbber {
      // position: absolute;
      // right: -10px;
      // display: block;
      // width: 20px;
      // height: 20px;
      // top: 338px;
      // right: 230px;
      position: absolute;
      right: -10px;
      display: block;
      width: 135px;
      height: 20px;
      right: 0px;
      top: 386px;
        
      @include breakpoint(550px) {
        top: 380px;
        right: 24px;
        left: none;
      }

      @include breakpoint(440px) {
        top: 490px;
        left: 30px;
      }

      .throbber {
        margin: 0;
        order: 99;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        background: transparent url(../../../../default/files/throbber.gif) no-repeat ;
      }
  
      .message {
        font-size: 13px;
        padding: 0;
      }
    }

    input {
      display: none;
    }

  }
}

.views-form{
  img {
    display: block;
  }
  .views-row {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  min-height: 105px;

  .views-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint(440px) {
      flex-direction: column;
    }

    .views-field-uxwebinar-image {
      width: 21%;
    }

    .views-field-uxwebinar-title {
      width: 57%;
    }

    .views-field-commerce-unit-price {
      width: 16%;
    }

    .views-field-field-product-image {
      float: left;
    }

    .views-field-edit-quantity {
      width: 100%;
      margin-top: 45px;
      .field-content {
        display: flex;
        justify-content: space-between;

        @include breakpoint(440px) {
          flex-direction: column;
        }

        .pre-quantity {
          max-width: 200px;
          font-size: 14px;
          @include breakpoint(440px) {
            margin-bottom: 20px;
          }
        }

        .commerce-quantity-plusminus-link {
          a {
            text-decoration: none;
            background-color: white;
            border-radius: 28px;
            font-size: 21px;
            padding: 7px 16px;
            border: 2px solid #d6d6d6;
            margin-right: 15px;
            color: #63636d;
            cursor: pointer;
          }

          a:visited {
            color: #63636d;
          }
        }
        .commerce-quantity-plusminus-link-increase {
          a {
            margin-right: 0;
            margin-left: 15px;
            padding: 7px 13px;
            color: #63636d;
            cursor: pointer;
          }

          a:visited {
            color: #63636d;
          }
        }
        input {
          border: none;
          background-color: inherit;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          cursor: not-allowed;
          pointer-events: none;
          max-width: 45px;
        }
      }
    }

    .field--type-commerce-price {
      clear: both;
    }

    .views-field-title {
      margin-left: 20px;
      font-weight: bold;
      font-size: 14px;
      padding-right: 10px;
      @include breakpoint(450px) {
        margin-left: 0;
      }
    }

    .views-field-commerce-unit-price {
      margin-left: auto;
      font-weight: bold;
      @include breakpoint(450px) {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
}

.form-item-customer-profile-billing-field-invoice-check-und {
  margin-top: 20px;
  
  input {
      z-index: 99;
  }
  
  .checkmark {
      z-index: -99999;
  }
}

.vat-helper {
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: -10px;
}