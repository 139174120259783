@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Montserrat:300,300i,400,400i,700,700i");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.tabs a {
  background-color: #100a0f;
  color: #fbc31e;
  font-weight: 300; }
  .tabs a.active, .tabs a:hover, .tabs a:focus {
    background-color: #fbc31e;
    color: #100a0f; }

textarea,
input,
button,
a {
  outline: none; }

.l-main .node ul,
.l-main .block ul,
.l-content-2 .node ul,
.l-content-2 .block ul {
  list-style: disc;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 2em; }
  .l-main .node ul li,
  .l-main .block ul li,
  .l-content-2 .node ul li,
  .l-content-2 .block ul li {
    margin-bottom: 0.1em; }
    .l-main .node ul li:last-child,
    .l-main .block ul li:last-child,
    .l-content-2 .node ul li:last-child,
    .l-content-2 .block ul li:last-child {
      margin-bottom: 0; }

.l-main .node ol,
.l-main .block ol,
.l-content-2 .node ol,
.l-content-2 .block ol {
  list-style: decimal;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 2em; }
  .l-main .node ol li,
  .l-main .block ol li,
  .l-content-2 .node ol li,
  .l-content-2 .block ol li {
    margin-bottom: 0.1em; }
    .l-main .node ol li:last-child,
    .l-main .block ol li:last-child,
    .l-content-2 .node ol li:last-child,
    .l-content-2 .block ol li:last-child {
      margin-bottom: 0; }

.menu {
  list-style: none;
  list-style-image: none; }
  .menu .collapsed,
  .menu .leaf {
    list-style: none;
    list-style-image: none; }

strong {
  font-weight: 700; }

u {
  text-decoration: line-through; }

em {
  font-style: italic; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  position: relative; }

.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  *display: inline; }

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  *display: inline; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  *display: inline; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0); }

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
  position: absolute; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s; }

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer; }
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu .slicknav_nav {
  padding-top: 40px; }

.slicknav_menu {
  clear: both;
  background: #ffffff; }
  .slicknav_menu a {
    color: #100a0f;
    text-decoration: none; }
  .slicknav_menu .slicknav_btn {
    position: static;
    display: block;
    vertical-align: middle;
    float: none;
    padding: 0;
    line-height: 0;
    cursor: pointer; }
    .slicknav_menu .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
      margin-top: 0; }
    .slicknav_menu .slicknav_btn .slicknav_icon {
      float: none;
      display: none; }
  .slicknav_menu .slicknav_nav {
    /* margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;*/
    height: 100vh;
    overflow-y: auto; }
    .slicknav_menu .slicknav_nav .activew-circle {
      right: 50%;
      top: 18px;
      transform: translate(58px); }
      @media (max-width: 1024px) {
        .slicknav_menu .slicknav_nav .activew-circle {
          right: 50% !important;
          top: 18px;
          transform: translateX(60px); } }
      @media (max-width: 570px) {
        .slicknav_menu .slicknav_nav .activew-circle {
          right: 50% !important;
          top: 18px;
          transform: translateX(60px); } }
    .slicknav_menu .slicknav_nav .slicknav_arrow {
      color: #100a0f;
      margin-left: 0.1em;
      font-size: 16px; }
    .slicknav_menu .slicknav_nav li {
      text-align: center; }
      .slicknav_menu .slicknav_nav li a, .slicknav_menu .slicknav_nav li span.nolink {
        display: block;
        color: #100a0f;
        position: relative;
        padding: 15px 0;
        font-size: 1.38888rem;
        text-align: center;
        font-weight: 300; }
        .slicknav_menu .slicknav_nav li a:last-child a, .slicknav_menu .slicknav_nav li span.nolink:last-child a {
          border-bottom: none; }
      .slicknav_menu .slicknav_nav li.slicknav_parent > a {
        padding-left: 0; }
        @media (max-width: 1100px) {
          .slicknav_menu .slicknav_nav li.slicknav_parent > a {
            padding-bottom: 0; } }
      .slicknav_menu .slicknav_nav li .slicknav_arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        width: 56px;
        display: block;
        line-height: 36px;
        vertical-align: middle;
        text-align: center; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    float: none;
    font-size: 0.88888rem;
    /*     margin-right: 0.8em;
        margin-bottom: -0.2em; */
    vertical-align: text-bottom;
    padding: 15px 18px;
    width: 88px;
    line-height: 1.5;
    background: none;
    color: #100a0f;
    cursor: pointer;
    position: absolute;
    right: 92px;
    bottom: 0; }

.selectboxit-container .selectboxit-option-anchor {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block; }

.selectboxit-container * {
  font-family: "Montserrat", sans-serif;
  font-size: 0.77777rem;
  font-weight: 300; }

.selectboxit-container .selectboxit-list {
  background-color: #efefef;
  border: none; }

.selectboxit-container .selectboxit-options {
  border-radius: 0;
  /* Set's the drop down options width to the same width as the drop down button */
  max-width: 440px;
  /* Set's the max-height property to only show a subset of the drop down items.
       If you do not set a max-height property, SelectBoxIt will dynamically
       position the dropdown (when opened) to make sure the drop down items are not
       displayed outside of the current window viewport.
    */
  max-height: 500px; }
  .selectboxit-container .selectboxit-options .selectboxit-option-anchor {
    border-radius: 0; }

.selectboxit-container .selectboxit {
  border-radius: 0;
  background: #efefef;
  border: none;
  float: none;
  height: 42px; }
  .selectboxit-container .selectboxit:hover, .selectboxit-container .selectboxit:focus {
    color: #100a0f;
    background: #efefef; }
  .selectboxit-container .selectboxit span, .selectboxit-container .selectboxit .selectboxit-options a {
    border-radius: 0;
    text-align: left;
    height: 42px;
    border: none;
    line-height: 42px;
    display: block;
    color: #100a0f;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 0.88888rem; }

.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  margin-top: -4px; }

.selectboxit .selectboxit-arrow-container .uxprodigy-select {
  border: solid #888888;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  width: 8px; }

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: #100a0f;
  background: #fbc31e; }

.selectboxit.selectboxit-focus {
  border: 1px solid #fbc31e; }

.full-size-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .full-size-img-node {
    min-height: 400px;
    height: 684px;
    position: relative;
    max-height: calc(100vh - 185px); }

.mCSB_inside > .mCSB_container {
  margin-right: 40px; }

.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }
  .mCSB_scrollTools .mCSB_draggerRail {
    background-color: #100a0f;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
    width: 8px; }
  .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: white;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: white;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
    width: 8px;
    border-radius: 0; }
  .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: white; }
  .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
  .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: white;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)"; }

.field_delimiter {
  margin-bottom: 100px;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 100px; }

.external-associate {
  padding: 12px 22px;
  color: #100a0f;
  font-size: 0.77777rem;
  text-align: center;
  text-decoration: none;
  background: #fbc31e;
  font-weight: 300;
  min-width: 168px;
  float: right; }
  @media (max-width: 550px) {
    .external-associate {
      float: none;
      width: 100%;
      margin-bottom: 20px; } }
  .external-associate-mobile {
    display: none; }

.use-ajax {
  text-decoration: none; }

.has-accordion-item .field--type-text {
  position: relative; }
  .has-accordion-item .field--type-text:hover, .has-accordion-item .field--type-text:focus {
    cursor: pointer; }
  .has-accordion-item .field--type-text:after {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -3px;
    content: "";
    border-top: 6px solid #100a0f;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent; }
  .has-accordion-item .field--type-text.close-accit:after {
    border-top: none;
    border-bottom: 6px solid #100a0f;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent; }

.has-accordion-item .field--type-text-long {
  display: none; }

.grid-wrapper {
  position: relative; }

.slicknav_menu .slicknav_menutxt {
  background-color: #f3f3f3; }

.pgwloaderModal img {
  max-width: 200px; }

.pgwloaderModal .pm-body {
  background: transparent; }

.pgwloaderModal .pm-content {
  text-align: center; }

.custom-title-box {
  width: 23.5%;
  padding: 52px 32px;
  background-color: #fbc31e;
  height: 376px;
  min-width: 367px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%); }
  .custom-title-box:after {
    content: "";
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -310px -1244px;
    width: 36px;
    height: 36px;
    position: absolute;
    right: 32px;
    bottom: 32px; }
  .custom-title-box .title-text {
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.2em;
    font-size: 1.66666rem;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    color: #000000; }
  .custom-title-box .number {
    margin-bottom: 32px;
    width: 70px;
    height: 45px;
    background-size: contain; }
    .custom-title-box .number-01 {
      background: url("../images/chapterwhite/chapter-01.svg") 0 0 no-repeat; }
    .custom-title-box .number-02 {
      background: url("../images/chapterwhite/chapter-02.svg") 0 0 no-repeat; }
    .custom-title-box .number-03 {
      background: url("../images/chapterwhite/chapter-03.svg") 0 0 no-repeat; }
    .custom-title-box .number-04 {
      background: url("../images/chapterwhite/chapter-04.svg") 0 0 no-repeat; }
    .custom-title-box .number-05 {
      background: url("../images/chapterwhite/chapter-05.svg") 0 0 no-repeat; }
  .custom-title-box-nav {
    background-color: #100a0f;
    transform: none;
    position: relative; }
    .custom-title-box-nav .title-text {
      text-transform: uppercase; }
    .custom-title-box-nav:after {
      content: "";
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -270px -1244px;
      width: 38px;
      height: 38px; }

.close-btn-wrapper {
  text-align: right;
  margin: 50px 0 80px; }

.activew-bubble {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .activew-bubble img {
    max-width: 206px;
    margin-left: 8rem; }

.activew-circle {
  width: 22px !important;
  height: 22px !important;
  text-align: center;
  line-height: 22px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #fbc31e;
  font-weight: 700;
  font-size: 0.66666rem !important;
  display: block;
  position: absolute !important;
  z-index: 1;
  top: 22px;
  right: 22px;
  padding: 0 !important; }

.training-ulitem-inside {
  position: relative;
  z-index: 2; }

.mobile-only {
  display: none; }
  .mobile-only.universal-buttons-wrap .deactivated-price {
    border: none;
    border-left: 1px solid #c0c0c0; }
  @media (max-width: 450px) {
    .mobile-only.universal-buttons-wrap .field--name-field-early-product-ref .activated-price {
      width: 100%; } }

.universal-buttons-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #f9f9f9;
  text-align: center; }
  @media (max-width: 1024px) {
    .universal-buttons-wrap {
      z-index: 2147483642; } }
  .universal-buttons-wrap .field--name-field-early-bird-price .deactivated-price,
  .universal-buttons-wrap .field--name-field-standard-price .deactivated-price {
    border: none; }

.close-btn {
  width: 105px;
  display: inline-block; }
  .close-btn.close-btn-nots {
    color: #000000;
    text-decoration: none; }
    .close-btn.close-btn-nots:active, .close-btn.close-btn-nots:visited, .close-btn.close-btn-nots:hover {
      color: #000000; }
  .close-btn:hover, .close-btn:focus {
    cursor: pointer; }
  .close-btn .close-md {
    display: flex;
    font-weight: 400;
    font-size: 0.88888rem;
    vertical-align: middle;
    line-height: 40px; }
    .close-btn .close-md:after {
      content: "";
      display: block;
      clear: both; }
  .close-btn .x-close-modal {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -226px -1244px;
    width: 42px;
    height: 40px;
    display: block;
    float: right; }
  .close-btn .x-close-text {
    margin-right: 20px;
    vertical-align: middle; }

.block__title {
  font-family: "Libre Baskerville", serif;
  font-size: 1.55555rem;
  font-weight: 400;
  margin-bottom: 40px; }

.pgwModal .pm-content {
  padding: 0;
  border-radius: none;
  background: none; }
  .pgwModal .pm-content .messages {
    font-size: 0.77777rem;
    margin-bottom: 20px; }

.pgwModal .close-md {
  display: flex;
  font-weight: 400;
  font-size: 0.88888rem;
  vertical-align: middle;
  line-height: 40px; }
  .pgwModal .close-md:after {
    content: "";
    display: block;
    clear: both; }

.pgwModal .x-close-modal {
  background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
  background-position: -226px -1244px;
  width: 42px;
  height: 40px;
  display: block;
  float: right; }

.pgwModal .x-close-text {
  margin-right: 20px;
  vertical-align: middle; }

.pgwModal .pm-body {
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  padding: 0 70px; }

.pgwModal .pm-close {
  top: 20px;
  right: 20px; }

#webform_drawer-1 {
  padding: 70px 0;
  max-width: 700px;
  margin: 0 auto; }
  #webform_drawer-1:after {
    content: "";
    display: block;
    clear: both; }

#webform_drawer-2 {
  padding: 70px 0;
  max-width: 460px;
  margin: 0 auto; }
  #webform_drawer-2:after {
    content: "";
    display: block;
    clear: both; }

#webform_drawer-36 {
  padding: 70px 0;
  max-width: 460px;
  margin: 0 auto; }
  #webform_drawer-36:after {
    content: "";
    display: block;
    clear: both; }

.metrics-wrapper:after {
  content: "";
  display: block;
  clear: both; }

.metrics-wrapper .metrics {
  float: left;
  width: 22.72727%;
  margin-right: 3.0303%;
  position: relative;
  display: flex;
  flex-direction: column; }
  .metrics-wrapper .metrics-desc {
    font-weight: 400;
    font-size: 0.66666rem;
    color: #757575; }
  .metrics-wrapper .metrics-extra {
    font-size: 4.44444rem;
    font-weight: 300;
    color: #fbc31e;
    line-height: 1.1;
    margin-left: 5px; }
  .metrics-wrapper .metrics:last-child {
    margin-right: 0; }
  .metrics-wrapper .metrics-label {
    font-weight: 400;
    font-size: 0.66666rem; }
  .metrics-wrapper .metrics-animate {
    position: relative;
    font-size: 4.44444rem;
    font-weight: 300;
    color: #fbc31e;
    line-height: 1.1; }
  .metrics-wrapper .metrics .training-animate {
    float: left; }

.webform-confirmation {
  text-align: center;
  min-height: 628px;
  position: relative; }
  .webform-confirmation .webconf {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%); }
  .webform-confirmation .tickbox {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: 0px -1082px;
    width: 224px;
    height: 224px;
    margin: 0 auto; }
  .webform-confirmation .webform-success-txt {
    margin-top: 40px;
    text-transform: uppercase; }

.webform-client-form .form-actions,
.user-login .form-actions {
  margin-bottom: 0; }

.webform-client-form .form-item,
.user-login .form-item {
  margin-bottom: 20px; }

.webform-client-form label,
.user-login label {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.66666rem;
  margin-bottom: 3px;
  font-weight: 300;
  text-transform: uppercase; }

.webform-client-form input,
.webform-client-form textarea,
.webform-client-form select,
.user-login input,
.user-login textarea,
.user-login select {
  width: 100%;
  border: none;
  box-shadow: none;
  background: #efefef;
  padding: 12px;
  font-size: 0.88888rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1.13;
  font-weight: 300; }
  .webform-client-form input[type="submit"],
  .webform-client-form textarea[type="submit"],
  .webform-client-form select[type="submit"],
  .user-login input[type="submit"],
  .user-login textarea[type="submit"],
  .user-login select[type="submit"] {
    background: #fbc31e;
    color: #100a0f;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 0.77777rem;
    padding: 13px; }
    .webform-client-form input[type="submit"]:hover, .webform-client-form input[type="submit"]:focus,
    .webform-client-form textarea[type="submit"]:hover,
    .webform-client-form textarea[type="submit"]:focus,
    .webform-client-form select[type="submit"]:hover,
    .webform-client-form select[type="submit"]:focus,
    .user-login input[type="submit"]:hover,
    .user-login input[type="submit"]:focus,
    .user-login textarea[type="submit"]:hover,
    .user-login textarea[type="submit"]:focus,
    .user-login select[type="submit"]:hover,
    .user-login select[type="submit"]:focus {
      cursor: pointer; }

.webform-client-form textarea,
.user-login textarea {
  line-height: 1.4; }

.slick-arrow {
  text-align: center;
  width: 40px; }
  .slick-arrow .carousel-arrow-left, .slick-arrow .carousel-arrow-right {
    border: solid #fbc31e;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 6px; }
  .slick-arrow .carousel-arrow-left {
    transform: rotate(135deg); }
  .slick-arrow .carousel-arrow-right {
    transform: rotate(-45deg); }

.arrow-wrapper {
  z-index: 30; }
  .arrow-wrapper:hover, .arrow-wrapper:focus {
    cursor: pointer; }
  .arrow-wrapper--right {
    position: absolute;
    top: 60px;
    right: 24%; }
  .arrow-wrapper--left {
    position: absolute;
    top: 60px;
    left: 24%; }

.applies-for {
  font-size: 0.77777rem;
  text-align: center;
  margin-top: 5px;
  width: 214px;
  padding: 0 8px; }

.button a {
  display: inline-block;
  padding: 22px;
  color: #100a0f;
  font-size: 0.77777rem;
  text-align: center;
  text-decoration: none;
  font-weight: 300;
  min-width: 225px;
  background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
  background-size: 200% 100%;
  background-position: 100% 0;
  background-repeat: no-repeat;
  transition: 0.3s background-position ease-out; }
  .button a:hover, .button a.active {
    background-position: 0 0;
    color: #fbc31e; }

.button.request_quote a {
  font-weight: 400; }

.field--name-field-pw-product-reference,
.field--name-field-early-product-ref,
.field--name-field-early-bird-price,
.field--name-field-standard-price {
  display: inline-block; }
  @media (max-width: 450px) {
    .field--name-field-pw-product-reference,
    .field--name-field-early-product-ref,
    .field--name-field-early-bird-price,
    .field--name-field-standard-price {
      display: block;
      width: 100%; } }
  .field--name-field-pw-product-reference .product-active,
  .field--name-field-pw-product-reference .activated-price,
  .field--name-field-early-product-ref .product-active,
  .field--name-field-early-product-ref .activated-price,
  .field--name-field-early-bird-price .product-active,
  .field--name-field-early-bird-price .activated-price,
  .field--name-field-standard-price .product-active,
  .field--name-field-standard-price .activated-price {
    padding: 16px 22px;
    color: #100a0f;
    font-size: 0.77777rem;
    width: 225px;
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    font-weight: 400;
    transition: 0.3s background-position ease-out; }
    @media (max-width: 450px) {
      .field--name-field-pw-product-reference .product-active,
      .field--name-field-pw-product-reference .activated-price,
      .field--name-field-early-product-ref .product-active,
      .field--name-field-early-product-ref .activated-price,
      .field--name-field-early-bird-price .product-active,
      .field--name-field-early-bird-price .activated-price,
      .field--name-field-standard-price .product-active,
      .field--name-field-standard-price .activated-price {
        width: 100%; }
        .field--name-field-pw-product-reference .product-active a,
        .field--name-field-pw-product-reference .activated-price a,
        .field--name-field-early-product-ref .product-active a,
        .field--name-field-early-product-ref .activated-price a,
        .field--name-field-early-bird-price .product-active a,
        .field--name-field-early-bird-price .activated-price a,
        .field--name-field-standard-price .product-active a,
        .field--name-field-standard-price .activated-price a {
          width: 100%; } }
    .field--name-field-pw-product-reference .product-active:hover, .field--name-field-pw-product-reference .product-active:focus,
    .field--name-field-pw-product-reference .activated-price:hover,
    .field--name-field-pw-product-reference .activated-price:focus,
    .field--name-field-early-product-ref .product-active:hover,
    .field--name-field-early-product-ref .product-active:focus,
    .field--name-field-early-product-ref .activated-price:hover,
    .field--name-field-early-product-ref .activated-price:focus,
    .field--name-field-early-bird-price .product-active:hover,
    .field--name-field-early-bird-price .product-active:focus,
    .field--name-field-early-bird-price .activated-price:hover,
    .field--name-field-early-bird-price .activated-price:focus,
    .field--name-field-standard-price .product-active:hover,
    .field--name-field-standard-price .product-active:focus,
    .field--name-field-standard-price .activated-price:hover,
    .field--name-field-standard-price .activated-price:focus {
      background-position: 0 0;
      color: #fbc31e; }
  .field--name-field-pw-product-reference .product-disabled,
  .field--name-field-pw-product-reference .product-active,
  .field--name-field-early-product-ref .product-disabled,
  .field--name-field-early-product-ref .product-active,
  .field--name-field-early-bird-price .product-disabled,
  .field--name-field-early-bird-price .product-active,
  .field--name-field-standard-price .product-disabled,
  .field--name-field-standard-price .product-active {
    padding: 0 !important; }
    .field--name-field-pw-product-reference .product-disabled a,
    .field--name-field-pw-product-reference .product-active a,
    .field--name-field-early-product-ref .product-disabled a,
    .field--name-field-early-product-ref .product-active a,
    .field--name-field-early-bird-price .product-disabled a,
    .field--name-field-early-bird-price .product-active a,
    .field--name-field-standard-price .product-disabled a,
    .field--name-field-standard-price .product-active a {
      background-image: none;
      transition: none; }
  .field--name-field-pw-product-reference .product-active,
  .field--name-field-early-product-ref .product-active,
  .field--name-field-early-bird-price .product-active,
  .field--name-field-standard-price .product-active {
    margin-bottom: 25px; }
  .field--name-field-pw-product-reference .product-disabled,
  .field--name-field-pw-product-reference .deactivated-price,
  .field--name-field-early-product-ref .product-disabled,
  .field--name-field-early-product-ref .deactivated-price,
  .field--name-field-early-bird-price .product-disabled,
  .field--name-field-early-bird-price .deactivated-price,
  .field--name-field-standard-price .product-disabled,
  .field--name-field-standard-price .deactivated-price {
    padding: 16px 22px;
    color: #c0c0c0;
    font-size: 0.77777rem;
    width: 225px;
    text-align: center;
    border: 1px solid #c0c0c0;
    text-decoration: none;
    background: transparent;
    font-weight: 400; }
    @media (max-width: 450px) {
      .field--name-field-pw-product-reference .product-disabled,
      .field--name-field-pw-product-reference .deactivated-price,
      .field--name-field-early-product-ref .product-disabled,
      .field--name-field-early-product-ref .deactivated-price,
      .field--name-field-early-bird-price .product-disabled,
      .field--name-field-early-bird-price .deactivated-price,
      .field--name-field-standard-price .product-disabled,
      .field--name-field-standard-price .deactivated-price {
        width: 100%; } }
    .field--name-field-pw-product-reference .product-disabled .price,
    .field--name-field-pw-product-reference .deactivated-price .price,
    .field--name-field-early-product-ref .product-disabled .price,
    .field--name-field-early-product-ref .deactivated-price .price,
    .field--name-field-early-bird-price .product-disabled .price,
    .field--name-field-early-bird-price .deactivated-price .price,
    .field--name-field-standard-price .product-disabled .price,
    .field--name-field-standard-price .deactivated-price .price {
      margin-right: 5px; }
    .field--name-field-pw-product-reference .product-disabled a,
    .field--name-field-pw-product-reference .deactivated-price a,
    .field--name-field-early-product-ref .product-disabled a,
    .field--name-field-early-product-ref .deactivated-price a,
    .field--name-field-early-bird-price .product-disabled a,
    .field--name-field-early-bird-price .deactivated-price a,
    .field--name-field-standard-price .product-disabled a,
    .field--name-field-standard-price .deactivated-price a {
      padding: 16px 20px;
      color: #c0c0c0;
      font-size: 0.77777rem;
      width: 225px;
      text-align: center;
      text-decoration: none;
      background: transparent;
      font-weight: 400; }
      .field--name-field-pw-product-reference .product-disabled a:hover,
      .field--name-field-pw-product-reference .deactivated-price a:hover,
      .field--name-field-early-product-ref .product-disabled a:hover,
      .field--name-field-early-product-ref .deactivated-price a:hover,
      .field--name-field-early-bird-price .product-disabled a:hover,
      .field--name-field-early-bird-price .deactivated-price a:hover,
      .field--name-field-standard-price .product-disabled a:hover,
      .field--name-field-standard-price .deactivated-price a:hover {
        cursor: default; }

.read-more-black a:after {
  background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
  background-position: -1567px -1082px;
  width: 37px;
  height: 12px; }

.read-more-black a:hover, .read-more-black a:focus {
  color: #100a0f; }

.read-more-yellow a:after {
  background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
  background-position: -1489px -1082px;
  width: 37px;
  height: 12px; }

.read-more-arrow a:after {
  content: "";
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle; }

.read-more.rmore.read-more-black a {
  border: 1px solid #100a0f; }

.read-more.rmore.read-more-yellow a {
  border: 1px solid #fbc31e; }

.read-more.rmore a {
  background-color: none;
  transition: 0.3s background-position ease-out;
  background-size: 204% 100%;
  background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
  background-position: 100% 0; }
  .read-more.rmore a:hover {
    background-position: 0 0;
    border-color: #fbc31e; }
    .read-more.rmore a:hover.read-more-yellow a {
      color: #100a0f; }
      .read-more.rmore a:hover.read-more-yellow a:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1567px -1082px;
        width: 37px;
        height: 12px; }

#ux-design-section:before,
#ux-evaluation-section:before,
#ux-research-section:before {
  display: block;
  content: "";
  margin-top: -100px;
  height: 100px;
  visibility: hidden; }

.vertical-number {
  color: #747474;
  width: 6.5%;
  float: left;
  margin-right: 2%;
  position: relative;
  text-align: center; }
  .vertical-number .yellow-title {
    position: relative;
    /*   position: absolute;
    top: 0;
    left: 0;*/ }
    .vertical-number .yellow-title .text {
      font-weight: 400;
      letter-spacing: 3.2px;
      position: relative;
      left: 35%;
      top: 180px;
      transform: rotate(-90deg) translateX(-50%);
      transform-origin: left;
      min-width: 200px;
      margin-bottom: -25px;
      text-align: right; }
    .vertical-number .yellow-title .number {
      width: 70px;
      height: 45px; }
      .vertical-number .yellow-title .number-01 {
        background: url("../images/chapter-01.svg") 0 0 no-repeat; }
      .vertical-number .yellow-title .number-02 {
        background: url("../images/chapter-02.svg") 0 0 no-repeat; }
      .vertical-number .yellow-title .number-03 {
        background: url("../images/chapter-03.svg") 0 0 no-repeat; }
      .vertical-number .yellow-title .number-04 {
        background: url("../images/chapter-04.svg") 0 0 no-repeat; }
      .vertical-number .yellow-title .number-05 {
        background: url("../images/chapter-05.svg") 0 0 no-repeat; }
      .vertical-number .yellow-title .number-06 {
        background: url("../images/chapter-06.svg?1231") 0 0 no-repeat;
        background-size: contain; }

.group-social-media {
  margin-top: 40px; }
  .group-social-media:after {
    content: "";
    display: block;
    clear: both; }
  .group-social-media .field--type-link-field {
    margin-right: 10px;
    float: left; }
    .group-social-media .field--type-link-field:last-child {
      margin-right: 0; }
  .group-social-media a {
    float: left;
    overflow: hidden;
    text-indent: -9999px; }
  .group-social-media .field--name-field-linkedin a {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1200px -1122px;
    width: 32px;
    height: 32px; }
  .group-social-media .field--name-field-dribbble a {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1234px -1122px;
    width: 32px;
    height: 32px; }
  .group-social-media .field--name-field--facebook a {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1268px -1122px;
    width: 32px;
    height: 32px; }
  .group-social-media .field--name-field-twitter a {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1302px -1122px;
    width: 31px;
    height: 32px; }

#sliding-popup .popup-content {
  display: block;
  padding: 20px 0 16px;
  max-width: 87%; }
  #sliding-popup .popup-content:after {
    content: "";
    display: block;
    clear: both; }
  #sliding-popup .popup-content #popup-text {
    margin: 0;
    max-width: 57%;
    margin-right: 3%;
    font-family: "Libre Baskerville", serif; }
    @media (max-width: 400px) {
      #sliding-popup .popup-content #popup-text h2 {
        font-size: 14px; } }
    #sliding-popup .popup-content #popup-text p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      font-family: "Montserrat", sans-serif; }
      @media (max-width: 400px) {
        #sliding-popup .popup-content #popup-text p {
          font-size: 12px; } }
  #sliding-popup .popup-content #popup-buttons {
    margin: 7px 0 0; }
    #sliding-popup .popup-content #popup-buttons button {
      border-radius: 0;
      border: none;
      background: #ffffff;
      color: #000000 !important;
      padding: 10px 30px;
      display: inline-block;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin-top: 0;
      box-shadow: none;
      text-shadow: none;
      transition: all 0.7s; }
      #sliding-popup .popup-content #popup-buttons button:hover {
        background: #000000;
        color: #ffffff !important; }
    #sliding-popup .popup-content #popup-buttons .agree-button {
      margin-right: 10px; }

.eu-cookie-compliance-more-button {
  font-size: 16px; }

@media (max-width: 800px) {
  #sliding-popup .popup-content {
    padding: 13px 0; }
    #sliding-popup .popup-content #popup-buttons {
      float: none;
      max-width: 100%;
      margin-top: 15px; }
      #sliding-popup .popup-content #popup-buttons button {
        padding: 7px 20px; }
    #sliding-popup .popup-content #popup-text {
      float: none;
      max-width: 100%; } }

.l-region--widescreen {
  position: relative; }

#block-views-frontpage-featured-block {
  min-height: 200px;
  box-shadow: 0 0 30px rgba(1, 1, 1, 0.07);
  background-color: #ffffff;
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1057px;
  position: relative;
  margin-top: -460px;
  margin-bottom: 180px; }
  @media (max-width: 1080px) {
    #block-views-frontpage-featured-block {
      position: relative;
      top: unset;
      left: unset;
      bottom: unset;
      transform: none;
      width: 95%;
      margin: 0 auto;
      margin-top: -40px;
      margin-bottom: 60px; } }
  #block-views-frontpage-featured-block .box {
    width: 100%;
    display: flex;
    align-items: center; }
    @media (max-width: 700px) {
      #block-views-frontpage-featured-block .box {
        flex-direction: column; } }
  #block-views-frontpage-featured-block .custom-title {
    color: #100a0f;
    font-family: "Libre Baskerville";
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 15px; }
    @media (max-width: 1080px) {
      #block-views-frontpage-featured-block .custom-title {
        line-height: 35px; } }
    @media (max-width: 700px) {
      #block-views-frontpage-featured-block .custom-title {
        font-size: 24px; } }
  #block-views-frontpage-featured-block .field--name-body {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 300;
    line-height: 25px; }
    @media (max-width: 1080px) {
      #block-views-frontpage-featured-block .field--name-body br {
        display: none; } }
  #block-views-frontpage-featured-block .required-fields {
    width: 61.36364%;
    padding: 50px 0 50px 50px; }
    @media (max-width: 1080px) {
      #block-views-frontpage-featured-block .required-fields {
        padding: 25px 0 25px 25px; } }
    @media (max-width: 700px) {
      #block-views-frontpage-featured-block .required-fields {
        width: 100%;
        padding: 25px; } }
  #block-views-frontpage-featured-block .button {
    margin-left: auto;
    margin-right: 50px;
    margin-left: 50px; }
    @media (max-width: 1080px) {
      #block-views-frontpage-featured-block .button {
        margin-right: 25px; } }
    @media (max-width: 700px) {
      #block-views-frontpage-featured-block .button {
        margin-right: auto;
        margin-bottom: 25px;
        width: 100%;
        padding: 0 25px; } }
  #block-views-frontpage-featured-block .field--name-field-cta a {
    color: #100a0f;
    font-size: 14px;
    padding: 20px 40px;
    border: 1px solid #e1e1e1;
    text-decoration: none; }
    @media (max-width: 780px) {
      #block-views-frontpage-featured-block .field--name-field-cta a {
        padding: 20px 0; } }
    @media (max-width: 700px) {
      #block-views-frontpage-featured-block .field--name-field-cta a {
        width: 100%;
        min-width: 100%; } }
    #block-views-frontpage-featured-block .field--name-field-cta a:after {
      content: "";
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1567px -1082px;
      width: 37px;
      height: 12px;
      width: 37px;
      height: 12px; }
    #block-views-frontpage-featured-block .field--name-field-cta a:visited {
      color: #100a0f; }

.terms-text {
  font-size: 14px; }
  .terms-text a {
    position: relative;
    z-index: 99999; }
  @media (max-width: 700px) {
    .terms-text {
      font-size: 12px; } }
  @media (max-width: 425px) {
    .terms-text {
      width: 200px;
      display: inline-block;
      text-align: left;
      margin-left: 40px; } }

.activew-bubble {
  display: none; }

#sliding-popup {
  box-shadow: 0 0 30px rgba(1, 1, 1, 0.07); }
  #sliding-popup > div {
    padding: 20px;
    display: flex; }
    #sliding-popup > div:before {
      content: "";
      width: 56px;
      height: 68px;
      background: url("/sites/all/themes/theuxprodigy/images/cookies.png");
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 30px; }
  #sliding-popup.sliding-popup-top {
    top: 100px !important;
    max-width: 500px !important;
    width: 100% !important;
    right: 20px !important;
    left: auto !important;
    background: white; }
    @media (max-width: 540px) {
      #sliding-popup.sliding-popup-top {
        right: 0 !important; } }
  #sliding-popup .popup-content {
    display: flex;
    flex-direction: column;
    padding: 0 !important; }
    #sliding-popup .popup-content #popup-text {
      color: black;
      max-width: 100% !important;
      line-height: 18px; }
      @media (max-width: 600px) {
        #sliding-popup .popup-content #popup-text br {
          display: none; } }
    #sliding-popup .popup-content #popup-buttons {
      width: 100%;
      float: none;
      display: flex;
      max-width: 100% !important;
      margin-top: 30px; }
      #sliding-popup .popup-content #popup-buttons button {
        padding: 0; }
        #sliding-popup .popup-content #popup-buttons button.agree-button {
          font-weight: bold; }
        #sliding-popup .popup-content #popup-buttons button:hover {
          background: none !important;
          color: black !important; }

#block-gtweaks-what-we-do .block-title-top {
  font-size: 2.5rem;
  margin-bottom: 40px;
  max-width: 760px; }
  @media (max-width: 1024px) {
    #block-gtweaks-what-we-do .block-title-top {
      font-size: 1.815rem; } }

@media (max-width: 700px) {
  .block--gtweaks-front-main-banner .block__content {
    min-height: 680px !important;
    max-height: unset !important; } }

.newsletter-popup {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 999;
  width: 750px;
  height: 400px; }
  .newsletter-popup .left-column {
    width: calc(100% - 377px);
    position: relative; }
    .newsletter-popup .left-column h2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 20px; }
    .newsletter-popup .left-column p {
      font-size: 16px; }
    .newsletter-popup .left-column .continue-browsing {
      font-size: 14px;
      margin-top: 40px;
      border: 1px solid #e1e1e1;
      padding: 20px 12px; }
      .newsletter-popup .left-column .continue-browsing:hover {
        cursor: pointer; }
  .newsletter-popup .popup-footer {
    position: absolute;
    bottom: 0;
    left: 37%;
    transform: translateX(-50%);
    font-size: 12px; }
  .newsletter-popup .right-column {
    position: relative;
    width: 377px; }
  .newsletter-popup .bg-image {
    background: url(/sites/all/themes/theuxprodigy/images/newsletter.png);
    background-repeat: repeat;
    height: 463px;
    width: 377px;
    position: absolute;
    background-repeat: no-repeat;
    bottom: -20px;
    right: -20px; }
  .newsletter-popup .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 230px; }
  @media (max-width: 670px) {
    .newsletter-popup {
      transform: translate(-50%, -50%) scale(0.75); } }

body.popup-open {
  overflow: hidden; }
  body.popup-open:after {
    content: "";
    background: rgba(0, 0, 0, 0.45);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998; }

.find-more-button {
  font-size: 14px; }

.pgwloaderModal img {
  margin: 0 auto; }

.node-type-plain-page table {
  width: 100%; }
  .node-type-plain-page table td, .node-type-plain-page table tr {
    border: 1px solid;
    padding: 10px; }

.node-type-plain-page u {
  text-decoration: none; }

@media screen and (max-width: 600px) {
  .field--name-field-caption {
    display: none; } }

.pgwModal .pm-body .pm-content {
  display: block !important; }

.node-type-accessibility *:focus {
  outline-style: solid !important;
  outline-color: #7D2B6D !important;
  outline-width: 2px !important; }

.node-type-accessibility #edit-submit:disabled {
  background: #8e8e8e; }
  @media (min-width: 1080px) {
    .node-type-accessibility #edit-submit:disabled:hover {
      cursor: url("../images/hov.png"), auto !important; } }
  @media (max-width: 1024px) {
    .node-type-accessibility #edit-submit:disabled {
      cursor: default; } }

.node-type-accessibility .error {
  border: 1px solid red !important; }

.node-type-accessibility .group-our-team {
  position: relative;
  z-index: 0; }

.node-type-accessibility .l-header-wrapper {
  background: transparent;
  box-shadow: 0px 3px 20px #00000019;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px); }

.node-type-accessibility .l-header {
  height: 100px;
  display: flex;
  align-items: center;
  max-width: 1640px;
  margin: 0 auto;
  z-index: 999;
  position: relative; }
  @media screen and (max-width: 1640px) {
    .node-type-accessibility .l-header {
      max-width: 98.2%;
      padding: 0 16px;
      margin: 0 auto; } }
  @media screen and (max-width: 600px) {
    .node-type-accessibility .l-header {
      height: 80px; } }
  @media screen and (max-width: 415px) {
    .node-type-accessibility .l-header {
      height: 60px; } }
  @media screen and (max-width: 1080px) {
    .node-type-accessibility .l-header .l-branding {
      min-width: 140px; } }
  @media screen and (max-width: 600px) {
    .node-type-accessibility .l-header .l-branding {
      min-width: 130px; } }
  .node-type-accessibility .l-header .l-region--header {
    position: relative;
    display: flex;
    justify-content: flex-end; }
    .node-type-accessibility .l-header .l-region--header ul {
      display: flex; }
      @media screen and (max-width: 1552px) {
        .node-type-accessibility .l-header .l-region--header ul {
          margin-right: -18px; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .l-header .l-region--header ul {
          margin-right: 0; } }
      .node-type-accessibility .l-header .l-region--header ul > li {
        display: flex;
        align-items: center;
        margin-right: 54px; }
        @media screen and (max-width: 1366px) {
          .node-type-accessibility .l-header .l-region--header ul > li {
            margin-right: 20px; } }
        .node-type-accessibility .l-header .l-region--header ul > li a {
          font-size: 16px; }
          @media screen and (max-width: 1250px) {
            .node-type-accessibility .l-header .l-region--header ul > li a {
              margin-right: 25px; } }
        @media screen and (max-width: 980px) {
          .node-type-accessibility .l-header .l-region--header ul > li {
            visibility: hidden; } }
        .node-type-accessibility .l-header .l-region--header ul > li:last-child {
          margin-right: 0; }
        .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
          color: white;
          background: #0c4f8e;
          width: 344px;
          height: 59px;
          font-size: 16px;
          border-radius: 8px;
          line-height: 59px;
          text-align: center;
          cursor: pointer; }
          @media screen and (max-width: 1366px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              width: 310px;
              height: 50px;
              line-height: 50px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              visibility: visible;
              margin-right: -80px; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              margin-right: -89px;
              font-size: 12px;
              width: 265px;
              height: 48px;
              line-height: 49px; } }
          @media screen and (max-width: 480px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              margin-right: -98px;
              font-size: 11px;
              line-height: 48px;
              width: 228px;
              height: 45px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              width: 182px;
              height: 35px;
              font-size: 9px;
              line-height: 36px; } }
          @media screen and (max-width: 359px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              width: 120px;
              height: auto;
              font-size: 9px;
              line-height: 14px;
              padding: 8px 10px; } }
        .node-type-accessibility .l-header .l-region--header ul > li a {
          text-decoration: none;
          color: black;
          font-weight: bold;
          margin: 0 auto; }

.node-type-accessibility .l-main {
  max-width: 100%; }
  @media (max-width: 1600px) {
    .node-type-accessibility .l-main {
      max-width: 100%;
      overflow-x: hidden;
      margin: 0; } }

.node-type-accessibility .node--accessibility {
  scroll-behavior: smooth; }
  .node-type-accessibility .node--accessibility .field-collection-view {
    margin: 0; }
  @media screen and (max-width: 1080px) {
    .node-type-accessibility .node--accessibility .node__content {
      width: 100%;
      margin: 0 auto; } }
  .node-type-accessibility .node--accessibility h1,
  .node-type-accessibility .node--accessibility h2,
  .node-type-accessibility .node--accessibility h3,
  .node-type-accessibility .node--accessibility h4,
  .node-type-accessibility .node--accessibility p,
  .node-type-accessibility .node--accessibility a {
    font-family: "Montserrat", "Arial", sans-serif; }
  .node-type-accessibility .node--accessibility p {
    font-size: 18px;
    font-weight: normal; }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility p {
        font-size: 14px; } }
  .node-type-accessibility .node--accessibility h1,
  .node-type-accessibility .node--accessibility h2 {
    font-size: 46px;
    font-weight: bold;
    line-height: 60px; }
    @media screen and (max-width: 1820px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 44px;
        line-height: 58px; } }
    @media screen and (max-width: 1720px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 42px;
        line-height: 56px; } }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 40px;
        line-height: 52px; } }
    @media screen and (max-width: 1500px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 36px;
        line-height: 50px; } }
    @media screen and (max-width: 1358px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 34px;
        line-height: 48px; } }
    @media screen and (max-width: 1200px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 32px;
        line-height: 46px; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 30px;
        line-height: 44px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 28px;
        line-height: 42px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 26px;
        line-height: 40px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 22px;
        line-height: 30px; } }
    @media screen and (max-width: 360px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 20px;
        line-height: 28px; } }
  .node-type-accessibility .node--accessibility h3 {
    font-size: 18px;
    font-weight: bold; }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility h3 {
        font-size: 16px; } }
  .node-type-accessibility .node--accessibility h4 {
    font-size: 16px;
    font-weight: bold; }
  .node-type-accessibility .node--accessibility img {
    display: block; }
  .node-type-accessibility .node--accessibility .blue-text {
    color: #0c4f8e; }
  .node-type-accessibility .node--accessibility .field-collection-container.clearfix {
    margin-bottom: 0; }
  .node-type-accessibility .node--accessibility .group-hero-section {
    background-color: #e7eded;
    position: relative; }
    .node-type-accessibility .node--accessibility .group-hero-section::before {
      content: "";
      position: absolute;
      background: url("../images/sprites/big-shape.png") no-repeat;
      top: 24px;
      right: 0;
      background-size: cover;
      width: 100%;
      height: 910px;
      background-position: center; }
      @media screen and (max-width: 2652px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          width: 98%;
          top: 0; } }
      @media screen and (max-width: 1720px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          width: 100%;
          top: -100px; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          right: -79px;
          height: 602px;
          top: -20px; } }
      @media screen and (max-width: 880px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          top: 100px;
          right: 0;
          height: 910px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          top: -33px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          background: url("../images/sprites/small-shape.png") center no-repeat;
          top: -186px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          background: url("../images/sprites/small-shape.png") center no-repeat;
          top: -130px;
          background-size: cover; } }
    .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero {
      max-width: 1640px;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero {
          max-width: 98.2%;
          padding: 0 16px;
          margin: 0 auto; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::before {
        content: "";
        position: absolute;
        width: 232px;
        height: 132px;
        background: transparent;
        top: -5px;
        border: 2px solid white;
        left: 364px;
        border-bottom-left-radius: 162px;
        border-bottom-right-radius: 162px;
        border-top: none; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::before {
            display: none; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::after {
        content: "";
        position: absolute;
        width: 23px;
        height: 23px;
        background: #ffc31e;
        top: 54px;
        left: 364px;
        border-radius: 50%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::after {
            display: none; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
        width: 40%;
        padding: 15% 0; }
        @media screen and (max-width: 1780px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 45%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 50%; } }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 85%;
            margin: 0 auto;
            text-align: center;
            padding: 100px 0 45px; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 100%;
            padding: 50px 0 40px; } }
    .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
      max-width: 1640px;
      margin: 0 auto;
      position: relative;
      z-index: 4;
      padding-bottom: 194px; }
      @media screen and (max-width: 1775px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          margin-left: 20px; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          padding-bottom: 125px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          padding-bottom: 100px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          padding-bottom: 85px; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container {
        position: relative;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ffffff;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        padding-left: 8%; }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container {
            height: auto;
            background: transparent;
            padding: 0;
            align-items: center; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container::before {
          content: "";
          position: absolute;
          width: 124px;
          height: 124px;
          border: 2px solid #ffc31e;
          bottom: 0;
          top: -15%;
          left: -4%;
          border-radius: 50%;
          z-index: -1; }
          @media screen and (max-width: 1775px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container::before {
              display: none; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container:after {
          content: "";
          position: absolute;
          top: 0;
          right: calc((-100vw + 1640px) / 2);
          width: calc((100vw - 1640px) / 2);
          height: 400px;
          background: #ffffff; }
          @media screen and (min-width: 1680px) and (max-width: 1775px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container:after {
              width: calc((100vw - 1450px) / 2);
              margin-right: -67px;
              z-index: -1; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container:after {
              display: none; } }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container {
            order: 2;
            background: white;
            padding: 20px 40px;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            /* position: relative; */
            top: -100px;
            z-index: 5;
            margin-top: -100px;
            height: 280px;
            display: flex;
            flex-direction: column;
            justify-content: center; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container {
            padding: 30px 35px 20px;
            height: 259px; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body {
          width: 45%; }
          @media screen and (max-width: 1270px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body {
              width: 40%; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body {
              width: 100%;
              position: relative; } }
          @media screen and (max-width: 1640px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body > .field__items {
              padding-left: 5px; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body > .field__items {
              padding-left: 40px; } }
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
            font-size: 32px;
            font-weight: lighter;
            padding-bottom: 30px;
            line-height: 42px; }
            @media screen and (max-width: 1260px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 25px;
                line-height: 32px; } }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 22px; } }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 20px;
                line-height: 28px; } }
            @media screen and (max-width: 359px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 17px; } }
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body::before {
            content: "";
            position: absolute;
            width: 25px;
            height: 20px;
            background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
            background-position: -1462px -1082px;
            width: 25px;
            height: 20px;
            top: 83px;
            left: 5%; }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body::before {
                left: 4%; } }
            @media screen and (max-width: 880px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body::before {
                top: 10px;
                left: 0; } }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption {
            padding-left: 5px; } }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption {
            padding-left: 40px;
            margin-top: -15px;
            display: block; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption p {
          font-size: 16px;
          line-height: 22px; }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption p {
              font-size: 14px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption p {
              font-size: 12px; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
          position: absolute;
          bottom: 0;
          right: 0; }
          @media screen and (max-width: 1640px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 50%; } }
          @media screen and (max-width: 1220px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 55%; } }
          @media screen and (max-width: 940px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              right: 0; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              position: static;
              order: 1;
              width: 60%; } }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 65%; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 75%; } }
          @media screen and (max-width: 575px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 75%; } }
          @media screen and (max-width: 470px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 90%; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 95%; } }
          @media screen and (max-width: 360px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 100%; } }
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image img {
            display: block;
            width: 100%; }
  .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
    background-color: #e7eded;
    padding-bottom: 490px; }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 430px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 360px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 300px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 215px; } }
    @media screen and (max-width: 359px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 190px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container {
      max-width: 1640px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container {
          max-width: 98.2%;
          padding: 0 16px;
          margin: 0 auto; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container {
          flex-direction: column;
          align-items: center; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
        position: relative;
        left: calc((-100vw + 1640px) / 2);
        margin-right: 4vw;
        margin-left: -9.4%;
        width: 53vw; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            left: 0;
            margin-left: -11.3%;
            width: 58vw; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -31.4%;
            width: 95vw; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -28.4%;
            width: 100vw; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -29.4%;
            width: 100vw; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -30%;
            width: 100vw; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image-2 {
          z-index: 40;
          position: absolute;
          right: 0;
          top: 0;
          width: 21vw; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image-2 {
              width: 32vw; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image-2 {
              width: 40vw; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image {
          z-index: 30;
          position: relative;
          margin-top: 6%; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
            content: "";
            position: absolute;
            background: url(../images/sprites/arrow_01.svg) no-repeat center;
            width: 80px;
            height: 94px;
            right: 70%;
            bottom: -1.2%; }
            @media screen and (max-width: 1820px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -1.5%; } }
            @media screen and (max-width: 1500px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -2.5%; } }
            @media screen and (max-width: 1300px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -3.5%; } }
            @media screen and (max-width: 1200px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -4.5%; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                display: none; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-caption {
          width: 260px;
          position: absolute;
          margin-top: 18px;
          margin-left: 29%; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-caption {
              display: none; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-caption .field__item p {
            font-size: 16px; }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div {
        width: 43vw;
        margin-top: 15%;
        margin-left: -10%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div {
            margin: 0 auto;
            width: 100%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-main-title {
          padding-bottom: 30px; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-main-title {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body {
          padding-bottom: 60px; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
            font-size: 24px;
            line-height: 36px;
            max-width: 85%; }
            @media screen and (max-width: 1358px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
                max-width: 100%;
                font-size: 20px; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
                max-width: 95%;
                font-size: 18px; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
                max-width: 100%;
                font-size: 16px;
                line-height: 28px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-cards-group-title {
        padding-bottom: 20px; }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items {
            flex-wrap: wrap; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item {
          width: 49%;
          width: 32%;
          margin-right: 2%;
          margin-bottom: 1.5%; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 48%;
              margin-bottom: 1.5%;
              margin-right: 1.5%; } }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item {
              margin-right: 1.5%; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
            display: flex;
            align-items: center;
            padding: 16px 15px;
            background: white;
            border-radius: 12px;
            height: 102px; }
            @media screen and (max-width: 1792px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 18px 15px;
                height: 108px; } }
            @media screen and (max-width: 1640px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                height: 130px; } }
            @media screen and (max-width: 1250px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 10px;
                height: 165px; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 16px 15px;
                height: 135px; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 16px 15px;
                height: auto; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(3n) {
            margin-right: 0; }
            @media (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(3n) {
                margin-right: 1.5%; } }
          @media (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(2n) {
              margin-right: 1.5%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
          min-width: 63px; }
          @media (max-width: 1600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
              min-width: auto; } }
          @media (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
              min-width: 63px; } }
          @media (max-width: 450px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
              min-width: 20px;
              max-width: 40px; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title img {
                max-width: 60%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .field--name-field-icon {
          margin-right: 15px;
          flex-shrink: 0; }
          @media (max-width: 1400px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .field--name-field-icon {
              margin-right: 7px; } }
          @media (max-width: 450px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .field--name-field-icon {
              margin-right: 0; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group {
          width: 80%; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title {
            padding-bottom: 0;
            padding-top: 0; }
            @media (max-width: 1600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title h3 {
                font-size: 14px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-cards-body {
            display: none; }
  .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 160px; }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        max-width: 98.2%;
        margin: 0 auto;
        padding: 0 16px;
        padding-bottom: 175px; } }
    @media screen and (max-width: 1358px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 145px; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 200px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 180px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 160px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 120px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container {
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container {
          flex-direction: column-reverse;
          align-items: center;
          padding-top: 190px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div {
        width: 43vw;
        margin-top: 13%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div {
            margin: 0 auto;
            width: 100%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-main-title {
          padding-bottom: 30px;
          width: 80%; }
          @media screen and (min-width: 1080px) and (max-width: 1200px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-main-title {
              width: 100%; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-main-title {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
          font-size: 24px;
          line-height: 36px;
          max-width: 85%;
          padding-bottom: 60px; }
          @media screen and (max-width: 1358px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
              max-width: 100%;
              font-size: 20px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
              max-width: 95%;
              font-size: 18px;
              padding-bottom: 68px; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
              max-width: 100%;
              font-size: 16px;
              line-height: 28px;
              padding-bottom: 40px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
        position: relative;
        right: calc((-100vw + 1640px) / 2);
        width: 68vw;
        margin-left: 0;
        margin-right: -23%;
        margin-top: -320px; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            right: 0;
            width: 58vw;
            margin-left: 0;
            margin-right: -12%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            position: static;
            width: 95vw;
            margin-right: -51%;
            margin-top: -503px; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            width: 100vw;
            margin-right: -37%;
            margin-top: -443px; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            width: 100vw;
            margin-right: -33%;
            margin-top: -390px; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            width: 100vw;
            margin-right: -37%;
            margin-top: -329px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
          width: 260px;
          position: absolute;
          left: -230px;
          top: 185px;
          z-index: 6; }
          @media screen and (max-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
              left: -255px; } }
          @media screen and (max-width: 1200px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
              left: -265px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
              display: none; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption .field__items p {
            text-align: right;
            font-size: 16px; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
          content: "";
          position: absolute;
          width: 94px;
          height: 49px;
          background: url(../images/sprites/arrow_02.svg) no-repeat center;
          top: 15%;
          left: 2.8%; }
          @media screen and (max-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: 1.8%; } }
          @media screen and (max-width: 1358px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -0.2%; } }
          @media screen and (max-width: 1300px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -0.8%; } }
          @media screen and (max-width: 1238px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -2.8%; } }
          @media screen and (max-width: 1174px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -3.8%; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              display: none; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards {
      margin-top: 8%; }
      @media screen and (max-width: 768px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards {
          margin-top: 40px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards {
          margin-top: 0; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items {
            justify-content: center; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items {
            flex-wrap: nowrap;
            flex-direction: column; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
          width: 24.25%;
          position: relative;
          margin-right: 1%;
          border: 1px solid #e7eded;
          border-radius: 12px;
          padding: 5% 2% 2%; }
          @media screen and (max-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 31.33%;
              margin-bottom: 8%;
              margin-left: 1%; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 42%;
              margin: 0 15px 115px; } }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 70%;
              padding: 5% 2%; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 100%;
              border: none;
              margin-bottom: 20px;
              padding: 0;
              margin-left: 0; } }
          @media screen and (min-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(3n + 4) {
              margin-right: 0; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(-n + 4) {
              margin-bottom: 7%; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item::before {
            content: "";
            position: absolute;
            height: 100px;
            width: 42%;
            background: white;
            top: -62px;
            z-index: 10;
            left: 0;
            right: 0;
            margin: 0 auto; }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item::before {
                width: 36%; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item::before {
                display: none; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .content {
              display: flex;
              align-items: center; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title {
              display: flex;
              justify-content: flex-start;
              align-items: center; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: linear-gradient(219deg, #1f80db, #52ed93);
            border-radius: 50%;
            z-index: 12; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon {
                margin: 0 12px 0 0;
                width: 69px;
                height: 69px;
                position: static; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon .field__items {
              background: #ffffff;
              border-radius: 50%;
              width: 96px;
              height: 96px;
              display: flex;
              align-items: center;
              justify-content: center; }
              @media screen and (max-width: 600px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon .field__items {
                  width: 65px;
                  height: 65px; }
                  .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon .field__items img {
                    margin: 0 auto;
                    width: 67%; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
            padding-bottom: 4%;
            text-align: center; }
            @media screen and (max-width: 1640px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 20px 5px 20px; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 25px 5px 20px; } }
            @media screen and (max-width: 880px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 30px 5px 20px; } }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 40px 5px 20px; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 0;
                margin-left: 5px; }
                .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title h3 {
                  text-align: left; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-cards-body {
            line-height: 27px;
            text-align: center; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-cards-body {
                display: none; } }
  .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
    background-color: #e7eded;
    padding-bottom: 250px; }
    @media screen and (max-width: 1220px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
        padding-bottom: 170px; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
        padding-bottom: 150px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
        padding-bottom: 80px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container {
      max-width: 1640px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container {
          max-width: 98.2%;
          padding: 0 16px;
          margin: 0 auto; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container {
          flex-direction: column;
          align-items: center; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
        position: relative;
        left: calc((-100vw + 1640px) / 2);
        margin-right: 4vw;
        margin-left: -5.3%;
        width: 53vw; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            left: 0;
            margin-left: -8.5%;
            width: 58vw; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -26%;
            width: 95vw; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -30.5%;
            width: 100vw; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -31.5%;
            width: 100vw; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -33%;
            width: 100vw; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-image {
          margin-top: -26%;
          position: relative; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-image:before {
            content: "";
            position: absolute;
            background: url(../images/sprites/arrow_01.svg) no-repeat center;
            width: 80px;
            height: 94px;
            right: 64%;
            bottom: -66px; }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-image:before {
                display: none; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-caption {
          width: 260px;
          position: absolute;
          margin-top: 63px;
          margin-left: 35%; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-caption {
              display: none; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-caption .field__item p {
            font-size: 16px; }
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div {
        width: 43vw;
        margin-top: 9.5%;
        margin-left: -10%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div {
            margin: 0 auto;
            width: 100%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-main-title {
          padding-bottom: 30px; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-main-title {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
          font-size: 24px;
          line-height: 36px;
          max-width: 85%;
          padding-bottom: 60px; }
          @media screen and (max-width: 1358px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
              max-width: 100%;
              font-size: 20px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
              max-width: 95%;
              font-size: 18px; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
              max-width: 100%;
              font-size: 16px;
              line-height: 28px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-cards-group-title {
          padding-bottom: 20px; }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-cards-group-title {
              padding-bottom: 10px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start; }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items {
              flex-wrap: wrap;
              justify-content: flex-start; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item {
            width: 49%;
            margin-right: 2%;
            margin-bottom: 1.5%; }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item {
                width: 49%;
                margin-right: 2%; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
              display: flex;
              align-items: center;
              padding: 16px 15px;
              background: white;
              border-radius: 12px;
              height: 102px; }
              @media screen and (max-width: 1792px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 18px 15px;
                  height: 108px; } }
              @media screen and (max-width: 1640px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  height: 130px; } }
              @media screen and (max-width: 1250px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 10px;
                  height: 165px; } }
              @media screen and (max-width: 1080px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 16px 15px;
                  height: 135px; } }
              @media screen and (max-width: 600px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 16px 15px;
                  height: auto; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item:nth-child(2n) {
              margin-right: 0; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-icon-title {
            min-width: 63px; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .field--name-field-icon {
            margin-right: 15px;
            flex-shrink: 0; }
            @media (max-width: 1400px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .field--name-field-icon {
                margin-right: 7px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group {
            width: 80%; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title {
              padding-bottom: 0;
              padding-top: 0; }
              @media (max-width: 1600px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title h3 {
                  font-size: 14px; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-cards-body {
              font-size: 18px;
              padding-bottom: 0;
              line-height: 24px; }
  .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
    background-color: #e7eded;
    padding-bottom: 160px;
    padding-bottom: 30vw;
    padding-bottom: 390px;
    position: relative;
    z-index: 1; }
    @media (max-width: 1500px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 350px; } }
    @media (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 60vw;
        padding-bottom: 540px; } }
    @media (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 380px; } }
    @media (max-width: 680px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 330px; } }
    @media (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 310px; } }
    @media (max-width: 450px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 60vw; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-our-team {
      margin-bottom: 100px;
      margin-top: -100px; }
    .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container {
      max-width: 1640px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 16px 0 16px 0;
      position: relative; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container {
          max-width: 98.2%;
          margin: 0 20px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container::before {
        content: "";
        position: absolute;
        width: 124px;
        height: 124px;
        border: 2px solid #ffc31e;
        border-radius: 50%;
        top: -59px;
        left: 96%;
        z-index: -1; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container::before {
            width: 100px;
            height: 100px;
            left: 96.5%;
            top: -54px; } }
        @media screen and (max-width: 1780px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container::before {
            display: none; } }
      @media (max-width: 420px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container br {
          display: none; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-main-title {
        width: 70%;
        padding: 5% 0 5% 9.1%; }
        @media screen and (max-width: 936px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-main-title {
            width: 90%;
            padding: 8% 0 5% 9.1%; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-main-title {
            width: 252px;
            margin-right: 0;
            padding-bottom: 42px;
            padding-top: 40px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items {
            flex-wrap: wrap; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item {
          width: calc(96px + ((100% - 352px) / 12 * 4));
          padding: 30px 20px;
          position: relative; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item {
              margin-bottom: 15px;
              width: 50%;
              border: 0; } }
          @media (max-width: 400px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item {
              width: 50%;
              border: 0;
              margin-bottom: 5px;
              padding: 20px 20px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item:last-child {
            border-right: none; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item {
            position: relative;
            z-index: 1; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
              display: block;
              width: 42px;
              height: 42px;
              text-indent: -9999px;
              color: transparent;
              background: url(../images/sprites/linkedin-icon.png) no-repeat;
              background-position: center;
              border-radius: 50%;
              position: absolute;
              border-radius: 50%;
              margin-left: 60%;
              margin-top: 3%; }
              @media screen and (max-width: 1500px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 62%; } }
              @media screen and (max-width: 1358px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 63%; } }
              @media screen and (max-width: 1250px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 64%; } }
              @media screen and (max-width: 1150px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 65%; } }
              @media screen and (max-width: 1090px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 67%; } }
              @media screen and (max-width: 1080px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 60.5%; } }
              @media screen and (max-width: 880px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 62%; } }
              @media screen and (max-width: 768px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 64.5%; } }
              .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a:after {
                content: "";
                width: 42px;
                height: 42px;
                position: absolute;
                background: #1f80db;
                border-radius: 50%;
                z-index: -1;
                margin: 0 auto; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-image {
            padding-bottom: 4%; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-image img {
              border-radius: 100%;
              background: linear-gradient(226deg, #1f80db, #52ed93);
              padding: 2px;
              margin: 0 auto; }
              @media screen and (max-width: 415px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-image img {
                  width: 152px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-member-name {
            text-align: center;
            padding: 1% 0;
            word-break: break-word; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-member-name h3 {
              font-size: 20px; }
              @media screen and (max-width: 415px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-member-name h3 {
                  font-size: 14px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-body {
            text-align: center; }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-body p {
                font-size: 12px;
                line-height: 16px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group {
        width: 160px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 10px; }
        @media (max-width: 400px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group {
            width: 110px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-linkedin {
          position: absolute;
          right: 40px;
          top: 0; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-linkedin a {
            overflow: hidden; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-linkedin a:after {
              left: 0;
              top: 0;
              z-index: -1; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-secondary-title {
          background: white;
          border: 1px solid #B2C7D9;
          border-radius: 20px;
          text-align: center;
          font-size: 12px;
          padding: 5px 0;
          font-weight: bold;
          color: #0C4F8E;
          width: 100px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          background: white; }
          @media (max-width: 400px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-secondary-title {
              font-size: 10px; } }
  .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
    background-color: #e7eded;
    padding-bottom: 420px; }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 562px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 440px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 390px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 380px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container {
      max-width: 1640px;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container {
          max-width: 98.2%;
          padding: 0 16px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
        padding-bottom: 40px;
        width: 700px;
        position: relative; }
        @media screen and (max-width: 1024px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
            width: 65%;
            padding-left: 10px; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
            width: 216px;
            padding-left: 10px; } }
        @media screen and (max-width: 359px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
            width: 166px;
            padding-left: 10px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
      position: absolute;
      top: 20px;
      right: 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin: 0 20px; }
      @media screen and (max-width: 1024px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          right: 10px; } }
      @media screen and (max-width: 809px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 12px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 30px; } }
      @media screen and (max-width: 560px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 32px; } }
      @media screen and (max-width: 443px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 23px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 28px;
          margin: 0 10px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slider-nav-circle {
        width: 16px;
        height: 16px;
        background: #ffc31e;
        border-radius: 50%;
        display: block;
        margin: 0 20px; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slider-nav-circle {
            width: 11px;
            height: 11px;
            margin: 0 15px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-prev {
        display: block;
        width: 40px;
        height: 20px;
        top: -20%;
        right: 12%;
        background-color: transparent;
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1420px -1082px;
        width: 40px;
        height: 20px;
        color: transparent;
        text-indent: -9999px;
        border: 0;
        cursor: pointer; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-prev:disabled {
          opacity: 0.2;
          cursor: default; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-prev {
            background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
            background-position: -1236px -1106px;
            width: 28px;
            height: 14px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-next {
        display: block;
        width: 40px;
        height: 20px;
        top: -20%;
        right: 6%;
        background-color: transparent;
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1378px -1082px;
        width: 40px;
        height: 20px;
        color: transparent;
        text-indent: -9999px;
        border: 0;
        cursor: pointer; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-next:disabled {
          opacity: 0.2;
          cursor: default; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-next {
            background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
            background-position: -1266px -1106px;
            width: 28px;
            height: 14px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slick-slide {
      padding: 0 8px; }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slick-track {
      height: 380px; }
      @media screen and (max-width: 359px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slick-track {
          height: 415px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
      border: 1px solid #adc0d1;
      border-radius: 12px;
      padding: 4.5% 5.6% 0 11%;
      height: 268px;
      position: relative; }
      @media screen and (max-width: 522px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 325px;
          padding: 7% 5.6% 0 11%; } }
      @media screen and (max-width: 480px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 350px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 345px; } }
      @media screen and (max-width: 359px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 385px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body {
        position: relative;
        padding-left: 5px; }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body {
            padding-left: 5px;
            font-size: 14px;
            line-height: 24px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body:before {
          content: "";
          position: absolute;
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1462px -1082px;
          width: 25px;
          height: 20px;
          left: -7.5%;
          top: 11%; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body:before {
              background: url(../images/sprites/quote-small.png) no-repeat center;
              width: 17px;
              height: 13px;
              top: 12px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
      display: flex;
      align-items: center;
      position: absolute;
      padding: 0 46px;
      margin-left: 31px;
      background-color: #e7eded;
      z-index: 4;
      bottom: -40px;
      width: 80%;
      left: -70px;
      right: 0px;
      margin: 0 auto; }
      @media screen and (max-width: 550px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
          bottom: -30px;
          padding: 0 12px;
          left: 0; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
          width: 88%;
          bottom: -27px;
          padding: 0 12px;
          left: 8px; } }
      @media screen and (max-width: 359px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
          width: 90%; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image {
        flex-shrink: 0; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item {
          background: linear-gradient(226deg, #1f80db, #52ed93);
          border-radius: 100%;
          padding: 1px; }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item {
              padding: 2px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item img {
            border-radius: 100%; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item img {
                width: 65px;
                height: 65px; } }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item img {
                width: 50px;
                height: 50px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group {
        flex-direction: column;
        padding-left: 20px;
        max-width: 355px; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group {
            padding-left: 12px;
            max-width: 195px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-main-title {
          padding: 0; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-main-title {
              width: 100%; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-main-title h3 {
                font-size: 14px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body {
          font-size: 18px;
          padding-bottom: 0;
          line-height: 26px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body {
              width: 95%; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body p {
                font-size: 14px;
                line-height: 18px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body {
              width: 88%; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body p {
                font-size: 12px; } }
  .node-type-accessibility .node--accessibility .group-webform-container {
    max-width: 1640px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: none;
    padding-top: 4%;
    position: relative;
    z-index: 1;
    top: -18vw;
    height: 800px; }
    @media (min-width: 1920px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -340px; } }
    @media (min-width: 2560px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -360px; } }
    @media (min-width: 3000px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -380px; } }
    @media (min-width: 3600px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -410px; } }
    @media (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -15vw;
        height: 650px; } }
    @media (max-width: 1500px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        height: 570px; } }
    @media (max-width: 1420px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        height: 620px; } }
    @media (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        height: auto; } }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        max-width: 98.2%;
        padding: 0 16px;
        margin: 0 auto; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        border-radius: 0;
        max-width: 100%;
        flex-direction: column-reverse;
        margin-bottom: -110px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        margin-bottom: -75px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        margin-bottom: -50px; } }
    @media (max-width: 1400px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -17vw; } }
    .node-type-accessibility .node--accessibility .group-webform-container .group-title-form {
      margin-left: 8%; }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form {
          max-width: 98.2%;
          padding: 0 16px;
          margin-left: 0; } }
      .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
        width: 674px;
        padding-bottom: 6%;
        padding-top: 5%;
        padding-bottom: 100px; }
        @media screen and (max-width: 1500px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 80%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 100%; } }
        @media screen and (max-width: 720px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 95%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            padding-top: 40px; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 100%;
            margin: 0;
            padding-bottom: 6%; }
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title h2 {
              font-size: 22px;
              line-height: 30px; } }
        @media screen and (max-width: 360px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 85%; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow {
          position: relative; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
            content: "";
            position: absolute;
            height: 55px;
            width: 55px;
            background: url(../images/sprites/arrow_03.svg) no-repeat;
            right: -46%;
            top: 38%;
            z-index: 10; }
            @media screen and (max-width: 1358px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                width: 50px;
                height: 50px;
                right: -50%;
                top: 34%; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                height: 45px;
                width: 45px;
                right: -53%; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                height: 40px;
                width: 40px;
                right: -52%; } }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                height: 33px;
                width: 33px;
                right: -56%; } }
      .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
        width: 535px; }
        @media screen and (max-width: 1500px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 80%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            margin: 0 auto;
            width: 70%; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 90%; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 95%; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 100%; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: flex-end; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper > label {
            width: 480px;
            padding-left: 3%;
            font-weight: normal !important;
            padding-top: 3% !important; }
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper > label > a {
              color: #0c4f8e; }
            @media screen and (max-width: 1640px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper > label {
                padding-left: 2%; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item label {
          font-family: "Montserrat", "Arial", sans-serif;
          text-transform: none;
          font-weight: bold;
          font-size: 14px;
          padding-bottom: 10px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item label {
              padding-bottom: 7px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item label {
              margin-top: 10px;
              font-size: 12px; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-text,
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
          border: 1px solid #939393;
          border-radius: 12px;
          background-color: #ffffff;
          height: 60px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-text,
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
              height: 48px; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
          min-height: 90px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
              min-height: 96px; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-type-checkbox {
          display: inline-block; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-type-checkbox input[type="checkbox"] {
            height: 31px;
            width: 31px;
            border-radius: 8px;
            border: 1px solid #0c4f8e;
            margin: 0;
            cursor: pointer;
            opacity: 1;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none; }
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-type-checkbox input[type="checkbox"]:checked {
              background: url(../images/sprites/check-icon.png) no-repeat center; }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-actions {
          position: relative; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-actions > input {
            height: 60px;
            background-color: #0c4f8e;
            color: #ffffff;
            border-radius: 8px;
            font-size: 16px; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-actions > input {
                height: 48px;
                font-size: 12px; } }
    .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
      position: relative;
      right: calc((-100vw + 1640px) / 2);
      width: 54vw;
      margin-right: -4vw;
      margin-left: 0; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          right: 0; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          position: static;
          width: 95vw;
          margin-left: 26.5%;
          margin-top: -230px; } }
      @media screen and (max-width: 768px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          width: 100vw;
          margin-left: 19.5%;
          margin-top: -132px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          width: 100vw;
          margin-left: 20.5%;
          margin-top: -115px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          margin-left: 13.5%;
          width: 100vw;
          margin-top: -75px;
          margin-bottom: 5px; } }
  .node-type-accessibility .node--accessibility .field--name-field-footer {
    max-width: 1640px;
    margin: 0 auto;
    margin-top: -6%; }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .field--name-field-footer {
        max-width: 98.2%;
        padding: 0 16px;
        margin: 0 auto; } }
    @media (max-width: 850px) {
      .node-type-accessibility .node--accessibility .field--name-field-footer {
        margin-top: 50px; } }
    .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content {
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      height: 122px;
      font-size: 14px; }
      @media screen and (max-width: 850px) {
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content {
          flex-direction: column; } }
      .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 138px; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left {
            margin-left: 0; } }
        @media screen and (max-width: 850px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left {
            flex-direction: column;
            margin: 0 auto; } }
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-copyright {
          margin-right: 20px;
          font-weight: bold; }
          @media screen and (max-width: 850px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-copyright {
              margin: 0 0 20px 0; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-copyright .field__items .field__item {
              font-size: 14px; } }
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items {
          display: flex; }
          @media screen and (max-width: 850px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items {
              flex-direction: column; } }
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item {
            margin-right: 20px;
            font-weight: normal; }
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item:last-child {
              margin-right: 0; }
            @media screen and (max-width: 850px) {
              .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item {
                margin: 0 0 20px 0;
                text-align: center; } }
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a {
              text-decoration: none;
              color: #000000; }
              .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:hover, .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:visited, .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:link, .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:active {
                text-decoration: none; }
              @media screen and (max-width: 1080px) {
                .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a {
                  font-size: 14px; } }
      .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 138px; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right {
            margin-right: 0; } }
        @media screen and (max-width: 850px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right {
            margin: 30px auto;
            padding-bottom: 21px; } }
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right .field--name-field-footer-text {
          margin-right: 8px;
          font-weight: bold; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right .field--name-field-footer-text .field__item {
              font-size: 14px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right .field--name-field-footer-text .field__item {
              font-size: 10px; } }

/* Makes border-box properties */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.field--type-text a:link, .field--type-text a:visited, .field--type-text a:active,
.field--type-text-long a:link,
.field--type-text-long a:visited,
.field--type-text-long a:active,
.field--type-text-with-summary a:link,
.field--type-text-with-summary a:visited,
.field--type-text-with-summary a:active {
  text-decoration: none;
  color: black;
  font-weight: 400;
  text-decoration: underline; }

.field--type-text a:hover, .field--type-text a:focus,
.field--type-text-long a:hover,
.field--type-text-long a:focus,
.field--type-text-with-summary a:hover,
.field--type-text-with-summary a:focus {
  color: #100a0f; }

img {
  height: auto;
  max-width: 100%; }

html {
  font-size: 18px;
  line-height: 1.5;
  font-family: "Montserrat", "Arial", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slicknav_menu {
  display: none; }

.drup-msgs {
  max-width: calc(288px + ((100% - 352px) / 12 * 10));
  margin: 0 auto; }
  .drup-msgs .messages {
    margin: 20px auto;
    width: calc(160px + ((100% - 288px) / 10 * 6)); }

.user-login {
  margin-bottom: 100px; }
  .user-login input[type="submit"] {
    max-width: 230px; }

.intro-training-page .l-main {
  max-width: none;
  margin: 0; }

.l-nots {
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 80px; }

/*$local: (
  columns: susy-repeat(12),
  gutters: 0.5,
  spread: 'narrow',
  container-spread: 'narrow',
);*/
.l-page {
  padding-top: 106px; }

.notbar-removed .l-page {
  padding-top: 79px; }

.logged-in .l-page {
  padding-top: 78px; }

h1, h2, h3, h4, h5 {
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  line-height: 1.3; }

.price-bold {
  font-weight: 700;
  font-size: 1.11111rem;
  position: relative;
  top: 2px; }

.view-id-training.view-display-id-block_2 {
  height: calc(100vh - 280px);
  overflow: hidden; }
  .view-id-training.view-display-id-block_2 .field--name-field-early-bird-price,
  .view-id-training.view-display-id-block_2 .field--name-field-standard-price {
    margin-top: 12px; }
  .view-id-training.view-display-id-block_2 .rmore a {
    width: 225px;
    padding: 16px 22px;
    font-weight: 400;
    background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
    background-size: 200% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0; }
    .view-id-training.view-display-id-block_2 .rmore a:hover, .view-id-training.view-display-id-block_2 .rmore a:focus {
      color: #fbc31e;
      border: 1px solid #100a0f;
      background-position: 0 0; }
  .view-id-training.view-display-id-block_2 .views-row {
    margin-bottom: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid #ffe57c; }
  .view-id-training.view-display-id-block_2 .views-row-last {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }

.overlay-menu-open {
  overflow: hidden; }

.notifications-expanded {
  display: none;
  position: fixed;
  width: 100%;
  background: #fbc31e;
  z-index: 100;
  height: 100vh;
  top: 0;
  z-index: 999; }

.notifications-expandit {
  color: #100a0f;
  text-align: center;
  padding: 4px 30px;
  font-family: "Libre Baskerville", serif;
  font-size: 0.72222rem;
  position: relative;
  z-index: 110; }
  .notifications-expandit:hover, .notifications-expandit:focus {
    cursor: pointer; }
  .notifications-expandit .accented-txt {
    font-weight: 700; }
  .notifications-expandit:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -6px;
    z-index: 100;
    margin-left: -6px;
    border-top: 8px solid #fbc31e;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent; }
  .notifications-expandit.not-active:after {
    display: none; }

.notifications-bar-wrapper {
  background: #fbc31e; }

.page-node .notifications-bar-wrapper .read-more.rmore a {
  color: #100a0f; }
  .page-node .notifications-bar-wrapper .read-more.rmore a:hover, .page-node .notifications-bar-wrapper .read-more.rmore a:focus {
    color: #fbc31e; }

.l-header-wrapper {
  position: relative; }
  .l-header-wrapper:after {
    content: "";
    display: block;
    clear: both; }

.view-navigation-boxes {
  position: relative; }
  .view-navigation-boxes .custom-title {
    min-height: 75px;
    color: #ffffff; }
  .view-navigation-boxes .read-more.rmore {
    margin-top: 30px; }
    .view-navigation-boxes .read-more.rmore a {
      color: #fbc31e;
      border: none;
      text-align: left;
      padding: 0;
      border: 1px solid #fbc31e;
      padding: 22px; }
      .view-navigation-boxes .read-more.rmore a:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1489px -1082px;
        width: 37px;
        height: 12px; }
      .view-navigation-boxes .read-more.rmore a:hover {
        color: #100a0f; }
        .view-navigation-boxes .read-more.rmore a:hover:after {
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1567px -1082px;
          width: 37px;
          height: 12px; }
  .view-navigation-boxes .views-row {
    float: left;
    width: calc(64px + ((100% - 352px) / 12 * 3));
    margin-right: 32px; }
    .view-navigation-boxes .views-row:last-child {
      margin-right: 0;
      float: right; }
    .view-navigation-boxes .views-row .read-more {
      margin-top: 40px; }
    .view-navigation-boxes .views-row .node--box {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }

.l-header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 110;
  background: #ffffff;
  top: 0;
  left: 0; }

#mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form {
  position: relative;
  max-width: 500px;
  margin: 80px auto 0; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form #edit-actions.disabled {
    opacity: 0.5; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form #edit-actions.disabled input:hover {
      cursor: default !important;
      opacity: 0.5; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form #edit-actions.disabled:hover {
      cursor: default; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form #edit-actions:hover {
    cursor: pointer; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox {
    width: 360px;
    margin: 0 auto; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox input {
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox a {
      color: black; }
      #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox a:hover, #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox a:visited {
        color: black; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox .checkmark:after {
      left: 9px;
      top: 6px; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .checkmark {
    border: none !important; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-required {
    display: none; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="submit"] {
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    right: 14px;
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1567px -1082px;
    width: 37px;
    height: 12px; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="submit"]:hover, #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="submit"]:focus {
      cursor: pointer; }
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="text"] {
    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
    font-family: "Montserrat", "Arial", sans-serif;
    color: #555;
    padding: 14px 64px 14px 14px;
    border-bottom: 1px solid #100a0f;
    font-size: 16px; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="text"]::placeholder {
      color: #555;
      opacity: 1; }

.ajax-progress {
  display: none; }

.nnode-1 .webform-client-form-1 {
  float: left;
  width: 46%; }

.nnode-1 .field--name-field-body-below {
  float: left; }

.nnode-1 .webform-call-us {
  margin-top: -120px;
  font-weight: 400;
  line-height: 1.6; }
  .nnode-1 .webform-call-us .light-text {
    font-size: 0.66666rem;
    font-weight: 300; }
  .nnode-1 .webform-call-us .webform-text-1 {
    margin-bottom: 30px; }
    .nnode-1 .webform-call-us .webform-text-1 a {
      color: #100a0f; }
      .nnode-1 .webform-call-us .webform-text-1 a:hover {
        color: #fbc31e; }

.nnode-1 .field--name-body {
  position: relative;
  float: left;
  width: 46%;
  margin-right: 8%;
  font-size: 16px;
  font-weight: 400;
  line-height: 2; }

.nnode-36 .webform-component--your-name,
.nnode-36 .webform-component--select-workshop {
  float: left;
  width: 48%;
  margin-right: 4%; }

.nnode-36 .webform-component--select-workshop {
  margin-right: 0; }

.nnode-36 .webform-component--your-e-mail {
  clear: both; }

.nnode-36 .form-actions {
  max-width: 230px; }

.nnode-2 .webform-component--your-name,
.nnode-2 .webform-component--select-subject {
  float: left;
  width: 46%;
  margin-right: 4%; }

.nnode-2 .webform-component--select-workshop {
  margin-right: 0; }

.nnode-2 .webform-component--your-e-mail {
  clear: both; }

.nnode-2 .form-actions {
  max-width: 230px; }

.block--gtweaks {
  margin-bottom: 190px; }

.block--gtweaks-public-workshops-metrics {
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  margin-top: 100px;
  border-top: 1px solid #d7d7d7;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-bottom: 1px solid #d7d7d7; }

.block--views-training-block:after {
  content: "";
  display: block;
  clear: both; }

.block--views-training-block-4:after {
  content: "";
  display: block;
  clear: both; }

.block--gtweaks-our-mantra {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  float: left;
  position: relative;
  margin-top: 150px; }
  .block--gtweaks-our-mantra .mantra-image {
    height: 660px;
    background: url("../images/mantra.jpg") 0 0 no-repeat;
    background-size: cover; }
  .block--gtweaks-our-mantra .group-wrapper {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%); }
    .block--gtweaks-our-mantra .group-wrapper h2 {
      width: calc(32px + ((100% - 256px) / 9 * 2));
      margin-left: calc(32px + ((100% - 352px) / 12 * 1));
      margin-right: 32px;
      font-size: 1.55555rem;
      float: left; }
    .block--gtweaks-our-mantra .group-wrapper .group-right-wrapper {
      width: calc(128px + ((100% - 256px) / 9 * 5));
      float: left; }
      .block--gtweaks-our-mantra .group-wrapper .group-right-wrapper .large-title {
        font-size: 2.5rem;
        font-family: "Libre Baskerville", serif;
        line-height: 1.1; }
      .block--gtweaks-our-mantra .group-wrapper .group-right-wrapper .small-desc {
        margin-top: 20px;
        font-size: 0.66666rem; }

.block--views-team-block {
  margin-bottom: 200px;
  /*    .view-header {
      width: span(1);
      margin-right: gutter();
      float: left;
    }*/ }
  .block--views-team-block:after {
    content: "";
    display: block;
    clear: both; }
  .block--views-team-block .vertical-number {
    padding-left: 17px; }
  .block--views-team-block .view-content {
    padding-top: 80px;
    width: calc(256px + ((100% - 352px) / 12 * 9));
    margin-left: calc(32px + ((100% - 352px) / 12 * 1));
    float: left; }
    .block--views-team-block .view-content .views-row {
      box-shadow: 5px 5px 16px 0 #e5e5e5;
      margin-bottom: 30px; }
      .block--views-team-block .view-content .views-row:after {
        content: "";
        display: block;
        clear: both; }

.block--gtweaks-our-values {
  clear: both;
  /*    .view-header {
      width: span(1);
      margin-right: gutter();
      float: left;
    }*/ }
  .block--gtweaks-our-values:after {
    content: "";
    display: block;
    clear: both; }
  .block--gtweaks-our-values .vertical-number {
    padding-left: 12px; }
  .block--gtweaks-our-values .view-content {
    padding-top: 80px;
    width: calc(256px + ((100% - 352px) / 12 * 9));
    margin-left: calc(32px + ((100% - 352px) / 12 * 1));
    float: left; }
    .block--gtweaks-our-values .view-content .value-wrap {
      position: relative;
      height: 356px; }
    .block--gtweaks-our-values .view-content .value-item {
      position: relative;
      padding: 32px; }
      .block--gtweaks-our-values .view-content .value-item .value-wrap:after {
        display: block; }
      .block--gtweaks-our-values .view-content .value-item h2 {
        font-size: 1.55555rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0; }
    .block--gtweaks-our-values .view-content .value-item-1 {
      width: calc(96px + ((100% - 256px) / 9 * 4));
      float: left;
      background: #100a0f;
      color: #ffffff; }
      .block--gtweaks-our-values .view-content .value-item-1 .value-wrap:before {
        content: "";
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        height: 150px;
        width: 150px;
        right: 20px;
        top: 20px; }
    .block--gtweaks-our-values .view-content .value-item-2 {
      width: calc(128px + ((100% - 256px) / 9 * 5));
      float: right;
      background: #fbc31e;
      color: #100a0f;
      margin-bottom: 32px; }
      .block--gtweaks-our-values .view-content .value-item-2 .value-wrap:before {
        content: "";
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -803px -1082px;
        width: 105px;
        height: 102px;
        position: absolute;
        right: 20px;
        top: 40px; }
    .block--gtweaks-our-values .view-content .value-item-3 {
      width: calc(128px + ((100% - 256px) / 9 * 5));
      float: left;
      background: #fbc31e;
      color: #100a0f; }
      .block--gtweaks-our-values .view-content .value-item-3 .value-wrap:before {
        content: "";
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -540px -1082px;
        width: 150px;
        height: 133px;
        position: absolute;
        right: 20px;
        top: 20px; }
    .block--gtweaks-our-values .view-content .value-item-4 {
      width: calc(96px + ((100% - 256px) / 9 * 4));
      float: right;
      background: #100a0f;
      color: #ffffff; }
      .block--gtweaks-our-values .view-content .value-item-4 .value-wrap:before {
        content: "";
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -388px -1082px;
        width: 150px;
        height: 150px;
        position: absolute;
        right: 20px;
        top: 20px; }

.block--views-navigation-boxes-block {
  padding: 200px 0; }
  .block--views-navigation-boxes-block:after {
    content: "";
    display: block;
    clear: both; }
  .block--views-navigation-boxes-block .custom-title-box {
    min-width: 0; }
    .block--views-navigation-boxes-block .custom-title-box .custom-title {
      font-weight: 400; }

.block--gtweaks-notification-bar-top.block {
  margin-bottom: 0; }

.block--gtweaks-header-lets-talk.block {
  margin-bottom: 0;
  float: left; }
  .block--gtweaks-header-lets-talk.block a {
    font-size: 16px;
    padding: 28px 76px;
    color: #ffffff;
    display: block;
    text-decoration: none;
    background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, #100a0f 50%, #100a0f);
    background-size: 201% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    transition: 0.3s background-position ease-out; }
    @media (max-width: 1110px) {
      .block--gtweaks-header-lets-talk.block a {
        padding: 28px 50px; } }
    @media (max-width: 1080px) {
      .block--gtweaks-header-lets-talk.block a {
        padding: 28px 35px; } }
    .block--gtweaks-header-lets-talk.block a:hover, .block--gtweaks-header-lets-talk.block a.active {
      background-position: 0 0;
      color: #100a0f; }

.block--gtweaks-social-media {
  float: right;
  margin: 0;
  margin-bottom: 40px;
  font-size: 0.77777rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  width: 100%; }
  .block--gtweaks-social-media:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 950px) {
    .block--gtweaks-social-media {
      margin-top: 50px; } }
  .block--gtweaks-social-media .esepia-logo {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1106px -1122px;
    width: 92px;
    height: 33px;
    display: block; }
  .block--gtweaks-social-media .uxprodigy-logo {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1236px -1082px;
    width: 140px;
    height: 22px;
    display: block;
    margin-right: 20px; }
  .block--gtweaks-social-media .neverending-logo {
    position: relative;
    top: 10px;
    text-align: right; }
    .block--gtweaks-social-media .neverending-logo img {
      max-width: 170px; }
  .block--gtweaks-social-media .logo-wrap {
    float: left;
    margin-right: 26px; }
    .block--gtweaks-social-media .logo-wrap:last-child {
      margin-right: 0; }
    .block--gtweaks-social-media .logo-wrap a {
      display: inline-block;
      vertical-align: middle; }
    .block--gtweaks-social-media .logo-wrap-txt {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }

.block--gtweaks-footer-lets-talk.block {
  background-color: #fbc31e;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 0; }
  .block--gtweaks-footer-lets-talk.block:after {
    content: "";
    display: block;
    background-image: url("../images/uxletters/x.svg");
    width: 511px;
    height: 509px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 40px); }
  .block--gtweaks-footer-lets-talk.block:before {
    content: "";
    display: block;
    width: 511px;
    height: 509px;
    background-size: contain;
    background-image: url("../images/uxletters/u.svg");
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 40px); }
  .block--gtweaks-footer-lets-talk.block .newsletter-intro {
    font-family: "Libre Baskerville", serif;
    font-size: 1.38888rem;
    line-height: 1.35; }
  .block--gtweaks-footer-lets-talk.block > .block__content {
    position: relative;
    z-index: 10;
    width: calc(160px + ((100% - 352px) / 12 * 6));
    margin: 0 auto;
    padding: 230px 0; }

.block--gtweaks-copyright {
  margin: 40px 0;
  float: left;
  font-size: 0.77777rem;
  font-weight: 400;
  width: 100%; }
  .block--gtweaks-copyright .block__content {
    display: flex; }
    @media (max-width: 950px) {
      .block--gtweaks-copyright .block__content {
        flex-direction: column; } }
  .block--gtweaks-copyright .cp-text {
    display: block;
    float: left;
    position: relative;
    top: 6px; }
    @media (max-width: 950px) {
      .block--gtweaks-copyright .cp-text {
        padding-top: 30px;
        text-align: center; } }
  .block--gtweaks-copyright .gdpr-menu {
    margin: 0 auto; }
  .block--gtweaks-copyright .social-media-icons {
    float: left;
    margin-left: 90px; }
    @media (max-width: 950px) {
      .block--gtweaks-copyright .social-media-icons {
        margin-left: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center; } }
  .block--gtweaks-copyright a {
    text-decoration: none;
    font-weight: 700;
    color: #100a0f; }
    .block--gtweaks-copyright a:hover, .block--gtweaks-copyright a:focus {
      text-decoration: underline; }

.block--views-case-studies-block {
  margin-bottom: 180px; }

.block--views-companies-block {
  margin-bottom: 180px; }
  .block--views-companies-block .block__title {
    font-size: 2.5rem;
    margin-bottom: 40px; }
    .block--views-companies-block .block__title.companies-title {
      width: 100%;
      float: right;
      text-align: left;
      padding-top: 80px;
      margin-bottom: 80px; }
      @media (max-width: 1024px) {
        .block--views-companies-block .block__title.companies-title {
          text-align: center;
          font-size: 1.815rem; } }
      @media (max-width: 450px) {
        .block--views-companies-block .block__title.companies-title {
          padding-left: calc(((100% - 352px) / 12 * 1));
          padding-right: calc(((100% - 352px) / 12 * 1)); } }

.block--views-training-block-3 {
  position: relative;
  color: #ffffff; }
  .block--views-training-block-3:after {
    content: "";
    display: block;
    clear: both; }
  .block--views-training-block-3 .views-row {
    position: relative; }
  .block--views-training-block-3 .read-more.rmore {
    position: absolute;
    bottom: 0;
    left: 0; }
    .block--views-training-block-3 .read-more.rmore a {
      color: #fbc31e;
      border: none;
      background: none;
      text-align: left; }
      .block--views-training-block-3 .read-more.rmore a:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1489px -1082px;
        width: 37px;
        height: 12px; }
  .block--views-training-block-3 .group-wrapper-outer {
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin-left: auto; }
    .block--views-training-block-3 .group-wrapper-outer:after {
      content: "";
      display: block;
      clear: both; }
  .block--views-training-block-3 .group-wrapper {
    position: relative;
    float: left;
    margin-top: 290px;
    padding-top: 180px;
    padding-bottom: 90px;
    width: calc(96px + ((100% - 160px) / 6 * 4));
    min-height: 573px;
    margin-left: calc(64px + ((100% - 160px) / 6 * 2)); }
    .block--views-training-block-3 .group-wrapper h2 {
      font-size: 1.66666rem;
      margin-bottom: 30px;
      text-transform: capitalize;
      font-weight: 700; }
  .block--views-training-block-3 .vertical-number {
    width: calc(((100% - 160px) / 6 * 1));
    margin-right: 32px;
    padding-left: 26px;
    margin-top: 200px; }
  .block--views-training-block-3 .views-row-first .group-wrapper {
    margin-left: calc(32px + ((100% - 160px) / 6 * 1)); }
  .block--views-training-block-3 .views-row-last .group-wrapper-outer {
    margin-left: 0;
    margin-right: auto; }
  .block--views-training-block-3 .full-size-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0; }
    .block--views-training-block-3 .full-size-img:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      opacity: 0.7;
      background: #100a0f; }
  .block--views-training-block-3 .views-row {
    width: 50%;
    float: left; }
    .block--views-training-block-3 .views-row .node {
      height: 1030px; }
  .block--views-training-block-3 .vertical-number {
    padding-left: 25px; }

.block--gtweaks-what-we-do:after {
  content: "";
  display: block;
  clear: both; }

.block--gtweaks-what-we-do .view-content {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(64px + ((100% - 352px) / 12 * 1));
  padding-top: 80px;
  float: left; }
  .block--gtweaks-what-we-do .view-content .box {
    position: relative;
    box-shadow: 5px 5px 16px 0 #e5e5e5;
    padding: 42px 0;
    width: calc(64px + ((100% - 256px) / 9 * 3));
    float: left;
    margin-right: 32px;
    text-align: center;
    transition: .4s; }
    .block--gtweaks-what-we-do .view-content .box:hover {
      box-shadow: 5px 5px 16px 0 #c8c8c8; }
    .block--gtweaks-what-we-do .view-content .box h2 {
      font-size: 1.55555rem;
      margin: 32px 0;
      margin-bottom: 100px;
      padding: 0 20px; }
    .block--gtweaks-what-we-do .view-content .box-image {
      width: 168px;
      height: 168px;
      background-size: contain;
      margin: 0 auto; }
    .block--gtweaks-what-we-do .view-content .box-item-1 .box-image {
      background: url("../images/whatwedo/01-uxresearch.svg"); }
    .block--gtweaks-what-we-do .view-content .box-item-2 .box-image {
      background: url("../images/whatwedo/02-uxdesign.svg"); }
    .block--gtweaks-what-we-do .view-content .box-item-3 .box-image {
      background: url("../images/whatwedo/03-uxevaluation.svg"); }
    .block--gtweaks-what-we-do .view-content .box:nth-child(3n) {
      margin-right: 0;
      margin-bottom: 32px; }
    .block--gtweaks-what-we-do .view-content .box:nth-child(3n+1) {
      clear: both; }
    .block--gtweaks-what-we-do .view-content .box:last-child {
      margin-bottom: 0; }

.block--views-companies-block, .block--views-case-studies-block {
  margin-top: 200px; }
  .block--views-companies-block:after, .block--views-case-studies-block:after {
    content: "";
    display: block;
    clear: both; }
  .block--views-companies-block .view-content, .block--views-case-studies-block .view-content {
    width: calc(256px + ((100% - 352px) / 12 * 9));
    margin-left: calc(64px + ((100% - 352px) / 12 * 2));
    padding-top: 80px;
    float: left; }

.block--views-case-studies-block {
  margin-top: 120px; }

.block--views-companies-block .view-content {
  width: calc(288px + ((100% - 352px) / 12 * 10));
  float: right;
  padding-top: 0; }
  @media (max-width: 1024px) {
    .block--views-companies-block .view-content {
      width: 100%;
      float: none;
      margin-left: 0; } }

.block--gtweaks-why-ux:after {
  content: "";
  display: block;
  clear: both; }

.block--gtweaks-why-ux .why-ux-text {
  width: calc(192px + ((100% - 256px) / 9 * 7));
  margin-bottom: 54px; }

.block--gtweaks-why-ux .box {
  box-shadow: 5px 5px 16px 0 #e5e5e5;
  width: calc(64px + ((100% - 256px) / 9 * 3));
  float: left;
  margin-right: 32px;
  position: relative;
  padding: 0 18px;
  height: 130px; }
  .block--gtweaks-why-ux .box:after {
    content: "";
    display: block;
    clear: both; }
  .block--gtweaks-why-ux .box-image {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 60px;
    width: 60px;
    margin-right: 32px;
    float: left; }
  .block--gtweaks-why-ux .box-text {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden; }
  .block--gtweaks-why-ux .box-item-1 .box-image {
    background: url("../images/whyuxicons/whyux-icons-01.svg");
    background-size: contain; }
  .block--gtweaks-why-ux .box-item-2 .box-image {
    background: url("../images/whyuxicons/whyux-icons-02.svg");
    background-size: contain; }
  .block--gtweaks-why-ux .box-item-3 .box-image {
    background: url("../images/whyuxicons/whyux-icons-03.svg");
    background-size: contain; }
  .block--gtweaks-why-ux .box-item-4 .box-image {
    background: url("../images/whyuxicons/whyux-icons-04.svg");
    background-size: contain; }
  .block--gtweaks-why-ux .box-item-5 .box-image {
    background: url("../images/whyuxicons/whyux-icons-05.svg");
    background-size: contain; }
  .block--gtweaks-why-ux .box-item-6 .box-image {
    background: url("../images/whyuxicons/whyux-icons-06.svg");
    background-size: contain; }
  .block--gtweaks-why-ux .box:nth-child(3n) {
    margin-right: 0;
    margin-bottom: 32px; }
  .block--gtweaks-why-ux .box:nth-child(3n+1) {
    clear: both; }
  .block--gtweaks-why-ux .box:last-child {
    margin-bottom: 0; }

.block--gtweaks-why-ux .block-title {
  font-size: 2.5rem;
  margin-bottom: 40px; }

.block--gtweaks-why-ux .view-content {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(64px + ((100% - 352px) / 12 * 1));
  padding-top: 80px;
  float: left; }
  @media (max-width: 360px) {
    .block--gtweaks-why-ux .view-content {
      padding-top: 30px; } }

.block--gtweaks-front-main-banner {
  background: url("../images/uxp_frontpage.png") no-repeat center center;
  background-size: cover; }
  .block--gtweaks-front-main-banner .subtitle {
    margin-bottom: 80px; }
  .block--gtweaks-front-main-banner .main-banner-button a {
    max-width: 295px;
    width: 100%; }
  @media (max-width: 1080px) {
    .block--gtweaks-front-main-banner {
      margin-bottom: 0 !important; } }
  .block--gtweaks-front-main-banner .front-main-banner-wrapper {
    text-align: center;
    color: #ffffff;
    width: calc(288px + ((100% - 352px) / 12 * 10));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .block--gtweaks-front-main-banner .block__content {
    position: relative;
    height: 855px;
    min-height: 600px;
    max-height: calc(100vh - 106px); }
    @media (max-width: 1080px) {
      .block--gtweaks-front-main-banner .block__content {
        min-height: 680px; } }
  .block--gtweaks-front-main-banner h1 {
    font-size: 2.77777rem;
    font-family: "Libre Baskerville", serif;
    line-height: 1.3;
    margin-bottom: 40px; }

.block--system-main-menu {
  float: left; }
  .block--system-main-menu:after {
    content: "";
    display: block;
    clear: both; }
  .block--system-main-menu li {
    position: relative; }

.view-case-studies.view-display-id-block .vertical-number {
  position: relative; }
  .view-case-studies.view-display-id-block .vertical-number .text {
    margin-top: -50px; }
    @media (max-width: 1024px) {
      .view-case-studies.view-display-id-block .vertical-number .text {
        margin-top: 0; } }

.view-case-studies.view-display-id-block .views-row {
  margin-bottom: 190px; }
  .view-case-studies.view-display-id-block .views-row:after {
    content: "";
    display: block;
    clear: both; }

.view-case-studies.view-display-id-block .views-row-last {
  margin-bottom: 0; }

.view-case-studies.view-display-id-block .views-row-even .group-right-wrapper {
  width: calc(160px + ((100% - 256px) / 9 * 6));
  float: left;
  margin-left: 0; }

.view-case-studies.view-display-id-block .views-row-even .group-left-wrapper-cs {
  float: right;
  width: calc(32px + ((100% - 256px) / 9 * 2));
  margin-left: 32px;
  margin-right: 0; }

.view-case-studies.view-display-id-block_1 {
  margin-bottom: 200px; }
  .view-case-studies.view-display-id-block_1:after {
    content: "";
    display: block;
    clear: both; }
  .view-case-studies.view-display-id-block_1 .view-content {
    padding-top: 80px;
    width: calc(256px + ((100% - 352px) / 12 * 9));
    margin-left: calc(32px + ((100% - 352px) / 12 * 1));
    float: left; }
  .view-case-studies.view-display-id-block_1 .vertical-number {
    position: relative;
    left: -22px; }
  .view-case-studies.view-display-id-block_1 .views-row {
    margin-bottom: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid #d7d7d7; }
    .view-case-studies.view-display-id-block_1 .views-row-last {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0; }

body {
  line-height: inherit;
  color: #100a0f; }

.l-region--header {
  position: absolute;
  right: 0;
  bottom: 0; }

.l-region--navigation {
  clear: both; }

.l-footer-wrapper {
  background: #f9f9f9; }

* {
  outline: none; }

.view-testimonials {
  position: relative;
  padding: 140px 0;
  text-align: center; }
  .view-testimonials .view-content {
    width: calc(160px + ((100% - 352px) / 12 * 6));
    margin: 0 auto; }
  .view-testimonials .field--name-field-image {
    margin-bottom: 16px; }
    .view-testimonials .field--name-field-image img {
      margin: 0 auto;
      border-radius: 50%; }
  .view-testimonials .custom-title {
    margin-bottom: 6px;
    font-family: "Montserrat", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.77777rem;
    text-transform: uppercase; }
  .view-testimonials .field--name-field-capacity {
    font-size: 0.66666rem;
    color: #747474;
    font-weight: 400; }
  .view-testimonials .field--name-body {
    margin-top: 30px;
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    line-height: 1.8; }

.l-branding {
  float: left;
  width: calc(32px + ((100% - 352px) / 12 * 2));
  margin-right: 32px;
  max-height: 79px;
  min-width: 220px;
  position: relative;
  z-index: 10; }

.site-logo {
  margin: 23px 0;
  display: block; }
  .site-logo img {
    display: block; }

.l-header,
.l-main,
.l-footer,
.l-content-2,
.grid-wrapper {
  max-width: 1600px;
  margin: 0 auto; }
  .l-header:after,
  .l-main:after,
  .l-footer:after,
  .l-content-2:after,
  .grid-wrapper:after {
    content: "";
    display: block;
    clear: both; }

#superfish-1 li,
.block--system-main-menu li {
  float: left; }
  #superfish-1 li a,
  .block--system-main-menu li a {
    font-size: 16px;
    padding: 32px 20px;
    float: left;
    color: #100a0f;
    text-decoration: none;
    position: relative; }
    @media (max-width: 1300px) {
      #superfish-1 li a,
      .block--system-main-menu li a {
        padding: 28px 10px; } }
    #superfish-1 li a:after,
    .block--system-main-menu li a:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 6px;
      background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
      background-size: 202% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
      transition: 0.3s background-position ease-out; }
    #superfish-1 li a:hover:after, #superfish-1 li a.active:after,
    .block--system-main-menu li a:hover:after,
    .block--system-main-menu li a.active:after {
      background-position: 0 0; }

.view-Companies:after {
  content: "";
  display: block;
  clear: both; }

.view-Companies .views-row {
  margin-right: 32px;
  width: calc(32px + ((100% - 288px) / 10 * 2));
  float: left; }
  .view-Companies .views-row:nth-child(5n) {
    margin-right: 0;
    margin-bottom: 50px; }
  .view-Companies .views-row:nth-child(5n+1) {
    clear: both; }
  .view-Companies .views-row:last-child {
    margin-bottom: 0; }

.view-training.view-display-id-block .views-row, .view-training.view-display-id-block_1 .views-row {
  margin-bottom: 200px; }

.view-training.view-display-id-block .view-header .vertical-number, .view-training.view-display-id-block_1 .view-header .vertical-number {
  position: relative;
  left: -45px; }

.view-training.view-display-id-block .view-content, .view-training.view-display-id-block_1 .view-content {
  padding-top: 80px;
  width: calc(256px + ((100% - 352px) / 12 * 9));
  float: left;
  margin-left: calc(64px + ((100% - 352px) / 12 * 1)); }

.view-training.view-display-id-block_1 .view-header .vertical-number {
  position: relative;
  left: -41px; }

.view-training.view-display-id-block_6 .view-empty, .view-training.view-display-id-block .view-empty {
  margin-top: 80px;
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(32px + ((100% - 352px) / 12 * 1));
  float: left;
  text-align: center;
  background-color: #fbc31e;
  position: relative;
  margin-bottom: 200px; }
  .view-training.view-display-id-block_6 .view-empty .grid-wrapper-inner, .view-training.view-display-id-block .view-empty .grid-wrapper-inner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 10; }
    .view-training.view-display-id-block_6 .view-empty .grid-wrapper-inner .custom-title, .view-training.view-display-id-block .view-empty .grid-wrapper-inner .custom-title {
      line-height: 1.4; }
    .view-training.view-display-id-block_6 .view-empty .grid-wrapper-inner .empty-desc, .view-training.view-display-id-block .view-empty .grid-wrapper-inner .empty-desc {
      margin-top: 30px;
      font-size: 1.11111rem; }
    .view-training.view-display-id-block_6 .view-empty .grid-wrapper-inner .arrow-go-down, .view-training.view-display-id-block .view-empty .grid-wrapper-inner .arrow-go-down {
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1626px -1082px;
      width: 19px;
      height: 6px;
      transform: rotate(90deg);
      margin: 50px auto 0 auto; }
      .view-training.view-display-id-block_6 .view-empty .grid-wrapper-inner .arrow-go-down:hover, .view-training.view-display-id-block_6 .view-empty .grid-wrapper-inner .arrow-go-down:focus, .view-training.view-display-id-block .view-empty .grid-wrapper-inner .arrow-go-down:hover, .view-training.view-display-id-block .view-empty .grid-wrapper-inner .arrow-go-down:focus {
        cursor: pointer; }
  .view-training.view-display-id-block_6 .view-empty .grid-wrapper, .view-training.view-display-id-block .view-empty .grid-wrapper {
    width: calc(128px + ((100% - 256px) / 9 * 5));
    margin: 0 auto;
    min-height: 555px; }
  .view-training.view-display-id-block_6 .view-empty .custom-title, .view-training.view-display-id-block .view-empty .custom-title {
    color: #000000;
    font-size: 1.94444rem; }

.view-training.view-display-id-block_5, .view-training.view-display-id-block_4 {
  margin-bottom: 200px; }
  .view-training.view-display-id-block_5:after, .view-training.view-display-id-block_4:after {
    content: "";
    display: block;
    clear: both; }
  .view-training.view-display-id-block_5 .views-row, .view-training.view-display-id-block_4 .views-row {
    width: calc(64px + ((100% - 256px) / 9 * 3));
    margin-right: 32px;
    float: left; }
    .view-training.view-display-id-block_5 .views-row:nth-child(3n), .view-training.view-display-id-block_4 .views-row:nth-child(3n) {
      margin-right: 0;
      float: right;
      margin-bottom: 32px; }
    .view-training.view-display-id-block_5 .views-row:nth-child(3n+1), .view-training.view-display-id-block_4 .views-row:nth-child(3n+1) {
      clear: both; }
    .view-training.view-display-id-block_5 .views-row:last-child, .view-training.view-display-id-block_4 .views-row:last-child {
      margin-bottom: 0; }
    @media (max-width: 1024px) {
      .view-training.view-display-id-block_5 .views-row:nth-child(3n+1), .view-training.view-display-id-block_4 .views-row:nth-child(3n+1) {
        clear: none; } }
  .view-training.view-display-id-block_5 .view-header .vertical-number, .view-training.view-display-id-block_4 .view-header .vertical-number {
    position: relative;
    left: -95px; }
  .view-training.view-display-id-block_5 .view-content, .view-training.view-display-id-block_4 .view-content {
    padding-top: 80px;
    width: calc(256px + ((100% - 352px) / 12 * 9));
    float: left;
    margin-left: calc(64px + ((100% - 352px) / 12 * 1)); }

.view-training.view-display-id-page_1 {
  position: relative;
  color: #ffffff; }
  .view-training.view-display-id-page_1:after {
    content: "";
    display: block;
    clear: both; }
  .view-training.view-display-id-page_1 .views-row {
    position: relative; }
  .view-training.view-display-id-page_1 .read-more.rmore a {
    color: #fbc31e;
    border: 1px solid #fbc31e; }
    .view-training.view-display-id-page_1 .read-more.rmore a:after {
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1489px -1082px;
      width: 37px;
      height: 12px; }
    .view-training.view-display-id-page_1 .read-more.rmore a:hover {
      color: #100a0f; }
      .view-training.view-display-id-page_1 .read-more.rmore a:hover:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1567px -1082px;
        width: 37px;
        height: 12px; }
  .view-training.view-display-id-page_1 .activew-bubble {
    text-align: right; }
  .view-training.view-display-id-page_1 .group-wrapper-outer {
    z-index: 2;
    max-width: 800px;
    margin-left: auto;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 108px);
    min-height: 400px; }
    .view-training.view-display-id-page_1 .group-wrapper-outer:after {
      content: "";
      display: block;
      clear: both; }
  .view-training.view-display-id-page_1 .group-center-meta {
    display: inline-block;
    text-align: left; }
  .view-training.view-display-id-page_1 .group-placement {
    width: calc(64px + ((100% - 160px) / 6 * 3));
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .view-training.view-display-id-page_1 .group-wrapper {
    padding-top: 140px;
    text-align: center;
    /*      top: 50%;
      transform: translateY(-50%);*/ }
    .view-training.view-display-id-page_1 .group-wrapper h2 {
      font-size: 1.66666rem;
      margin-bottom: 90px;
      text-transform: capitalize;
      font-weight: 700; }
  .view-training.view-display-id-page_1 .views-row-first .group-placement {
    left: calc(32px + ((100% - 160px) / 6 * 1)); }
  .view-training.view-display-id-page_1 .views-row-last .group-wrapper-outer {
    margin-left: 0;
    margin-right: auto; }
  .view-training.view-display-id-page_1 .views-row-last .group-placement {
    right: calc(32px + ((100% - 160px) / 6 * 1)); }
  .view-training.view-display-id-page_1 .custom-title br {
    display: none; }
  .view-training.view-display-id-page_1 .full-size-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0; }
    .view-training.view-display-id-page_1 .full-size-img:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      opacity: 0.7;
      background: #100a0f; }
  .view-training.view-display-id-page_1 .views-row {
    width: 50%;
    float: left; }

.testimonials-our-work-block .view-testimonials {
  padding: 0;
  margin: 0;
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  margin-bottom: 140px; }

.node--webform .custom-title {
  font-size: 1.77777rem;
  text-align: center;
  margin-bottom: 40px; }

.node--advanced-page--full .field--name-body, .node--page--full .field--name-body {
  font-size: 1.22222rem;
  line-height: 1.65; }

.node p,
.node ul,
.node ol {
  margin-bottom: 2em; }
  .node p:last-child,
  .node ul:last-child,
  .node ol:last-child {
    margin-bottom: 0; }

.node--full .field-collection-view {
  padding: 0;
  margin: 0;
  border: none;
  margin-bottom: 200px; }
  .node--full .field-collection-view:after {
    content: "";
    display: block;
    clear: both; }

.node--full.nnode-31 .field--name-field-collection-advanced-text .field-collection-view {
  margin-bottom: 90px; }

.node--full.nnode-31 .field--name-field-collection-advanced-text .field-collection-view-final {
  margin-bottom: 200px; }

.node--full.nnode-159 .field--name-field-strong-title, .node--full.nnode-118 .field--name-field-strong-title, .node--full.nnode-5 .field--name-field-strong-title, .node--full.nnode-6 .field--name-field-strong-title {
  font-size: 2.5rem;
  font-family: "Libre Baskerville", serif;
  line-height: 60px;
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-bottom: 60px; }
  @media (max-width: 1024px) {
    .node--full.nnode-159 .field--name-field-strong-title, .node--full.nnode-118 .field--name-field-strong-title, .node--full.nnode-5 .field--name-field-strong-title, .node--full.nnode-6 .field--name-field-strong-title {
      font-size: 2.01666rem;
      line-height: 1.3;
      width: 100%;
      margin-left: 0; } }
  @media (max-width: 480px) {
    .node--full.nnode-159 .field--name-field-strong-title, .node--full.nnode-118 .field--name-field-strong-title, .node--full.nnode-5 .field--name-field-strong-title, .node--full.nnode-6 .field--name-field-strong-title {
      font-size: 1.55555rem; } }

.node--full.nnode-159 .field--name-body, .node--full.nnode-118 .field--name-body, .node--full.nnode-5 .field--name-body, .node--full.nnode-6 .field--name-body {
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-bottom: 140px; }
  @media (max-width: 1024px) {
    .node--full.nnode-159 .field--name-body, .node--full.nnode-118 .field--name-body, .node--full.nnode-5 .field--name-body, .node--full.nnode-6 .field--name-body {
      width: 100%;
      margin-left: 0; } }

.node--full.nnode-32 .field-collection-view {
  margin-bottom: 0; }
  .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text {
    width: calc(256px + ((100% - 352px) / 12 * 9));
    margin-left: calc(32px + ((100% - 352px) / 12 * 1));
    margin-bottom: 100px;
    padding-bottom: 100px;
    border-bottom: 1px solid #d7d7d7; }
    .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text .field-collection-container {
      float: none;
      margin: 0;
      width: auto; }
    .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text .field--name-field-methods-techniques {
      clear: both;
      padding-top: 90px;
      width: calc(160px + ((100% - 256px) / 9 * 6));
      margin-left: calc(96px + ((100% - 256px) / 9 * 3)); }
      .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text .field--name-field-methods-techniques .field--name-field-collection-title {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        float: none; }
      .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text .field--name-field-methods-techniques .field--name-field-collection-body {
        width: 100%;
        margin-left: 0; }
    .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text .field--name-field-collection-title {
      width: calc(32px + ((100% - 256px) / 9 * 2));
      margin-right: calc(32px + ((100% - 256px) / 9 * 1));
      margin-left: 0; }
    .node--full.nnode-32 .field-collection-view .field-collection-item-field-collection-advanced-text .field--name-field-collection-body {
      width: calc(160px + ((100% - 256px) / 9 * 6));
      margin-left: 32px; }
  .node--full.nnode-32 .field-collection-view-final {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none; }
    .node--full.nnode-32 .field-collection-view-final .field-collection-item-field-collection-advanced-text {
      margin-bottom: 200px;
      padding-bottom: 0;
      border-bottom: none; }

.node--full.nnode-118 .field--name-body, .node--full.nnode-7 .field--name-body {
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  margin-bottom: 140px; }
  @media (max-width: 1024px) {
    .node--full.nnode-118 .field--name-body, .node--full.nnode-7 .field--name-body {
      width: 100%;
      margin-left: 0; } }

.node--full.nnode-118 .field--name-body {
  margin-bottom: 0; }

@media (max-width: 960px) {
  .node--full.nnode-118.node--full .field--name-field-text-columns .field-collection-view {
    margin-bottom: 0; } }

.node--full.nnode-118.node--full .field-collection-view {
  margin-bottom: 120px; }

.node--full.nnode-33 .field-collection-view {
  margin-bottom: 150px; }

.node--full .field-collection-container {
  border-bottom: 0;
  margin-bottom: 0; }

.node--full .field--name-field-collection-advanced-text {
  margin-top: 80px; }
  .node--full .field--name-field-collection-advanced-text .field-collection-view:after {
    content: "";
    display: block;
    clear: both; }
  .node--full .field--name-field-collection-advanced-text .field-collection-view .field--name-field-collection-title {
    padding-top: 50px;
    padding-bottom: 60px;
    font-weight: 400;
    font-family: "Libre Baskerville", serif;
    font-size: 2.5rem;
    line-height: 60px; }
    @media (max-width: 1024px) {
      .node--full .field--name-field-collection-advanced-text .field-collection-view .field--name-field-collection-title {
        padding-bottom: 20px; } }
  .node--full .field--name-field-collection-advanced-text .field-collection-view .field--name-field-collection-body,
  .node--full .field--name-field-collection-advanced-text .field-collection-view .field--name-field-body {
    line-height: 40px; }
  .node--full .field--name-field-collection-advanced-text .field-collection-view .field--name-field-collection-body img {
    display: block;
    margin: 130px auto;
    display: block; }
  .node--full .field--name-field-collection-advanced-text .field-collection-view .field-collection-container {
    width: calc(160px + ((100% - 352px) / 12 * 6));
    float: left;
    margin-left: calc(128px + ((100% - 352px) / 12 * 4));
    margin-top: 90px; }

.node--full .field--name-field-methods-techniques .field__label {
  display: block;
  margin-bottom: 30px;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 1.11111rem; }

.node--full .field--name-field-methods-techniques .field-collection-view {
  margin-bottom: 10px;
  padding-bottom: 0;
  border-bottom: none; }
  .node--full .field--name-field-methods-techniques .field-collection-view:after {
    content: "";
    display: block;
    clear: both; }
  .node--full .field--name-field-methods-techniques .field-collection-view-final {
    margin-bottom: 0; }
  .node--full .field--name-field-methods-techniques .field-collection-view .field--name-field-collection-title {
    color: #100a0f;
    float: none;
    width: 100%;
    padding: 10px 30px;
    background: #f3f3f3;
    margin-left: 0;
    font-weight: 300;
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 16px; }
  .node--full .field--name-field-methods-techniques .field-collection-view .field--name-field-collection-body,
  .node--full .field--name-field-methods-techniques .field-collection-view .field--name-field-body {
    line-height: 1.65;
    padding: 20px 40px;
    background: #f9f9f9;
    font-size: 16px; }
  .node--full .field--name-field-methods-techniques .field-collection-view .field--name-field-collection-body {
    width: 100%;
    float: none;
    margin-left: 0; }

.node--full.nnode-33 .vertical-number .yellow-title .text {
  letter-spacing: 2px; }

.node--full.nnode-33 .field--name-field-collection-advanced-text {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(32px + ((100% - 352px) / 12 * 1));
  float: left; }

.node--full.nnode-33 .field--name-body {
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-bottom: 140px; }

.node--full.nnode-33 .field-collection-view {
  padding: 80px 0;
  border-top: 1px solid #d7d7d7;
  margin-bottom: 0;
  /* Node 33 specific change in styling */ }
  .node--full.nnode-33 .field-collection-view-final {
    padding-bottom: 0; }
  .node--full.nnode-33 .field-collection-view .group-collection-placeholder {
    display: block;
    width: 100%;
    margin-left: 0; }
    .node--full.nnode-33 .field-collection-view .group-collection-placeholder:after {
      content: "";
      display: block;
      clear: both; }
  .node--full.nnode-33 .field-collection-view .group-content-right {
    width: 100%;
    margin-left: 0; }
  .node--full.nnode-33 .field-collection-view .field--name-field-collection-title {
    float: left;
    width: calc(96px + ((100% - 256px) / 9 * 4));
    margin-left: 0;
    margin-right: 32px;
    color: #fbc31e;
    padding-top: 0;
    font-weight: 400;
    font-family: "Libre Baskerville",serif;
    font-size: 1.55555rem; }
  .node--full.nnode-33 .field-collection-view .field--name-field-collection-body {
    width: calc(96px + ((100% - 256px) / 9 * 4));
    margin-left: calc(32px + ((100% - 256px) / 9 * 1));
    margin-left: 0;
    float: left;
    width: 42.95302%;
    margin-left: 11.4094%;
    float: left;
    font-size: 18px;
    line-height: 1.6; }

.node--trainer--teaser {
  position: relative;
  /*      &.node-associate-tag {
        .group-right-wrapper {
          padding: calc(50px + 2%) 0 gutter();
        }
      }*/ }
  .node--trainer--teaser .node__content {
    height: 470px; }
  .node--trainer--teaser .custom-title {
    font-size: 1.55555rem; }
  .node--trainer--teaser .field--name-field-capacity {
    color: #747474;
    margin-top: 3px; }
  .node--trainer--teaser .field--name-body {
    margin-top: 30px; }
  .node--trainer--teaser .field--name-field-image {
    width: calc(96px + ((100% - 256px) / 9 * 4));
    margin-right: 32px;
    float: left; }
    .node--trainer--teaser .field--name-field-image .field__item {
      /*          height: 450px;
          line-height: 450px;*/
      height: 470px;
      line-height: 470px; }
      .node--trainer--teaser .field--name-field-image .field__item img {
        vertical-align: bottom; }
  .node--trainer--teaser .group-right-wrapper {
    padding: 32px 0;
    font-size: 16px;
    width: calc(128px + ((100% - 256px) / 9 * 4));
    float: left; }

.node--trainer--minimal:after {
  content: "";
  display: block;
  clear: both; }

.node--trainer--minimal div.group-image {
  margin-bottom: 30px; }
  .node--trainer--minimal div.group-image:after {
    content: "";
    display: block;
    clear: both; }
  .node--trainer--minimal div.group-image .group-right-wrapper-trainer {
    position: relative;
    height: 90px;
    overflow: hidden; }
  .node--trainer--minimal div.group-image .group-train-min-inside {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .node--trainer--minimal div.group-image .custom-title {
    color: #100a0f;
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 400; }
  .node--trainer--minimal div.group-image .field--name-field-capacity {
    font-size: 0.77777rem;
    color: #747474; }
  .node--trainer--minimal div.group-image .field--name-field-image {
    float: left;
    margin-right: 20px; }
    .node--trainer--minimal div.group-image .field--name-field-image img {
      border-radius: 50%; }

.node--case-studies--full > .node__content {
  padding-top: 200px; }

.node--case-studies--full .field--name-field-body-5 {
  font-size: 1.38888rem; }
  .node--case-studies--full .field--name-field-body-5 ul li {
    margin-bottom: 0.4em; }
  .node--case-studies--full .field--name-field-body-5 ol li {
    margin-bottom: 0.4em; }

.node--case-studies--full .field--name-field-body-3 ul li {
  margin-bottom: 2em; }

.node--case-studies--full .field--name-field-body-3 ol li {
  margin-bottom: 2em; }

.node--case-studies--full .field--name-field-image {
  margin-bottom: 80px;
  width: calc(288px + ((100% - 352px) / 12 * 10));
  margin-left: calc(32px + ((100% - 352px) / 12 * 1)); }

.node--case-studies--full .group-meta {
  text-align: center;
  margin-bottom: 80px;
  width: calc(160px + ((100% - 352px) / 12 * 6));
  margin-left: calc(96px + ((100% - 352px) / 12 * 3)); }

.node--case-studies--full .custom-title,
.node--case-studies--full .field--name-field-subtitle {
  line-height: 1.2;
  font-size: 2.5rem;
  font-family: "Libre Baskerville", serif;
  margin-bottom: 3px; }

.node--case-studies--full .case-study-title {
  font-size: 1.11111rem;
  margin-bottom: 3px; }

.node--case-studies--full .field--type-text-long,
.node--case-studies--full .field--type-text-with-summary {
  margin-bottom: 100px;
  padding-bottom: 100px;
  border-bottom: 1px solid #d7d7d7;
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(32px + ((100% - 352px) / 12 * 1));
  /*        .field-collection-container {
            width: span(6);
            float: left;
            margin-left: span(4);
            margin-top: 90px;
          }*/ }
  .node--case-studies--full .field--type-text-long:after,
  .node--case-studies--full .field--type-text-with-summary:after {
    content: "";
    display: block;
    clear: both; }
  .node--case-studies--full .field--type-text-long:last-child,
  .node--case-studies--full .field--type-text-with-summary:last-child {
    margin-bottom: 200px;
    padding-bottom: 0;
    border-bottom: none; }
  .node--case-studies--full .field--type-text-long .field__label,
  .node--case-studies--full .field--type-text-with-summary .field__label {
    color: #fbc31e;
    float: left;
    width: calc(32px + ((100% - 256px) / 9 * 2));
    margin-right: 32px;
    margin-left: 0;
    font-weight: 400;
    font-family: "Libre Baskerville", serif;
    font-size: 1.55555rem; }
  .node--case-studies--full .field--type-text-long .field__items,
  .node--case-studies--full .field--type-text-with-summary .field__items {
    line-height: 1.65;
    width: calc(160px + ((100% - 256px) / 9 * 6));
    float: left;
    margin-left: calc(32px + ((100% - 256px) / 9 * 1)); }
    .node--case-studies--full .field--type-text-long .field__items img,
    .node--case-studies--full .field--type-text-with-summary .field__items img {
      display: block;
      margin: 130px auto 0;
      display: block; }

.node--case-studies--teaser:after {
  content: "";
  display: block;
  clear: both; }

.node--case-studies--teaser .read-more a:hover, .node--case-studies--teaser .read-more a:focus {
  color: #fbc31e; }
  .node--case-studies--teaser .read-more a:hover:after, .node--case-studies--teaser .read-more a:focus:after {
    background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
    background-position: -1489px -1082px;
    width: 37px;
    height: 12px; }

.node--case-studies--teaser .read-more.mobe {
  display: none; }

.node--case-studies--teaser a {
  color: #100a0f;
  text-decoration: none; }

.node--case-studies--teaser .field--name-field-logo {
  margin-bottom: 25px; }

.node--case-studies--teaser .custom-title {
  margin-bottom: 90px;
  font-size: 1.66666rem; }

.node--case-studies--teaser .group-right-wrapper {
  width: calc(160px + ((100% - 256px) / 9 * 6));
  margin-left: calc(32px + ((100% - 256px) / 9 * 1));
  float: left; }

.node--case-studies--teaser .group-left-wrapper-cs {
  width: calc(32px + ((100% - 256px) / 9 * 2));
  margin-right: 32px;
  float: left; }

.node--case-studies--minimal:after {
  content: "";
  display: block;
  clear: both; }

.node--case-studies--minimal .group-right-wrapper {
  width: calc(160px + ((100% - 256px) / 9 * 6));
  margin-left: calc(32px + ((100% - 256px) / 9 * 1));
  float: left; }

.node--case-studies--minimal .group-left-wrapper {
  width: calc(32px + ((100% - 256px) / 9 * 2));
  margin-right: 32px;
  float: left; }

.node--in-house-training--box {
  position: relative;
  text-align: center; }
  .node--in-house-training--box .field--name-field-date .field__label {
    font-weight: 300;
    font-size: 0.77777rem;
    float: none;
    display: inline-block; }
  .node--in-house-training--box .field--name-field-date .field__items {
    font-weight: 700;
    font-size: 16px;
    float: none;
    display: inline-block; }
  .node--in-house-training--box .custom-title {
    font-size: 1.38888rem;
    margin-top: 20px;
    line-height: 1.5; }
    @media (max-width: 550px) {
      .node--in-house-training--box .custom-title {
        font-size: 1rem;
        line-height: 1.6; } }
    .node--in-house-training--box .custom-title a {
      color: #ffffff;
      text-decoration: none; }
  .node--in-house-training--box .field--name-field-image {
    min-height: 555px;
    position: relative;
    overflow: hidden; }
    .node--in-house-training--box .field--name-field-image a:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(16, 10, 15, 0.85); }
    .node--in-house-training--box .field--name-field-image img {
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      max-width: none;
      transform: translateX(-50%); }
  .node--in-house-training--box .read-more {
    position: absolute;
    bottom: 108px;
    left: 50%;
    transform: translateX(-50%); }
    .node--in-house-training--box .read-more.rmore.button a {
      border: 1px solid #ffffff;
      color: #ffffff; }
      .node--in-house-training--box .read-more.rmore.button a:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1528px -1082px;
        width: 37px;
        height: 12px; }
      .node--in-house-training--box .read-more.rmore.button a:hover, .node--in-house-training--box .read-more.rmore.button a:focus {
        border: 1px solid #fbc31e;
        color: #100a0f; }
        .node--in-house-training--box .read-more.rmore.button a:hover:after, .node--in-house-training--box .read-more.rmore.button a:focus:after {
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1567px -1082px;
          width: 37px;
          height: 12px; }
  .node--in-house-training--box .group-all-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 64px 32px 32px 32px;
    color: #ffffff;
    z-index: 10; }
  @media (max-width: 550px) {
    .node--in-house-training--box {
      max-height: 400px; } }
  @media (max-width: 550px) {
    .node--in-house-training--box .group-all-info {
      padding: 32px; } }

.node--in-house-training--teaser:after {
  content: "";
  display: block;
  clear: both; }

.node--in-house-training--teaser .custom-title {
  color: #ffffff;
  font-size: 1.94444rem; }

.node--in-house-training--teaser .field--name-field-image {
  min-height: 555px;
  position: relative;
  overflow: hidden; }
  .node--in-house-training--teaser .field--name-field-image:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    background-color: #100a0f; }
  .node--in-house-training--teaser .field--name-field-image img {
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    max-width: none;
    transform: translateX(-50%); }

.node--in-house-training--teaser .group-image-wrapper {
  text-align: center;
  position: relative; }
  .node--in-house-training--teaser .group-image-wrapper .custom-title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    line-height: 1.4;
    z-index: 10; }
    .node--in-house-training--teaser .group-image-wrapper .custom-title .title-grid-wrapper {
      width: calc(128px + ((100% - 256px) / 9 * 5));
      margin: 0 auto; }

.node--in-house-training--teaser .group-left-wrapper {
  width: calc(96px + ((100% - 256px) / 9 * 3));
  float: left; }

.node--in-house-training--teaser .group-right-wrapper {
  width: calc(160px + ((100% - 256px) / 9 * 6));
  float: left;
  text-align: right;
  padding-top: 20px;
  padding-right: 35px; }
  .node--in-house-training--teaser .group-right-wrapper .button {
    display: inline-block; }
    .node--in-house-training--teaser .group-right-wrapper .button.read-more {
      margin-right: 10px;
      margin-bottom: 10px; }

.node--in-house-training--teaser .group-info {
  margin: 40px 0 80px; }
  .node--in-house-training--teaser .group-info:after {
    content: "";
    display: block;
    clear: both; }
  .node--in-house-training--teaser .group-info .field {
    float: left;
    margin-right: 20px; }
    .node--in-house-training--teaser .group-info .field__label {
      font-weight: 300;
      font-size: 0.77777rem;
      position: relative;
      top: 2px; }
    .node--in-house-training--teaser .group-info .field__items {
      font-weight: 700;
      font-size: 16px; }
    .node--in-house-training--teaser .group-info .field:last-child {
      margin-right: 0; }

.node--in-house-training--full {
  width: calc(288px + ((100% - 352px) / 12 * 10));
  margin: 0 auto;
  line-height: 1.65; }
  .node--in-house-training--full > .node__content {
    padding-top: 200px; }
  .node--in-house-training--full .addthis-mobile {
    margin-top: -10px;
    margin-bottom: 60px; }
  .node--in-house-training--full .field--name-field-intro-text {
    margin-bottom: 100px; }
  .node--in-house-training--full .group-pricing-wrapper {
    display: block; }
  .node--in-house-training--full .group-content-bounds:after {
    content: "";
    display: block;
    clear: both; }
  .node--in-house-training--full .field--name-field-body-4 {
    margin-bottom: 100px; }
  .node--in-house-training--full .group-image-wrapper .field--name-field-image {
    min-height: 618px;
    position: relative;
    overflow: hidden; }
    .node--in-house-training--full .group-image-wrapper .field--name-field-image:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.7;
      background-color: #100a0f; }
    .node--in-house-training--full .group-image-wrapper .field--name-field-image img {
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      max-width: none;
      transform: translateX(-50%);
      width: 100%;
      display: block; }
  .node--in-house-training--full .group-right-wrapper .field__label {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 1.11111rem; }
  .node--in-house-training--full .field-collection-view {
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: none; }
    .node--in-house-training--full .field-collection-view:after {
      content: "";
      display: block;
      clear: both; }
    .node--in-house-training--full .field-collection-view-final {
      margin-bottom: 0; }
    .node--in-house-training--full .field-collection-view .field--name-field-collection-title {
      color: #100a0f;
      float: none;
      width: 100%;
      padding: 10px 40px;
      background: #f3f3f3;
      margin-left: 0;
      font-weight: 300;
      font-family: "Montserrat", "Arial", sans-serif;
      font-size: 16px; }
    .node--in-house-training--full .field-collection-view .field--name-field-collection-body,
    .node--in-house-training--full .field-collection-view .field--name-field-body {
      line-height: 1.65;
      padding: 20px;
      background: #f9f9f9;
      font-size: 16px; }
    .node--in-house-training--full .field-collection-view .field--name-field-collection-body {
      width: 100%;
      float: none;
      margin-left: 0; }
  .node--in-house-training--full .field--name-body,
  .node--in-house-training--full .field--name-field-body-2,
  .node--in-house-training--full .field--name-field-body-3 {
    margin-bottom: 100px;
    padding-bottom: 100px; }
  .node--in-house-training--full .field--name-field-intro-text {
    font-size: 1.22222rem;
    color: #757575;
    font-weight: 400; }
  .node--in-house-training--full .like-this {
    font-weight: 400;
    font-size: 1.11111rem;
    margin-bottom: 10px; }
    .node--in-house-training--full .like-this .lk-2 {
      font-weight: 700; }
  .node--in-house-training--full .group-meta {
    width: calc(160px + ((100% - 288px) / 10 * 6));
    margin-left: calc(64px + ((100% - 288px) / 10 * 2));
    text-align: center;
    margin-bottom: 130px; }
  .node--in-house-training--full .custom-title {
    font-size: 2.5rem;
    line-height: 65px; }
    @media (max-width: 1024px) {
      .node--in-house-training--full .custom-title {
        line-height: 1.3;
        font-size: 1.815rem; } }
  .node--in-house-training--full .field--name-field-date {
    font-size: 1.11111rem;
    margin-bottom: 10px;
    text-align: center; }
    .node--in-house-training--full .field--name-field-date .field__label,
    .node--in-house-training--full .field--name-field-date .field__items {
      display: inline-block;
      float: none;
      font-weight: 300; }
  .node--in-house-training--full .group-left-wrapper {
    width: calc(64px + ((100% - 288px) / 10 * 3));
    margin-right: 32px;
    float: left;
    text-align: left; }
  .node--in-house-training--full .group-right-wrapper {
    float: left;
    width: calc(160px + ((100% - 288px) / 10 * 6)); }
  .node--in-house-training--full .group-pricing-wrapper legend {
    display: block;
    margin-bottom: 10px;
    font-size: 1.11111rem;
    font-weight: 700; }
  .node--in-house-training--full .group-pricing-wrapper .field--type-number-integer {
    margin-bottom: 25px; }
    .node--in-house-training--full .group-pricing-wrapper .field--type-number-integer:last-child {
      margin-bottom: 0; }
  .node--in-house-training--full .addthis-wrapper {
    margin-top: 60px; }
  .node--in-house-training--full .group-image-wrapper {
    text-align: center;
    position: relative;
    margin-bottom: 80px;
    overflow: hidden; }
    .node--in-house-training--full .group-image-wrapper .group-image-align {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      color: #ffffff;
      width: calc(224px + ((100% - 288px) / 10 * 8));
      font-size: 1.38888rem; }
      .node--in-house-training--full .group-image-wrapper .group-image-align .field__label,
      .node--in-house-training--full .group-image-wrapper .group-image-align .field__items {
        display: inline-block;
        float: none; }
      .node--in-house-training--full .group-image-wrapper .group-image-align .field__label {
        font-weight: 400; }
      .node--in-house-training--full .group-image-wrapper .group-image-align .field__items {
        font-weight: 700; }
  .node--in-house-training--full.node-archived-workshop .custom-title {
    font-size: 2.5rem;
    color: #100a0f; }

.node--webinars--box {
  position: relative;
  text-align: center; }
  .node--webinars--box .field--name-field-date .field__label {
    font-weight: 300;
    font-size: 0.77777rem;
    float: none;
    display: inline-block; }
  .node--webinars--box .field--name-field-date .field__items {
    font-weight: 700;
    font-size: 16px;
    float: none;
    display: inline-block; }
  .node--webinars--box .custom-title {
    font-size: 1.38888rem;
    margin-top: 20px;
    line-height: 1.5; }
    @media (max-width: 550px) {
      .node--webinars--box .custom-title {
        font-size: 1rem;
        line-height: 1.6; } }
    .node--webinars--box .custom-title a {
      color: #ffffff;
      text-decoration: none; }
  .node--webinars--box .field--name-field-image {
    min-height: 555px;
    position: relative;
    overflow: hidden; }
    .node--webinars--box .field--name-field-image a:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(16, 10, 15, 0.85); }
    .node--webinars--box .field--name-field-image img {
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      max-width: none;
      transform: translateX(-50%); }
  .node--webinars--box .read-more {
    position: absolute;
    bottom: 108px;
    left: 50%;
    transform: translateX(-50%); }
    .node--webinars--box .read-more.rmore.button a {
      border: 1px solid #ffffff;
      color: #ffffff; }
      .node--webinars--box .read-more.rmore.button a:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1528px -1082px;
        width: 37px;
        height: 12px; }
      .node--webinars--box .read-more.rmore.button a:hover, .node--webinars--box .read-more.rmore.button a:focus {
        border: 1px solid #fbc31e;
        color: #100a0f; }
        .node--webinars--box .read-more.rmore.button a:hover:after, .node--webinars--box .read-more.rmore.button a:focus:after {
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1567px -1082px;
          width: 37px;
          height: 12px; }
  .node--webinars--box .group-all-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 64px 32px 32px 32px;
    color: #ffffff;
    z-index: 10; }
  @media (max-width: 550px) {
    .node--webinars--box {
      max-height: 400px; } }
  @media (max-width: 550px) {
    .node--webinars--box .group-all-info {
      padding: 32px; } }

.node--webinars--full, .node--public-workshops--full {
  width: calc(288px + ((100% - 352px) / 12 * 10));
  margin: 0 auto;
  line-height: 1.65; }
  .node--webinars--full > .node__content, .node--public-workshops--full > .node__content {
    padding-top: 200px; }
  .node--webinars--full .addthis-mobile, .node--public-workshops--full .addthis-mobile {
    margin-top: -10px;
    margin-bottom: 60px; }
  .node--webinars--full .field--name-field-map .map-wrap, .node--public-workshops--full .field--name-field-map .map-wrap {
    overflow: visible; }
    .node--webinars--full .field--name-field-map .map-wrap-1, .node--public-workshops--full .field--name-field-map .map-wrap-1 {
      background: #100a0f;
      height: 470px;
      position: relative; }
    .node--webinars--full .field--name-field-map .map-wrap-2, .node--public-workshops--full .field--name-field-map .map-wrap-2 {
      position: relative;
      width: calc(128px + ((100% - 160px) / 6 * 5)); }
    .node--webinars--full .field--name-field-map .map-wrap-fields, .node--public-workshops--full .field--name-field-map .map-wrap-fields {
      color: #ffffff;
      padding: 40px 0;
      width: calc(96px + ((100% - 160px) / 6 * 4));
      margin: 0 auto; }
      .node--webinars--full .field--name-field-map .map-wrap-fields:after, .node--public-workshops--full .field--name-field-map .map-wrap-fields:after {
        content: "";
        display: block;
        clear: both; }
      .node--webinars--full .field--name-field-map .map-wrap-fields .field--name-field-location, .node--public-workshops--full .field--name-field-map .map-wrap-fields .field--name-field-location {
        width: 62%;
        margin-right: 4%;
        float: left; }
        .node--webinars--full .field--name-field-map .map-wrap-fields .field--name-field-location .field__label, .node--public-workshops--full .field--name-field-map .map-wrap-fields .field--name-field-location .field__label {
          font-size: 0.77777rem;
          margin-bottom: 0;
          font-weight: 400;
          position: relative;
          top: 2px; }
        .node--webinars--full .field--name-field-map .map-wrap-fields .field--name-field-location .field__items, .node--public-workshops--full .field--name-field-map .map-wrap-fields .field--name-field-location .field__items {
          font-size: 16px;
          font-weight: 700; }
      .node--webinars--full .field--name-field-map .map-wrap-fields .visit-website-pw, .node--public-workshops--full .field--name-field-map .map-wrap-fields .visit-website-pw {
        font-size: 0.77777rem;
        float: right;
        width: 34%;
        text-align: right;
        position: relative;
        top: 2px; }
        .node--webinars--full .field--name-field-map .map-wrap-fields .visit-website-pw:after, .node--public-workshops--full .field--name-field-map .map-wrap-fields .visit-website-pw:after {
          content: "";
          display: inline-block;
          margin-left: 18px;
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1356px -1122px;
          width: 18px;
          height: 15px;
          position: relative;
          top: 2px; }
      .node--webinars--full .field--name-field-map .map-wrap-fields a, .node--public-workshops--full .field--name-field-map .map-wrap-fields a {
        color: #ffffff;
        text-decoration: none; }
  .node--webinars--full .field--name-field-intro-text, .node--public-workshops--full .field--name-field-intro-text {
    margin-bottom: 100px; }
  .node--webinars--full .group-pricing-wrapper, .node--public-workshops--full .group-pricing-wrapper {
    display: block; }
  .node--webinars--full .group-content-bounds:after, .node--public-workshops--full .group-content-bounds:after {
    content: "";
    display: block;
    clear: both; }
  .node--webinars--full .field--name-field-map, .node--public-workshops--full .field--name-field-map {
    margin: 100px 0; }
  .node--webinars--full .field--name-field-body-4, .node--public-workshops--full .field--name-field-body-4 {
    margin-bottom: 100px; }
  .node--webinars--full .field--name-field-entity-ref-trainer > .field__items > .field__item, .node--public-workshops--full .field--name-field-entity-ref-trainer > .field__items > .field__item {
    margin-bottom: 100px; }
    .node--webinars--full .field--name-field-entity-ref-trainer > .field__items > .field__item:last-child, .node--public-workshops--full .field--name-field-entity-ref-trainer > .field__items > .field__item:last-child {
      margin-bottom: 0; }
  .node--webinars--full .group-image-wrapper .field--name-field-image, .node--public-workshops--full .group-image-wrapper .field--name-field-image {
    min-height: 618px;
    position: relative;
    overflow: hidden; }
    .node--webinars--full .group-image-wrapper .field--name-field-image:after, .node--public-workshops--full .group-image-wrapper .field--name-field-image:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.7;
      background-color: #100a0f; }
    .node--webinars--full .group-image-wrapper .field--name-field-image img, .node--public-workshops--full .group-image-wrapper .field--name-field-image img {
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      max-width: none;
      transform: translateX(-50%); }
  .node--webinars--full .group-right-wrapper .field__label, .node--public-workshops--full .group-right-wrapper .field__label {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 1.11111rem; }
  .node--webinars--full .field-collection-view, .node--public-workshops--full .field-collection-view {
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: none; }
    .node--webinars--full .field-collection-view:after, .node--public-workshops--full .field-collection-view:after {
      content: "";
      display: block;
      clear: both; }
    .node--webinars--full .field-collection-view-final, .node--public-workshops--full .field-collection-view-final {
      margin-bottom: 0; }
    .node--webinars--full .field-collection-view .field--name-field-collection-title, .node--public-workshops--full .field-collection-view .field--name-field-collection-title {
      color: #100a0f;
      float: none;
      width: 100%;
      padding: 10px 40px;
      background: #f3f3f3;
      margin-left: 0;
      font-weight: 300;
      font-family: "Montserrat", "Arial", sans-serif;
      font-size: 16px; }
    .node--webinars--full .field-collection-view .field--name-field-collection-body,
    .node--webinars--full .field-collection-view .field--name-field-body, .node--public-workshops--full .field-collection-view .field--name-field-collection-body,
    .node--public-workshops--full .field-collection-view .field--name-field-body {
      line-height: 1.65;
      padding: 20px;
      background: #f9f9f9;
      font-size: 16px; }
    .node--webinars--full .field-collection-view .field--name-field-collection-body, .node--public-workshops--full .field-collection-view .field--name-field-collection-body {
      width: 100%;
      float: none;
      margin-left: 0; }
  .node--webinars--full .field--name-body,
  .node--webinars--full .field--name-field-body-2,
  .node--webinars--full .field--name-field-body-3, .node--public-workshops--full .field--name-body,
  .node--public-workshops--full .field--name-field-body-2,
  .node--public-workshops--full .field--name-field-body-3 {
    margin-bottom: 100px;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 100px; }
  .node--webinars--full .field--name-field-intro-text, .node--public-workshops--full .field--name-field-intro-text {
    font-size: 1.22222rem;
    color: #757575;
    font-weight: 400; }
  .node--webinars--full .like-this, .node--public-workshops--full .like-this {
    font-weight: 400;
    font-size: 1.11111rem;
    margin-bottom: 10px; }
    .node--webinars--full .like-this .lk-2, .node--public-workshops--full .like-this .lk-2 {
      font-weight: 700; }
  .node--webinars--full .group-meta, .node--public-workshops--full .group-meta {
    width: calc(160px + ((100% - 288px) / 10 * 6));
    margin-left: calc(64px + ((100% - 288px) / 10 * 2));
    text-align: center;
    margin-bottom: 130px; }
  .node--webinars--full .custom-title, .node--public-workshops--full .custom-title {
    font-size: 2.5rem; }
  .node--webinars--full .field--name-field-date, .node--public-workshops--full .field--name-field-date {
    font-size: 1.11111rem;
    margin-bottom: 10px;
    text-align: center; }
    .node--webinars--full .field--name-field-date .field__label,
    .node--webinars--full .field--name-field-date .field__items, .node--public-workshops--full .field--name-field-date .field__label,
    .node--public-workshops--full .field--name-field-date .field__items {
      display: inline-block;
      float: none;
      font-weight: 300; }
  .node--webinars--full .group-left-wrapper, .node--public-workshops--full .group-left-wrapper {
    width: calc(64px + ((100% - 288px) / 10 * 3));
    margin-right: 32px;
    float: left;
    text-align: left; }
  .node--webinars--full .group-right-wrapper, .node--public-workshops--full .group-right-wrapper {
    float: left;
    width: calc(160px + ((100% - 288px) / 10 * 6)); }
  .node--webinars--full .group-pricing-wrapper legend, .node--public-workshops--full .group-pricing-wrapper legend {
    display: block;
    margin-bottom: 10px;
    font-size: 1.11111rem;
    font-weight: 700; }
  .node--webinars--full .group-pricing-wrapper .field--type-number-integer, .node--public-workshops--full .group-pricing-wrapper .field--type-number-integer {
    margin-bottom: 25px; }
    .node--webinars--full .group-pricing-wrapper .field--type-number-integer:last-child, .node--public-workshops--full .group-pricing-wrapper .field--type-number-integer:last-child {
      margin-bottom: 0; }
  .node--webinars--full .addthis-wrapper, .node--public-workshops--full .addthis-wrapper {
    margin-top: 60px; }
  .node--webinars--full .group-image-wrapper, .node--public-workshops--full .group-image-wrapper {
    text-align: center;
    position: relative;
    margin-bottom: 80px;
    overflow: hidden; }
    .node--webinars--full .group-image-wrapper .group-image-align, .node--public-workshops--full .group-image-wrapper .group-image-align {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      color: #ffffff;
      width: calc(224px + ((100% - 288px) / 10 * 8));
      font-size: 1.38888rem; }
      .node--webinars--full .group-image-wrapper .group-image-align .field__label,
      .node--webinars--full .group-image-wrapper .group-image-align .field__items, .node--public-workshops--full .group-image-wrapper .group-image-align .field__label,
      .node--public-workshops--full .group-image-wrapper .group-image-align .field__items {
        display: inline-block;
        float: none; }
      .node--webinars--full .group-image-wrapper .group-image-align .field__label, .node--public-workshops--full .group-image-wrapper .group-image-align .field__label {
        font-weight: 400; }
      .node--webinars--full .group-image-wrapper .group-image-align .field__items, .node--public-workshops--full .group-image-wrapper .group-image-align .field__items {
        font-weight: 700; }
  .node--webinars--full.node-archived-workshop .custom-title, .node--public-workshops--full.node-archived-workshop .custom-title {
    font-size: 2.5rem;
    color: #100a0f; }

.node--webinars--box, .node--public-workshops--box {
  position: relative;
  text-align: center; }
  .node--webinars--box .field--name-field-date .field__label, .node--public-workshops--box .field--name-field-date .field__label {
    font-weight: 300;
    font-size: 0.77777rem;
    float: none;
    display: inline-block; }
  .node--webinars--box .field--name-field-date .field__items, .node--public-workshops--box .field--name-field-date .field__items {
    font-weight: 700;
    font-size: 16px;
    float: none;
    display: inline-block; }
  .node--webinars--box .custom-title, .node--public-workshops--box .custom-title {
    font-size: 1.38888rem;
    margin-top: 20px;
    line-height: 1.5; }
    @media (max-width: 550px) {
      .node--webinars--box .custom-title, .node--public-workshops--box .custom-title {
        font-size: 1rem;
        line-height: 1.6; } }
    .node--webinars--box .custom-title a, .node--public-workshops--box .custom-title a {
      color: #ffffff;
      text-decoration: none; }
  .node--webinars--box .field--name-field-image, .node--public-workshops--box .field--name-field-image {
    min-height: 555px;
    position: relative;
    overflow: hidden; }
    .node--webinars--box .field--name-field-image a:after, .node--public-workshops--box .field--name-field-image a:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(16, 10, 15, 0.85); }
    .node--webinars--box .field--name-field-image img, .node--public-workshops--box .field--name-field-image img {
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      max-width: none;
      transform: translateX(-50%); }
  .node--webinars--box .read-more, .node--public-workshops--box .read-more {
    position: absolute;
    bottom: 108px;
    left: 50%;
    transform: translateX(-50%); }
    .node--webinars--box .read-more.rmore.button a, .node--public-workshops--box .read-more.rmore.button a {
      border: 1px solid #ffffff;
      color: #ffffff; }
      .node--webinars--box .read-more.rmore.button a:after, .node--public-workshops--box .read-more.rmore.button a:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1528px -1082px;
        width: 37px;
        height: 12px; }
      .node--webinars--box .read-more.rmore.button a:hover, .node--webinars--box .read-more.rmore.button a:focus, .node--public-workshops--box .read-more.rmore.button a:hover, .node--public-workshops--box .read-more.rmore.button a:focus {
        border: 1px solid #fbc31e;
        color: #100a0f; }
        .node--webinars--box .read-more.rmore.button a:hover:after, .node--webinars--box .read-more.rmore.button a:focus:after, .node--public-workshops--box .read-more.rmore.button a:hover:after, .node--public-workshops--box .read-more.rmore.button a:focus:after {
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1567px -1082px;
          width: 37px;
          height: 12px; }
  .node--webinars--box .group-all-info, .node--public-workshops--box .group-all-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 64px 32px 32px 32px;
    color: #ffffff;
    z-index: 10; }
  .node--webinars--box .button.request_quote a, .node--public-workshops--box .button.request_quote a {
    padding: 17px 22px; }
  .node--webinars--box .read-more a, .node--public-workshops--box .read-more a {
    padding: 17px 22px; }

.node--webinars--teaser:after, .node--public-workshops--teaser:after {
  content: "";
  display: block;
  clear: both; }

.node--webinars--teaser .custom-title, .node--public-workshops--teaser .custom-title {
  color: #ffffff;
  font-size: 1.94444rem; }

.node--webinars--teaser .read-more, .node--public-workshops--teaser .read-more {
  clear: both;
  padding-top: 60px; }
  .node--webinars--teaser .read-more a, .node--public-workshops--teaser .read-more a {
    padding: 17px 22px; }

.node--webinars--teaser .field--name-field-image, .node--public-workshops--teaser .field--name-field-image {
  min-height: 555px;
  position: relative;
  overflow: hidden; }
  .node--webinars--teaser .field--name-field-image a:after, .node--public-workshops--teaser .field--name-field-image a:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    background-color: #100a0f; }
  .node--webinars--teaser .field--name-field-image img, .node--public-workshops--teaser .field--name-field-image img {
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    max-width: none;
    transform: translateX(-50%); }

.node--webinars--teaser .group-image-wrapper, .node--public-workshops--teaser .group-image-wrapper {
  text-align: center;
  position: relative; }
  .node--webinars--teaser .group-image-wrapper .custom-title, .node--public-workshops--teaser .group-image-wrapper .custom-title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    line-height: 1.4;
    z-index: 10; }
    .node--webinars--teaser .group-image-wrapper .custom-title a, .node--public-workshops--teaser .group-image-wrapper .custom-title a {
      color: #ffffff;
      text-decoration: none; }
    .node--webinars--teaser .group-image-wrapper .custom-title .title-grid-wrapper, .node--public-workshops--teaser .group-image-wrapper .custom-title .title-grid-wrapper {
      width: calc(128px + ((100% - 256px) / 9 * 5));
      margin: 0 auto; }

.node--webinars--teaser .group-left-wrapper, .node--public-workshops--teaser .group-left-wrapper {
  width: calc(160px + ((100% - 256px) / 9 * 6));
  float: left;
  margin-right: 32px; }

.node--webinars--teaser .field--name-field-intro-text, .node--public-workshops--teaser .field--name-field-intro-text {
  font-size: 1.22222rem;
  color: #757575;
  font-weight: 400; }

.node--webinars--teaser .group-right-wrapper, .node--public-workshops--teaser .group-right-wrapper {
  width: calc(64px + ((100% - 256px) / 9 * 3));
  float: left;
  text-align: right;
  padding-top: 40px; }
  .node--webinars--teaser .group-right-wrapper .field--type-number-integer,
  .node--webinars--teaser .group-right-wrapper .field--type-number-integer, .node--public-workshops--teaser .group-right-wrapper .field--type-number-integer,
  .node--public-workshops--teaser .group-right-wrapper .field--type-number-integer {
    margin-bottom: 25px; }

.node--webinars--teaser .group-info, .node--public-workshops--teaser .group-info {
  margin: 40px 0 40px; }
  .node--webinars--teaser .group-info:after, .node--public-workshops--teaser .group-info:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 600px) {
    .node--webinars--teaser .group-info, .node--public-workshops--teaser .group-info {
      margin-top: 10px;
      margin-bottom: 20px; } }
  .node--webinars--teaser .group-info .field, .node--public-workshops--teaser .group-info .field {
    float: left;
    margin-right: 20px; }
    .node--webinars--teaser .group-info .field__label, .node--public-workshops--teaser .group-info .field__label {
      font-weight: 300;
      font-size: 0.77777rem;
      position: relative;
      top: 2px; }
    .node--webinars--teaser .group-info .field__items, .node--public-workshops--teaser .group-info .field__items {
      font-weight: 700;
      font-size: 16px; }
    .node--webinars--teaser .group-info .field:last-child, .node--public-workshops--teaser .group-info .field:last-child {
      margin-right: 0; }

.node--webinars--minimal:after, .node--public-workshops--minimal:after {
  content: "";
  display: block;
  clear: both; }

.node--webinars--minimal .field--name-field-pw-product-reference .activated-price,
.node--webinars--minimal .field--name-field-early-product-ref .activated-price,
.node--webinars--minimal .field--name-field-early-bird-price .activated-price,
.node--webinars--minimal .field--name-field-standard-price .activated-price, .node--public-workshops--minimal .field--name-field-pw-product-reference .activated-price,
.node--public-workshops--minimal .field--name-field-early-product-ref .activated-price,
.node--public-workshops--minimal .field--name-field-early-bird-price .activated-price,
.node--public-workshops--minimal .field--name-field-standard-price .activated-price {
  background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #ffffff 50%, #ffffff);
  background-repeat: none; }
  .node--webinars--minimal .field--name-field-pw-product-reference .activated-price:hover, .node--webinars--minimal .field--name-field-pw-product-reference .activated-price:focus,
  .node--webinars--minimal .field--name-field-early-product-ref .activated-price:hover,
  .node--webinars--minimal .field--name-field-early-product-ref .activated-price:focus,
  .node--webinars--minimal .field--name-field-early-bird-price .activated-price:hover,
  .node--webinars--minimal .field--name-field-early-bird-price .activated-price:focus,
  .node--webinars--minimal .field--name-field-standard-price .activated-price:hover,
  .node--webinars--minimal .field--name-field-standard-price .activated-price:focus, .node--public-workshops--minimal .field--name-field-pw-product-reference .activated-price:hover, .node--public-workshops--minimal .field--name-field-pw-product-reference .activated-price:focus,
  .node--public-workshops--minimal .field--name-field-early-product-ref .activated-price:hover,
  .node--public-workshops--minimal .field--name-field-early-product-ref .activated-price:focus,
  .node--public-workshops--minimal .field--name-field-early-bird-price .activated-price:hover,
  .node--public-workshops--minimal .field--name-field-early-bird-price .activated-price:focus,
  .node--public-workshops--minimal .field--name-field-standard-price .activated-price:hover,
  .node--public-workshops--minimal .field--name-field-standard-price .activated-price:focus {
    color: #fbc31e; }

.node--webinars--minimal .group-left-wrapper, .node--public-workshops--minimal .group-left-wrapper {
  width: calc(160px + ((100% - 352px) / 12 * 6));
  float: left; }

.node--webinars--minimal .custom-title, .node--public-workshops--minimal .custom-title {
  width: calc(160px + ((100% - 352px) / 12 * 6)); }

.node--webinars--minimal .group-right-wrapper, .node--public-workshops--minimal .group-right-wrapper {
  width: calc(96px + ((100% - 352px) / 12 * 4));
  float: right;
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  text-align: right; }

.node--webinars--minimal .group-info:after, .node--public-workshops--minimal .group-info:after {
  content: "";
  display: block;
  clear: both; }

.node--webinars--minimal .group-info .field, .node--public-workshops--minimal .group-info .field {
  float: left;
  margin-right: 20px; }
  .node--webinars--minimal .group-info .field__label, .node--public-workshops--minimal .group-info .field__label {
    font-weight: 300;
    font-size: 0.77777rem;
    position: relative;
    top: 2px; }
  .node--webinars--minimal .group-info .field__items, .node--public-workshops--minimal .group-info .field__items {
    font-weight: 700;
    font-size: 16px; }
  .node--webinars--minimal .group-info .field:last-child, .node--public-workshops--minimal .group-info .field:last-child {
    margin-right: 0; }

.node--webinars--minimal .field--name-body, .node--public-workshops--minimal .field--name-body {
  margin-bottom: 50px; }

.node--webinars--minimal .custom-title, .node--public-workshops--minimal .custom-title {
  font-size: 1.55555rem;
  margin-bottom: 25px; }
  .node--webinars--minimal .custom-title a, .node--public-workshops--minimal .custom-title a {
    color: #100a0f;
    text-decoration: none; }

.social-media-icons a {
  margin-right: 10px;
  float: left; }
  .social-media-icons a:last-child {
    margin-right: 0; }

.social-media-icons__item--linkedin {
  background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
  background-position: -1200px -1122px;
  width: 32px;
  height: 32px; }

.social-media-icons__item--twitter {
  background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
  background-position: -1302px -1122px;
  width: 31px;
  height: 32px; }

.social-media-icons__item--facebook {
  background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
  background-position: -1268px -1122px;
  width: 32px;
  height: 32px; }

.social-media-icons__item--instagram {
  background: url(/sites/all/themes/theuxprodigy/images/instagram.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px; }

.equal-height {
  min-height: 73px; }

.rmore-plain a {
  background: none; }

.view-header {
  float: left;
  width: calc(((100% - 352px) / 12 * 1)); }
  .view-header:after {
    content: "";
    display: block;
    clear: both; }
  .view-header .vertical-number {
    float: none;
    width: 100%; }

.views-row-content:after {
  content: "";
  display: block;
  clear: both; }

@media (max-width: 500px) {
  .views-row-content {
    width: calc(288px + ((100% - 352px) / 12 * 10));
    margin: 0 auto; } }

@media (max-width: 1024px) {
  #block-views-testimonials-block .view-header {
    padding-bottom: 80px; } }

.group-collection-placeholder .collection-number:after {
  content: "";
  display: block;
  clear: both; }

.group-content-right {
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2)); }
  .group-content-right .field--name-field-collection-body {
    font-size: 22px; }
  @media (max-width: 1024px) {
    .group-content-right {
      width: 100%;
      margin-left: 0; } }

.field-rotate .collection-number {
  float: left;
  width: 6.5%;
  margin-top: -30px; }
  .field-rotate .collection-number:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 1024px) {
    .field-rotate .collection-number {
      width: 100%; } }
  .field-rotate .collection-number .vertical-number {
    width: auto;
    float: none; }
    .field-rotate .collection-number .vertical-number .text {
      top: 130px; }
      @media (max-width: 1024px) {
        .field-rotate .collection-number .vertical-number .text {
          top: 0; } }

.field--name-field-text-columns {
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2)); }
  .field--name-field-text-columns:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 1024px) {
    .field--name-field-text-columns {
      width: 100%;
      margin-left: 0;
      margin-bottom: 60px; }
      .field--name-field-text-columns > .field__items > .field__item {
        margin-bottom: 20px; } }
  .field--name-field-text-columns .field__item {
    float: left;
    width: 33.33%;
    padding-left: 70px;
    line-height: 26px; }
    @media (max-width: 960px) {
      .field--name-field-text-columns .field__item {
        width: 100%;
        padding-left: 0; } }
    .field--name-field-text-columns .field__item:first-child {
      padding-right: 30px;
      padding-left: 0; }
    .field--name-field-text-columns .field__item:last-child {
      margin-right: 0; }
    .field--name-field-text-columns .field__item .field--name-field-column-body {
      font-size: 16px; }
      .field--name-field-text-columns .field__item .field--name-field-column-body:after {
        content: "";
        display: block;
        clear: both; }
      .field--name-field-text-columns .field__item .field--name-field-column-body .field__item {
        width: 100%; }
    .field--name-field-text-columns .field__item .field--name-field-column-title {
      font-weight: 600; }
      .field--name-field-text-columns .field__item .field--name-field-column-title .field__item {
        display: block;
        width: 100%; }

@media (max-width: 1024px) {
  #block-block-2 {
    text-align: center; } }

.services-cta {
  width: calc(192px + ((100% - 352px) / 12 * 7));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2)); }
  @media (max-width: 1024px) {
    .services-cta {
      width: calc(288px + ((100% - 352px) / 12 * 10));
      margin-left: 0;
      display: inline-block; } }
  .services-cta .container {
    padding: 80px 0;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    margin-bottom: 250px;
    margin-top: 80px; }
    .services-cta .container:after {
      content: "";
      display: block;
      clear: both; }
    @media (max-width: 700px) {
      .services-cta .container {
        padding: 40px 0;
        margin-top: 0; } }
  .services-cta .button {
    float: right; }
    .services-cta .button a:hover {
      color: #fbc31e; }
      .services-cta .button a:hover:after {
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1489px -1082px;
        width: 37px;
        height: 12px; }
    @media (max-width: 700px) {
      .services-cta .button {
        float: none;
        margin-top: 50px; } }
  .services-cta .custom-block-title {
    float: left;
    font-size: 2.5rem;
    font-family: "Libre Baskerville", serif;
    line-height: 60px; }
    @media (max-width: 1024px) {
      .services-cta .custom-block-title {
        font-size: 2.01666rem;
        line-height: 1.3; } }
    @media (max-width: 700px) {
      .services-cta .custom-block-title {
        float: none; } }

.header-actions {
  position: fixed;
  width: 100%;
  z-index: 9; }
  .header-actions:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 820px) {
    .header-actions {
      top: 70px; } }
  @media (max-width: 700px) {
    .header-actions {
      display: none; } }
  .header-actions li a {
    float: left;
    width: 25%;
    text-align: center;
    background: #f2f2f2;
    padding: 20px;
    border-right: 1px solid #d4d4d4;
    font-family: "Libre Baskerville", serif; }
    @media (max-width: 1030px) {
      .header-actions li a {
        min-height: 88px; } }
  @media (max-width: 1030px) {
    .header-actions li:not(:nth-child(2)) a {
      line-height: 88px;
      padding: 0; } }
  .header-actions li a:link, .header-actions li a:visited, .header-actions li a:active {
    text-decoration: none;
    color: #100a0f;
    font-weight: ""; }
  .header-actions li a:hover, .header-actions li a:focus {
    color: #100a0f;
    text-decoration: ""; }
  .header-actions li:last-child {
    border-right: none; }

/* Services Content */
#block-views-services-block {
  padding-top: 260px; }
  @media (max-width: 850px) {
    #block-views-services-block {
      padding-top: 120px; } }
  @media (max-width: 700px) {
    #block-views-services-block {
      padding-top: 40px; } }
  #block-views-services-block .views-row-last {
    position: relative;
    padding-bottom: 260px;
    margin-bottom: 260px;
    border-bottom: 1px solid #d4d4d4; }
    @media (max-width: 550px) {
      #block-views-services-block .views-row-last {
        padding-bottom: 160px;
        margin-bottom: 160px; } }
    #block-views-services-block .views-row-last .node--services .group-container {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0; }
    #block-views-services-block .views-row-last:after {
      content: "or";
      color: #fbc31e;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -60px;
      width: 123px;
      height: 123px;
      line-height: 117px;
      background: #ffffff;
      border: 1px solid #100a0f;
      border-radius: 50%;
      text-align: center;
      font-family: "Libre Baskerville", serif;
      font-size: 1.55555rem; }
      @media (max-width: 550px) {
        #block-views-services-block .views-row-last:after {
          width: 103px;
          height: 103px;
          bottom: -50px;
          line-height: 101px; } }

.node--services:after {
  content: "";
  display: block;
  clear: both; }

.node--services .action-links,
.node--services .tabs--primary {
  position: relative;
  top: 110px; }

.node--services .node__content {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(32px + ((100% - 352px) / 12 * 1)); }
  @media (max-width: 1100px) {
    .node--services .node__content {
      width: calc(288px + ((100% - 352px) / 12 * 10)); } }

.node--services .group-container {
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 150px;
  margin-bottom: 150px; }
  .node--services .group-container:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 550px) {
    .node--services .group-container {
      padding-bottom: 100px;
      margin-bottom: 100px; } }

.node--services .group-left {
  float: left;
  width: calc(32px + ((100% - 256px) / 9 * 2));
  text-align: center;
  margin-right: calc(64px + ((100% - 256px) / 9 * 1)); }
  @media (max-width: 1100px) {
    .node--services .group-left {
      width: calc(64px + ((100% - 288px) / 10 * 3));
      margin-right: calc(((100% - 288px) / 10 * 1)); } }
  @media (max-width: 850px) {
    .node--services .group-left {
      width: 100%;
      margin-bottom: 50px; } }

.node--services .field--name-field-title {
  color: #000000;
  font-family: "Montserrat", "Arial", sans-serif;
  font-size: 1.22222rem;
  line-height: 40px;
  font-weight: 600; }

.node--services .custom-title {
  color: #000000;
  font-size: 1.55555rem;
  margin-top: 30px; }

.node--services .group-right {
  float: left;
  width: calc(160px + ((100% - 256px) / 9 * 6)); }
  @media (max-width: 1100px) {
    .node--services .group-right {
      width: calc(160px + ((100% - 288px) / 10 * 6)); } }
  @media (max-width: 850px) {
    .node--services .group-right {
      width: 100%; } }

.node--services .field--name-field-service-type > .field__items > .field__item {
  padding-top: 180px; }
  .node--services .field--name-field-service-type > .field__items > .field__item:first-child {
    padding-top: 0; }
  @media (max-width: 1024px) {
    .node--services .field--name-field-service-type > .field__items > .field__item {
      padding-top: 100px; } }

.node--services .field--name-field-collection-body,
.node--services .field--name-field-introduction-body {
  width: 100%;
  font-size: 1.22222rem;
  line-height: 40px;
  font-weight: 500; }
  @media (max-width: 1024px) {
    .node--services .field--name-field-collection-body,
    .node--services .field--name-field-introduction-body {
      font-size: 1.22222rem;
      line-height: 1.65; } }
  @media (max-width: 550px) {
    .node--services .field--name-field-collection-body,
    .node--services .field--name-field-introduction-body {
      font-size: 1rem; } }

.node--services .field--name-field-strong-title {
  font-size: 2.5rem;
  font-family: "Libre Baskerville", serif;
  line-height: 60px;
  margin-bottom: 28px; }
  @media (max-width: 1024px) {
    .node--services .field--name-field-strong-title {
      font-size: 2.01666rem;
      line-height: 1.3;
      width: 100%;
      margin-left: 0; } }
  @media (max-width: 480px) {
    .node--services .field--name-field-strong-title {
      font-size: 1.55555rem; } }

.node--services .field-collection-view {
  margin-bottom: 0 !important; }

.node--services .field--name-field-methods-techniques {
  margin: 100px 0; }
  .node--services .field--name-field-methods-techniques:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 550px) {
    .node--services .field--name-field-methods-techniques {
      margin: 60px 0; } }
  .node--services .field--name-field-methods-techniques .field__label {
    font-size: 1rem; }
  .node--services .field--name-field-methods-techniques .field--name-field-collection-title .field__item.even, .node--services .field--name-field-methods-techniques .field--name-field-collection-title .field__item.odd {
    float: none;
    width: 100%;
    margin: 0;
    font-weight: 500; }
  .node--services .field--name-field-methods-techniques .field__items:after {
    content: "";
    display: block;
    clear: both; }
  .node--services .field--name-field-methods-techniques .methods-column {
    width: calc(64px + ((100% - 160px) / 6 * 3));
    float: left; }
    @media (max-width: 500px) {
      .node--services .field--name-field-methods-techniques .methods-column {
        width: 100%; } }
    .node--services .field--name-field-methods-techniques .methods-column.first {
      margin-right: 32px; }
      @media (max-width: 500px) {
        .node--services .field--name-field-methods-techniques .methods-column.first {
          margin-right: 0; } }
  .node--services .field--name-field-methods-techniques .field__item {
    margin-bottom: 10px; }
    .node--services .field--name-field-methods-techniques .field__item:after {
      content: "";
      display: block;
      clear: both; }
    .node--services .field--name-field-methods-techniques .field__item .column-left {
      width: calc(64px + ((100% - 160px) / 6 * 3));
      float: left; }

.hidden {
  display: none; }

/* Select Box it */
#block-views-solutions-block .selectboxit-container {
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  width: calc(192px + ((100% - 352px) / 12 * 7));
  border: 1px solid #100a0f;
  z-index: 1; }
  @media (max-width: 1024px) {
    #block-views-solutions-block .selectboxit-container {
      width: calc(288px + ((100% - 352px) / 12 * 10));
      margin-left: calc(((100% - 288px) / 10 * 1)); } }
  #block-views-solutions-block .selectboxit-container .selectboxit {
    background: #ffffff !important;
    width: 100% !important;
    height: 60px; }
    @media (max-width: 660px) {
      #block-views-solutions-block .selectboxit-container .selectboxit {
        width: auto !important; } }
  #block-views-solutions-block .selectboxit-container .selectboxit-list {
    background-color: #ffffff !important;
    width: 100% !important;
    min-width: 100% !important;
    max-width: none !important;
    border: 1px solid #100a0f;
    list-style: none !important;
    padding-left: 30px;
    top: 60px !important; }

#block-views-solutions-block .selectboxit-container * {
  font-size: 16px; }

#block-views-solutions-block .selectboxit-arrow-container {
  margin-right: 25px; }

#block-views-solutions-block .selectboxit .selectboxit-option-icon-container {
  margin-left: 30px; }

#block-views-solutions-block .selectboxit-container .selectboxit span, #block-views-solutions-block .selectboxit-container .selectboxit .selectboxit-options a {
  height: 60px;
  line-height: 60px; }
  @media (max-width: 505px) {
    #block-views-solutions-block .selectboxit-container .selectboxit span, #block-views-solutions-block .selectboxit-container .selectboxit .selectboxit-options a {
      top: -60px;
      position: relative; } }

#block-views-solutions-block .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  background: none; }

#block-views-solutions-block .selectboxit-focus {
  background: #fbc31e;
  margin-left: -30px;
  padding-left: 30px;
  border-radius: 0; }

#block-views-solutions-block .selectboxit-enabled.selectboxit-focus {
  margin-left: 0;
  padding-left: 0; }

#block-views-solutions-block .selectboxit-option {
  padding-top: 20px;
  padding-bottom: 20px; }
  #block-views-solutions-block .selectboxit-option.selectboxit-option-first {
    padding-top: 15px;
    padding-bottom: 15px; }
  #block-views-solutions-block .selectboxit-option.selectboxit-option-last {
    padding-top: 15px;
    padding-bottom: 15px; }

#block-views-solutions-block .selectboxit-option-icon {
  display: none !important; }

/* Solution Blocks */
#block-views-solutions-block {
  padding-bottom: 250px; }
  #block-views-solutions-block .block__title {
    margin-left: calc(64px + ((100% - 352px) / 12 * 2));
    width: calc(192px + ((100% - 352px) / 12 * 7));
    font-size: 2.5rem;
    line-height: 3.333rem;
    margin-bottom: 60px; }
    @media (max-width: 1024px) {
      #block-views-solutions-block .block__title {
        font-size: 1.815rem;
        line-height: 37px;
        width: calc(288px + ((100% - 352px) / 12 * 10));
        margin-left: calc(((100% - 288px) / 10 * 1)); } }
  #block-views-solutions-block .field--name-body {
    font-size: 1.22222rem;
    line-height: 2.222rem; }
  #block-views-solutions-block .group-solution {
    margin-left: calc(64px + ((100% - 352px) / 12 * 2));
    width: calc(192px + ((100% - 352px) / 12 * 7));
    margin-top: 100px; }
    @media (max-width: 1024px) {
      #block-views-solutions-block .group-solution {
        width: calc(288px + ((100% - 352px) / 12 * 10));
        margin-left: calc(((100% - 288px) / 10 * 1)); } }
  #block-views-solutions-block .group-solution > div span {
    font-size: 1.22222rem;
    font-weight: 500;
    padding-bottom: 10px;
    display: block; }
  #block-views-solutions-block .group-solution .custom-title {
    font-size: 2.5rem;
    color: #000000;
    line-height: 3.333rem;
    width: 100%;
    margin-bottom: 40px; }
    @media (max-width: 1024px) {
      #block-views-solutions-block .group-solution .custom-title {
        font-size: 2.01666rem;
        line-height: 1.3;
        width: 100%;
        margin-left: 0; } }
    @media (max-width: 480px) {
      #block-views-solutions-block .group-solution .custom-title {
        font-size: 1.55555rem; } }
  #block-views-solutions-block .field-collection-view {
    padding: 0;
    margin: 0;
    border-bottom: none; }
  #block-views-solutions-block .field-collection-container {
    border-bottom: none;
    margin-bottom: 100px; }
  #block-views-solutions-block .field--name-field-methods-techniques .field__label {
    margin-bottom: 30px; }
  #block-views-solutions-block .field--name-field-methods-techniques .field-collection-view .field--name-field-collection-title {
    color: #100a0f;
    float: none;
    width: 100%;
    padding: 10px 30px;
    background: #f3f3f3;
    margin-left: 0;
    font-weight: 500;
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 16px;
    margin-top: 10px; }
  #block-views-solutions-block .use-modal {
    font-size: 16px;
    padding: 20px 0;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    text-decoration: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, #100a0f), to(#100a0f));
    background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, #100a0f 50%, #100a0f);
    background-size: 201% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    width: 200px;
    margin-top: 50px;
    font-weight: 500; }
    #block-views-solutions-block .use-modal:hover {
      background-position: 0 0;
      color: #100a0f; }
  #block-views-solutions-block .methods-column {
    width: calc(64px + ((100% - 160px) / 6 * 3));
    float: left; }
    @media (max-width: 500px) {
      #block-views-solutions-block .methods-column {
        width: 100%; } }
    #block-views-solutions-block .methods-column.first {
      margin-right: 32px; }
      @media (max-width: 500px) {
        #block-views-solutions-block .methods-column.first {
          margin-right: 0; } }

/* Our Workshops Page */
.page-node-5 .view-testimonials .view-header {
  display: none; }

.page-node-5 .view-testimonials .view-content {
  width: 100%; }

/* Public Workshops */
#block-views-training-block-5 .vertical-number .yellow-title .text,
#block-views-training-block-4 .vertical-number .yellow-title .text,
#block-views-training-block .vertical-number .yellow-title .text {
  min-width: 300px; }
  @media (max-width: 400px) {
    #block-views-training-block-5 .vertical-number .yellow-title .text,
    #block-views-training-block-4 .vertical-number .yellow-title .text,
    #block-views-training-block .vertical-number .yellow-title .text {
      min-width: auto;
      letter-spacing: 2px;
      line-height: 38px; } }

#block-views-training-block-5 .view-training.view-display-id-block_4 .view-header .vertical-number, #block-views-training-block-5 .view-training.view-display-id-block .view-header .vertical-number,
#block-views-training-block-4 .view-training.view-display-id-block_4 .view-header .vertical-number,
#block-views-training-block-4 .view-training.view-display-id-block .view-header .vertical-number,
#block-views-training-block .view-training.view-display-id-block_4 .view-header .vertical-number,
#block-views-training-block .view-training.view-display-id-block .view-header .vertical-number {
  left: 0; }
  @media (max-width: 400px) {
    #block-views-training-block-5 .view-training.view-display-id-block_4 .view-header .vertical-number .text, #block-views-training-block-5 .view-training.view-display-id-block .view-header .vertical-number .text,
    #block-views-training-block-4 .view-training.view-display-id-block_4 .view-header .vertical-number .text,
    #block-views-training-block-4 .view-training.view-display-id-block .view-header .vertical-number .text,
    #block-views-training-block .view-training.view-display-id-block_4 .view-header .vertical-number .text,
    #block-views-training-block .view-training.view-display-id-block .view-header .vertical-number .text {
      letter-spacing: 2px;
      min-width: auto;
      line-height: 38px; } }

/* Fix broken sidebar title (header) position */
.view-training.view-display-id-block_5 {
  margin-top: 150px;
  display: inline-block;
  width: 100%; }
  @media (max-width: 550px) {
    .view-training.view-display-id-block_5 {
      margin-top: 60px; } }
  @media (max-width: 550px) {
    .view-training.view-display-id-block_5 .view-header {
      margin-bottom: 40px; } }
  .view-training.view-display-id-block_5 .view-header .vertical-number {
    left: 0; }

#block-views-training-block-5 .vertical-number .yellow-title .text {
  min-width: 450px;
  top: 302px; }
  @media (max-width: 1024px) {
    #block-views-training-block-5 .vertical-number .yellow-title .text {
      min-width: auto;
      top: auto;
      letter-spacing: 2px;
      line-height: 40px; } }
  @media (max-width: 450px) {
    #block-views-training-block-5 .vertical-number .yellow-title .text {
      max-width: 250px;
      line-height: 1.4; } }
  @media (max-width: 340px) {
    #block-views-training-block-5 .vertical-number .yellow-title .text {
      max-width: 180px; } }

@media (max-width: 1024px) {
  #block-views-training-block-5 .vertical-number .yellow-title .number {
    float: left; } }

#block-views-training-block-4 .view-header {
  height: 500px; }
  @media (max-width: 1024px) {
    #block-views-training-block-4 .view-header {
      height: auto; } }

@media (max-width: 1024px) {
  #block-views-training-block-4 .vertical-number .yellow-title .number {
    float: left; } }

#block-views-training-block-4 .vertical-number .yellow-title .text {
  top: 215px; }
  @media (max-width: 1024px) {
    #block-views-training-block-4 .vertical-number .yellow-title .text {
      top: auto;
      line-height: 38px; } }

#block-views-training-block-1 .view-training.view-display-id-block_1 .view-content {
  margin-left: calc(64px + ((100% - 352px) / 12 * 2)); }
  @media (max-width: 1024px) {
    #block-views-training-block-1 .view-training.view-display-id-block_1 .view-content {
      margin-left: auto; } }

#block-views-training-block-1 .view-training.view-display-id-block_1 .view-header .vertical-number {
  left: 0; }

.views-content-header {
  padding-top: 80px;
  width: calc(256px + ((100% - 352px) / 12 * 9));
  float: left;
  margin-left: calc(32px + ((100% - 352px) / 12 * 1)); }
  .views-content-header h2 {
    font-family: "Libre Baskerville", serif;
    font-size: 2.5rem;
    line-height: 3.333rem;
    width: calc(192px + ((100% - 256px) / 9 * 7)); }
    @media (max-width: 1024px) {
      .views-content-header h2 {
        width: 100%;
        font-size: 1.815rem;
        line-height: 1.3; } }
  .views-content-header span {
    display: inline-block;
    width: calc(192px + ((100% - 256px) / 9 * 7));
    padding-top: 50px;
    line-height: 2.222rem;
    font-size: 1.22222rem; }
    @media (max-width: 1024px) {
      .views-content-header span {
        font-size: 1rem;
        margin-bottom: 50px;
        width: 100%;
        line-height: 1.8; } }

/* Public Workshops Box + (Box in house training) view mode */
#block-views-training-block-7 .request_quote,
#block-views-training-block-5 .request_quote,
#block-views-training-block-4 .request_quote {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%); }

/* Request Workshop Webform */
#webform_drawer-163,
#webform_drawer-125 {
  padding: 70px 0;
  max-width: 460px;
  margin: 0 auto; }
  #webform_drawer-163 .webform-title,
  #webform_drawer-125 .webform-title {
    padding-bottom: 50px;
    text-align: center; }

/* In House Training Newest Changes */
#block-views-training-block-5 {
  clear: both; }

.view-training.view-display-id-block_1 .views-row {
  margin-bottom: 70px; }

.node--full.nnode-6 .field--name-body {
  margin-bottom: 20px; }

/* InHouse last row yellow */
.custom-row {
  width: calc(64px + ((100% - 256px) / 9 * 3));
  margin-right: 32px;
  float: left; }
  .custom-row .button a:hover {
    border: 1px solid #100a0f !important; }
  .custom-row .custom-title {
    color: #100a0f;
    padding: 0 48px;
    text-align: left;
    font-size: 1.77777rem;
    line-height: 46px; }
    @media (max-width: 1500px) {
      .custom-row .custom-title {
        font-size: 1.38888rem;
        margin-top: 20px;
        line-height: 1.5;
        padding: 0; } }
  .custom-row .box .button a {
    font-size: 16px;
    padding: 20px 16px;
    color: #ffffff;
    display: block;
    text-decoration: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, #100a0f), to(#100a0f));
    background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, #100a0f 50%, #100a0f);
    background-size: 201% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    border: 1px solid #100a0f; }
    .custom-row .box .button a:hover {
      background-position: 0 0;
      color: #100a0f; }
  .custom-row .yellow {
    background: #fbc31e; }

.view-inhouse-info .field--custom-strong-title {
  font-size: 2.5rem;
  font-family: "Libre Baskerville", serif;
  line-height: 60px;
  margin-bottom: 70px;
  width: calc(192px + ((100% - 256px) / 9 * 7)); }
  @media (max-width: 1024px) {
    .view-inhouse-info .field--custom-strong-title {
      width: 100%; } }
  @media (max-width: 600px) {
    .view-inhouse-info .field--custom-strong-title {
      font-size: 2.01666rem;
      line-height: 1.3; } }

.view-inhouse-info .field--name-body {
  line-height: 2.222rem;
  font-size: 1.22222rem;
  margin-bottom: 90px;
  width: calc(192px + ((100% - 256px) / 9 * 7)); }
  @media (max-width: 1024px) {
    .view-inhouse-info .field--name-body {
      width: 100%; } }
  @media (max-width: 600px) {
    .view-inhouse-info .field--name-body {
      font-size: 1rem;
      line-height: 1.8; } }

.node-type-services-content #block-views-56988818e5dad1bfb43137cef0925cf9,
.node-type-services-content #block-views-d501b9c211e362b75675678db578e59d,
.node-type-blog #block-views-56988818e5dad1bfb43137cef0925cf9,
.node-type-blog #block-views-d501b9c211e362b75675678db578e59d,
.node-type-advanced-page #block-views-56988818e5dad1bfb43137cef0925cf9,
.node-type-advanced-page #block-views-d501b9c211e362b75675678db578e59d,
.node-type-public-workshops #block-views-56988818e5dad1bfb43137cef0925cf9,
.node-type-public-workshops #block-views-d501b9c211e362b75675678db578e59d,
.node-type-page #block-views-56988818e5dad1bfb43137cef0925cf9,
.node-type-page #block-views-d501b9c211e362b75675678db578e59d {
  display: none; }

.node-type-in-house-training #block-views-56988818e5dad1bfb43137cef0925cf9,
.node-type-in-house-training #block-views-d501b9c211e362b75675678db578e59d {
  display: block; }
  .node-type-in-house-training #block-views-56988818e5dad1bfb43137cef0925cf9 .block__title,
  .node-type-in-house-training #block-views-d501b9c211e362b75675678db578e59d .block__title {
    font-size: 1.55555rem;
    line-height: 50px;
    margin-left: calc(32px + ((100% - 352px) / 12 * 1)); }
    @media (max-width: 700px) {
      .node-type-in-house-training #block-views-56988818e5dad1bfb43137cef0925cf9 .block__title,
      .node-type-in-house-training #block-views-d501b9c211e362b75675678db578e59d .block__title {
        width: 83%;
        margin: 0 auto;
        line-height: 1.3;
        margin-bottom: 40px; } }

.node-type-in-house-training .block--views {
  clear: both; }

.view-display-id-inhouse_training_for_managers .block__title {
  font-size: 1.55555rem;
  line-height: 50px;
  margin-left: calc(32px + ((100% - 352px) / 12 * 1)); }

.view-display-id-inhouse_training_for_managers .view-content {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  margin-right: calc(32px + ((100% - 352px) / 12 * 1)); }

.view-related-inhouse-training:after {
  content: "";
  display: block;
  clear: both; }

@media (max-width: 1100px) {
  .view-related-inhouse-training.view-display-id-inhouse_training_for_design_teams .view-content {
    width: calc(288px + ((100% - 352px) / 12 * 10));
    margin-left: calc(32px + ((100% - 352px) / 12 * 2));
    margin-right: 0; } }

@media (max-width: 1024px) {
  .view-related-inhouse-training.view-display-id-inhouse_training_for_design_teams .view-content {
    margin-left: 0;
    margin: 0 auto; } }

.view-related-inhouse-training .view-content {
  width: calc(256px + ((100% - 352px) / 12 * 9));
  margin-left: calc(64px + ((100% - 352px) / 12 * 2));
  margin-right: calc(32px + ((100% - 352px) / 12 * 1)); }
  @media (max-width: 700px) {
    .view-related-inhouse-training .view-content {
      width: 83%;
      margin-left: 0;
      margin-right: 0;
      margin: 0 auto; } }

.view-display-id-inhouse_training_for_design_teams .views-row {
  margin-bottom: 150px; }
  .view-display-id-inhouse_training_for_design_teams .views-row:last-child {
    margin-bottom: 0; }

.view-display-id-inhouse_training_for_design_teams .views-row {
  width: calc(64px + ((100% - 256px) / 9 * 3));
  margin-right: 32px;
  float: left;
  overflow: hidden; }
  .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(3n) {
    margin-right: 0;
    float: right; }
  .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(3n+1) {
    clear: both; }
  .view-display-id-inhouse_training_for_design_teams .views-row:last-child {
    margin-bottom: 0; }
  @media (max-width: 1024px) {
    .view-display-id-inhouse_training_for_design_teams .views-row {
      width: calc(128px + ((100% - 288px) / 10 * 5));
      margin-right: 32px;
      margin-bottom: 20px; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(2n+1) {
        clear: left; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(2n) {
        margin-right: 0; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(3n+1) {
        clear: none; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(3n) {
        float: left; } }
  @media (max-width: 680px) {
    .view-display-id-inhouse_training_for_design_teams .views-row {
      width: calc(288px + (100% - 288px));
      margin-right: 0;
      margin-bottom: 20px; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(2n+1) {
        clear: left; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(2n) {
        margin-right: 0; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(3n+1) {
        clear: none; }
      .view-display-id-inhouse_training_for_design_teams .views-row:nth-child(3n) {
        float: left; } }

.view-display-id-inhouse_training_for_design_teams .request_quote {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px; }

@media (max-width: 1435px) {
  .activew-circle {
    right: 5px !important; } }

@media (max-width: 1300px) {
  .activew-circle {
    right: -5px !important; } }

@media (max-width: 800px) {
  .activew-circle {
    right: 50% !important;
    top: 18px;
    transform: translateX(60px); } }

@media (max-width: 1024px) {
  .slicknav_menu .slicknav_nav .training-ulitem-inside .activew-circle {
    right: 0 !important;
    top: 30px;
    transform: none !important; } }

.training-ulitem-inside .activew-circle {
  z-index: 1;
  right: 1px; }
  @media (max-width: 1435px) {
    .training-ulitem-inside .activew-circle {
      right: -10px !important; } }

.node--services-content--full,
.node--blog--full {
  width: calc(288px + ((100% - 352px) / 12 * 10));
  margin: 0 auto;
  line-height: 1.65; }
  .node--services-content--full > .node__content,
  .node--blog--full > .node__content {
    padding-top: 200px; }
    @media (max-width: 1024px) {
      .node--services-content--full > .node__content,
      .node--blog--full > .node__content {
        width: 100% !important;
        padding-top: 100px; } }
  @media (max-width: 1024px) {
    .node--services-content--full,
    .node--blog--full {
      width: 100%; }
      .node--services-content--full .group-content-bounds,
      .node--blog--full .group-content-bounds {
        width: 83%;
        margin: 0 auto; } }
  .node--services-content--full .addthis-mobile,
  .node--blog--full .addthis-mobile {
    margin-top: -10px;
    margin-bottom: 60px; }
  .node--services-content--full .field--name-field-intro-text,
  .node--blog--full .field--name-field-intro-text {
    margin-bottom: 100px; }
  .node--services-content--full .group-pricing-wrapper,
  .node--blog--full .group-pricing-wrapper {
    display: block; }
    @media (max-width: 1024px) {
      .node--services-content--full .group-pricing-wrapper,
      .node--blog--full .group-pricing-wrapper {
        margin-top: 0 !important;
        display: table-footer-group; } }
  .node--services-content--full .group-content-bounds:after,
  .node--blog--full .group-content-bounds:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 1024px) {
    .node--services-content--full .group-content-bounds,
    .node--blog--full .group-content-bounds {
      display: table; } }
  .node--services-content--full .field--name-field-body-4,
  .node--blog--full .field--name-field-body-4 {
    margin-bottom: 100px; }
  .node--services-content--full .bg-image,
  .node--blog--full .bg-image {
    height: 618px;
    background-position: center;
    background-size: cover; }
    @media (max-width: 1024px) {
      .node--services-content--full .bg-image,
      .node--blog--full .bg-image {
        height: 480px; } }
    @media (max-width: 680px) {
      .node--services-content--full .bg-image,
      .node--blog--full .bg-image {
        height: 370px; } }
  @media (max-width: 580px) {
    .node--services-content--full .group-image-wrapper,
    .node--blog--full .group-image-wrapper {
      margin-bottom: 0; } }
  .node--services-content--full .group-image-wrapper .field--name-field-image,
  .node--blog--full .group-image-wrapper .field--name-field-image {
    position: relative;
    overflow: hidden; }
  .node--services-content--full .group-right-wrapper .field__label,
  .node--blog--full .group-right-wrapper .field__label {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 1.11111rem; }
  .node--services-content--full .field-collection-view,
  .node--blog--full .field-collection-view {
    margin-bottom: 10px;
    padding-bottom: 0;
    border-bottom: none; }
    .node--services-content--full .field-collection-view:after,
    .node--blog--full .field-collection-view:after {
      content: "";
      display: block;
      clear: both; }
    .node--services-content--full .field-collection-view-final,
    .node--blog--full .field-collection-view-final {
      margin-bottom: 0; }
    .node--services-content--full .field-collection-view .field--name-field-collection-title,
    .node--blog--full .field-collection-view .field--name-field-collection-title {
      color: #100a0f;
      float: none;
      width: 100%;
      padding: 10px 40px;
      background: #f3f3f3;
      margin-left: 0;
      font-weight: 300;
      font-family: "Montserrat", "Arial", sans-serif;
      font-size: 16px; }
    .node--services-content--full .field-collection-view .field--name-field-collection-body,
    .node--services-content--full .field-collection-view .field--name-field-body,
    .node--blog--full .field-collection-view .field--name-field-collection-body,
    .node--blog--full .field-collection-view .field--name-field-body {
      line-height: 1.65;
      padding: 20px;
      background: #f9f9f9;
      font-size: 16px;
      font-weight: 400; }
    .node--services-content--full .field-collection-view .field--name-field-collection-body,
    .node--blog--full .field-collection-view .field--name-field-collection-body {
      width: 100%;
      float: none;
      margin-left: 0; }
  .node--services-content--full .field--name-body,
  .node--services-content--full .field--name-field-body-2,
  .node--services-content--full .field--name-field-body-3,
  .node--blog--full .field--name-body,
  .node--blog--full .field--name-field-body-2,
  .node--blog--full .field--name-field-body-3 {
    margin-bottom: 100px;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 100px;
    font-weight: 400; }
    @media (max-width: 1024px) {
      .node--services-content--full .field--name-body,
      .node--services-content--full .field--name-field-body-2,
      .node--services-content--full .field--name-field-body-3,
      .node--blog--full .field--name-body,
      .node--blog--full .field--name-field-body-2,
      .node--blog--full .field--name-field-body-3 {
        padding-bottom: 60px;
        margin-bottom: 60px; } }
  .node--services-content--full .field--name-field-intro-text,
  .node--blog--full .field--name-field-intro-text {
    font-size: 1.22222rem;
    color: #757575;
    font-weight: 400; }
  .node--services-content--full .like-this,
  .node--blog--full .like-this {
    font-weight: 400;
    font-size: 1.11111rem;
    margin-bottom: 10px; }
    .node--services-content--full .like-this .lk-2,
    .node--blog--full .like-this .lk-2 {
      font-weight: 700; }
  .node--services-content--full .group-meta,
  .node--blog--full .group-meta {
    width: calc(160px + ((100% - 288px) / 10 * 6));
    margin-left: calc(64px + ((100% - 288px) / 10 * 2));
    text-align: center;
    margin-bottom: 130px; }
  .node--services-content--full .custom-title,
  .node--blog--full .custom-title {
    font-size: 2.5rem;
    line-height: 65px;
    text-align: center;
    width: calc(160px + ((100% - 352px) / 12 * 6));
    margin: 0 auto; }
    @media (max-width: 1024px) {
      .node--services-content--full .custom-title,
      .node--blog--full .custom-title {
        font-size: 2.01666rem;
        line-height: 1.3;
        width: 100%; } }
    @media (max-width: 500px) {
      .node--services-content--full .custom-title,
      .node--blog--full .custom-title {
        margin-bottom: 25px;
        font-size: 1.66666rem; } }
  .node--services-content--full blockquote,
  .node--blog--full blockquote {
    color: #747474;
    font-size: 1.66666rem;
    line-height: 54px;
    border-top: 1px solid #fbc31e;
    border-bottom: 1px solid #fbc31e;
    padding: 30px 0;
    margin: 60px 0; }
    @media (max-width: 550px) {
      .node--services-content--full blockquote,
      .node--blog--full blockquote {
        line-height: 1.4;
        font-size: 22px; } }
  .node--services-content--full .field--name-field-date,
  .node--blog--full .field--name-field-date {
    font-size: 1.11111rem;
    margin-bottom: 10px;
    text-align: center; }
    .node--services-content--full .field--name-field-date .field__label,
    .node--services-content--full .field--name-field-date .field__items,
    .node--blog--full .field--name-field-date .field__label,
    .node--blog--full .field--name-field-date .field__items {
      display: inline-block;
      float: none;
      font-weight: 300; }
  .node--services-content--full .group-left-wrapper,
  .node--blog--full .group-left-wrapper {
    width: calc(64px + ((100% - 288px) / 10 * 3));
    margin-right: 32px;
    float: left;
    text-align: left; }
    @media (max-width: 1024px) {
      .node--services-content--full .group-left-wrapper,
      .node--blog--full .group-left-wrapper {
        width: 100%;
        margin-right: 0;
        display: table-footer-group;
        float: none; } }
  .node--services-content--full .group-right-wrapper,
  .node--blog--full .group-right-wrapper {
    float: left;
    width: calc(160px + ((100% - 288px) / 10 * 6)); }
    @media (max-width: 1024px) {
      .node--services-content--full .group-right-wrapper,
      .node--blog--full .group-right-wrapper {
        width: 100%;
        margin-right: 0;
        margin-top: 40px; } }
  .node--services-content--full .group-pricing-wrapper legend,
  .node--blog--full .group-pricing-wrapper legend {
    display: block;
    margin-bottom: 10px;
    font-size: 1.11111rem;
    font-weight: 700; }
  .node--services-content--full .group-pricing-wrapper .field--type-number-integer,
  .node--blog--full .group-pricing-wrapper .field--type-number-integer {
    margin-bottom: 25px; }
    .node--services-content--full .group-pricing-wrapper .field--type-number-integer:last-child,
    .node--blog--full .group-pricing-wrapper .field--type-number-integer:last-child {
      margin-bottom: 0; }
  .node--services-content--full .addthis-wrapper,
  .node--blog--full .addthis-wrapper {
    margin-top: 0; }
  .node--services-content--full .group-image-wrapper,
  .node--blog--full .group-image-wrapper {
    text-align: center;
    position: relative;
    margin-bottom: 80px;
    overflow: hidden; }
    .node--services-content--full .group-image-wrapper .group-image-align,
    .node--blog--full .group-image-wrapper .group-image-align {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      color: #ffffff;
      width: calc(224px + ((100% - 288px) / 10 * 8));
      font-size: 1.38888rem; }
      .node--services-content--full .group-image-wrapper .group-image-align .field__label,
      .node--services-content--full .group-image-wrapper .group-image-align .field__items,
      .node--blog--full .group-image-wrapper .group-image-align .field__label,
      .node--blog--full .group-image-wrapper .group-image-align .field__items {
        display: inline-block;
        float: none; }
      .node--services-content--full .group-image-wrapper .group-image-align .field__label,
      .node--blog--full .group-image-wrapper .group-image-align .field__label {
        font-weight: 400; }
      .node--services-content--full .group-image-wrapper .group-image-align .field__items,
      .node--blog--full .group-image-wrapper .group-image-align .field__items {
        font-weight: 700; }
  .node--services-content--full.node-archived-workshop .custom-title,
  .node--blog--full.node-archived-workshop .custom-title {
    font-size: 2.5rem;
    color: #100a0f; }

/* Blog Related Styling */
.view-blog.view-display-id-page {
  padding: 150px 0 150px 0; }

.view-blog .node--blog--teaser {
  margin-bottom: 60px;
  border: 1px solid #e1e1e1;
  min-height: 600px;
  width: 100%;
  position: relative; }
  .view-blog .node--blog--teaser img {
    width: 100%; }
  .view-blog .node--blog--teaser .custom-title {
    padding: 0 30px;
    margin-top: 10px;
    font-size: 1.33333rem;
    line-height: 32px; }
    .view-blog .node--blog--teaser .custom-title a:link, .view-blog .node--blog--teaser .custom-title a:visited, .view-blog .node--blog--teaser .custom-title a:active {
      text-decoration: none;
      color: #100a0f; }
    .view-blog .node--blog--teaser .custom-title a:hover, .view-blog .node--blog--teaser .custom-title a:focus {
      color: #100a0f; }
  .view-blog .node--blog--teaser .read-more {
    position: absolute;
    bottom: 30px;
    left: 30px; }
    .view-blog .node--blog--teaser .read-more a {
      border: 1px solid #e1e1e1;
      background: none; }
    .view-blog .node--blog--teaser .read-more:hover a {
      border: 1px solid #100a0f;
      background: none; }

.view-blog .views-row {
  float: left;
  width: calc(96px + ((100% - 352px) / 12 * 4));
  margin-right: 32px; }
  .view-blog .views-row:nth-child(3n) {
    margin-right: 0; }
  .view-blog .views-row:nth-child(3n+1) {
    clear: left; }
  @media (max-width: 1024px) {
    .view-blog .views-row {
      width: calc(160px + ((100% - 352px) / 12 * 6));
      margin-right: 32px; }
      .view-blog .views-row:nth-child(3n+1) {
        margin-right: 32px;
        clear: none; }
      .view-blog .views-row:nth-child(2n) {
        margin-right: 0; }
      .view-blog .views-row:nth-child(2n+1) {
        margin-right: 32px;
        clear: left; } }
  @media (max-width: 580px) {
    .view-blog .views-row {
      width: 100%;
      margin-right: 0; }
      .view-blog .views-row .node--blog--teaser {
        min-height: 650px; }
      .view-blog .views-row:nth-child(2n+1) {
        clear: none;
        margin-right: 0; }
      .view-blog .views-row:nth-child(3n+1) {
        margin-right: 0; } }
  @media (max-width: 360px) {
    .view-blog .views-row .node--blog--teaser {
      min-height: 580px; } }

#block-views-blog-block .block__title {
  margin-left: calc(((100% - 352px) / 12 * 1)); }

/* Front Page Box links fix */
.box .button a {
  border: 1px solid #100a0f;
  background-color: none;
  -webkit-transition: 0.3s background-position ease-out;
  transition: 0.3s background-position ease-out;
  background-size: 204% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, transparent), to(transparent));
  background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
  background-position: 100% 0; }
  .box .button a:hover {
    background-position: 0 0;
    border-color: #fbc31e; }

#block-gtweaks-what-we-do .box-wrapper {
  display: flex;
  flex-wrap: wrap; }

.block--gtweaks-what-we-do .view-content .box-item-4, .block--gtweaks-what-we-do .view-content .box-item-5, .block--gtweaks-what-we-do .view-content .box-item-6 {
  padding-top: 0px; }
  @media (max-width: 560px) {
    .block--gtweaks-what-we-do .view-content .box-item-4, .block--gtweaks-what-we-do .view-content .box-item-5, .block--gtweaks-what-we-do .view-content .box-item-6 {
      padding-top: 0px; } }
  .block--gtweaks-what-we-do .view-content .box-item-4 .box-image, .block--gtweaks-what-we-do .view-content .box-item-5 .box-image, .block--gtweaks-what-we-do .view-content .box-item-6 .box-image {
    width: 100%;
    height: auto;
    margin-bottom: 30px; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .box-image img, .block--gtweaks-what-we-do .view-content .box-item-5 .box-image img, .block--gtweaks-what-we-do .view-content .box-item-6 .box-image img {
      display: block;
      width: 100%; }
  .block--gtweaks-what-we-do .view-content .box-item-4 .box-meta, .block--gtweaks-what-we-do .view-content .box-item-5 .box-meta, .block--gtweaks-what-we-do .view-content .box-item-6 .box-meta {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .block--gtweaks-what-we-do .view-content .box-item-4 h2, .block--gtweaks-what-we-do .view-content .box-item-5 h2, .block--gtweaks-what-we-do .view-content .box-item-6 h2 {
    text-align: left;
    padding: 0 35px !important;
    margin-bottom: 20px !important; }
  .block--gtweaks-what-we-do .view-content .box-item-4 .text, .block--gtweaks-what-we-do .view-content .box-item-5 .text, .block--gtweaks-what-we-do .view-content .box-item-6 .text {
    padding: 0 35px;
    text-align: left;
    font-size: 16px;
    margin-bottom: 35px; }
  .block--gtweaks-what-we-do .view-content .box-item-4 .box-align, .block--gtweaks-what-we-do .view-content .box-item-5 .box-align, .block--gtweaks-what-we-do .view-content .box-item-6 .box-align {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .block--gtweaks-what-we-do .view-content .box-item-4 .button, .block--gtweaks-what-we-do .view-content .box-item-5 .button, .block--gtweaks-what-we-do .view-content .box-item-6 .button {
    margin-top: auto;
    padding: 0 35px; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .button a, .block--gtweaks-what-we-do .view-content .box-item-5 .button a, .block--gtweaks-what-we-do .view-content .box-item-6 .button a {
      min-width: unset;
      width: 100%; }
  @media (max-width: 1420px) {
    .block--gtweaks-what-we-do .view-content .box-item-4 h2, .block--gtweaks-what-we-do .view-content .box-item-5 h2, .block--gtweaks-what-we-do .view-content .box-item-6 h2 {
      padding: 0 20px !important; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .text, .block--gtweaks-what-we-do .view-content .box-item-5 .text, .block--gtweaks-what-we-do .view-content .box-item-6 .text {
      padding: 0 20px; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .button, .block--gtweaks-what-we-do .view-content .box-item-5 .button, .block--gtweaks-what-we-do .view-content .box-item-6 .button {
      padding: 0 20px; } }
  @media (max-width: 1260px) {
    .block--gtweaks-what-we-do .view-content .box-item-4 h2, .block--gtweaks-what-we-do .view-content .box-item-5 h2, .block--gtweaks-what-we-do .view-content .box-item-6 h2 {
      padding: 0 10px !important; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .text, .block--gtweaks-what-we-do .view-content .box-item-5 .text, .block--gtweaks-what-we-do .view-content .box-item-6 .text {
      padding: 0 10px; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .button, .block--gtweaks-what-we-do .view-content .box-item-5 .button, .block--gtweaks-what-we-do .view-content .box-item-6 .button {
      padding: 0 10px; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .read-more-arrow a::after, .block--gtweaks-what-we-do .view-content .box-item-5 .read-more-arrow a::after, .block--gtweaks-what-we-do .view-content .box-item-6 .read-more-arrow a::after {
      margin-left: 10px; } }
  @media (max-width: 930px) {
    .block--gtweaks-what-we-do .view-content .box-item-4 .box-align, .block--gtweaks-what-we-do .view-content .box-item-5 .box-align, .block--gtweaks-what-we-do .view-content .box-item-6 .box-align {
      width: 100% !important;
      height: auto !important;
      display: flex;
      flex-direction: row;
      align-items: center; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .box-meta, .block--gtweaks-what-we-do .view-content .box-item-5 .box-meta, .block--gtweaks-what-we-do .view-content .box-item-6 .box-meta {
      width: 50%;
      -webkit-transform: translateY(0) !important;
      transform: translateY(0) !important; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .box-image, .block--gtweaks-what-we-do .view-content .box-item-5 .box-image, .block--gtweaks-what-we-do .view-content .box-item-6 .box-image {
      width: 50%;
      margin-right: 0px;
      margin-bottom: 0px; }
    .block--gtweaks-what-we-do .view-content .box-item-4 h2, .block--gtweaks-what-we-do .view-content .box-item-5 h2, .block--gtweaks-what-we-do .view-content .box-item-6 h2 {
      padding-right: 0px !important;
      margin-bottom: 15px !important; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .text, .block--gtweaks-what-we-do .view-content .box-item-5 .text, .block--gtweaks-what-we-do .view-content .box-item-6 .text {
      padding-right: 0px !important;
      margin-bottom: 25px !important; }
    .block--gtweaks-what-we-do .view-content .box-item-4 .button, .block--gtweaks-what-we-do .view-content .box-item-5 .button, .block--gtweaks-what-we-do .view-content .box-item-6 .button {
      padding: 0 0 0 10px; }
      .block--gtweaks-what-we-do .view-content .box-item-4 .button a, .block--gtweaks-what-we-do .view-content .box-item-5 .button a, .block--gtweaks-what-we-do .view-content .box-item-6 .button a {
        min-width: unset; } }
  @media (max-width: 650px) {
    .block--gtweaks-what-we-do .view-content .box-item-4, .block--gtweaks-what-we-do .view-content .box-item-5, .block--gtweaks-what-we-do .view-content .box-item-6 {
      padding: 0px 0px 30px 0px !important; }
      .block--gtweaks-what-we-do .view-content .box-item-4 .box-align, .block--gtweaks-what-we-do .view-content .box-item-5 .box-align, .block--gtweaks-what-we-do .view-content .box-item-6 .box-align {
        width: 100%;
        flex-direction: column; }
      .block--gtweaks-what-we-do .view-content .box-item-4 .box-meta, .block--gtweaks-what-we-do .view-content .box-item-5 .box-meta, .block--gtweaks-what-we-do .view-content .box-item-6 .box-meta {
        width: 100%; }
      .block--gtweaks-what-we-do .view-content .box-item-4 .box-image, .block--gtweaks-what-we-do .view-content .box-item-5 .box-image, .block--gtweaks-what-we-do .view-content .box-item-6 .box-image {
        width: 100%;
        margin-bottom: 30px; }
      .block--gtweaks-what-we-do .view-content .box-item-4 h2, .block--gtweaks-what-we-do .view-content .box-item-5 h2, .block--gtweaks-what-we-do .view-content .box-item-6 h2 {
        padding: 0 20px !important; }
      .block--gtweaks-what-we-do .view-content .box-item-4 .text, .block--gtweaks-what-we-do .view-content .box-item-5 .text, .block--gtweaks-what-we-do .view-content .box-item-6 .text {
        padding: 0 20px !important; }
      .block--gtweaks-what-we-do .view-content .box-item-4 .button, .block--gtweaks-what-we-do .view-content .box-item-5 .button, .block--gtweaks-what-we-do .view-content .box-item-6 .button {
        padding: 0px;
        display: flex;
        justify-content: center; }
        .block--gtweaks-what-we-do .view-content .box-item-4 .button a, .block--gtweaks-what-we-do .view-content .box-item-5 .button a, .block--gtweaks-what-we-do .view-content .box-item-6 .button a {
          width: 80%; } }
  @media (max-width: 460px) {
    .block--gtweaks-what-we-do .view-content .box-item-4 .button a, .block--gtweaks-what-we-do .view-content .box-item-5 .button a, .block--gtweaks-what-we-do .view-content .box-item-6 .button a {
      width: 70%; } }

.block--gtweaks-what-we-do .view-content .box-item-1, .block--gtweaks-what-we-do .view-content .box-item-2, .block--gtweaks-what-we-do .view-content .box-item-3 {
  margin-bottom: 32px; }
  @media (max-width: 560px) {
    .block--gtweaks-what-we-do .view-content .box-item-1 .button, .block--gtweaks-what-we-do .view-content .box-item-2 .button, .block--gtweaks-what-we-do .view-content .box-item-3 .button {
      padding: 0 20px !important; }
      .block--gtweaks-what-we-do .view-content .box-item-1 .button a, .block--gtweaks-what-we-do .view-content .box-item-2 .button a, .block--gtweaks-what-we-do .view-content .box-item-3 .button a {
        width: 80%; } }
  @media (max-width: 460px) {
    .block--gtweaks-what-we-do .view-content .box-item-1 .button a, .block--gtweaks-what-we-do .view-content .box-item-2 .button a, .block--gtweaks-what-we-do .view-content .box-item-3 .button a {
      width: 70%; } }

.block--gtweaks-what-we-do .view-content .box-item-4 .button a {
  color: white;
  border: 0px;
  font-weight: 600;
  background-image: -webkit-gradient(linear, left top, right top, from(#133a5f), color-stop(50%, #133a5f), color-stop(50%, #0c4f8e), to(#0c4f8e));
  background-image: linear-gradient(to right, #133a5f, #133a5f 50%, #0c4f8e 50%, #0c4f8e); }
  .block--gtweaks-what-we-do .view-content .box-item-4 .button a:hover {
    background-position: 0 0;
    border: 0px; }
  .block--gtweaks-what-we-do .view-content .box-item-4 .button a::after {
    filter: brightness(0) invert(1); }

.block--gtweaks-what-we-do .view-content .box-item-5 .button a {
  color: white;
  border: 0px;
  font-weight: 600;
  background-image: -webkit-gradient(linear, left top, right top, from(#A22E8B), color-stop(50%, #A22E8B), color-stop(50%, #7D2B6D), to(#7D2B6D));
  background-image: linear-gradient(to right, #A22E8B, #A22E8B 50%, #7D2B6D 50%, #7D2B6D); }
  .block--gtweaks-what-we-do .view-content .box-item-5 .button a:hover {
    background-position: 0 0;
    border: 0px; }
  .block--gtweaks-what-we-do .view-content .box-item-5 .button a::after {
    filter: brightness(0) invert(1); }

.block--gtweaks-what-we-do .view-content .box-item-6 .button a {
  border: 0px;
  font-weight: 600;
  background-image: -webkit-gradient(linear, left top, right top, from(#100a0f), color-stop(50%, #100a0f), color-stop(50%, #fbc31e), to(#fbc31e));
  background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e); }
  .block--gtweaks-what-we-do .view-content .box-item-6 .button a:hover {
    background-position: 0 0;
    color: white;
    border: 0px; }
    .block--gtweaks-what-we-do .view-content .box-item-6 .button a:hover::after {
      filter: brightness(0) invert(1); }

.block--views-training-block-3 .read-more.rmore a {
  border: 1px solid #fbc31e;
  background-color: none;
  -webkit-transition: 0.3s background-position ease-out;
  transition: 0.3s background-position ease-out;
  background-size: 204% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, transparent), to(transparent));
  background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
  background-position: 100% 0; }
  .block--views-training-block-3 .read-more.rmore a:hover {
    background-position: 0 0;
    color: #100a0f; }
    .block--views-training-block-3 .read-more.rmore a:hover:after {
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1567px -1082px;
      width: 37px;
      height: 12px; }

/* NUMBERS Vertical - Fixes for each ones height */
#block-gtweaks-what-we-do .vertical-number .yellow-title .text {
  top: 120px; }
  @media (max-width: 1024px) {
    #block-gtweaks-what-we-do .vertical-number .yellow-title .text {
      top: auto;
      line-height: 38px; } }

#block-gtweaks-why-ux .vertical-number .yellow-title .text,
#block-views-testimonials-block .vertical-number .yellow-title .text,
#block-views-companies-block .vertical-number .yellow-title .text {
  top: 135px; }
  @media (max-width: 1024px) {
    #block-gtweaks-why-ux .vertical-number .yellow-title .text,
    #block-views-testimonials-block .vertical-number .yellow-title .text,
    #block-views-companies-block .vertical-number .yellow-title .text {
      top: auto;
      line-height: 38px; } }

#block-views-training-block-3 {
  top: 135px; }

.block--views-companies-block .block__title.companies-title {
  padding-top: 40px; }

.nnode-118 .field--name-body img {
  display: block;
  margin: 0 auto; }

.node-type-page .field--name-field-strong-title,
.node--advanced-page .field--name-field-strong-title {
  padding-top: 30px; }

.metrics-extra {
  display: none; }

#block-gtweaks-testimonials-our-work {
  margin-bottom: 100px; }
  @media (max-width: 1024px) {
    #block-gtweaks-testimonials-our-work {
      margin-bottom: 0;
      max-height: 600px; } }
  @media (max-width: 600px) {
    #block-gtweaks-testimonials-our-work {
      max-height: unset; } }
  #block-gtweaks-testimonials-our-work .node--testimonials .field--name-body {
    padding: 0 70px; }
    @media (max-width: 600px) {
      #block-gtweaks-testimonials-our-work .node--testimonials .field--name-body {
        padding: 0; } }
  #block-gtweaks-testimonials-our-work .button a {
    padding: 17px 22px; }

.node--in-house-training .button a {
  padding: 17px 22px; }

.menu .last.leaf {
  margin-right: 40px; }
  @media (max-width: 1300px) {
    .menu .last.leaf {
      margin-right: 10px; } }

.node-archived-workshop.node--public-workshops--full .request_quote {
  display: block; }

.node--public-workshops--full .request_quote {
  display: none; }

.sold-out {
  padding: 12px 24px;
  background: black;
  color: white;
  font-weight: 500; }

@media (max-width: 1024px) {
  .view-related-inhouse-training .node--in-house-training--teaser {
    margin-bottom: 40px; } }

@media (max-width: 350px) {
  .nnode-118 .vertical-number .yellow-title .text {
    display: inline;
    line-height: 38px; } }

@media (max-width: 350px) {
  .nnode-118 .vertical-number .yellow-title .number {
    margin-left: -20px; } }

.inhouse-legend legend {
  display: none !important; }

.service-arrow-down {
  border-color: black transparent;
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  height: 0px;
  width: 0px;
  display: inline-block; }

.select-repl {
  border: 1px solid #100a0f;
  padding: 13px;
  width: calc(288px + ((100% - 352px) / 12 * 10));
  margin-left: calc(32px + ((100% - 352px) / 12 * 1)); }
  .select-repl:hover {
    cursor: pointer; }
  .select-repl .text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 10px);
    padding-right: 40px; }

.modal-is-active {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99999999;
  top: 0; }

.services-options {
  background: white;
  width: 80vw;
  margin: 0 auto;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  overflow-y: scroll; }

.option-wrapper {
  padding: 10px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .option-wrapper:hover {
    cursor: pointer; }

.service-option {
  width: calc(100% - 12px);
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px; }

.circle-input {
  width: 12px;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  display: inline-block; }

.hidden {
  display: none; }

.selected .circle-input {
  background: #fbc31e; }

#pgwModal {
  min-height: 600px; }
  #pgwModal input {
    -webkit-appearance: none; }

.node-author-group-teaser {
  padding: 15px 30px;
  font-size: 12px;
  font-family: "Montserrat", "Arial", sans-serif; }
  .node-author-group-teaser:after {
    content: "";
    display: block;
    clear: both; }
  .node-author-group-teaser .group-left {
    float: left; }
    @media (max-width: 1120px) {
      .node-author-group-teaser .group-left {
        float: none; } }
    @media (max-width: 1024px) {
      .node-author-group-teaser .group-left {
        float: left; } }
    @media (max-width: 768px) {
      .node-author-group-teaser .group-left {
        float: none; } }
  .node-author-group-teaser .group-right {
    float: right; }
    @media (max-width: 1120px) {
      .node-author-group-teaser .group-right {
        float: none;
        display: block;
        margin-top: 3px; } }
    @media (max-width: 1024px) {
      .node-author-group-teaser .group-right {
        float: right; } }
    @media (max-width: 768px) {
      .node-author-group-teaser .group-right {
        float: none;
        display: block;
        margin-top: 3px; } }
  .node-author-group-teaser a {
    font-size: 12px;
    font-weight: 600;
    color: #100a0f;
    text-decoration: none; }
    .node-author-group-teaser a:visited, .node-author-group-teaser a:active {
      color: #100a0f;
      text-decoration: none; }
    .node-author-group-teaser a:hover {
      color: #fbc31e; }

.node-author-group-full {
  padding-bottom: 50px;
  margin-bottom: 60px;
  border-bottom: 1px solid #fbc31e; }
  .node-author-group-full:after {
    content: "";
    display: block;
    clear: both; }
  @media (max-width: 1300px) {
    .node-author-group-full {
      padding-bottom: 20px; } }
  @media (max-width: 650px) {
    .node-author-group-full {
      text-align: center; } }
  .node-author-group-full .group-left {
    float: left;
    font-size: 18px; }
    @media (max-width: 1300px) {
      .node-author-group-full .group-left {
        display: block;
        float: none; } }
    @media (max-width: 1024px) {
      .node-author-group-full .group-left {
        display: inline;
        float: left; } }
    @media (max-width: 770px) {
      .node-author-group-full .group-left {
        display: block;
        float: none; } }
    @media (max-width: 650px) {
      .node-author-group-full .group-left {
        margin-bottom: 10px; } }
  .node-author-group-full .group-right {
    float: right;
    font-size: 16px;
    line-height: 30px; }
    @media (max-width: 1300px) {
      .node-author-group-full .group-right {
        display: block;
        float: none;
        margin-bottom: 10px; } }
    @media (max-width: 1024px) {
      .node-author-group-full .group-right {
        display: inline;
        float: right; } }
    @media (max-width: 770px) {
      .node-author-group-full .group-right {
        display: block;
        float: none;
        margin-bottom: 10px; } }
  @media (max-width: 650px) {
    .node-author-group-full .follow-btn {
      margin-top: 10px;
      display: block;
      margin-right: 0; } }
  .node-author-group-full .follow-btn a {
    font-size: 12px;
    font-weight: 600;
    padding: 10px 30px;
    border: 1px solid #100a0f;
    border-radius: 30px;
    color: #100a0f;
    text-decoration: none;
    display: inline-block; }
    .node-author-group-full .follow-btn a:visited, .node-author-group-full .follow-btn a:active {
      color: #100a0f;
      text-decoration: none; }
    .node-author-group-full .follow-btn a:hover {
      background: #100a0f;
      color: #ffffff; }
  .node-author-group-full .author-name {
    font-weight: 600;
    margin-right: 10px; }
    @media (max-width: 360px) {
      .node-author-group-full .author-name {
        margin-right: 0; } }

.page-node-119 .tabs--primary {
  position: relative;
  top: 150px; }

@media (max-width: 1024px) {
  .page-node.node-type-blog .block--views-navigation-boxes-block {
    margin-top: 60px; } }

#block-views-training-block-5 .deactivated-price {
  display: none; }

.pgwModal .pm-body {
  padding: 0 70px 0 0; }
  @media (max-width: 950px) {
    .pgwModal .pm-body {
      padding-right: 0; } }
  @media (max-width: 560px) {
    .pgwModal .pm-body {
      padding: 0 !important; } }
  .pgwModal .pm-body .pm-content {
    display: flex; }
    @media (max-width: 950px) {
      .pgwModal .pm-body .pm-content {
        flex-direction: column; } }
    .pgwModal .pm-body .pm-content .pay-now {
      background-color: #fbc31e;
      width: 35%;
      padding: 0 45px; }
      @media (max-width: 950px) {
        .pgwModal .pm-body .pm-content .pay-now {
          width: 100%;
          padding: 45px; } }
      .pgwModal .pm-body .pm-content .pay-now .pay-now-title {
        margin-bottom: 45px;
        margin-top: 100px;
        font-size: 24px; }
        @media (max-width: 950px) {
          .pgwModal .pm-body .pm-content .pay-now .pay-now-title {
            margin-top: 0; } }
        @media (max-width: 400px) {
          .pgwModal .pm-body .pm-content .pay-now .pay-now-title {
            margin-top: 40px; } }
      .pgwModal .pm-body .pm-content .pay-now .pay-now-subtitle {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 10px; }
      .pgwModal .pm-body .pm-content .pay-now a {
        text-decoration: none;
        width: 100%;
        text-align: center;
        display: block;
        color: white;
        font-weight: bold;
        background-color: black;
        padding: 10px; }

body {
  overflow-x: hidden; }

.l-header {
  display: flex; }

.l-region--header {
  width: calc(100% - 270px);
  display: flex;
  justify-content: flex-end; }

#accessibility {
  width: 100%;
  color: white !important;
  background: -webkit-gradient(linear, right top, left top, color-stop(50%, #0c4f8e), color-stop(50%, #133a5f)) right;
  background: linear-gradient(to left, #0c4f8e 50%, #133a5f 50%) right;
  background-size: 200%;
  -webkit-transition: .5s ease-out;
  transition: .5s ease-out; }
  #accessibility:hover {
    background-position: left; }
  #accessibility::after {
    display: none; }

#block-superfish-1 {
  display: flex; }
  @media (max-width: 1100px) {
    #block-superfish-1 {
      display: none; } }
  #block-superfish-1 .block__title {
    display: none; }
  #block-superfish-1 li span,
  #block-superfish-1 li a {
    height: 100%; }

#superfish-1 {
  display: flex;
  height: 100%; }
  #superfish-1 .menuparent:hover ul {
    position: absolute;
    top: 100%;
    background: white;
    left: 50%;
    transform: translateX(-50%); }
    #superfish-1 .menuparent:hover ul li span,
    #superfish-1 .menuparent:hover ul li a {
      width: 100%;
      text-align: center; }
      #superfish-1 .menuparent:hover ul li span:after,
      #superfish-1 .menuparent:hover ul li a:after {
        content: none; }
  @media (max-width: 1435px) {
    #superfish-1 li span,
    #superfish-1 li a {
      padding: 32px 10px; } }
  @media (max-width: 1170px) {
    #superfish-1 li span,
    #superfish-1 li a {
      padding: 32px 7px;
      font-size: 15px; } }
  @media (max-width: 1100px) {
    #superfish-1 {
      display: none; } }

#menu-980-1 {
  text-indent: -9999px;
  width: 184px;
  height: 80px; }
  @media (max-width: 1435px) {
    #menu-980-1 {
      width: 124px; } }

#myux {
  background: #44c1f0;
  color: white;
  text-indent: -99999px;
  width: 104px;
  font-size: 0 !important;
  width: 100%; }
  #myux:hover {
    cursor: pointer;
    background: #0facdf; }
  #myux:before {
    content: "";
    background: url("/sites/all/themes/theuxprodigy/images/write-my-ux-menu-logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 104px;
    height: 20px !important;
    position: relative;
    display: block;
    margin: 0 auto; }
    @media (max-width: 1170px) {
      #myux:before {
        width: 80px;
        background-size: contain; } }
  #myux:after {
    content: none !important; }

.menuparent ul {
  box-shadow: 0 0 30px rgba(1, 1, 1, 0.07);
  background-color: #ffffff;
  z-index: -1; }

.menuparent span {
  padding: 32px 10px;
  font-size: 16px;
  padding: 32px 20px;
  color: #100a0f;
  text-decoration: none;
  position: relative; }

.menuparent li.sf-depth-2 a {
  padding-bottom: 20px !important;
  padding-top: 20px !important; }
  @media (max-width: 1110px) {
    .menuparent li.sf-depth-2 a {
      padding-bottom: 5px !important;
      padding-top: 0px !important; } }

.menuparent li:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 6px;
  background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, transparent), to(transparent));
  background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
  background-size: 202% 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  -webkit-transition: 0.3s background-position ease-out;
  transition: 0.3s background-position ease-out; }

.menuparent li:hover:after {
  background-position: 0 0; }
  @media (max-width: 1110px) {
    .menuparent li:hover:after {
      content: none; } }

@media (max-width: 1435px) {
  .block--gtweaks-header-lets-talk.block a {
    padding: 28px 32px; } }

@media (max-width: 1100px) {
  .slicknav_menu {
    display: block;
    margin: 0 !important; } }

.slicknav_menu .slicknav_menutxt {
  height: 100%;
  line-height: 43px;
  text-align: center;
  right: 135px;
  max-height: 80px; }
  @media (max-width: 1024px) {
    .slicknav_menu .slicknav_menutxt {
      line-height: 21px; } }

.slicknav_menu .slicknav_nav {
  position: absolute;
  background: white;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 160px; }
  @media (max-width: 1024px) {
    .slicknav_menu .slicknav_nav {
      width: 100vw;
      left: -20px; } }
  @media (max-width: 500px) {
    .slicknav_menu .slicknav_nav {
      left: -10px; } }
  .slicknav_menu .slicknav_nav li .slicknav_arrow {
    display: none; }

.slicknav_menu .social-media-mobile {
  display: flex;
  margin: 0 auto;
  text-align: center !important;
  justify-content: center;
  margin-top: 40px; }
  .slicknav_menu .social-media-mobile span {
    display: none; }
  @media (max-width: 1024px) {
    .slicknav_menu .social-media-mobile {
      display: flex !important;
      margin-bottom: 100px; } }

.slicknav_menu li .slicknav_hidden {
  display: flex !important;
  flex-direction: column;
  box-shadow: none;
  margin: 0 auto; }
  .slicknav_menu li .slicknav_hidden li a {
    font-size: 18px !important; }

.block--gtweaks-what-we-do .view-content .box.full-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0; }
  @media (max-width: 930px) {
    .block--gtweaks-what-we-do .view-content .box.full-width {
      padding: 0 !important; } }
  @media (max-width: 850px) {
    .block--gtweaks-what-we-do .view-content .box.full-width {
      flex-direction: column-reverse; } }
  .block--gtweaks-what-we-do .view-content .box.full-width .col:first-child {
    width: 510px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-top: 40px; }
    @media (max-width: 1200px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .col:first-child {
        width: 300px; } }
    @media (max-width: 850px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .col:first-child {
        width: 100%; } }
    .block--gtweaks-what-we-do .view-content .box.full-width .col:first-child img {
      margin-bottom: 80px;
      display: block; }
      @media (max-width: 560px) {
        .block--gtweaks-what-we-do .view-content .box.full-width .col:first-child img {
          margin-bottom: 40px; } }
  .block--gtweaks-what-we-do .view-content .box.full-width .col:last-child {
    width: 60%; }
    @media (max-width: 1200px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .col:last-child {
        margin-left: auto; } }
    @media (max-width: 850px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .col:last-child {
        width: 100%; } }
    .block--gtweaks-what-we-do .view-content .box.full-width .col:last-child img {
      margin-bottom: 70px;
      display: block;
      margin-left: auto; }
      @media (max-width: 850px) {
        .block--gtweaks-what-we-do .view-content .box.full-width .col:last-child img {
          margin-bottom: 0; } }
  @media (max-width: 700px) {
    .block--gtweaks-what-we-do .view-content .box.full-width .text br {
      display: none; } }
  .block--gtweaks-what-we-do .view-content .box.full-width a {
    padding: 25px 40px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    background-color: #23c9ff;
    display: block;
    text-decoration: none;
    margin-top: 40px;
    margin-bottom: 40px; }
    .block--gtweaks-what-we-do .view-content .box.full-width a:hover {
      cursor: pointer;
      background: #0facdf; }
    @media (max-width: 600px) {
      .block--gtweaks-what-we-do .view-content .box.full-width a {
        padding: 15px; } }
    @media (max-width: 400px) {
      .block--gtweaks-what-we-do .view-content .box.full-width a {
        padding: 10px; } }
    .block--gtweaks-what-we-do .view-content .box.full-width a:active, .block--gtweaks-what-we-do .view-content .box.full-width a:visited {
      color: white; }
  .block--gtweaks-what-we-do .view-content .box.full-width h2 {
    margin-top: 0;
    text-align: left;
    margin-bottom: 20px;
    line-height: 1;
    padding-left: 0; }
    @media (max-width: 560px) {
      .block--gtweaks-what-we-do .view-content .box.full-width h2 {
        margin-bottom: 20px !important; } }
  .block--gtweaks-what-we-do .view-content .box.full-width .content {
    width: 400px;
    margin: 0 auto;
    text-align: left;
    margin-left: 30px; }
    @media (max-width: 850px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .content {
        margin-left: auto; } }
    @media (max-width: 600px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .content {
        width: 300px; } }
    @media (max-width: 430px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .content {
        width: 250px; } }
    @media (max-width: 400px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .content {
        width: 180px; } }
    @media (max-width: 600px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .content .cta {
        text-align: center; } }
    @media (max-width: 400px) {
      .block--gtweaks-what-we-do .view-content .box.full-width .content .cta {
        font-size: 12px;
        text-align: center; } }

#block-views-training-block-3 .view-header {
  display: none; }
  @media (max-width: 1024px) {
    #block-views-training-block-3 .view-header {
      display: block; }
      #block-views-training-block-3 .view-header .vertical-number .yellow-title {
        display: flex;
        align-items: center;
        padding-left: 20px; }
        #block-views-training-block-3 .view-header .vertical-number .yellow-title .text {
          margin-bottom: 0; } }
  @media (max-width: 500px) {
    #block-views-training-block-3 .view-header .vertical-number .yellow-title {
      padding-left: 10px; } }

@media (max-width: 1024px) {
  #block-views-training-block-3 .required-fields .vertical-number {
    display: none; } }

#block-views-training-block-3 .group-wrapper-outer {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 1035px; }
  @media (max-width: 460px) {
    #block-views-training-block-3 .group-wrapper-outer {
      height: 750px; } }

#block-views-training-block-3 .group-content {
  width: calc(224px + ((100% - 352px) / 12 * 8));
  margin: 0 auto; }
  @media (max-width: 600px) {
    #block-views-training-block-3 .group-content {
      width: 100%;
      padding: 0 20px; } }
  @media (max-width: 360px) {
    #block-views-training-block-3 .group-content {
      padding: 0 10px; } }

#block-views-training-block-3 .read-more.rmore {
  position: relative; }

#block-views-training-block-3 .group-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px; }
  @media (max-width: 800px) {
    #block-views-training-block-3 .group-buttons {
      flex-direction: column; } }
  #block-views-training-block-3 .group-buttons .button {
    margin-right: 30px; }
    #block-views-training-block-3 .group-buttons .button:last-child {
      margin-right: 0; }
    @media (max-width: 800px) {
      #block-views-training-block-3 .group-buttons .button {
        text-align: center;
        margin-bottom: 20px;
        margin-right: 0; } }
    #block-views-training-block-3 .group-buttons .button a {
      text-align: center;
      border: 1px solid white;
      color: white;
      background-image: linear-gradient(to right, white, white 50%, transparent 50%, transparent); }
      #block-views-training-block-3 .group-buttons .button a:hover {
        color: black; }

#block-views-training-block-3 .group-wrapper {
  position: relative;
  float: none;
  padding-top: 0;
  padding-bottom: 0;
  min-height: auto;
  margin-left: 0;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
  max-width: 1600px; }

#block-views-training-block-3 .field--name-field-image-2 {
  position: absolute; }

#block-views-training-block-3 .vertical-number {
  padding-left: 0;
  width: 105px;
  margin-top: -95px; }

#block-views-training-block-3 .views-row {
  width: 100%; }
  #block-views-training-block-3 .views-row .node {
    height: auto; }

#block-views-training-block-3 .custom-title {
  color: #ffffff;
  font-family: "Libre Baskerville";
  font-size: 50px;
  font-weight: 400;
  line-height: 74px;
  letter-spacing: 1px;
  margin-bottom: 60px;
  text-align: center;
  text-transform: none; }
  @media (max-width: 1260px) {
    #block-views-training-block-3 .custom-title {
      font-size: 32px;
      line-height: 45px; } }
  @media (max-width: 800px) {
    #block-views-training-block-3 .custom-title {
      margin-bottom: 30px; } }
  @media (max-width: 460px) {
    #block-views-training-block-3 .custom-title {
      font-size: 1.66666rem;
      line-height: 38px; } }
  @media (max-width: 360px) {
    #block-views-training-block-3 .custom-title {
      font-size: 24px;
      line-height: 38px; } }

#block-views-training-block-3 .field--name-body {
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px; }
  @media (max-width: 900px) {
    #block-views-training-block-3 .field--name-body p br {
      display: none; } }

.bg-image-training {
  width: 100vw;
  height: 1035px;
  background-position: center center !important;
  background-repeat: no-repeat; }
  @media (max-width: 460px) {
    .bg-image-training {
      height: 750px; } }

#block-views-blog-block-1 {
  margin-top: 220px; }
  #block-views-blog-block-1 .view-blog {
    display: flex;
    position: relative; }
    @media (max-width: 1024px) {
      #block-views-blog-block-1 .view-blog .views-row:nth-child(2n+1) {
        clear: none; } }
    @media (max-width: 1024px) {
      #block-views-blog-block-1 .view-blog {
        flex-direction: column; } }
    #block-views-blog-block-1 .view-blog .vertical-number .yellow-title .text {
      top: 125px; }
      @media (max-width: 1024px) {
        #block-views-blog-block-1 .view-blog .vertical-number .yellow-title .text {
          top: 10px; } }
    #block-views-blog-block-1 .view-blog .view-content {
      margin-top: 85px;
      margin-left: calc(64px + ((100% - 352px) / 12 * 1)); }
      @media (max-width: 450px) {
        #block-views-blog-block-1 .view-blog .view-content {
          margin-left: 35px; } }
    #block-views-blog-block-1 .view-blog .views-row {
      max-width: 512px !important;
      margin-right: 30px; }
      #block-views-blog-block-1 .view-blog .views-row:nth-child(3n+1) {
        clear: none; }
      @media (max-width: 700px) {
        #block-views-blog-block-1 .view-blog .views-row {
          max-width: 285px !important;
          height: 330px;
          margin-bottom: 330px; }
          #block-views-blog-block-1 .view-blog .views-row .node--blog--teaser {
            min-height: 420px; }
          #block-views-blog-block-1 .view-blog .views-row .node-author-group-teaser {
            font-size: 9px;
            padding: 10px 10px 0 10px; }
          #block-views-blog-block-1 .view-blog .views-row .custom-title {
            font-size: 13px;
            line-height: 22px;
            padding: 10px; } }
      @media (max-width: 700px) {
        #block-views-blog-block-1 .view-blog .views-row {
          margin-bottom: 180px; } }
      @media (max-width: 450px) {
        #block-views-blog-block-1 .view-blog .views-row {
          margin-right: 5px; } }
  #block-views-blog-block-1 .slick-dots {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding-left: 0;
    max-width: 1330px; }
    #block-views-blog-block-1 .slick-dots li {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #C4C4C4;
      margin-right: 10px; }
      #block-views-blog-block-1 .slick-dots li:hover {
        cursor: pointer; }
      #block-views-blog-block-1 .slick-dots li.slick-active {
        background: #fbc31e; }
      #block-views-blog-block-1 .slick-dots li button {
        opacity: 0; }
  #block-views-blog-block-1 .view-footer {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px; }
  #block-views-blog-block-1 .slick-arrows-blog {
    display: flex;
    justify-items: center;
    height: 100%; }
    @media (max-width: 550px) {
      #block-views-blog-block-1 .slick-arrows-blog {
        display: none; } }
    #block-views-blog-block-1 .slick-arrows-blog .blog-prev {
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1567px -1082px;
      width: 37px;
      height: 12px;
      transform: rotate(180deg);
      margin-right: 30px;
      margin-top: auto;
      margin-bottom: auto; }
      @media (max-width: 410px) {
        #block-views-blog-block-1 .slick-arrows-blog .blog-prev {
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1626px -1082px;
          width: 19px;
          height: 6px;
          margin-right: 10px; } }
      #block-views-blog-block-1 .slick-arrows-blog .blog-prev:hover {
        cursor: pointer; }
    #block-views-blog-block-1 .slick-arrows-blog .blog-next {
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1567px -1082px;
      width: 37px;
      height: 12px;
      margin-top: auto;
      margin-bottom: auto; }
      @media (max-width: 410px) {
        #block-views-blog-block-1 .slick-arrows-blog .blog-next {
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1626px -1082px;
          width: 19px;
          height: 6px; } }
    #block-views-blog-block-1 .slick-arrows-blog:hover {
      cursor: pointer; }

.block--gtweaks-footer-lets-talk.block {
  height: 420px; }
  @media (max-width: 400px) {
    .block--gtweaks-footer-lets-talk.block {
      height: 480px; } }
  .block--gtweaks-footer-lets-talk.block > .block__content {
    padding: 100px 0;
    display: flex;
    flex-direction: column; }
    @media (max-width: 750px) {
      .block--gtweaks-footer-lets-talk.block > .block__content {
        padding: 0 !important; } }
  .block--gtweaks-footer-lets-talk.block .newsletter-intro {
    margin-top: auto; }
    .block--gtweaks-footer-lets-talk.block .newsletter-intro a {
      color: black; }
      .block--gtweaks-footer-lets-talk.block .newsletter-intro a:hover, .block--gtweaks-footer-lets-talk.block .newsletter-intro a:visited {
        color: black; }
    @media (max-width: 750px) {
      .block--gtweaks-footer-lets-talk.block .newsletter-intro {
        margin-top: 40px;
        font-size: 18px;
        margin-bottom: 25px; } }
  .block--gtweaks-footer-lets-talk.block #block-mailchimp-signup-theuxprodigy-newsletter {
    margin-bottom: auto; }
  .block--gtweaks-footer-lets-talk.block .form-actions {
    display: flex;
    position: absolute;
    top: 30%;
    right: 0;
    padding-bottom: 14px;
    transform: translateY(-50%);
    margin-bottom: 0;
    padding-bottom: 0; }
    @media (max-width: 750px) {
      .block--gtweaks-footer-lets-talk.block .form-actions {
        top: 25%; } }
    @media (max-width: 425px) {
      .block--gtweaks-footer-lets-talk.block .form-actions {
        top: 22%; } }
    .block--gtweaks-footer-lets-talk.block .form-actions:before {
      content: "send";
      display: inline;
      letter-spacing: 0.8px;
      font-size: 16px;
      margin-right: 10px; }
      @media (max-width: 700px) {
        .block--gtweaks-footer-lets-talk.block .form-actions:before {
          font-size: 12px; } }
    .block--gtweaks-footer-lets-talk.block .form-actions input[type="submit"] {
      position: relative !important;
      top: initial !important;
      right: initial !important;
      margin-top: auto;
      margin-bottom: auto; }

.social-media-icons {
  display: flex;
  align-items: center; }
  @media (max-width: 950px) {
    .social-media-icons {
      padding-top: 30px; } }
  .social-media-icons span {
    margin-right: 10px; }
  .social-media-icons a {
    float: none; }

.gdpr-menu {
  display: flex;
  position: relative;
  top: 6px; }
  @media (max-width: 950px) {
    .gdpr-menu {
      padding-top: 30px; } }
  @media (max-width: 600px) {
    .gdpr-menu {
      padding-bottom: 30px; } }
  @media (max-width: 450px) {
    .gdpr-menu {
      display: flex;
      flex-direction: column; }
      .gdpr-menu a {
        padding-bottom: 5px;
        margin: 0 !important; } }
  .gdpr-menu a {
    margin-right: 15px;
    font-weight: normal;
    text-decoration: underline; }
    .gdpr-menu a:first-child {
      margin-left: 15px; }

@media (max-width: 750px) {
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form {
    margin: 10px auto 0; }
    #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="text"] {
      font-size: 12px !important;
      padding: 8px 64px 8px 14px; } }

#mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-item-mergevars-EMAIL {
  margin-bottom: 10px; }

@media (max-width: 700px) {
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox {
    padding-left: 0; } }

@media (max-width: 425px) {
  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form .form-type-checkbox {
    max-width: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0; } }

#webform_drawer-161 {
  padding: 70px 60px 70px 130px; }
  #webform_drawer-161.flex-wrapper .node__content {
    display: flex;
    flex-wrap: wrap; }
    #webform_drawer-161.flex-wrapper .node__content .custom-title {
      flex-basis: 100%; }
  #webform_drawer-161.flex-wrapper form {
    flex-basis: 320px;
    margin-left: auto; }
    @media (max-width: 1024px) {
      #webform_drawer-161.flex-wrapper form {
        flex-basis: 100%; } }
  #webform_drawer-161.flex-wrapper .field--name-body {
    flex-basis: 50%; }
    @media (max-width: 1024px) {
      #webform_drawer-161.flex-wrapper .field--name-body {
        flex-basis: 100%;
        margin-bottom: 30px; }
        #webform_drawer-161.flex-wrapper .field--name-body br {
          display: none; } }
  #webform_drawer-161.flex-wrapper .field--name-body {
    color: #100a0f;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 1.6px;
    display: flex; }
    #webform_drawer-161.flex-wrapper .field--name-body .field__item {
      height: 100%;
      display: flex; }
      #webform_drawer-161.flex-wrapper .field--name-body .field__item .webform-text {
        display: flex;
        flex-direction: column;
        font-weight: bold; }
      #webform_drawer-161.flex-wrapper .field--name-body .field__item .webform-call-us {
        margin-top: auto;
        width: 100%; }
        #webform_drawer-161.flex-wrapper .field--name-body .field__item .webform-call-us .light-text {
          font-weight: normal; }
        #webform_drawer-161.flex-wrapper .field--name-body .field__item .webform-call-us div:not(.light-text) {
          color: #100a0f;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 1.6px;
          font-weight: bold; }

.node--webinars--teaser {
  margin-bottom: 130px; }
  @media (max-width: 680px) {
    .node--webinars--teaser {
      margin-bottom: 70px; } }
  .node--webinars--teaser .group-left-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 0; }
    @media (max-width: 550px) {
      .node--webinars--teaser .group-left-wrapper {
        flex-direction: column; } }
  @media (max-width: 600px) {
    .node--webinars--teaser .group-info {
      margin-right: auto; } }
  @media (max-width: 680px) {
    .node--webinars--teaser .title-grid-wrapper {
      line-height: 1.6;
      font-size: 1.11111rem; } }
  @media (max-width: 680px) {
    .node--webinars--teaser .field--name-field-image {
      min-height: 280px; } }
  .node--webinars--teaser .read-more {
    padding-top: 0;
    margin-left: auto; }
    @media (max-width: 550px) {
      .node--webinars--teaser .read-more {
        margin-left: 0;
        margin-right: auto; } }

#block-views-training-block-7 .view,
#block-views-training-block-6 .view {
  display: flex; }
  @media (max-width: 1024px) {
    #block-views-training-block-7 .view,
    #block-views-training-block-6 .view {
      flex-direction: column; } }
  #block-views-training-block-7 .view .view-content,
  #block-views-training-block-6 .view .view-content {
    width: 1200px;
    margin-left: auto;
    margin-top: 80px;
    margin-right: auto; }
    @media (max-width: 1024px) {
      #block-views-training-block-7 .view .view-content,
      #block-views-training-block-6 .view .view-content {
        width: 100%; } }

#block-views-training-block-7 .vertical-number .yellow-title .text,
#block-views-training-block-6 .vertical-number .yellow-title .text {
  top: 120px; }
  @media (max-width: 1024px) {
    #block-views-training-block-7 .vertical-number .yellow-title .text,
    #block-views-training-block-6 .vertical-number .yellow-title .text {
      top: 10px; } }

#block-views-training-block-7 {
  margin-bottom: 200px; }
  @media (max-width: 1024px) {
    #block-views-training-block-7 {
      margin-bottom: 80px; } }
  #block-views-training-block-7 .vertical-number .yellow-title .text {
    min-width: 250px;
    top: 200px; }
    @media (max-width: 1024px) {
      #block-views-training-block-7 .vertical-number .yellow-title .text {
        top: 10px; } }
    @media (max-width: 400px) {
      #block-views-training-block-7 .vertical-number .yellow-title .text {
        min-width: unset; } }
  #block-views-training-block-7 .views-row-wrapper,
  #block-views-training-block-7 .view-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    #block-views-training-block-7 .views-row-wrapper .views-row,
    #block-views-training-block-7 .view-content .views-row {
      margin-bottom: 33px;
      width: 31.54362%;
      margin-right: 2.68456%; }
      @media (max-width: 1024px) {
        #block-views-training-block-7 .views-row-wrapper .views-row,
        #block-views-training-block-7 .view-content .views-row {
          width: 48.79518%;
          margin-right: 2.40964%; } }
      @media (max-width: 680px) {
        #block-views-training-block-7 .views-row-wrapper .views-row,
        #block-views-training-block-7 .view-content .views-row {
          width: 100%;
          margin-right: 0; } }
      #block-views-training-block-7 .views-row-wrapper .views-row:nth-child(3n),
      #block-views-training-block-7 .view-content .views-row:nth-child(3n) {
        margin-right: 0; }
        @media (max-width: 1024px) {
          #block-views-training-block-7 .views-row-wrapper .views-row:nth-child(3n),
          #block-views-training-block-7 .view-content .views-row:nth-child(3n) {
            margin-right: 2.40964%; } }
        @media (max-width: 680px) {
          #block-views-training-block-7 .views-row-wrapper .views-row:nth-child(3n),
          #block-views-training-block-7 .view-content .views-row:nth-child(3n) {
            margin-right: 0; } }
      #block-views-training-block-7 .views-row-wrapper .views-row:nth-child(2n),
      #block-views-training-block-7 .view-content .views-row:nth-child(2n) {
        margin-right: 0; }

@media (max-width: 680px) {
  .node--webinars--box .field--name-field-image {
    min-height: 350px; } }

.node--public-workshops--box .read-more,
.node--webinars--box .read-more {
  width: 250px; }
  .node--public-workshops--box .read-more a,
  .node--webinars--box .read-more a {
    width: 100%; }

.node--public-workshops--box .request_quote a,
.node--webinars--box .request_quote a {
  width: 250px;
  color: #100a0f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px !important; }
  .node--public-workshops--box .request_quote a:hover,
  .node--webinars--box .request_quote a:hover {
    color: #fbc31e; }

#block-views-training-block-4 .node--public-workshops--box .read-more {
  width: 250px; }
  #block-views-training-block-4 .node--public-workshops--box .read-more a {
    width: 100%; }

#block-views-training-block-4 .node--public-workshops--box .request_quote a {
  width: 250px;
  color: #100a0f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px; }
  #block-views-training-block-4 .node--public-workshops--box .request_quote a:hover {
    color: #fbc31e; }

#webform_drawer-163 .custom-title {
  font-size: 1.77777rem;
  text-align: center;
  margin-bottom: 40px;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  line-height: 1.3; }

#webform_drawer-163 .node--webform .custom-title {
  display: none; }

.webex-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 99;
  bottom: 30px;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px; }
  .webex-logo img {
    margin-left: 20px; }

@media (max-width: 1024px) {
  .node--webinars--full {
    width: 100%; } }

@media (max-width: 1024px) {
  .node--webinars--full .node__content {
    width: 100% !important; } }

@media (max-width: 500px) {
  .node--webinars--full .node__content {
    padding-top: 100px; } }

.node--webinars--full .node--trainer .node__content {
  padding-top: 0; }

.node--webinars--full .webex-logo {
  position: relative;
  bottom: 0;
  left: 0;
  transform: none;
  justify-content: center;
  color: black;
  margin-top: 90px; }

.node--webinars--full .group-meta {
  margin-bottom: 40px; }
  @media (max-width: 1024px) {
    .node--webinars--full .group-meta {
      width: 100%;
      margin-left: 0; } }
  @media (max-width: 1024px) {
    .node--webinars--full .group-meta .custom-title {
      font-size: 2.01666rem;
      line-height: 1.3; } }
  @media (max-width: 500px) {
    .node--webinars--full .group-meta .custom-title {
      font-size: 1.66666rem; } }

@media (max-width: 500px) {
  .node--webinars--full .field--name-field-body-3,
  .node--webinars--full .field--name-field-body-2,
  .node--webinars--full .field--name-body {
    padding-bottom: 45px;
    margin-bottom: 45px; } }

.node--webinars--full .field--name-field-image {
  min-height: auto !important; }
  .node--webinars--full .field--name-field-image img {
    min-height: auto !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    max-width: 100% !important;
    width: 100% !important;
    transform: none !important; }
  .node--webinars--full .field--name-field-image:after {
    content: none !important; }

.node--webinars--full .field--name-field-date-range {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }
  @media (max-width: 450px) {
    .node--webinars--full .field--name-field-date-range {
      align-items: flex-start; } }
  .node--webinars--full .field--name-field-date-range .field__label {
    font-weight: normal;
    line-height: 27px;
    margin-bottom: 0;
    font-size: 17px; }
    @media (max-width: 470px) {
      .node--webinars--full .field--name-field-date-range .field__label {
        line-height: 24px; } }
  .node--webinars--full .field--name-field-date-range .field__items {
    float: none; }
  .node--webinars--full .field--name-field-date-range .date-display-single {
    font-weight: bold;
    font-size: 17px;
    line-height: 1; }
    .node--webinars--full .field--name-field-date-range .date-display-single .date-display-range {
      font-weight: bold;
      font-size: 17px;
      line-height: 1; }
      .node--webinars--full .field--name-field-date-range .date-display-single .date-display-range:after {
        content: "(GMT+2)";
        padding-left: 4px; }

@media (max-width: 900px) {
  .node--webinars--full .group-content-bounds .group-left-wrapper {
    display: none; } }

@media (max-width: 900px) {
  .node--webinars--full .group-content-bounds .group-left-wrapper .group-pricing-wrapper {
    display: flex;
    align-items: end;
    flex-direction: row-reverse; } }

@media (max-width: 470px) {
  .node--webinars--full .group-content-bounds .group-left-wrapper .group-pricing-wrapper {
    flex-direction: column; } }

@media (max-width: 470px) {
  .node--webinars--full .group-content-bounds .group-left-wrapper .group-pricing-wrapper .field--name-field-pw-product-reference {
    margin: 0 auto; } }

@media (max-width: 900px) {
  .node--webinars--full .group-content-bounds .group-left-wrapper .group-pricing-wrapper .addthis-wrapper {
    margin-top: 0;
    margin-right: auto; } }

@media (max-width: 470px) {
  .node--webinars--full .group-content-bounds .group-left-wrapper .group-pricing-wrapper .addthis-wrapper {
    margin-top: 30px;
    margin-left: auto; } }

@media (max-width: 900px) {
  .node--webinars--full .group-right-wrapper {
    width: 83%;
    margin: 0 auto;
    float: none; } }

@media (max-width: 850px) {
  .node-type-webinars .view-related-inhouse-training .view-content {
    width: 83%;
    margin-left: auto;
    margin-right: auto; } }

.page-checkout .l-main {
  max-width: 100%; }
  @media (max-width: 1160px) {
    .page-checkout .l-main {
      margin-left: 0;
      margin-right: 0; } }

.page-checkout .l-header-wrapper {
  border-bottom: 1px solid #d8d8d8; }

.page-checkout .messages {
  font-size: 14px; }

.page-checkout .l-region--header {
  height: 100%; }
  @media (max-width: 850px) {
    .page-checkout .l-region--header {
      width: 100%;
      position: relative;
      right: 0;
      left: 0;
      justify-content: center; } }

.page-checkout .block-gtweaks-notification-bar-top {
  display: none; }

@media (max-width: 850px) {
  .page-checkout .l-header {
    display: flex;
    flex-direction: column;
    height: 100px; }
    .page-checkout .l-header .l-branding {
      float: none;
      margin: 0 auto; } }

.commerce-checkout-form-complete > div,
.commerce-checkout-form-checkout > div {
  display: flex; }
  @media (max-width: 1160px) {
    .commerce-checkout-form-complete > div,
    .commerce-checkout-form-checkout > div {
      flex-direction: column-reverse; } }
  .commerce-checkout-form-complete > div .grouper,
  .commerce-checkout-form-checkout > div .grouper {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 200px; }
    @media (max-width: 1160px) {
      .commerce-checkout-form-complete > div .grouper,
      .commerce-checkout-form-checkout > div .grouper {
        width: 100%;
        margin-right: 0; } }
    @media (max-width: 500px) {
      .commerce-checkout-form-complete > div .grouper,
      .commerce-checkout-form-checkout > div .grouper {
        margin-bottom: 60px; } }
    .commerce-checkout-form-complete > div .grouper .content,
    .commerce-checkout-form-checkout > div .grouper .content {
      max-width: 510px;
      margin: 0 auto; }
      @media (max-width: 550px) {
        .commerce-checkout-form-complete > div .grouper .content,
        .commerce-checkout-form-checkout > div .grouper .content {
          padding: 0 30px; } }
    .commerce-checkout-form-complete > div .grouper .messages.error,
    .commerce-checkout-form-checkout > div .grouper .messages.error {
      position: absolute;
      max-width: 512px; }
    .commerce-checkout-form-complete > div .grouper .account legend,
    .commerce-checkout-form-checkout > div .grouper .account legend {
      padding-top: 120px;
      font-size: 24px;
      margin-bottom: 30px; }
      @media (max-width: 500px) {
        .commerce-checkout-form-complete > div .grouper .account legend,
        .commerce-checkout-form-checkout > div .grouper .account legend {
          padding-top: 100px; } }
    .commerce-checkout-form-complete > div .grouper .account .fieldset-wrapper #account-login-container .form-item,
    .commerce-checkout-form-checkout > div .grouper .account .fieldset-wrapper #account-login-container .form-item {
      margin-bottom: 0; }
      .commerce-checkout-form-complete > div .grouper .account .fieldset-wrapper #account-login-container .form-item label,
      .commerce-checkout-form-checkout > div .grouper .account .fieldset-wrapper #account-login-container .form-item label {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 8px; }
      .commerce-checkout-form-complete > div .grouper .account .fieldset-wrapper #account-login-container .form-item input,
      .commerce-checkout-form-checkout > div .grouper .account .fieldset-wrapper #account-login-container .form-item input {
        max-width: 512px;
        height: 50px;
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px; }
    .commerce-checkout-form-complete > div .grouper .account .fieldset-wrapper #edit-account-consent,
    .commerce-checkout-form-checkout > div .grouper .account .fieldset-wrapper #edit-account-consent {
      margin-bottom: 45px; }
      .commerce-checkout-form-complete > div .grouper .account .fieldset-wrapper #edit-account-consent .form-item label,
      .commerce-checkout-form-checkout > div .grouper .account .fieldset-wrapper #edit-account-consent .form-item label {
        font-size: 14px; }
    .commerce-checkout-form-complete > div .grouper .commerce-payment-title,
    .commerce-checkout-form-checkout > div .grouper .commerce-payment-title {
      font-family: "Libre Baskerville";
      font-size: 24px; }
    .commerce-checkout-form-complete > div .grouper #edit-account .fieldset-legend,
    .commerce-checkout-form-checkout > div .grouper #edit-account .fieldset-legend {
      font-family: "Libre Baskerville";
      font-size: 24px; }
    .commerce-checkout-form-complete > div .grouper .customer_profile_billing legend,
    .commerce-checkout-form-checkout > div .grouper .customer_profile_billing legend {
      font-size: 24px;
      margin-bottom: 35px; }
    .commerce-checkout-form-complete > div .grouper .customer_profile_billing .fieldset-legend,
    .commerce-checkout-form-checkout > div .grouper .customer_profile_billing .fieldset-legend {
      font-family: "Libre Baskerville";
      font-size: 24px; }
    .commerce-checkout-form-complete > div .grouper .customer_profile_billing label,
    .commerce-checkout-form-checkout > div .grouper .customer_profile_billing label {
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 8px;
      color: #000000;
      font-family: Montserrat; }
    .commerce-checkout-form-complete > div .grouper .customer_profile_billing input,
    .commerce-checkout-form-checkout > div .grouper .customer_profile_billing input {
      max-width: 512px;
      height: 50px;
      width: 100%;
      padding: 0 15px;
      font-size: 14px; }
    .commerce-checkout-form-complete > div .grouper .customer_profile_billing .field-name-field-customer-phone .form-item,
    .commerce-checkout-form-checkout > div .grouper .customer_profile_billing .field-name-field-customer-phone .form-item {
      margin-bottom: 0; }
      .commerce-checkout-form-complete > div .grouper .customer_profile_billing .field-name-field-customer-phone .form-item label,
      .commerce-checkout-form-checkout > div .grouper .customer_profile_billing .field-name-field-customer-phone .form-item label {
        font-weight: normal;
        font-size: 14px;
        margin-bottom: 8px; }
      .commerce-checkout-form-complete > div .grouper .customer_profile_billing .field-name-field-customer-phone .form-item input,
      .commerce-checkout-form-checkout > div .grouper .customer_profile_billing .field-name-field-customer-phone .form-item input {
        max-width: 512px;
        height: 50px;
        width: 100%; }
    .commerce-checkout-form-complete > div .grouper .customer_profile_billing .addressfield-container-inline,
    .commerce-checkout-form-checkout > div .grouper .customer_profile_billing .addressfield-container-inline {
      display: flex;
      flex-direction: column; }
      .commerce-checkout-form-complete > div .grouper .customer_profile_billing .addressfield-container-inline .form-item,
      .commerce-checkout-form-checkout > div .grouper .customer_profile_billing .addressfield-container-inline .form-item {
        width: 100%;
        max-width: 512px; }
    .commerce-checkout-form-complete > div .grouper .phone-helper,
    .commerce-checkout-form-checkout > div .grouper .phone-helper {
      font-size: 12px;
      margin-bottom: 65px;
      margin-top: 10px; }
    .commerce-checkout-form-complete > div .grouper .commerce-payment-title,
    .commerce-checkout-form-checkout > div .grouper .commerce-payment-title {
      font-size: 24px;
      margin-bottom: 35px;
      margin-top: 45px; }
    .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper,
    .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper {
      margin-top: 40px;
      max-width: 512px;
      width: 100%;
      border: 1px solid #cccccc; }
      .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method,
      .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method {
        min-height: 88px;
        display: flex;
        align-items: center; }
        .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .checkmark,
        .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .checkmark {
          top: 50%;
          transform: translateY(-50%);
          left: 20px; }
        .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item,
        .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item {
          display: flex;
          align-items: center;
          margin-bottom: 0; }
          .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item input,
          .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item input {
            margin-left: 20px; }
          .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item label,
          .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item label {
            font-size: 14px;
            margin-left: 30px; }
            .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item label span,
            .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper #edit-commerce-payment-payment-method .form-item label span {
              margin-bottom: 17px;
              display: block; }
      .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper legend,
      .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper legend {
        display: none; }
      .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper:after,
      .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper:after {
        content: "";
        background-image: url(../images/payment_instructions.png);
        display: block;
        width: 100%;
        height: 300px;
        border-top: 1px solid #cccccc; }
      .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper .accepted-cards-payment li,
      .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper .accepted-cards-payment li {
        margin-right: 8px; }
      @media (max-width: 850px) {
        .commerce-checkout-form-complete > div .grouper #commerce-payment-method-ajax-wrapper .accepted-cards-payment,
        .commerce-checkout-form-checkout > div .grouper #commerce-payment-method-ajax-wrapper .accepted-cards-payment {
          margin: 0 auto; } }
    .commerce-checkout-form-complete > div .grouper .accept_terms,
    .commerce-checkout-form-checkout > div .grouper .accept_terms {
      margin-top: 100px; }
      .commerce-checkout-form-complete > div .grouper .accept_terms .form-item,
      .commerce-checkout-form-checkout > div .grouper .accept_terms .form-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px; }
      .commerce-checkout-form-complete > div .grouper .accept_terms .option,
      .commerce-checkout-form-checkout > div .grouper .accept_terms .option {
        display: none; }
    .commerce-checkout-form-complete > div .grouper .checkout-buttons .fieldset-wrapper,
    .commerce-checkout-form-checkout > div .grouper .checkout-buttons .fieldset-wrapper {
      display: flex;
      flex-direction: column; }
    .commerce-checkout-form-complete > div .grouper .checkout-buttons .checkout-processing,
    .commerce-checkout-form-complete > div .grouper .checkout-buttons .button-operator,
    .commerce-checkout-form-checkout > div .grouper .checkout-buttons .checkout-processing,
    .commerce-checkout-form-checkout > div .grouper .checkout-buttons .button-operator {
      display: none; }
    .commerce-checkout-form-complete > div .grouper .checkout-buttons .checkout-continue,
    .commerce-checkout-form-checkout > div .grouper .checkout-buttons .checkout-continue {
      width: 100%;
      max-width: 512px;
      height: 64px;
      background-color: #fbc31e;
      border: none;
      font-size: 16px;
      cursor: pointer;
      background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
      background-size: 200% 100%;
      background-position: 100% 0;
      background-repeat: no-repeat;
      -webkit-transition: 0.3s background-position ease-out;
      transition: 0.3s background-position ease-out;
      color: black; }
      .commerce-checkout-form-complete > div .grouper .checkout-buttons .checkout-continue:hover,
      .commerce-checkout-form-checkout > div .grouper .checkout-buttons .checkout-continue:hover {
        background-position: 0 0;
        color: #fbc31e; }
    .commerce-checkout-form-complete > div .grouper .checkout-buttons .checkout-cancel,
    .commerce-checkout-form-checkout > div .grouper .checkout-buttons .checkout-cancel {
      width: 100%;
      max-width: 512px;
      height: 64px;
      border: none;
      font-size: 16px;
      color: black;
      cursor: pointer; }
  .commerce-checkout-form-complete > div > .cart_contents,
  .commerce-checkout-form-checkout > div > .cart_contents {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #f6f6f6;
    z-index: 99;
    position: relative; }
    @media (max-width: 1160px) {
      .commerce-checkout-form-complete > div > .cart_contents,
      .commerce-checkout-form-checkout > div > .cart_contents {
        width: 100%;
        margin-right: 0;
        padding-bottom: 90px; } }
    .commerce-checkout-form-complete > div > .cart_contents .fieldset-legend,
    .commerce-checkout-form-checkout > div > .cart_contents .fieldset-legend {
      color: #000000;
      font-family: "Libre Baskerville";
      font-size: 24px;
      font-weight: 400; }
    .commerce-checkout-form-complete > div > .cart_contents legend,
    .commerce-checkout-form-checkout > div > .cart_contents legend {
      padding-top: 115px;
      font-size: 26px;
      font-family: serif;
      margin-bottom: 40px; }
    .commerce-checkout-form-complete > div > .cart_contents .content,
    .commerce-checkout-form-checkout > div > .cart_contents .content {
      max-width: 510px;
      margin: 0 auto;
      position: sticky;
      top: 0; }
      @media (max-width: 550px) {
        .commerce-checkout-form-complete > div > .cart_contents .content,
        .commerce-checkout-form-checkout > div > .cart_contents .content {
          padding: 0 30px;
          position: relative; } }
    .commerce-checkout-form-complete > div > .cart_contents .view-header,
    .commerce-checkout-form-checkout > div > .cart_contents .view-header {
      width: 100%;
      float: none;
      display: none; }
    .commerce-checkout-form-complete > div > .cart_contents .view-content,
    .commerce-checkout-form-checkout > div > .cart_contents .view-content {
      min-height: 105px; }
      .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row,
      .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row {
        display: flex;
        align-items: center; }
        @media (max-width: 440px) {
          .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row,
          .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row {
            flex-direction: column; } }
        .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row .views-field-field-product-image,
        .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row .views-field-field-product-image {
          float: left; }
        .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row .field--type-commerce-price,
        .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row .field--type-commerce-price {
          clear: both; }
        .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row .views-field-title,
        .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row .views-field-title {
          margin-left: 20px;
          font-weight: bold;
          font-size: 14px;
          padding-right: 10px; }
          @media (max-width: 450px) {
            .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row .views-field-title,
            .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row .views-field-title {
              margin-left: 0; } }
        .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row .views-field-commerce-unit-price,
        .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row .views-field-commerce-unit-price {
          margin-left: auto;
          font-weight: bold; }
          @media (max-width: 450px) {
            .commerce-checkout-form-complete > div > .cart_contents .view-content .views-row .views-field-commerce-unit-price,
            .commerce-checkout-form-checkout > div > .cart_contents .view-content .views-row .views-field-commerce-unit-price {
              margin-left: 0;
              margin-right: auto; } }
    .commerce-checkout-form-complete > div > .cart_contents:after,
    .commerce-checkout-form-checkout > div > .cart_contents:after {
      content: "";
      display: block;
      width: 50vw;
      height: 100%;
      background-color: #f6f6f6;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }

.view-commerce-cart-summary img {
  display: block; }

.view-commerce-cart-summary .views-row {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 40px;
  margin-bottom: 40px; }

fieldset[id^="edit-commerce-coupon"] label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 10px; }

fieldset[id^="edit-commerce-coupon"] .messages {
  flex-basis: 100%;
  margin-bottom: 30px; }

fieldset[id^="edit-commerce-coupon"] .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
  border-bottom: 1px solid #d9d9d9; }
  @media (max-width: 440px) {
    fieldset[id^="edit-commerce-coupon"] .fieldset-wrapper {
      flex-direction: column; } }

fieldset[id^="edit-commerce-coupon"] input[type="text"] {
  height: 50px;
  border: 1px solid #cccccc;
  padding: 20px;
  background: none;
  font-size: 14px; }

.form-item-commerce-coupon-coupon-code {
  width: calc(100% - 105px); }
  @media (max-width: 440px) {
    .form-item-commerce-coupon-coupon-code {
      width: 100%; } }
  .form-item-commerce-coupon-coupon-code input {
    width: 100%; }

input[id^="edit-commerce-coupon-coupon-add"] {
  width: 105px;
  height: 50px;
  background-color: #d7d7d7;
  border: none;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  top: 3px; }
  input[id^="edit-commerce-coupon-coupon-add"]:hover {
    cursor: pointer; }
  @media (max-width: 1160px) {
    input[id^="edit-commerce-coupon-coupon-add"] {
      top: 4px; } }
  @media (max-width: 440px) {
    input[id^="edit-commerce-coupon-coupon-add"] {
      width: 100%; } }

#edit-account-login-mail {
  padding: 0 15px; }

/* The container */
.form-type-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 16px; }

/* Hide the browser's default checkbox */
.form-type-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #b7b7b7; }

/* On mouse-over, add a grey background color */
.form-type-checkbox:hover input ~ .checkmark {
  background-color: white; }

/* When the checkbox is checked, add a blue background */
.form-type-checkbox input:checked ~ .checkmark {
  background-color: white; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.form-type-checkbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.form-type-checkbox .checkmark:after {
  left: 7px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

#co-order-total-checkout-ajax-wrapper {
  margin-top: 30px; }

.co-order--row {
  display: flex; }
  .co-order--row:last-child {
    margin-top: 20px; }
    .co-order--row:last-child .co-order--value,
    .co-order--row:last-child .co-order--label {
      color: #000000;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 700; }

.co-order--value,
.co-order--label {
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 300; }

.co-order--value {
  margin-left: auto; }

.form-item-accept-terms-terms-of-use .description {
  font-size: 14px;
  font-weight: 300; }

#edit-accept-terms .description {
  height: 22px;
  line-height: 22px; }

#edit-accept-terms a {
  color: black;
  position: relative;
  z-index: 100; }
  #edit-accept-terms a:hover, #edit-accept-terms a:active, #edit-accept-terms a:visited {
    color: black; }

#commerce-alphabank-redirect-form {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 150px 0; }
  #commerce-alphabank-redirect-form #edit-submit {
    display: inline-block;
    padding: 22px;
    color: #100a0f;
    font-size: 13.99986px;
    font-size: 0.77777rem;
    text-align: center;
    text-decoration: none;
    font-weight: 300;
    min-width: 225px;
    background-image: -webkit-gradient(linear, left top, right top, from(#100a0f), color-stop(50%, #100a0f), color-stop(50%, #fbc31e), to(#fbc31e));
    background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    border: none;
    margin-top: 30px; }
    #commerce-alphabank-redirect-form #edit-submit:hover {
      cursor: pointer;
      background-position: 0 0;
      color: #fbc31e; }

.messages.error {
  display: none; }

#block-gtweaks-accepted-cards-payment {
  margin: 0;
  display: flex; }
  #block-gtweaks-accepted-cards-payment .block__content {
    display: flex; }

.accepted-cards-payment {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 160px; }
  .accepted-cards-payment li {
    margin-right: 20px; }
    .accepted-cards-payment li:last-child {
      margin-right: 0; }

@media (max-width: 440px) {
  .views-field-uxwebinar-image {
    margin-bottom: 20px;
    margin-right: auto; } }

@media (max-width: 850px) {
  .accepted-cards-payment {
    margin: 0 auto; } }

@media (max-width: 350px) {
  .accepted-cards-payment li {
    margin-right: 10px; } }

.views-form .form-actions .ajax-progress-throbber {
  position: absolute;
  right: -10px;
  display: block;
  width: 135px;
  height: 20px;
  right: 0px;
  top: 386px; }
  @media (max-width: 550px) {
    .views-form .form-actions .ajax-progress-throbber {
      top: 380px;
      right: 24px;
      left: none; } }
  @media (max-width: 440px) {
    .views-form .form-actions .ajax-progress-throbber {
      top: 490px;
      left: 30px; } }
  .views-form .form-actions .ajax-progress-throbber .throbber {
    margin: 0;
    order: 99;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background: transparent url(../../../../default/files/throbber.gif) no-repeat; }
  .views-form .form-actions .ajax-progress-throbber .message {
    font-size: 13px;
    padding: 0; }

.views-form .form-actions input {
  display: none; }

.views-form {
  min-height: 105px; }
  .views-form img {
    display: block; }
  .views-form .views-row {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 40px;
    margin-bottom: 40px; }
  .views-form .views-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 440px) {
      .views-form .views-row {
        flex-direction: column; } }
    .views-form .views-row .views-field-uxwebinar-image {
      width: 21%; }
    .views-form .views-row .views-field-uxwebinar-title {
      width: 57%; }
    .views-form .views-row .views-field-commerce-unit-price {
      width: 16%; }
    .views-form .views-row .views-field-field-product-image {
      float: left; }
    .views-form .views-row .views-field-edit-quantity {
      width: 100%;
      margin-top: 45px; }
      .views-form .views-row .views-field-edit-quantity .field-content {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 440px) {
          .views-form .views-row .views-field-edit-quantity .field-content {
            flex-direction: column; } }
        .views-form .views-row .views-field-edit-quantity .field-content .pre-quantity {
          max-width: 200px;
          font-size: 14px; }
          @media (max-width: 440px) {
            .views-form .views-row .views-field-edit-quantity .field-content .pre-quantity {
              margin-bottom: 20px; } }
        .views-form .views-row .views-field-edit-quantity .field-content .commerce-quantity-plusminus-link a {
          text-decoration: none;
          background-color: white;
          border-radius: 28px;
          font-size: 21px;
          padding: 7px 16px;
          border: 2px solid #d6d6d6;
          margin-right: 15px;
          color: #63636d;
          cursor: pointer; }
        .views-form .views-row .views-field-edit-quantity .field-content .commerce-quantity-plusminus-link a:visited {
          color: #63636d; }
        .views-form .views-row .views-field-edit-quantity .field-content .commerce-quantity-plusminus-link-increase a {
          margin-right: 0;
          margin-left: 15px;
          padding: 7px 13px;
          color: #63636d;
          cursor: pointer; }
        .views-form .views-row .views-field-edit-quantity .field-content .commerce-quantity-plusminus-link-increase a:visited {
          color: #63636d; }
        .views-form .views-row .views-field-edit-quantity .field-content input {
          border: none;
          background-color: inherit;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          cursor: not-allowed;
          pointer-events: none;
          max-width: 45px; }
    .views-form .views-row .field--type-commerce-price {
      clear: both; }
    .views-form .views-row .views-field-title {
      margin-left: 20px;
      font-weight: bold;
      font-size: 14px;
      padding-right: 10px; }
      @media (max-width: 450px) {
        .views-form .views-row .views-field-title {
          margin-left: 0; } }
    .views-form .views-row .views-field-commerce-unit-price {
      margin-left: auto;
      font-weight: bold; }
      @media (max-width: 450px) {
        .views-form .views-row .views-field-commerce-unit-price {
          margin-left: 0;
          margin-right: auto; } }

.form-item-customer-profile-billing-field-invoice-check-und {
  margin-top: 20px; }
  .form-item-customer-profile-billing-field-invoice-check-und input {
    z-index: 99; }
  .form-item-customer-profile-billing-field-invoice-check-und .checkmark {
    z-index: -99999; }

.vat-helper {
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: -10px; }

#commerce-checkout-form-complete .block-gtweaks-notification-bar-top {
  display: none; }

#commerce-checkout-form-complete #edit-cart-contents > .fieldset-wrapper,
#commerce-checkout-form-complete #edit-grouper > .fieldset-wrapper {
  min-height: 980px;
  padding-top: 190px; }
  @media (max-width: 1160px) {
    #commerce-checkout-form-complete #edit-cart-contents > .fieldset-wrapper,
    #commerce-checkout-form-complete #edit-grouper > .fieldset-wrapper {
      min-height: auto; } }
  @media (max-width: 500px) {
    #commerce-checkout-form-complete #edit-cart-contents > .fieldset-wrapper,
    #commerce-checkout-form-complete #edit-grouper > .fieldset-wrapper {
      padding-top: 60px; } }

#commerce-checkout-form-complete .title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px; }

#commerce-checkout-form-complete > div .grouper {
  max-width: 510px;
  margin-right: auto; }

#commerce-checkout-form-complete .message {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-top: 60px; }
  #commerce-checkout-form-complete .message:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url("/sites/all/themes/theuxprodigy/images/checkout-check.png");
    background-size: cover;
    background-repeat: no-repeat; }
  #commerce-checkout-form-complete .message p {
    width: calc(100% - 40px);
    padding-left: 20px; }

#commerce-checkout-form-complete .help {
  font-size: 16px;
  margin-top: 130px; }
  #commerce-checkout-form-complete .help a {
    color: #2b78b9;
    text-decoration: underline; }

#edit-buttons {
  margin-top: 30px; }
  #edit-buttons .fieldset-wrapper {
    height: auto;
    padding-top: 0; }
  #edit-buttons .checkout-gofrontpage {
    max-width: 241px;
    line-height: 64px;
    text-align: center;
    text-decoration: none;
    min-width: 225px;
    background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    color: black; }
    #edit-buttons .checkout-gofrontpage:hover {
      background-position: 0 0;
      color: #fbc31e; }

#edit-webinar-link {
  display: flex;
  flex-direction: column;
  margin-top: 60px; }

.webinar-link--description a {
  color: #1089f4;
  display: block;
  margin-top: 15px;
  word-break: break-all; }
  .webinar-link--description a:visited {
    color: #1089f4; }

.webinar-to-calendar--description {
  margin-bottom: 15px;
  display: inline-block; }

.webinar-link--copy-link a {
  color: #100a0f;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  line-height: 49px;
  width: 240px;
  display: inline-block;
  text-align: center;
  border: 1px solid #acacac;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 40px;
  background-color: none;
  -webkit-transition: 0.3s background-position ease-out;
  transition: 0.3s background-position ease-out;
  background-size: 204% 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, transparent), to(transparent));
  background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
  background-position: 100% 0; }
  .webinar-link--copy-link a:hover {
    cursor: pointer;
    background-position: 0 0; }

.commerce-checkout-form-complete > div > .cart_contents,
.commerce-checkout-form-checkout > div > .cart_contents {
  margin-right: 0; }

.webinar-to-calendar--button {
  margin-bottom: 10px;
  display: flex;
  justify-content: center; }
  .webinar-to-calendar--button:last-child {
    margin-bottom: 0; }
  .webinar-to-calendar--button.microsoft {
    width: 240px;
    height: 48px;
    background: #25baff;
    line-height: 49px;
    text-align: center; }
    .webinar-to-calendar--button.microsoft:before {
      content: "";
      background: url("/sites/all/themes/theuxprodigy/images/microsoft_icon.png");
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px; }
  .webinar-to-calendar--button.google {
    width: 240px;
    height: 48px;
    background-color: #1089f4;
    line-height: 49px;
    text-align: center; }
    .webinar-to-calendar--button.google:before {
      content: "";
      background: url("/sites/all/themes/theuxprodigy/images/google_icon.png");
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px; }
  .webinar-to-calendar--button.apple {
    width: 240px;
    height: 48px;
    background-color: #f35b58;
    line-height: 49px;
    text-align: center; }
    .webinar-to-calendar--button.apple:before {
      content: "";
      background: url("/sites/all/themes/theuxprodigy/images/apple_icon.png");
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px; }
  .webinar-to-calendar--button a {
    color: #fefefe;
    text-decoration: none;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400; }
    .webinar-to-calendar--button a:visited, .webinar-to-calendar--button a:hover {
      color: #fefefe; }

.copied {
  background: #fbc31e; }

.node--services-content--full .custom-title {
  width: 100%; }

@media (max-width: 600px) {
  .node--services-content--full .group-meta {
    margin-bottom: 30px; } }

@media (max-width: 600px) {
  .node--services-content--full .group-image-wrapper {
    margin-bottom: 30px; } }

.group-content-bounds .group-left-wrapper {
  position: sticky;
  top: 100px;
  bottom: 0;
  margin-bottom: 130px; }
  @media (max-width: 1024px) {
    .group-content-bounds .group-left-wrapper {
      position: relative;
      top: unset;
      bottom: unset; } }

.is-linked .field-collection-item-field-methods-techniques {
  position: relative; }
  .is-linked .field-collection-item-field-methods-techniques .field--name-field-collection-title {
    position: relative; }
    .is-linked .field-collection-item-field-methods-techniques .field--name-field-collection-title:after {
      content: "";
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
      background-position: -1606px -1082px;
      width: 18px;
      height: 12px; }
    .is-linked .field-collection-item-field-methods-techniques .field--name-field-collection-title:hover {
      background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
      background-size: 200% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
      cursor: pointer; }

.is-linked .field--name-field-page-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  text-indent: -9999px; }

.group-right-wrapper .product-link {
  margin-bottom: 25px; }

.node--page--full {
  padding-top: 70px; }

.field--name-field-early-product-ref {
  margin-bottom: 30px; }
  @media (max-width: 450px) {
    .field--name-field-early-product-ref {
      margin-bottom: 10px; } }

.reserve-your-seat {
  text-decoration: none; }

.deactivated-price,
.activated-price {
  padding: 16px 0 !important;
  line-height: 1; }

.node--public-workshops--minimal .group-right-wrapper {
  width: auto !important;
  display: flex;
  flex-direction: column; }
  .node--public-workshops--minimal .group-right-wrapper .field--name-field-early-product-ref {
    margin-top: 30px; }

.node--plain-page--full {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 190px 0 150px 0; }
  @media (max-width: 600px) {
    .node--plain-page--full {
      padding: 100px 0 100px 0; } }
  .node--plain-page--full .custom-title {
    font-family: "Libre Baskerville";
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 70px; }
    @media (max-width: 700px) {
      .node--plain-page--full .custom-title {
        font-size: 35px; } }
    @media (max-width: 400px) {
      .node--plain-page--full .custom-title {
        font-size: 28px; } }
  .node--plain-page--full .field--name-body {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px; }
    @media (max-width: 400px) {
      .node--plain-page--full .field--name-body {
        font-size: 16px; } }

.field--name-field-agenda {
  margin-bottom: 100px; }

@media (pointer: coarse) {
  .services-cta .button a:hover,
  .button.main-banner-button a:hover {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    background: #fbc31e;
    color: #100a0f; }
  .box .button a:hover {
    border-color: #100a0f;
    background-position: none;
    background: white; }
  .node--webinars--box .read-more.rmore.button a:hover,
  .node--public-workshops--box .read-more.rmore.button a:hover,
  #block-views-training-block-3 .group-buttons .button a:hover {
    border: 1px solid white;
    color: white;
    background: none; }
  .node--case-studies--teaser .read-more a:hover {
    color: #100a0f;
    background-position: none;
    background: #fbc31e; }
  .view-navigation-boxes .read-more.rmore a:hover {
    border: 1px solid #fbc31e;
    color: #fbc31e;
    background: none; }
  .node--public-workshops--box .request_quote a:hover, .node--webinars--box .request_quote a:hover,
  #block-views-training-block-4 .node--public-workshops--box .request_quote a:hover {
    color: #100a0f;
    background-position: none;
    background: #fbc31e; }
  .read-more.rmore a:hover {
    background-position: none;
    border-color: #100a0f;
    background: none; }
  .commerce-checkout-form-complete > div .grouper .checkout-buttons .checkout-continue:hover, .commerce-checkout-form-checkout > div .grouper .checkout-buttons .checkout-continue:hover,
  .field--name-field-pw-product-reference .activated-price:hover,
  .button a:hover {
    background-position: none;
    color: #100a0f;
    background: #fbc31e; }
  .custom-row .box .button a:hover,
  .block--gtweaks-header-lets-talk.block a:hover {
    color: white;
    background-position: none;
    background: black; }
  .webinar-link--copy-link a:hover {
    background: none; }
  #edit-buttons .checkout-gofrontpage {
    color: #100a0f;
    background-position: none;
    background: #fbc31e; } }

.slicknav_menu .training-ulitem-inside {
  font-size: 1.38888rem !important; }

.node--blog--full .custom-title {
  margin-bottom: 60px; }

.nnode-33 {
  position: relative;
  top: 60px; }
  @media (max-width: 1024px) {
    .nnode-33 {
      top: 0; } }

a:focus, button:focus, input:focus, textarea:focus, select:focus, area:focus {
  outline: auto !important; }

@media (max-width: 400px) {
  .node--webinars--minimal .custom-title {
    width: 90%; } }
