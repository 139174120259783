#block-views-training-block-3 {


  .view-header {
    display: none;
    @include breakpoint(1024px) {
      display: block;
      .vertical-number {
        
        .yellow-title {
          display: flex;
          align-items: center;
          padding-left: 20px;          
          .text {
            margin-bottom: 0;
          }
        }
      }
    }
    @include breakpoint(500px) {
      .vertical-number {
        .yellow-title {
          padding-left: 10px;
        }
      }
    }
  }

  .required-fields {
    @include breakpoint(1024px) {
      .vertical-number {
        display: none;
      }
    }    
  }

  .group-wrapper-outer {
    position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
    width: 100%;
    max-width: 100%;
    height: 1035px;
    @include breakpoint(460px) {
      height: 750px;
    }
  }

  .group-content {
    width: span(8 of 12);
    margin: 0 auto;

    @include breakpoint(600px) {
      width: 100%;
      padding: 0 20px;
    }
    @include breakpoint(360px) {
      padding: 0 10px;
    }
  }

  .read-more {
    &.rmore {
      position: relative;
    }
  }

  .group-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include breakpoint(800px) {
      flex-direction: column;
    }

    .button {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      @include breakpoint(800px) {
        text-align: center;
        margin-bottom: 20px;
        margin-right: 0;
      }
      a {
        text-align: center;
        border: 1px solid white;
        color: white;
        background-image: linear-gradient(to right, white, white 50%, transparent 50%, transparent);
        &:hover {
          color: black;
        }
      }
    }
  }

  .group-wrapper {
    position: relative;
    float: none;    
    padding-top: 0;
    padding-bottom: 0;
    
    min-height: auto;
    margin-left: 0;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    margin-top: 0;
    max-width: 1600px;
  }
  .field--name-field-image-2 {
    position: absolute;
  }
  .vertical-number {
    padding-left: 0;
    width: 105px;
    margin-top: -95px;
  }
  .views-row {
    width: 100%;
    .node {
      height: auto;
    }
  }
  .custom-title {
    color: #ffffff;
    font-family: "Libre Baskerville";
    font-size: 50px;
    font-weight: 400;
    line-height: 74px;
    // Text style for "We provide"
    letter-spacing: 1px;
    margin-bottom: 60px;
    text-align: center;
    text-transform: none;
    @include breakpoint(1260px) {
      font-size: 32px;
      line-height: 45px;
    }
    @include breakpoint(800px) {
      margin-bottom: 30px;
    }
    @include breakpoint(460px) {
      font-size: 1.66666rem;
      line-height: 38px;
    }
    @include breakpoint(360px) {
      font-size: 24px;
      line-height: 38px;
    }
  }
  .field--name-body {
    text-align: center;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;

    p {
      br {
        @include breakpoint(900px) {
          display: none;
        }
      }
    }
  }
}

.bg-image-training {
  width: 100vw;
  height: 1035px;
  background-position: center center !important;
  background-repeat: no-repeat;
  @include breakpoint(460px) {
    height: 750px;
  }
}