// Webinars inherit most of the styling from existing cts (ref. public-workshops)
// this file will overwrite only parts that are unique.
.node--webinars--teaser {
  margin-bottom: 130px;

  @include breakpoint(680px) {
    margin-bottom: 70px;
  }

  .group-left-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 0;
    
    @include breakpoint(550px) {
      flex-direction: column;  
    }
  }

  .group-info {
    @include breakpoint(600px) {
      margin-right: auto;
    }
  }

  .title-grid-wrapper {
    @include breakpoint(680px) {
      line-height: 1.6;
      font-size: 1.11111rem;
    }
  }
  .field--name-field-image {
    @include breakpoint(680px) {
      min-height: 280px;
    }
  }
  .read-more {
    padding-top: 0;
    margin-left: auto;
    @include breakpoint(550px) {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

#block-views-training-block-7,
#block-views-training-block-6 {
  .view {
    display: flex;
    
    @include breakpoint(1024px) {
      flex-direction: column;
    }

    .view-content {
      // width: 83%;
      width: 1200px;
      margin-left: auto;
      margin-top: 80px;      
      margin-right: auto;

      @include breakpoint(1024px) {
        width: 100%;
      }
    }
  }
  .vertical-number .yellow-title .text {
    top: 120px;
    @include breakpoint(1024px) {
      top: 10px;
    }
  }
}

#block-views-training-block-7 {
  margin-bottom: 200px;

  @include breakpoint(1024px) {
    margin-bottom: 80px;
  }

  .vertical-number .yellow-title .text {
    min-width: 250px;
    top: 200px;

    @include breakpoint(1024px) {
      top: 10px;
    }
    @include breakpoint(400px) {
      min-width: unset;
    }
  }
  .views-row-wrapper,
  .view-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .views-row {
      // width: 375px;
      // margin-right: 33px;
      margin-bottom: 33px;
      width: 31.54362%;
      margin-right: 2.68456%;

      @include breakpoint(1024px) {
        width: 48.79518%;
        margin-right: 2.40964%;
      }
      @include breakpoint(680px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
        @include breakpoint(1024px) {
          margin-right: 2.40964%;
        }
        @include breakpoint(680px) {
          margin-right: 0;
        }
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }  
}

.node--webinars--box {
  .field--name-field-image {
    @include breakpoint(680px) {
      min-height: 350px;
    }    
  }  
}

.node--public-workshops--box,
.node--webinars--box {
  .read-more {
    width: 250px;
    a {
      width: 100%;
    }
  }
  .request_quote {
    a {
      width: 250px;
      color: #100a0f;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 10px !important;
      &:hover {
        color: #fbc31e;
      }
    }
  }
}


// boxes
#block-views-training-block-4 {
  .node--public-workshops--box {
    .read-more {
      width: 250px;
      a {
        width: 100%;
      }
    }
    .request_quote {
      a {
        width: 250px;
        color: #100a0f;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 10px;
        &:hover {
          color: #fbc31e;
        }
      }
    }
  }
}


#webform_drawer-163 {
  .custom-title {
    font-size: 1.77777rem;
    text-align: center;
    margin-bottom: 40px;
    font-family: "Libre Baskerville", serif;
    font-weight: 400;
    line-height: 1.3;
  }

  .node--webform {
    .custom-title {
      display: none;
    }
  }
}


.webex-logo {
  position: absolute;  
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 99;
  bottom: 30px;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  img {
    margin-left: 20px;
  }
}

.node--webinars--full {
  @include breakpoint(1024px) {
    width: 100%;
  }

  .node__content {
    @include breakpoint(1024px) {
      width: 100% !important;
    }
    @include breakpoint(500px) {
      padding-top: 100px;
    }
  }

  .node--trainer {
    .node__content {
      padding-top: 0;
    }
  }

  .webex-logo {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    justify-content: center;
    color: black;
    margin-top: 90px;
  }
  .group-meta {
    margin-bottom: 40px;
    @include breakpoint(1024px) {
      width: 100%;
      margin-left: 0;
    }
    .custom-title {
      @include breakpoint(1024px) {
        font-size: 2.01666rem;
        line-height: 1.3;
      }
      @include breakpoint(500px) {
        font-size: 1.66666rem;
      }
    }
  }
  .field--name-field-body-3,
  .field--name-field-body-2,
  .field--name-body {
    @include breakpoint(500px) {
      padding-bottom: 45px;
      margin-bottom: 45px;
    }
  }
  .field--name-field-image {
    min-height: auto !important;
    img {
      // @todo: refactor this to avoid all these important statements.
      min-height: auto !important;
      position: relative !important;
      top: unset !important;
      left: unset !important;
      max-width: 100% !important;
      width: 100% !important;
      transform: none !important;
    }

    &:after {
      content: none !important;
    }
  }
  .field--name-field-date-range {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include breakpoint(450px) {
      align-items: flex-start;
    }

    .field__label {
      font-weight: normal;
      line-height: 27px;
      margin-bottom: 0;
      font-size: 17px;
      @include breakpoint(470px) {
        line-height: 24px;
      }
    }
    .field__items {
      float: none;
    }
    .date-display-single {
      font-weight: bold;
      font-size: 17px;
      line-height: 1;
      .date-display-range {
        font-weight: bold;
        font-size: 17px;
        line-height: 1;
        &:after {
          content: "(GMT+2)";
          padding-left: 4px;
        }
      }
    }
  }

  .group-content-bounds {
    .group-left-wrapper {
      @include breakpoint(900px) {
        // position: relative;
        // top: 0;
        // bottom: 0;
        // margin-bottom: 80px;
        // width: 100%;
        // margin-right: 0;
        // float: none;
        display: none;
      }

  
      .group-pricing-wrapper {
        @include breakpoint(900px) {
          display: flex;
          align-items: end;
          flex-direction: row-reverse;
        }
        @include breakpoint(470px) {
          flex-direction: column;
        }
  
        .field--name-field-pw-product-reference {
          @include breakpoint(470px) {
            margin: 0 auto;
          }
        }

        .addthis-wrapper {
          @include breakpoint(900px) {
            margin-top: 0;
            margin-right: auto;
          }
          @include breakpoint(470px) {
            margin-top: 30px;
            margin-left: auto;
          }
        }
      }
    }
  }
  
  .group-right-wrapper {
    @include breakpoint(900px) {
      width: 83%;
      margin: 0 auto;
      float: none;
    }
  }

}



.node-type-webinars {
  .view-related-inhouse-training {
    .view-content {
      @include breakpoint(850px) {
        width: 83%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

