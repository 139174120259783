.l-header {
  display: flex;
}

.l-region--header {
  
  width: calc(100% - 270px);
  display: flex;
  justify-content: flex-end;
}

#accessibility {
  width: 100%;
  color: white !important;
  background: -webkit-gradient(linear, right top, left top, color-stop(50%, #0c4f8e), color-stop(50%, #133a5f)) right;
  background: linear-gradient(to left, #0c4f8e 50%, #133a5f 50%) right;
  background-size: 200%;
  -webkit-transition: .5s ease-out;
  transition: .5s ease-out;
  &:hover{
    background-position: left;
  }
  &::after{
    display: none;
  }
}

#block-superfish-1 {
  display: flex;

  @include breakpoint(1100px) {
    display: none;
  }

  .block__title {
    display: none;
  }
  li {
    span,
    a {
      height: 100%;
    }
  }
}

#superfish-1 {
  display: flex;
  height: 100%;
  .menuparent {    
    &:hover {
      ul {
        position: absolute;
        top: 100%;
        background: white;
        left: 50%;
        transform: translateX(-50%);
        li {
          span,
          a {
            width: 100%;
            text-align: center;
            &:after {
              content: none;
            }
          }
        }        
      }
    }
  }  

  li {
    span,
    a {
      @include breakpoint(1435px) {
        padding: 32px 10px;
      }
      @include breakpoint(1170px) {
        padding: 32px 7px;
        font-size: 15px;
      }
    }
  }

  @include breakpoint(1100px) { 
    display: none;
  }
}



// This intends to target #myux menu item
#menu-980-1 {
  text-indent: -9999px;  
  width: 184px;
  height: 80px;
  @include breakpoint(1435px) {
    width: 124px;
  }
}

#myux {
  background: #44c1f0;
  color: white;
  text-indent: -99999px;
  width: 104px;
  font-size: 0 !important;
  width: 100%;  
  
  // @include breakpoint(1170px) {
  //   width: 90px;
  //   background-size: contain;
  // } 
  &:hover {
    cursor: pointer;
    background: #0facdf;
  }

  &:before {
    content: "";    
    background: url('/sites/all/themes/theuxprodigy/images/write-my-ux-menu-logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 104px;
    height: 20px !important;
    position: relative;
    display: block;
    margin: 0 auto;

    @include breakpoint(1170px) {
      width: 80px;
      background-size: contain;
    }    
  }
  &:after {
    content: none !important;
  }
}



.menuparent {
  ul {
    box-shadow: 0 0 30px rgba(1, 1, 1, 0.07);
    background-color: #ffffff;
    z-index: -1;
  }
  span {
    padding: 32px 10px;
    font-size: 16px;
    padding: 32px 20px;    
    color: #100a0f;
    text-decoration: none;
    position: relative;
  }
  li {    
    
    &.sf-depth-2 {
      a {
        padding-bottom: 20px !important;
        padding-top: 20px !important;        
        @include breakpoint(1110px) {
          padding-bottom: 5px !important;
          padding-top: 0px !important;
        }
      }
    }    
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      height: 6px;
      background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, transparent), to(transparent));
      background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
      background-size: 202% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
      -webkit-transition: 0.3s background-position ease-out;
      transition: 0.3s background-position ease-out;
    }
    &:hover {
      &:after {
        background-position: 0 0;
        @include breakpoint(1110px) {
          content: none;
        }
      }
    }
    // &.last {
    //   &.sf-depth-2 {
    //     a {
    //       padding-bottom: 20px !important;
    //     }        
    //   }
    // }
  }
}



.block--gtweaks-header-lets-talk {
  &.block {
    a {
      @include breakpoint(1435px) {
        padding: 28px 32px;
      }      
    }
  }
}


.slicknav_menu {
  @include breakpoint(1100px) {
    display: block;
    margin: 0 !important;
  } 
  .slicknav_menutxt {
    height: 100%;
    line-height: 43px;
    text-align: center;
    right: 135px;
    max-height: 80px;
    @include breakpoint(1024px) {
      line-height: 21px;
    }
  }
  .slicknav_nav {
    position: absolute;
    background: white;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 160px;

    @include breakpoint(1024px) {
      width: 100vw;
      left: -20px;
    }
    @include breakpoint(500px) {
      left: -10px;
    }

    li {
      .slicknav_arrow {
        display: none;
      }
    }
  }
  .social-media-mobile {
    display: flex;
    margin: 0 auto;
    text-align: center !important;
    justify-content: center;
    margin-top: 40px;
    span {
      display: none;
    }
    @include breakpoint(1024px) {
      display: flex !important;
      margin-bottom: 100px;
    }
  }
  li {
    .slicknav_hidden {
      display: flex !important;
      flex-direction: column;
      box-shadow: none;
      margin: 0 auto;
      li {
        a {
          font-size: 18px !important;
        }
      }
    }
  }
  
}