#webform_drawer-161 {
  padding: 70px 60px 70px 130px;
  &.flex-wrapper {
    .node__content {
      display: flex;
      flex-wrap: wrap;
      .custom-title {
        flex-basis: 100%;
      }
    }
    form {
      flex-basis: 320px;
      margin-left: auto;
      @include breakpoint(1024px) {
        flex-basis: 100%;
      }
    }
    .field--name-body {
      flex-basis: 50%;
      @include breakpoint(1024px) {
        flex-basis: 100%;
        margin-bottom: 30px;

        br {
          display: none;
        }
      }
    }
    .field--name-body {
      color: #100a0f;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      // Text style for "Fill in th"
      letter-spacing: 1.6px;
      display: flex;
      // margin-right: 70px;

      .field__item {
        height: 100%;
        display: flex;
        .webform-text {
          display: flex;
          flex-direction: column;
          font-weight: bold;
        }
        .webform-call-us {
          margin-top: auto;
          width: 100%;
          .light-text {
            font-weight: normal;
          }
          div {
            &:not(.light-text) {
              color: #100a0f;
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
              // Text style for "Fill in th"
              letter-spacing: 1.6px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}