@import "susy";
@import 'plugins/svg-grid';
@import "breakpoint";

@import "abstractions/_mixins.scss";
@import "variables/_grid.scss";
@import "variables/_typography.scss";
@import "variables/_colors.scss";
@import "variables/_sprites.scss";
@import "base/_essentials.scss";
@import "components/_odometer_minimal.scss";
@import "components/_slick.scss";
@import "components/_slicknav.scss";
@import "components/_selectBoxit.scss";
@import "components/_base_components.scss";
@import "components/_cookies.scss";
@import "components/_frontpage_featured.scss";
@import "components/_accessibility.scss";
// Header.scss file is imported in the end of the file to overwrite existing header styles

/* Makes border-box properties */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.field--type-text,
.field--type-text-long,
.field--type-text-with-summary {
  // @include links($yellow, $yellow, underline, 400);
  a {
    &:link, &:visited, &:active {
      text-decoration: none;
      color: black;
      font-weight: 400;
      text-decoration: underline;
    }

    &:hover, &:focus {
      color: $black;
      // text-decoration: $decoration;
    }
  }
}

img {
  height: auto;
  max-width: 100%;
}

html {
  font-size: 18px;
  line-height: 1.5;
  font-family: $mont;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slicknav_menu {
  display: none;
}

.drup-msgs {
  max-width: span(10);
  margin: 0 auto;

  .messages{
    margin: 20px auto;
    width: span(6 of 10);
  }
}

// .node__content {
//  // @include links($yellow, $yellow, none, 700);
// }

.user-login {
  input[type="submit"] {
    max-width: 230px;
  }

  margin-bottom: $gdelimit / 2;
}

.intro-training-page {
  .l-main {
    max-width: none;
    margin: 0;
  }
}

.l-nots {
  max-width: $container;
  margin: 0 auto;
  padding-bottom: 80px;
}

/*$local: (
  columns: susy-repeat(12),
  gutters: 0.5,
  spread: 'narrow',
  container-spread: 'narrow',
);*/

//$test: susy-settings($local);
//susy-get('gutters');

.l-page {
  padding-top: 106px;
 // padding-top: 107px;
}

.notbar-removed {
  .l-page {
    padding-top: 79px;
  }
}

.logged-in {
  .l-page {
      padding-top: 78px;
  }
}

h1, h2, h3, h4, h5 {
  font-family: $libre;
  font-weight: 400;
  line-height: 1.3;
}

.price-bold {
  font-weight: 700;
  font-size: $slight;
  position: relative;
  top: 2px;
}

.view-id-training {
  &.view-display-id-block_2 {

    // scrollbar stuff
    height: calc(100vh - 280px);
    overflow: hidden;

    .field--name-field-early-bird-price,
    .field--name-field-standard-price {
      margin-top: 12px;
    }

    .rmore {
      a {
        // width: 214px;
        width: 225px;
        padding: 16px 22px;
        font-weight: 400;

        background-image: linear-gradient(to right, $black, $black 50%, $yellow 50%, $yellow);
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position: 100% 0;

        &:hover,
        &:focus {
          color: $yellow;
          border: 1px solid $black;
          background-position: 0 0;
        }
      }
    }

    .views-row {
      margin-bottom: 100px;
      padding-bottom: 100px;
      border-bottom: 1px solid $divider;
    }

    .views-row-last {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.overlay-menu-open {
  overflow: hidden;
}

.notifications {

  &-expanded {
    display: none;
    position: fixed;
    width: 100%;
    background: $yellow;
    z-index: 100;
    height: 100vh;
    top: 0;
    z-index: 999;
  }  

  &-expandit {
    color: $black;
    text-align: center;
    padding: 4px 30px;
    font-family: $libre;
    font-size: $noti;
    position: relative;
    z-index: 110;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    .accented-txt {
      font-weight: 700;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: -6px;
      z-index: 100;
      margin-left: -6px;
      border-top: 8px solid $yellow;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
    }

    &.not-active {
      &:after {
        display: none;
      }
    }
  }
}

.notifications-bar-wrapper {
  background: $yellow;
}

.page-node {
  .notifications-bar-wrapper {
    .read-more.rmore {
      a {
        color: $black;

          &:hover,
          &:focus {
            color: $yellow;
          }
        }
      }
    }
}

.l-header-wrapper {
  @include clearfix;
  position: relative;
}

// .l-header {
//   //height: 80px;
//  // height: 79px;
// }

.view-navigation-boxes {

  position: relative;

  .custom-title {
    min-height: 75px;
    color: $white;
  }

  .read-more.rmore {
    margin-top: 30px;

    a {
      color: $yellow;
      border: none;
      text-align: left;
      padding: 0;
      //background: none;
      border: 1px solid $yellow;
      padding: 22px;

      &:after {
        @include sprite($sprite-read-more-yellow);
      }

      &:hover {
        color: $black;

        &:after {
          @include sprite($sprite-read-more-black);
        }
      }
    }
  }

  .views-row {
    float: left;
    width: span(3);
    margin-right: gutter();

    &:last-child {
      margin-right: 0;
      float: right;
    }

    .read-more {
      margin-top: 40px;
    }

    .node--box {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

}

.l-header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 110;
  background: $white;
  top: 0;
  left: 0;
}


#mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form {
  position: relative;
  max-width: 500px;
  margin: 80px auto 0;

  #edit-actions {
    &.disabled {
      opacity: 0.5;
      input {
        &:hover {
          cursor: default !important;
          opacity: 0.5;
        }
      }
      &:hover {
        cursor: default; 
      }
    }
    &:hover {
      cursor: pointer;
    }   
  }

  .form-type-checkbox {
    width: 360px;
    margin: 0 auto;

    input {
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 99;
    }
    a {
      color: black;
      &:hover,
      &:visited {
        color: black;
      }
    }

    .checkmark {
      &:after {
        left: 9px;
        top: 6px;
      }
    }
  }

  .checkmark {
    border: none !important;
  }

  .form-required {
    display: none;
  }

  input[type="submit"] {
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    right: 14px;
    @include sprite($sprite-read-more-black);

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  input[type="text"] {

    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
    font-family: $mont;
    color: #555;
    padding: 14px 64px 14px 14px;
    border-bottom: 1px solid $black;
    font-size: 16px;

    &::placeholder {
      color: #555;
      opacity: 1;
    }
  }
}

.ajax-progress {
  display: none;
}

.nnode-1 {
  .webform-client-form-1 {
    float: left;
    width: 46%;
   // height: 400px;
  //  min-height: 400px;
  }

  .field--name-field-body-below {
    float: left;
  }

  .webform-call-us {
    margin-top: -120px;
    font-weight: 400;
    line-height: 1.6;

    .light-text {
      font-size: $smallest;
      font-weight: 300;
    }

    .webform-text-1 {
      margin-bottom: 30px;
      a {
        color: $black;
        &:hover {
          color: $yellow;
        }
      }
    }

  }

  .field--name-body {
    position: relative;
    float: left;
    width: 46%;
    margin-right: 8%;
    font-size: $small;
    font-weight: 400;
    line-height: 2;
   // height: 400px;
   // min-height: 400px;
  }
}


.nnode-36 {

  .webform-component--your-name,
  .webform-component--select-workshop {
    float: left;
    width: 48%;
    margin-right: 4%;
  }

  .webform-component--select-workshop {
    margin-right: 0;
  }

  .webform-component--your-e-mail {
    clear: both;
  }

  .form-actions {
    max-width: 230px;
  }
}

.nnode-2 {
  .webform-component--your-name,
  .webform-component--select-subject {
    float: left;
    width: 46%;
    margin-right: 4%;
  }

  .webform-component--select-workshop {
    margin-right: 0;
  }

  .webform-component--your-e-mail {
    clear: both;
  }

  .form-actions {
    max-width: 230px;
  }
}


.block {

  &--gtweaks {
    // margin-bottom: $gdelimit;
    margin-bottom: 190px;
  }

  &--gtweaks-public-workshops-metrics {
    width: span(7);
    margin-left: span(2 wide);
    margin-top: $gdelimit / 2;
    border-top: 1px solid $collg;
    padding-top: $gdelimit / 2;
    padding-bottom: $gdelimit / 2;
    margin-bottom: $gdelimit / 2;
    border-bottom: 1px solid $collg;
  }

  &--views-training-block {
   @include clearfix;
  }

  &--views-training-block-4 {
    @include clearfix;
  }

  &--gtweaks-our-mantra {
   width: span(9);
   margin-left: span(2 wide);
   float: left;
    position: relative;
    margin-top: 150px;

    .mantra-image {
      height: 660px;
      background: url('../images/mantra.jpg') 0 0 no-repeat;
      background-size: cover;
    }

    .group-wrapper {
      color: $white;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);

      h2 {
        width: span(2 of 9);
        margin-left: span(1 wide);
        margin-right: gutter(of 9);
        font-size: $box;
        float: left;
      }

      .group-right-wrapper {
        width: span(5 of 9);
        float: left;

        .large-title {
          font-size: $kelvin;
          font-family: $libre;
          line-height: 1.1;
        }

        .small-desc {
          margin-top: 20px;
          font-size: $smallest;
        }
      }
    }
  }

  &--views-team-block {
    @include clearfix;
    margin-bottom: $gdelimit;

    .vertical-number {
      padding-left: 17px;
    }

/*    .view-header {
      width: span(1);
      margin-right: gutter();
      float: left;
    }*/

    .view-content {
      padding-top: 80px;
      width: span(9);
      margin-left: span(1 wide);
      float: left;

      .views-row {
        @include clearfix;
        @include box;
        margin-bottom: 30px;
      }
    }
  }

  &--gtweaks-our-values {
    clear: both;
    @include clearfix;

    .vertical-number {
      padding-left: 12px;
    }

/*    .view-header {
      width: span(1);
      margin-right: gutter();
      float: left;
    }*/

    .view-content {
      padding-top: 80px;
      width: span(9);
      margin-left: span(1 wide);
      float: left;

      .value-wrap {
        position: relative;
        height: 356px;
      }

      .value-item {
        position: relative;
        padding: 32px;

        .value-wrap {
          &:after {
            display: block;
          }
        }

        h2 {
          font-size: $box;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      .value-item-1 {
        width: span(4 of 9);
        float: left;
        background: $black;
        color: $white;

        .value-wrap {
          &:before {
            content: "";
            background: $white;
            border-radius: 50%;
            position: absolute;
            height: 150px;
            width: 150px;
            right: 20px;
            top: 20px;
          }
        }
      }

      .value-item-2 {
        width: span(5 of 9);
        float: right;
        background: $yellow;
        color: $black;
        margin-bottom: gutter(of 9);

        .value-wrap {
          &:before {
            content: "";
            @include sprite($sprite-value-icon-1);
            position: absolute;
            right: 20px;
            top: 40px;
          }
        }
      }

      .value-item-3 {
        width: span(5 of 9);
        float: left;
        background: $yellow;
        color: $black;

        .value-wrap {
          &:before {
            content: "";
            @include sprite($sprite-value-icon-2);
            position: absolute;
            right: 20px;
            top: 20px;
          }
        }
      }

      .value-item-4 {
        width: span(4 of 9);
        float: right;
        background: $black;
        color: $white;

        .value-wrap {
          &:before {
            content: "";
            @include sprite($sprite-value-icon-3);
            position: absolute;
            right: 20px;
            top: 20px;
          }
        }
      }
    }
  }

  &--views-navigation-boxes-block {
    padding: 200px 0;
    @include clearfix;

    .custom-title-box {
      min-width: 0;
      .custom-title {
        font-weight: 400;
      }
    }
  }

  &--gtweaks-notification-bar-top.block {
    margin-bottom: 0;
  }

  &--gtweaks-header-lets-talk.block {
    margin-bottom: 0;
    float: left;

    a {
      font-size: $small;
      padding: 28px 76px;
      @include breakpoint(1110px) {
        padding: 28px 50px;
      }
      @include breakpoint(1080px) {
        padding: 28px 35px;
      }
      color: $white;
      display: block;
      text-decoration: none;

      background-image: linear-gradient(to right, $yellow, $yellow 50%, $black 50%, $black);
     // background-size: 200% 100%;
      background-size: 201% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
      transition: 0.3s background-position ease-out;

      &:hover,
      &.active {
        background-position: 0 0;
        color: $black;
      }
    }

  }

  &--gtweaks-social-media {
    float: right;
    // margin: 40px 0;
    margin: 0;
    margin-bottom: 40px;
    font-size: $smaller;
    font-weight: 400;
    display: flex;
    justify-content: center;
    width: 100%;
    @include clearfix;

    @include breakpoint(950px) {
      margin-top: 50px;
    }

    .esepia-logo {
      @include sprite($sprite-e-sepia-cp-32);
      display: block;
    }

    .uxprodigy-logo {
      @include sprite($sprite-theuxprodigy);
      display: block;
      margin-right: 20px;
    }

    .neverending-logo {
      position: relative;
      top: 10px;
      text-align: right;

      img {
      //  width: 94%;
        max-width: 170px;
      }
    }

    .logo-wrap {
      float: left;
      margin-right: 26px;

      &:last-child {
        margin-right: 0;
      }

      a {
        //display: block;
        //float: right;
        display: inline-block;
        vertical-align: middle;
      }

      &-txt {
        // position: relative;
        // top: 6px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }

      // &:last-child {
      // }
    }
  }

  &--gtweaks-footer-lets-talk.block {
    background-color: $yellow;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;

    &:after {
      content: "";
      display: block;
      background-image: url('../images/uxletters/x.svg');
      width: 511px;
      height: 509px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50%,40px);
    }

    &:before {
      content: "";
      display: block;
      width: 511px;
      height: 509px;
      background-size: contain;
      background-image: url('../images/uxletters/u.svg');
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%,40px);
    }

    .newsletter-intro {
      font-family: $libre;
      font-size: $newsletter;
      line-height: 1.35;
    }

   > .block__content {
     position: relative;
     z-index: 10;
      width: span(6);
      margin: 0 auto;
      padding: 230px 0;
    }
  }

  &--gtweaks-copyright {
    margin: 40px 0;
    float: left;
    font-size: $smaller;
    font-weight: 400;
    width: 100%;

    

    .block__content {
      display: flex;
      @include breakpoint(950px) {
        flex-direction: column;
      }
    }

    .cp-text {
      display: block;
      float: left;
      position: relative;
      top: 6px;
      
      @include breakpoint(950px) {
        padding-top: 30px;
        text-align: center;
      }
    }

    .gdpr-menu {
      margin: 0 auto;
    }

    .social-media-icons {
      float: left;
      margin-left: 90px;
      @include breakpoint(950px) {
        margin-left: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;        
      }
    }

    a {
      text-decoration: none;
      font-weight: 700;
      color: $black;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &--views-case-studies-block {
    margin-bottom: 180px;
  }

  &--views-companies-block {
    margin-bottom: 180px;

    // .block__title {
    //   text-align: center;
    //   font-family: $mont;
    //   font-weight: 300;
    //   margin-bottom: 50px;
    // }

    .block__title {
      font-size: $super;
      margin-bottom: 40px;

      &.companies-title {
        width: 100%;
        float: right;
        text-align: left;
        padding-top: 80px;
        margin-bottom: 80px;
        @include breakpoint(1024px) {
          text-align: center;
          font-size: 1.815rem;          
        }
        @include breakpoint(450px) {
          padding-left: span(1);
          padding-right: span(1);
        }
      }
    }

  }

  &--views-training-block-3 {
    @include clearfix;
    position: relative;
    color: $white;

    .views-row {
      position: relative;
    }

    .read-more.rmore {
      position: absolute;
      bottom: 0;
      left: 0;

      a {
        color: $yellow;
        // border: 1px solid $yellow;
        border: none;
        //padding-left: 0;
        background: none;
        text-align: left;

        &:after {
          @include sprite($sprite-read-more-yellow);
        }

        // &:hover {
        //   // color: $black;

        //   &:after {
        //     // @include sprite($sprite-read-more-black);
        //   }
        // }
      }
    }

    .group-wrapper-outer {
      position: relative;
      z-index: 2;
      max-width: $container / 2;
      margin-left: auto;
      @include clearfix;
    }

      .group-wrapper {
        position: relative;
        float: left;
        margin-top: 290px;
        padding-top: 180px;
        padding-bottom: 90px;
        width: span(4 of 6);
        min-height: 573px;
        margin-left: span(2 wide of 6);

        // .field--name-body {
        // //  margin-bottom: 100px;
        // }

        h2 {
          font-size: $larger;
          margin-bottom: 30px;
          text-transform: capitalize;
          font-weight: 700;
        }
      }

    
    .vertical-number {
      width: span(1 of 6);
      margin-right: gutter(of 6);
      padding-left: 26px;
      margin-top: 200px;

    }

    .views-row-first {
      .group-wrapper {
      //  margin-left: span(1 of 6) + gutter(of 6);
        margin-left: span(1 wide of 6);
      }
    }

    .views-row-last {
      .group-wrapper-outer {
        margin-left: 0;
        margin-right: auto;
      }
    }

    .full-size-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0.7;
        background: $black;
      }
    }

    .views-row {
     width: 50%;
      float: left;

      .node {
        height: 1030px;
      }
    }

    .vertical-number {
      padding-left: 25px;
    }
  }

  &--gtweaks-what-we-do {
    @include clearfix;

    // .vertical-number {
    //   //padding-left: 5px;
    // }

    .view-content {
      width: span(9);
      margin-left: span(1 wider);
      padding-top: 80px;
      float: left;

      .box {
        position: relative;
        @include box;
        padding: 42px 0;
        width: span(3 of 9);
        float: left;
        margin-right: gutter(of 9);
        text-align: center;
        transition: .4s;

        &:hover {
          box-shadow: 5px 5px 16px 0 #c8c8c8;
        }

        h2 {
          font-size: $box;
          margin: gutter(of 3) 0;
          margin-bottom: 100px;
          padding: 0 20px;
        }

        &-image {
          width: 168px;
          height: 168px;
          background-size: contain;
          margin: 0 auto;
        }

        &-item-1 {
          .box-image {
            background: url('../images/whatwedo/01-uxresearch.svg');
          }
        }

        &-item-2 {
          .box-image {
            background: url('../images/whatwedo/02-uxdesign.svg');
          }
        }

        &-item-3 {
          .box-image {
            background: url('../images/whatwedo/03-uxevaluation.svg');
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
          margin-bottom: gutter(of 9);
        }

        &:nth-child(3n+1) {
          clear: both;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &--views-companies-block,
  &--views-case-studies-block {
    @include clearfix;

    margin-top: $gdelimit;


    .view-content {
      width: span(9);
      margin-left: span(2 wide);
      padding-top: 80px;
      float: left;
    }
  }

  &--views-case-studies-block {
    margin-top: 120px;
  }

  &--views-companies-block {
    .view-content {
      width: span(10);
      float: right;
      padding-top: 0;
      @include breakpoint(1024px) {
        width: 100%;
        float: none;
        margin-left: 0;
      }
    }
  }

  &--gtweaks-why-ux {
    @include clearfix;

    .why-ux-text {
      width: span(7 of 9);
      margin-bottom: 54px;
    }

    .box {
      @include box;
      width: span(3 of 9);
      float: left;
      margin-right: gutter(of 9);
      position: relative;
      padding: 0 18px;
      height: 130px;
      @include clearfix;

      &-image {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 60px;
        width: 60px;
        margin-right: gutter(of 3);
        float: left;
      }

      &-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
      }

      &-item-1 {
        .box-image {
          background: url('../images/whyuxicons/whyux-icons-01.svg');
          background-size: contain;
        }
      }

      &-item-2 {
        .box-image {
          background: url('../images/whyuxicons/whyux-icons-02.svg');
          background-size: contain;
        }
      }

      &-item-3 {
        .box-image {
          background: url('../images/whyuxicons/whyux-icons-03.svg');
          background-size: contain;
        }
      }

      &-item-4 {
        .box-image {
          background: url('../images/whyuxicons/whyux-icons-04.svg');
          background-size: contain;
        }
      }

      &-item-5 {
        .box-image {
          background: url('../images/whyuxicons/whyux-icons-05.svg');
          background-size: contain;
        }
      }

      &-item-6 {
        .box-image {
          background: url('../images/whyuxicons/whyux-icons-06.svg');
          background-size: contain;
        }
      }

      &:nth-child(3n) {
        margin-right: 0;
        margin-bottom: gutter(of 9);
      }

      &:nth-child(3n+1) {
        clear: both;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .block-title {
      font-size: $super;
      margin-bottom: 40px;
    }

    // .vertical-number {
    //   //padding-left: 21px;
    // }

    .view-content {
      width: span(9);
      margin-left: span(1 wider);
      padding-top: 80px;
      float: left;

      @include breakpoint(360px) {
        padding-top: 30px;
      }
    }
  }

  &--gtweaks-front-main-banner {
    background: url('../images/uxp_frontpage.png') no-repeat center center;
    background-size: cover;

    .subtitle {
      margin-bottom: 80px;
    }

    .main-banner-button {
      a {
        // max-width: 375px;
        max-width: 295px;
        width: 100%;
        
      }
    }

    // keep margin-bottom to 0 at all breakpoints starting from 1080
    @include breakpoint(1080px) {
      margin-bottom: 0 !important;
    }

    .front-main-banner-wrapper {
      text-align: center;
      color: $white;
    //  max-width: 870px;
      width: span(10);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .block__content {
      position: relative;
      height: 855px;
      min-height: 600px;
      max-height: calc(100vh - 106px);
      @include breakpoint(1080px) {
        min-height: 680px;
      }
    }

    h1 {
      font-size: $mega;
      font-family: $libre;
      line-height: 1.3;
      margin-bottom: 40px;
    }
  }

  &--system-main-menu {
    @include clearfix;
    float: left;

    li {
      position: relative;
    }
  }

}

.view-case-studies {
  &.view-display-id-block {

    .vertical-number {
      position: relative;
      //left: -22px;
      .text {
        margin-top: -50px;
        @include breakpoint(1024px) {
          margin-top: 0;
        }
      }
    }

    .views-row {
      margin-bottom: 190px;
      @include clearfix;
    }

    .views-row-last {
      margin-bottom: 0;
    }

    .views-row-even {
      .group-right-wrapper {
        width: span(6 of 9);
        float: left;
        margin-left: 0; // override code for the page of case studies
      }

      .group-left-wrapper-cs {
        float: right;
        width: span(2 of 9);
        margin-left: gutter(of 9);
        margin-right: 0;
      }
    }
  }

  &.view-display-id-block_1 {
    @include clearfix;

    .view-content {
      padding-top: 80px;
      width: span(9);
      margin-left: span(1 wide);
      float: left;
    }

    .vertical-number {
      position: relative;
      left: -22px;
    }

    .views-row {
      margin-bottom: $gdelimit / 2;
      padding-bottom: $gdelimit / 2;
      border-bottom: 1px solid $collg;

      &-last {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    margin-bottom: $gdelimit;
  }
}

body {
  // line height inherit to move to html tag, because we have a reset rule to make it 1 in body
  line-height: inherit;
  color: $black;
}

.l-region {

  &--header {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &--navigation {
    clear: both;
  }
}

.l-footer-wrapper {
  background: $lgray;
}

* {
  outline: none;
}

.view-testimonials {
  position: relative;  
  padding: 140px 0;
  text-align: center;

  .view-content {
    width: span(6);
    margin: 0 auto;
  }

  .field--name-field-image {
    margin-bottom: 16px;

    img {
      margin: 0 auto;
      border-radius: 50%;
    }
  }

  .custom-title {
    margin-bottom: 6px;
    font-family: $mont;
    font-weight: 400;
    font-size: $smaller;
    text-transform: uppercase;
  }

  .field--name-field-capacity {
    font-size: $smallest;
    // color: $gray;
    color: #747474;
    font-weight: 400;
  }

  .field--name-body {
    margin-top: 30px;
    font-family: $libre;
    font-size: $small;
    line-height: 1.8;
  }
}

.l-branding {
  float: left;
  width: span(2);
  margin-right: gutter();
//  max-height: 80px;
  max-height: 79px;
  min-width: 220px;
  position: relative;
  z-index: 10;
}

.site-logo {
  margin: 23px 0;
  display: block;

  img {
    display: block;
  }
}


.l-header,
.l-main,
.l-footer,
.l-content-2,
.grid-wrapper {
  max-width: $container;
  margin: 0 auto;
  // background: susy-svg-grid() no-repeat scroll;
  @include clearfix;
}

#superfish-1,
.block--system-main-menu {
 // margin-left: span(4 of 10) + gutter(of 10);

  li {
    float: left;

    a {
      font-size: $small;
      // padding: 28px 38px;
      padding: 32px 20px;

      @include breakpoint(1300px) {
        padding: 28px 10px;
      }
      
      float: left;
      color: $black;
      text-decoration: none;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 6px;
        background-image: linear-gradient(to right, $yellow, $yellow 50%, transparent 50%, transparent);
        background-size: 202% 100%;
        background-repeat: no-repeat;
      //  background-position: 100% 0;
        background-position: 100% 0;
        transition: 0.3s background-position ease-out;
      }

      &:hover,
      &.active {
        &:after {
          background-position: 0 0;
        }
      }
    }
  }
}

.view-Companies {
  @include clearfix;

  .views-row {
    margin-right: gutter(1 of 10);
    width: span(2 of 10);
    float: left;

    &:nth-child(5n) {
      margin-right: 0;
      margin-bottom: 50px;
    }

    &:nth-child(5n+1) {
      clear: both;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.view-training {
  &.view-display-id-block,
  &.view-display-id-block_1 {

    .views-row {
      margin-bottom: $gdelimit;
    }

    .view-header {
      .vertical-number {
        position: relative;
        left: -45px;
      }
    }

    .view-content {
      padding-top: 80px;
      width: span(9);
      float: left;
      margin-left: span(1 wider);
    }

  }

  &.view-display-id-block_1 {

    .view-header {
      .vertical-number {
        position: relative;
        left: -41px;
      }
    }
  }


  
  &.view-display-id-block_6,
  &.view-display-id-block {
    .view-empty {
      margin-top: 80px;
      width: span(9);
      margin-left: span(1 wide);
      float: left;
      text-align: center;
      background-color: $yellow;
      position: relative;
      margin-bottom: $gdelimit;

      .grid-wrapper-inner {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        z-index: 10;

        .custom-title {
          line-height: 1.4;
        }

        .empty-desc {
          margin-top: 30px;
          font-size: $slight;
        }

        .arrow-go-down {
          @include sprite($sprite-next);
          transform: rotate(90deg);
          margin: 50px auto 0 auto;

          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
      }

      .grid-wrapper {
        width: span(5 of 9);
        margin: 0 auto;
        min-height: 555px;
      }

      .custom-title {
        // color: $white;
        color: #000000;
        font-size: $pwork;
      }
    }
  }

  &.view-display-id-block_5,
  &.view-display-id-block_4 {
    @include clearfix;
    margin-bottom: $gdelimit;

    .views-row {
      width: span(3 of 9);
      margin-right: gutter(of 9);
      float: left;

      &:nth-child(3n) {
        margin-right: 0;
        float: right;
        margin-bottom: gutter(of 9);
      }

      &:nth-child(3n+1) {
        clear: both;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include breakpoint(1024px) {
        &:nth-child(3n+1) {
          clear: none;
        }
      }
    }

    .view-header {
      .vertical-number {
        position: relative;
        left: -95px;
      }
    }

    .view-content {
      padding-top: 80px;
      width: span(9);
      float: left;
      margin-left: span(1 wider);
    }
  }

  &.view-display-id-page_1 {
    @include clearfix;
    position: relative;
    color: $white;

    .views-row {
      position: relative;
    }

    .read-more.rmore {

      a {
        color: $yellow;
        border: 1px solid $yellow;

        &:after {
          @include sprite($sprite-read-more-yellow);
        }

        &:hover {
          color: $black;

          &:after {
            @include sprite($sprite-read-more-black);
          }
        }
      }
    }

    .activew-bubble {
      text-align: right;
    }

    .group-wrapper-outer {
      z-index: 2;
      max-width: $container / 2;
      margin-left: auto;
      @include clearfix;
      overflow: hidden;
      position: relative;
      height: calc(100vh - 108px);
      min-height: 400px;
    }

    .group-center-meta {
      display: inline-block;
      text-align: left;
    }

    .group-placement {
      width: span(3 of 6);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .group-wrapper {
      padding-top: 140px;
      text-align: center;
/*      top: 50%;
      transform: translateY(-50%);*/
      // margin-left: span(2 of 6) + gutter(of 6);

      h2 {
        font-size: $larger;
        margin-bottom: 90px;
        text-transform: capitalize;
        font-weight: 700;
      }
    }

    .views-row-first {
      .group-placement {
        // margin-left: span(1 of 6) + gutter(of 6)
        left: span(1 wide of 6);
      }
    }

    .views-row-last {
      .group-wrapper-outer {
        margin-left: 0;
        margin-right: auto;
      }

      .group-placement {
        right: span(1 wide of 6);
      }
    }

    .custom-title {
      br {
        display: none;
      }
    }

    .full-size-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0.7;
        background: $black;
      }
    }

    .views-row {
      width: 50%;
      float: left;

      // .node {
      // }
    }
  }
}

.testimonials-our-work-block {
  .view-testimonials {
    padding: 0;
    margin: 0;
    width: span(7);
    margin-left: span(2 wide);
    margin-bottom: 140px;

    
  }
}

.node {

  &--webform {
    .custom-title {
      font-size: $largest;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  &--advanced-page,
  &--page {
    &--full {
      .field--name-body {
        font-size: $ptext2;
        line-height: 1.65;
      }
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }

  }

  &--full {

    // reset field collection values
    .field-collection-view {
      padding: 0;
      margin: 0;
      border: none;
      margin-bottom: $gdelimit;
      @include clearfix;
    }

    &.nnode-31 {
      .field--name-field-collection-advanced-text {

        .field-collection-view {
          margin-bottom: 90px;
        }

        .field-collection-view-final {
          margin-bottom: $gdelimit;
        }
      }
    }

    &.nnode-159,
    &.nnode-118,
    &.nnode-5,
    &.nnode-6 {
      .field--name-field-strong-title {
        font-size: $super;
        font-family: $libre;
        line-height: 60px;
        margin-left: span(2 wide);
        width: span(7);
        margin-bottom: 60px;

        @include breakpoint(1024px) {
          font-size: 2.01666rem;
          line-height: 1.3;
          width: 100%;
          margin-left: 0;
        }
        @include breakpoint(480px) {
          font-size: $box;
        }        
      }
      .field--name-body {
        margin-left: span(2 wide);
        width: span(7);
        margin-bottom: 140px;

        @include breakpoint(1024px) {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    &.nnode-32 {

        .field-collection-view {
        margin-bottom: 0;

          .field-collection-item-field-collection-advanced-text {
            width: span(9);
            margin-left: span(1 wide);

            .field-collection-container {
              float: none;
              margin: 0;
              width: auto;
            }

            .field--name-field-methods-techniques {
              clear: both;
              padding-top: 90px;
              width: span(6 of 9);
              margin-left: span(3 wide of 9);

              .field--name-field-collection-title {
               width: 100%;
                margin-left: 0;
                margin-right: 0;
                float: none;
              }

              .field--name-field-collection-body {
                width: 100%;
                margin-left: 0;
              }
            }

            .field--name-field-collection-title {
              width: span(2 of 9);
              margin-right: span(1 wide of 9);
              margin-left: 0;
            }

            .field--name-field-collection-body {
              width: span(6 of 9);
              margin-left: gutter(of 9);
            }

            margin-bottom: $gdelimit / 2;
            padding-bottom: $gdelimit / 2;
            border-bottom: 1px solid $collg;
          }

          &-final {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;

            .field-collection-item-field-collection-advanced-text {
              margin-bottom: $gdelimit;
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
    }

    &.nnode-118,
    &.nnode-7 {
      .field--name-body {
        width: span(7);
        margin-left: span(2 wide);
        margin-bottom: 140px;
        @include breakpoint(1024px) {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    &.nnode-118 {
      .field--name-body {
        margin-bottom: 0;
      }
      &.node--full {
        .field--name-field-text-columns {
          @include breakpoint(960px) {
            .field-collection-view {
              margin-bottom: 0;
            }
          }
        } 
        .field-collection-view {
          // advanced text field collection has a margin top of 80px
          // therefore we need 120 to reach 200 as set in the design.
          margin-bottom: 120px;
        }
      }
    }

    &.nnode-33 {

      .field-collection-view {
        margin-bottom: 150px;
      }
    }

    .field-collection-container {
      border-bottom: 0;
      margin-bottom: 0;

      // .vertical-number {
      //   //padding-left: 21px;
      // }
    }

    .field--name-field-collection-advanced-text {
      margin-top: 80px;

        .field-collection-view {
          @include clearfix;

          .field--name-field-collection-title {
            // color: $yellow;
            // float: left;
            // width: span(2);
            // margin-right: gutter();
            // margin-left: span(1 wide);
            padding-top: 50px;
            padding-bottom: 60px;
            font-weight: 400;
            font-family: $libre;
            //font-size: $box;
            font-size: $kelvin;
            line-height: 60px;
            @include breakpoint(1024px) {
              padding-bottom: 20px;
            }
          }

          .field--name-field-collection-body,
          .field--name-field-body {
            
            line-height: 40px;
          }

          .field--name-field-collection-body {
            // width: span(6);
            // float: left;
            // margin-left: span(1 wide);

            img {
              display: block;
              margin: 130px auto;
              display: block;
            }
          }

          .field-collection-container {
            width: span(6);
            float: left;
            margin-left: span(4 wide);
            margin-top: 90px;
          }
        }
      }

    .field--name-field-methods-techniques {
      .field__label {
        display: block;
        margin-bottom: 30px;
        font-family: $mont;
        font-size: $slight;
      }

      .field-collection-view {
        @include clearfix;
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: none;

        &-final {
          margin-bottom: 0;
        }

        .field--name-field-collection-title {
          color: $black;
          float: none;
          width: 100%;
          padding: 10px 30px;
          background: $bgray;
          margin-left: 0;
          font-weight: 300;
          font-family: $mont;
          font-size: $small;
        }

        .field--name-field-collection-body,
        .field--name-field-body {
          line-height: 1.65;
          padding: 20px 40px;
          background: $lgray;
          font-size: $small;
        }

        .field--name-field-collection-body {
          width: 100%;
          float: none;
          margin-left: 0;

        }
      }
    }

    &.nnode-33 {


      .vertical-number {
        .yellow-title {
          .text {
            letter-spacing: 2px;
            // min-width: auto;
          }
        }
      }

      .field--name-field-collection-advanced-text {
        width: span(9);
        margin-left: span(1 wide);
        float: left;
      }

      .field--name-body {
        margin-left: span(2 wide);
        width: span(7);
        margin-bottom: 140px;
      }

      .field-collection-view {
        padding: 80px 0;
        border-top: 1px solid $collg;
        margin-bottom: 0;

        &-final {
          padding-bottom: 0;
        }


        /* Node 33 specific change in styling */
        .group-collection-placeholder {
          //float: left;
          display: block;
          width: 100%;
          margin-left: 0;
          @include clearfix;
        }

        .group-content-right {
          width: 100%;
          margin-left: 0;
        }

        .field--name-field-collection-title {
          float: left;
          width: span(4 of 9);
          margin-left: 0;
          margin-right: gutter(1 of 9);
          color: #fbc31e;
          padding-top: 0;
          
          //margin-left: 8.5%;
          font-weight: 400;
          font-family: "Libre Baskerville",serif;
          font-size: 1.55555rem;
        }

        .field--name-field-collection-body {
          width: span(4 of 9);
        //  margin-left: span(1 of 9) + gutter(of 9);
          margin-left: span(1 wide of 9);
          margin-left: 0;
          float: left;
          width: 42.95302%;
          margin-left: 11.4094%;
          float: left;
          font-size: 18px;
          line-height: 1.6;
        }
      }
    }
  }

  &--trainer {
    &--teaser {
      .node__content {
       // height: 450px;
        height: 470px;
      }

      position: relative;

      .custom-title {
        font-size: $box;
      }

      .field--name-field-capacity {
        // color: $dgray;
        color: #747474;
        margin-top: 3px;
      }

      .field--name-body {
        margin-top: 30px;
      }

      .field--name-field-image {
        width: span(4 of 9);
        margin-right: gutter(of 9);
        float: left;

        .field__item {
          
/*          height: 450px;
          line-height: 450px;*/
          height: 470px;
          line-height: 470px;

          img {
            vertical-align: bottom;
          }
          
        }
      }

      .group-right-wrapper {
        padding: gutter() 0;
        font-size: $small;
        // position: relative;
        // top: 50%;
        // transform: translateY(-50%);
        width: span(4 wide of 9);
        float: left;
      }

/*      &.node-associate-tag {
        .group-right-wrapper {
          padding: calc(50px + 2%) 0 gutter();
        }
      }*/
    }

    &--minimal {
      @include clearfix;

      div.group-image {

        margin-bottom: 30px;
        @include clearfix;

        .group-right-wrapper-trainer {
          position: relative;
          height: 90px;
          overflow: hidden;
        }

        .group-train-min-inside {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .custom-title {
          color: $black;
          font-family: $mont;
          font-size: $small;
          font-weight: 400;
        }

        .field--name-field-capacity {
          font-size: $smaller;
          // color: $dgray;
          color: #747474;          
        }

        .field--name-field-image {
          float: left;
          margin-right: 20px;

          img {
            border-radius: 50%;
          }
        }
      }
    }
  }

  &--case-studies {

    &--full {

     > .node__content {
        padding-top: 200px;
      }

        .field--name-field-body-5 {
          font-size: $newsletter;

          ul {
            li {
              margin-bottom: 0.4em;
            }
          }
          ol {
            li {
              margin-bottom: 0.4em;
            }
          }
        }

        .field--name-field-body-3 {
          ul {
            li {
              margin-bottom: 2em;
            }
          }
          ol {
            li {
              margin-bottom: 2em;
            }
          }
        }

      .field--name-field-image {
        margin-bottom: 80px;
        width: span(10);
        margin-left: span(1 wide);
      }

      .group-meta {
        text-align: center;
        margin-bottom: 80px;

        width: span(6);
        margin-left: span(3 wide);
      }

      .custom-title,
      .field--name-field-subtitle {
        line-height: 1.2;
        font-size: $kelvin;
        font-family: $libre;
        margin-bottom: 3px;
      }

      .case-study-title {
        font-size: $slight;
        margin-bottom: 3px;
      }

      .field--type-text-long,
      .field--type-text-with-summary {
        margin-bottom: $gdelimit / 2;
        padding-bottom: $gdelimit / 2;
        border-bottom: 1px solid $collg;
        width: span(9);
        margin-left: span(1 wide);
        @include clearfix;

        &:last-child {
          margin-bottom: $gdelimit;
          padding-bottom: 0;
          border-bottom: none;
        }

          .field__label {
            color: $yellow;
            float: left;
            width: span(2 of 9);
            margin-right: gutter(of 9);
            margin-left: 0;
            font-weight: 400;
            font-family: $libre;
            font-size: $box;
          }

          .field__items {
            line-height: 1.65;
            width: span(6 of 9);
            float: left;
            margin-left: span(1 wide of 9);

            img {
              display: block;
              margin: 130px auto 0;
              display: block;
            }
          }

  /*        .field-collection-container {
            width: span(6);
            float: left;
            margin-left: span(4);
            margin-top: 90px;
          }*/
      }
    }

    &--teaser {
      @include clearfix;

      .read-more {
        a {
          &:hover,
          &:focus {
            color: $yellow;

            &:after {
              @include sprite($sprite-read-more-yellow);
            }
          }
        }

        &.mobe {
          display: none;
        }
      }

      a {
        color: $black;
        text-decoration: none;
      }

      .field--name-field-logo {
        margin-bottom: 25px;
      }

      .custom-title {
        margin-bottom: 90px;
        font-size: $larger;
      }

      .group-right-wrapper {
        width: span(6 of 9);
     //   margin-left: span(1 of 9) + gutter(of 9);
        margin-left: span(1 wide of 9);
        float: left;
      }

      .group-left-wrapper-cs {
        width: span(2 of 9);
        margin-right: gutter(of 9);
        float: left;
      }
    }

    &--minimal {
      @include clearfix;

      .group-right-wrapper {
        width: span(6 of 9);
       // margin-left: span(1 of 9) + gutter(of 9);
        margin-left: span(1 wide of 9);
        float: left;
      }

      .group-left-wrapper {
        width: span(2 of 9);
        margin-right: gutter(of 9);
        float: left;
      }
    }
  }

  &--in-house-training {
    &--box {
      @include nodebox;
      @include breakpoint(550px) {
        max-height: 400px;
      }
      .group-all-info {
        @include breakpoint(550px) {
          padding: 32px;
        }
      }

    }
    &--teaser {
      @include clearfix;

      .custom-title {
        color: $white;
        font-size: $pwork;
      }

      .field--name-field-image {
        min-height: 555px;
        position: relative;
        overflow: hidden;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.8;
            background-color: $black;
          }

        img {
          min-height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          max-width: none;
          transform: translateX(-50%);
        }
      }

      .group-image-wrapper {
        text-align: center;
        position: relative;

        .custom-title {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          line-height: 1.4;
          z-index: 10;

          .title-grid-wrapper {
            width: span(5 of 9);
            margin: 0 auto;
          }
        }
      }

      .group-left-wrapper {
        width: span(3 wide of 9);
        float: left;
       // margin-right: gutter(of 9);
      }

      .group-right-wrapper {
        width: span(6 of 9);
        float: left;
        text-align: right;
        padding-top: 20px;
        padding-right: 35px;

        .button {
          display: inline-block;
          &.read-more {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }

      .group-info {
        @include clearfix;
        margin: 40px 0 80px;

        .field {
          float: left;
          margin-right: 20px;

          &__label {
            font-weight: 300;
            font-size: $smaller;
            position: relative;
            top: 2px;
          }

          &__items {
            font-weight: 700;
            font-size: $small;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &--full {
      > .node__content {
         padding-top: 200px;
       }
 
       .addthis-mobile {
         margin-top: -10px;
         margin-bottom: 60px;
       }
 
       width: span(10);
       margin: 0 auto;
       line-height: 1.65;
 
       .field--name-field-intro-text {
         margin-bottom: $gdelimit / 2;
       }
 
       .group-pricing-wrapper {
         display: block;
       }
 
       .group-content-bounds {
         @include clearfix;
       }
 
 
       .field--name-field-body-4 {
         margin-bottom: $gdelimit / 2;
       }
 
       .group-image-wrapper {
         .field--name-field-image {
           min-height: 618px;
           position: relative;
           overflow: hidden;
 
           &:after {
             content: "";
             display: block;
             position: absolute;
             top: 0;
             bottom: 0;
             left: 0;
             right: 0;
             opacity: 0.7;
             background-color: $black;
           }
 
           img {
             min-height: 100%;
             position: absolute;
             top: 0;
             left: 50%;
             max-width: none;
             transform: translateX(-50%);
              width: 100%;
              display: block;
           }
         }
       }
 
       .group-right-wrapper {
         .field {
           &__label {
             margin-bottom: 30px;
             font-weight: 700;
             font-size: $slight;
           }
         }
       }
 
       .field-collection-view {
         @include clearfix;
         margin-bottom: 10px;
         padding-bottom: 0;
         border-bottom: none;
 
         &-final {
           margin-bottom: 0;
         }
 
         .field--name-field-collection-title {
           color: $black;
           float: none;
           width: 100%;
           padding: 10px 40px;
           background: $bgray;
           margin-left: 0;
           font-weight: 300;
           font-family: $mont;
           font-size: $small;
         }
 
         .field--name-field-collection-body,
         .field--name-field-body {
           line-height: 1.65;
           padding: 20px;
           background: $lgray;
           font-size: $small;
         }
 
         .field--name-field-collection-body {
           width: 100%;
           float: none;
           margin-left: 0;
 
         }
       }
 
       .field--name-body,
       .field--name-field-body-2,
       .field--name-field-body-3 {
         margin-bottom: $gdelimit / 2;
         //border-bottom: 1px solid $collg;
         padding-bottom: $gdelimit / 2;
       }
 
       .field--name-field-intro-text {
         font-size: $ptext2;
         color: $gray;
         font-weight: 400;
       }
 
       .like-this {
         font-weight: 400;
         font-size: $slight;
         margin-bottom: 10px;
 
         .lk-2 {
           font-weight: 700;
         }
       }
 
       // Susy Context 10 columns
       .group-meta {
         width: span(6 of 10);
         margin-left: span(2 wide of 10);
         text-align: center;
         margin-bottom: 130px;
       }
 
       .custom-title {
         font-size: $super;
         line-height: 65px;
         @include breakpoint(1024px) {
           line-height: 1.3;
           font-size: 1.815rem;
         }
       }
 
       .field--name-field-date {
         font-size: $slight;
         margin-bottom: 10px;
         text-align: center;
 
         .field__label,
         .field__items {
           display: inline-block;
           float: none;
           font-weight: 300;
         }
       }
 
       .group-left-wrapper {
         width: span(3 of 10);
         margin-right: gutter(of 10);
         float: left;
         text-align: left;
       }
 
       .group-right-wrapper {
        // margin-left: gutter(of 10);
         float: left;
         width: span(6 of 10);
       }
 
       .group-pricing-wrapper {
         legend {
           display: block;
           margin-bottom: 10px;
           font-size: $slight;
           font-weight: 700;
         }
 
         .field--type-number-integer {
           margin-bottom: 25px;
 
           &:last-child {
             margin-bottom: 0;
           }
         }
       }
 
       .addthis-wrapper {
         margin-top: 60px;
       }
 
       .group-image-wrapper {
         text-align: center;
         position: relative;
         margin-bottom: 80px;
         overflow: hidden; // ie fix
 
         .group-image-align {
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%, -50%);
           z-index: 10;
           color: $white;
          // max-width: span(8 of 10);
           width: span(8 of 10);
           font-size: $newsletter;
 
           .field__label,
           .field__items {
             display: inline-block;
             float: none;
           }
 
           .field__label {
             font-weight: 400;
           }
 
           .field__items {
             font-weight: 700;
           }
         }
       }
 
       &.node-archived-workshop {
 
         .custom-title {
           font-size: $super;
           color: $black;
         }
       }
     }
  }

  &--webinars {
    &--box {
      @include nodebox;
      @include breakpoint(550px) {
        max-height: 400px;
      }
      .group-all-info {
        @include breakpoint(550px) {
          padding: 32px;
        }
      }

    }
  }

  &--webinars,
  &--public-workshops {

    &--full {
     > .node__content {
        padding-top: 200px;
      }

      .addthis-mobile {
        margin-top: -10px;
        margin-bottom: 60px;
      }

      width: span(10);
      margin: 0 auto;
      line-height: 1.65;

      .field--name-field-map {

        .map-wrap {
          overflow: visible;

          &-1 {
            background: $black;
            height: 470px;
            position: relative;
          }

          &-2 {
            position: relative;
            //  top: 22%;
            width: span(5 of 6);
          }

          &-fields {
            color: $white;
            padding: 40px 0;
            @include clearfix;
            width: span(4 of 6);
            margin: 0 auto;

            .field--name-field-location {
              width: 62%;
              margin-right: 4%;
              float: left;

              .field__label {
                font-size: $smaller;
                margin-bottom: 0;
                font-weight: 400;
                position: relative;
                top: 2px;
              }

              .field__items {
                font-size: $small;
                font-weight: 700;
              }
            }

            .visit-website-pw {
              font-size: $smaller;
              float: right;
              width: 34%;
              text-align: right;
              position: relative;
              top: 2px;

              &:after {
                content: "";
                display: inline-block;
                margin-left: 18px;
                @include sprite($sprite-visit-website);
                position: relative;
                top: 2px;
              }
            }

            a {
              color: $white;
              text-decoration: none;
            }
          }
        }
      }

      .field--name-field-intro-text {
        margin-bottom: $gdelimit / 2;
      }

      .group-pricing-wrapper {
        display: block;
      }

      .group-content-bounds {
        @include clearfix;
      }

      .field--name-field-map {
        margin: $gdelimit / 2 0;
      }

      .field--name-field-body-4 {
        margin-bottom: $gdelimit / 2;
      }

      .field--name-field-entity-ref-trainer {

       > .field__items {
         > .field__item {
           margin-bottom: $gdelimit / 2;

           &:last-child {
             margin-bottom: 0;
           }
         }
       }
      }

      .group-image-wrapper {
        .field--name-field-image {
          min-height: 618px;
          position: relative;
          overflow: hidden;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.7;
            background-color: $black;
          }

          img {
            min-height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            max-width: none;
            transform: translateX(-50%);
          }
        }
      }

      .group-right-wrapper {
        .field {
          &__label {
            margin-bottom: 30px;
            font-weight: 700;
            font-size: $slight;
          }
        }
      }

      .field-collection-view {
        @include clearfix;
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: none;

        &-final {
          margin-bottom: 0;
        }

        .field--name-field-collection-title {
          color: $black;
          float: none;
          width: 100%;
          padding: 10px 40px;
          background: $bgray;
          margin-left: 0;
          font-weight: 300;
          font-family: $mont;
          font-size: $small;
        }

        .field--name-field-collection-body,
        .field--name-field-body {
          line-height: 1.65;
          padding: 20px;
          background: $lgray;
          font-size: $small;
        }

        .field--name-field-collection-body {
          width: 100%;
          float: none;
          margin-left: 0;

        }
      }

      .field--name-body,
      .field--name-field-body-2,
      .field--name-field-body-3 {
        margin-bottom: $gdelimit / 2;
        border-bottom: 1px solid $collg;
        padding-bottom: $gdelimit / 2;
      }

      .field--name-field-intro-text {
        font-size: $ptext2;
        color: $gray;
        font-weight: 400;
      }

      .like-this {
        font-weight: 400;
        font-size: $slight;
        margin-bottom: 10px;

        .lk-2 {
          font-weight: 700;
        }
      }

      // Susy Context 10 columns
      .group-meta {
        width: span(6 of 10);
        margin-left: span(2 wide of 10);
        text-align: center;
        margin-bottom: 130px;
      }

      .custom-title {
        font-size: $super;
      }

      .field--name-field-date {
        font-size: $slight;
        margin-bottom: 10px;
        text-align: center;

        .field__label,
        .field__items {
          display: inline-block;
          float: none;
          font-weight: 300;
        }
      }

      .group-left-wrapper {
        width: span(3 of 10);
        margin-right: gutter(of 10);
        float: left;
        text-align: left;
      }

      .group-right-wrapper {
       // margin-left: gutter(of 10);
        float: left;
        width: span(6 of 10);
      }

      .group-pricing-wrapper {
        legend {
          display: block;
          margin-bottom: 10px;
          font-size: $slight;
          font-weight: 700;
        }

        .field--type-number-integer {
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .addthis-wrapper {
        margin-top: 60px;
      }

      .group-image-wrapper {
        text-align: center;
        position: relative;
        margin-bottom: 80px;
        overflow: hidden; // ie fix

        .group-image-align {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          color: $white;
         // max-width: span(8 of 10);
          width: span(8 of 10);
          font-size: $newsletter;

          .field__label,
          .field__items {
            display: inline-block;
            float: none;
          }

          .field__label {
            font-weight: 400;
          }

          .field__items {
            font-weight: 700;
          }
        }
      }

      &.node-archived-workshop {

        .custom-title {
          font-size: $super;
          color: $black;
        }
      }
    }

    &--box {
      @include nodebox;

      .button {
        &.request_quote {
          a {
            padding: 17px 22px;
          }
        }
      }
      .read-more {
        a {
          padding: 17px 22px;
        }
      }
    }


    &--teaser {
      @include clearfix;

      .custom-title {
        color: $white;
        font-size: $pwork;
      }

      .read-more {
        clear: both;
        padding-top: 60px;
        a {
          padding: 17px 22px;
        }
      }

      .field--name-field-image {
        min-height: 555px;
        position: relative;
        overflow: hidden;

        a {
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.8;
            background-color: $black;
          }
        }

        img {
          min-height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          max-width: none;
          transform: translateX(-50%);
        }
      }

      .group-image-wrapper {
        text-align: center;
        position: relative;

        .custom-title {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          line-height: 1.4;
          z-index: 10;

          a {
            color: $white;
            text-decoration: none;
          }

          .title-grid-wrapper {
            width: span(5 of 9);
            margin: 0 auto;
          }
        }
      }

      .group-left-wrapper {
        width: span(6 of 9);
        float: left;
        margin-right: gutter(of 9);
      }

      .field--name-field-intro-text {
        font-size: $ptext2;
        color: $gray;
        font-weight: 400;
      }

      .group-right-wrapper {
        width: span(3 of 9);
        float: left;
        text-align: right;
        padding-top: 40px;

        .field--type-number-integer,
        .field--type-number-integer {
          margin-bottom: 25px;
        }
      }

      .group-info {
        @include clearfix;
        margin: 40px 0 40px;

        @include breakpoint(600px) {
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .field {
          float: left;
          margin-right: 20px;

          &__label {
            font-weight: 300;
            font-size: $smaller;
            position: relative;
            top: 2px;
          }

          &__items {
            font-weight: 700;
            font-size: $small;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &--minimal {
      @include clearfix;

      .field--name-field-pw-product-reference,
      .field--name-field-early-product-ref,
      .field--name-field-early-bird-price,
      .field--name-field-standard-price {
      
        .activated-price {
          background-image: linear-gradient(to right, $black, $black 50%, $white 50%, $white);
          background-repeat: none;

          &:hover,
          &:focus {
            color: $yellow;
          }
        }
      }

      .group-left-wrapper {
        width: span(6);
        float: left;
      }

      .custom-title {
        width: span(6);
      }

      .group-right-wrapper {
        width: span(4);
        float: right;
        margin-left: span(2 wide);
        text-align: right;
      }

      .group-info {
        @include clearfix;

        .field {
          float: left;
          margin-right: 20px;

          &__label {
            font-weight: 300;
            font-size: $smaller;
            position: relative;
            top: 2px;
          }

          &__items {
            font-weight: 700;
            font-size: $small;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .field--name-body {
        margin-bottom: 50px;
      }

      .custom-title {

        a {
          color: $black;
          text-decoration: none;
        }

        font-size: $box;
        margin-bottom: 25px;
      }

    }
  }
}

.social-media-icons {

  a {
    margin-right: 10px;
    float: left;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item {

    &--linkedin {
      @include sprite($sprite-linkedin);
    }

    &--twitter {
      @include sprite($sprite-twitter);
    }

    &--facebook {
      @include sprite($sprite-facebook);
    }
    &--instagram {
      background: url(/sites/all/themes/theuxprodigy/images/instagram.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 32px;
      height: 32px;
    }
  }
}

// Newest Changes 22.5

.equal-height {
  min-height: 73px;
}

.rmore-plain {
  a {
    background: none;
  }  
}

// .vertical-number .yellow-title .number-06 {
//   background: url("../images/chapter-06.svg") 0 0 no-repeat;
//   background-size: contain;
// }

.view-header {
  @include clearfix;
  float: left;
  width: span(1);
  .vertical-number {
    float: none;
    width: 100%;
  }
}


.views-row-content {
  @include clearfix;
  @include breakpoint(500px) {
    width: span(10);
    margin: 0 auto;
  }
}


#block-views-testimonials-block {
  .view-header {
    @include breakpoint(1024px) {
      padding-bottom: 80px;
    }
  }  
}


// Numbered field collection items - Use Case: How we work Page
.group-collection-placeholder {
  .collection-number {
    @include clearfix;
  }
}


// .collection-header {
//   @include clearfix;
//   float: left;
//   width: 6.5%;
// }

.group-content-right {
  //float: left;
  width: span(7 of 12);
  margin-left: span(2 wide);

  .field--name-field-collection-body {
    font-size: 22px;
  }

  @include breakpoint(1024px) {
    width: 100%;
    margin-left: 0;
  }
}


.field-rotate {
  .collection-number {
    @include clearfix;
    float: left;
    width: 6.5%;
    margin-top: -30px;

    @include breakpoint(1024px) {
      width: 100%;
    }

    .vertical-number {
      width: auto;
      float: none;


      .text {
        top: 130px;
        @include breakpoint(1024px) {
          top: 0;
        }
      }
    }
  }
}



.field--name-field-text-columns {
  @include clearfix;
  width: span(7 of 12);
  margin-left: span(2 wide);
  
  @include breakpoint(1024px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 60px;
    &>.field__items {
      &>.field__item {
        margin-bottom: 20px;
      }
    }
  }

  .field__item {
    float: left;
    width: 33.33%;
    padding-left: 70px;
    line-height: 26px;
    //margin-right: gutter();
    @include breakpoint(960px) {
      width: 100%;
      padding-left: 0;
      
    }

    &:first-child {
      padding-right: 30px;
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .field--name-field-column-body {
      @include clearfix;
      font-size: $small;

      .field__item {
        width: 100%;
      }
    }

    .field--name-field-column-title {
      font-weight: 600;
      .field__item {
        display: block;
        width: 100%;
      }
    }
  }
}

#block-block-2 {
  @include breakpoint(1024px) {
    text-align: center;
  }
}


// Services Call to action box
.services-cta {
  width: span(7);
  margin-left: span(2 wide);

  @include breakpoint(1024px) {
    width: span(10 of 12);
    margin-left: 0;
    display: inline-block;
  }

  .container {
    @include clearfix;
    padding: 80px 0;
    border-top: 1px solid rgb(218,218,218);
    border-bottom: 1px solid rgb(218,218,218);
    margin-bottom: 250px;
    margin-top: 80px;

    @include breakpoint(700px) {
      padding: 40px 0;
      margin-top: 0;
    }
  }

  .button {
    float: right;
    a {
      &:hover {
        color: $yellow;
        &:after {
          @include sprite($sprite-read-more-yellow);
        }
      }
    }
    @include breakpoint(700px) {
      float: none; 
      margin-top: 50px;
    }
  }
  .custom-block-title {
    float: left;
    font-size: $kelvin;
    font-family: $libre;
    line-height: 60px;

    @include breakpoint(1024px) {
      font-size: 2.01666rem;
      line-height: 1.3;
    }
    @include breakpoint(700px) {
      float: none;
    }
  }
}



// Newest Our services page.

.header-actions {
  @include clearfix;
  position: fixed;
  width: 100%;
  z-index: 9;
  @include breakpoint(820px) {
    top: 70px;
  }
  @include breakpoint(700px) {
    display: none;
  }

  li {
    a {
      float: left;
      width: percentage(480 / 1920);
      text-align: center;
      background: $ngray;
      padding: 20px;
      border-right: 1px solid $nngray;
      font-family: $libre;
      @include breakpoint(1030px) {
        min-height: 88px;
      }
    }
    @include breakpoint(1030px) {
      &:not(:nth-child(2)) {
        a {
          line-height: 88px;
          padding: 0;
        }      
      }
    }
    @include links($black,$black, "", "");

    &:last-child {
      border-right: none;
    }
  }
}


/* Services Content */

#block-views-services-block {
  padding-top: 260px;

  @include breakpoint(850px) {
    padding-top: 120px;
  }

  @include breakpoint(700px) {
    padding-top: 40px;
  }

  .views-row-last {
    position: relative;
    padding-bottom: 260px;
    margin-bottom: 260px;
    border-bottom: 1px solid #d4d4d4;

    @include breakpoint(550px) {
      padding-bottom: 160px;
      margin-bottom: 160px;
    }

    .node--services {
      .group-container {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &:after {
      content: "or";
      color: $yellow;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -60px;
      width: 123px;
      height: 123px;
      line-height: 117px;
      background: $white;
      border: 1px solid $black;
      border-radius: 50%;
      text-align: center;
      font-family: $libre;
      font-size: $box;
      @include breakpoint(550px) {
        width: 103px;
        height: 103px;
        bottom: -50px;
        line-height: 101px;
      }
    }
  }
}

.node--services {
  @include clearfix; 

  .action-links,
  .tabs--primary {
    position: relative; 
    top: 110px;
  }


  .node__content {    
    width: span(9 of 12);
    margin-left: span(1 wide);
    @include breakpoint(1100px) {
      width: span(10 of 12);
    }
  }

  .group-container {
    @include clearfix;
    border-bottom: 1px solid $nngray;
    padding-bottom: 150px;
    margin-bottom: 150px;
    @include breakpoint(550px) {
      padding-bottom: 100px;
      margin-bottom: 100px;
    }
  }

  .group-left {
    float: left;
    width: span(2 of 9);
    text-align: center;
    margin-right: span(1 wider of 9);
    @include breakpoint(1100px) {
      width: span(3 of 10);
      margin-right: span(1 of 10);
    }
    @include breakpoint(850px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  .field--name-field-title {
    // color: $yellow;
    color: #000000;
    font-family: $mont;
    font-size: $ptext2;
    line-height: 40px;
    font-weight: 600;
  }

  .custom-title {
    // color: $yellow;
    color: #000000;
    font-size: $box;
    margin-top: 30px;
  }


  .group-right {
    float: left;
    width: span(6 of 9);
    @include breakpoint(1100px) {
      width: span(6 of 10);
    }
    @include breakpoint(850px) {
      width: 100%;
    }
  }

  .field--name-field-service-type {
    &>.field__items {
      &>.field__item {
        &:first-child {
          padding-top: 0;
        }
        padding-top: 180px;
        @include breakpoint(1024px) {
          padding-top: 100px;
        }
      }
    }
  }

  .field--name-field-collection-body,
  .field--name-field-introduction-body {
    width: 100%;
    font-size: $ptext2;
    line-height: 40px;
    font-weight: 500;
    @include breakpoint(1024px) {
      font-size: 1.22222rem;
      line-height: 1.65;
    }
    @include breakpoint(550px) {
      font-size: 1rem;
    }
  }

  .field--name-field-strong-title {
    font-size: $super;
    font-family: $libre;
    line-height: 60px;
    margin-bottom: 28px;
    @include breakpoint(1024px) {
      font-size: 2.01666rem;
      line-height: 1.3;
      width: 100%;
      margin-left: 0;
    }
    @include breakpoint(480px) {
      font-size: $box;
    }  
  }

  // Methods & Techniques FC.
  .field-collection-view {
    margin-bottom: 0 !important;
  } 
  .field--name-field-methods-techniques {
    margin: 100px 0;
    @include clearfix;
    //display: inline-block;
    @include breakpoint(550px) {
      margin: 60px 0;
    }

    .field__label {
      font-size: $normal;
    }

    .field--name-field-collection-title {
      .field__item {
        
        &.even,
        &.odd {
          float: none;
          width: 100%;
          margin: 0;
          font-weight: 500;
        }
      }
    }

    .field__items {
      @include clearfix;
    }

    .methods-column{
      width: span(3 of 6);
      float: left;
      @include breakpoint(500px) {
        width: 100%;
      }
      
      &.first {
        margin-right: gutter(1 of 6);
        @include breakpoint(500px) {
         margin-right: 0;
        }
      }
    }

    .field__item {
      @include clearfix;     
      margin-bottom: 10px;
      // background: #f3f3f3;

      .column-left {
        width: span(3 of 6);
        float: left;  
        
      }
    }
  }
}


.hidden {
  display: none;
}


/* Select Box it */

#block-views-solutions-block {

  .selectboxit-container {
    
    margin-left: span(2 wide of 12);
    width: span(7 of 12);
    // width: span(6 of 12);
    // margin-left: span(3 wide of 12);
    
    border: 1px solid $black;
    z-index: 1;

    @include breakpoint(1024px) {
      width: span(10 of 12);
      margin-left: span(1 of 10);
    }
  
    .selectboxit {
      background: $white !important;
      width: 100% !important;
      height: 60px;
      @include breakpoint(660px) {
        width: auto !important;
      }
    }
    .selectboxit-list {
      background-color: $white !important;
      width: 100% !important;
      min-width: 100% !important;
      max-width: none !important;
      border: 1px solid $black;
      list-style: none !important;
      padding-left: 30px;
      top: 60px !important;
    
    }
  }
  
  .selectboxit-container * {
    font-size: 16px;
  }
  
  .selectboxit-arrow-container {
    margin-right: 25px;
  }
  .selectboxit .selectboxit-option-icon-container {
    margin-left: 30px;
  }
  
  .selectboxit-container .selectboxit span, .selectboxit-container .selectboxit .selectboxit-options a {
    height: 60px;
    line-height: 60px;
    @include breakpoint(505px) {
      top: -60px;
      position: relative;

    }
  }
  
  .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
    background: none;
  }
  .selectboxit-focus {
    background: #fbc31e;
    margin-left: -30px;
    padding-left: 30px;
    border-radius: 0;      
  }

  .selectboxit-enabled {
    &.selectboxit-focus {
      margin-left: 0;
      padding-left: 0;
    }
  }


  .selectboxit-option {
    // padding-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  
    &.selectboxit-option-first {
      // padding-top: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  
    &.selectboxit-option-last {
      // padding-bottom: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  
  .selectboxit-option-icon {
    display: none !important;
  }

}



/* Solution Blocks */
#block-views-solutions-block {
  padding-bottom: 250px;
  .block__title {
    margin-left: span(2 wide of 12);
    width: span(7 of 12);
    font-size: $super;
    line-height: $nkelvin;
    margin-bottom: 60px;
    @include breakpoint(1024px) {
      font-size: 1.815rem;
      line-height: 37px;
      width: span(10 of 12);
      margin-left: span(1 of 10);
    }
  }

  .field--name-body {
    font-size: $ptext2;
    line-height: $line40;
  }

  .group-solution {
    // margin-left: span(3 wide of 12);
    // width: span(6 of 12);
    margin-left: span(2 wide of 12);
    width: span(7 of 12);
    margin-top: 100px;
    @include breakpoint(1024px) {
      width: span(10 of 12);
      margin-left: span(1 of 10);
    }
  }



  .group-solution {    

    &>div {
      span {
        font-size: $ptext2;
        font-weight: 500;
        padding-bottom: 10px;
        display: block;
      }
    }


    .custom-title {
      font-size: $kelvin;
      // color: $yellow;
      color: #000000;
      line-height: $nkelvin;
      width: 100%;
      margin-bottom: 40px;
      //margin-left: span(3 wide of 12);
      @include breakpoint(1024px) {
        font-size: 2.01666rem;
        line-height: 1.3;
        width: 100%;
        margin-left: 0;
      }
      @include breakpoint(480px) {
        font-size: $box;
      }
    }
  }

  .field-collection-view {
    padding: 0;
    margin: 0;
    border-bottom: none;
  }

  .field-collection-container {
    border-bottom: none;
    margin-bottom: 100px;
  }

  .field--name-field-methods-techniques {

    .field__label {
      margin-bottom: 30px;
    }

    .field-collection-view {
      .field--name-field-collection-title {
        color: $black;
        float: none;
        width: 100%;
        padding: 10px 30px;
        background: $bgray;
        margin-left: 0;
        font-weight: 500;
        font-family: $mont;
        font-size: $small;
        margin-top: 10px;
      }
    }
  }

  .use-modal {
    font-size: 16px;
    padding: 20px 0;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    text-decoration: none;
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, #100a0f), to(#100a0f));
    background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, #100a0f 50%, #100a0f);
    background-size: 201% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    width: 200px;
    margin-top: 50px;
    font-weight: 500;
    &:hover {
      background-position: 0 0;
      color: #100a0f;
    }
  }

  .methods-column{
    width: span(3 of 6);
    float: left;
    @include breakpoint(500px) {
      width: 100%;
    }

    &.first {
      margin-right: gutter(1 of 6);
      @include breakpoint(500px) {
        margin-right: 0;
      }
    }
  }

}



/* Our Workshops Page */
.page-node-5 {
  .view-testimonials {
    .view-header {
      display: none;
    }
    .view-content {
      width: 100%;
    }
  }
}

// .views-content-header {
  
// }


/* Public Workshops */
#block-views-training-block-5,
#block-views-training-block-4,
#block-views-training-block {
  .vertical-number {
    .yellow-title {
      .text {
        min-width: 300px;
        @include breakpoint(400px) {
          min-width: auto;
          letter-spacing: 2px;
          line-height: 38px;
        }
      }
    }
  }

  .view-training {
    &.view-display-id-block_4,
    &.view-display-id-block {
      .view-header {
        .vertical-number {
          left: 0;
          .text {
            @include breakpoint(400px) {
              letter-spacing: 2px;
              min-width: auto;
              line-height: 38px;
            }
          }
        }
      }
    }
  }
}

/* Fix broken sidebar title (header) position */
.view-training {
  &.view-display-id-block_5 {
    margin-top: 150px;
    display: inline-block;
    width: 100%;

    @include breakpoint(550px) {
      margin-top: 60px;
    }
    .view-header {
      @include breakpoint(550px) {
        margin-bottom: 40px;
      }
      .vertical-number {
        left: 0;
      }
    } 
    
  }
}
#block-views-training-block-5 {
  .vertical-number {
    .yellow-title {
      .text {
        min-width: 450px;
        //top: 285px;
        top: 302px;
        @include breakpoint(1024px) {
          min-width: auto;
          top: auto;
          letter-spacing: 2px;
          line-height: 40px;
        }
        @include breakpoint(450px) {
          max-width: 250px;
          line-height: 1.4;
        }
        @include breakpoint(340px) {
          max-width: 180px;
        }
      }
      .number {
        @include breakpoint(1024px) {
          float: left;
        }
      }
    }
  }
}
#block-views-training-block-4 {
  .view-header {
    height: 500px;
    @include breakpoint(1024px) {
      height: auto;
    }
  }
  .vertical-number {
    .yellow-title {
      .number {
        @include breakpoint(1024px) {
          float: left;
        }
      }
      .text {
        top: 215px;
        @include breakpoint(1024px) {
          top: auto;
          line-height: 38px;
        }
      }
    }
  }
}

#block-views-training-block-1 {
  .view-training {
    &.view-display-id-block_1 {
      .view-content {
        margin-left: span(2 wide);
        @include breakpoint(1024px) {
          margin-left: auto;
        }
      }
      .view-header {
        .vertical-number {
          left: 0;
        }
      }
    }
  }
}


.views-content-header {
  padding-top: 80px;
  width: span(9);
  float: left;
  margin-left: span(1 wide);

  h2 {
    font-family: $libre;
    font-size: $kelvin;
    line-height: $nkelvin;
    width: span(7 of 9);
    @include breakpoint(1024px) {
      width: 100%;
      font-size: 1.815rem;
      line-height: 1.3;
    }
  }

  span {
    display: inline-block;
    width: span(7 of 9);
    padding-top: 50px;
    line-height: $line40;
    font-size: $ptext2;

    @include breakpoint(1024px) {
      font-size: $normal;
      margin-bottom: 50px;
      width: 100%;
      line-height: 1.8;
    }
  }
}

/* Public Workshops Box + (Box in house training) view mode */
#block-views-training-block-7,
#block-views-training-block-5,
#block-views-training-block-4 {
  .request_quote {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Request Workshop Webform */
#webform_drawer-163,
#webform_drawer-125 {
  padding: 70px 0;
  max-width: 460px;
  margin: 0 auto;

  .webform-title {
    padding-bottom: 50px;
    text-align: center;
  }
}


/* In House Training Newest Changes */
#block-views-training-block-5 {
  clear: both;
}

.view-training {
  &.view-display-id-block_1 {
    .views-row {
      margin-bottom: 70px;
    }
  }
}

.node--full {
  &.nnode-6 {
    .field--name-body {
      margin-bottom: 20px;
    }
  }
}


/* InHouse last row yellow */
.custom-row {
  width: span(3 of 9);
  margin-right: gutter(1 of 9);
  float: left;

  // @include breakpoint(1024px) {
  //   //clear: both !important;
  // }
  .button {
    a {
      &:hover {
        border: 1px solid $black !important;
      }
    }
  }

  .custom-title {
    color: $black;
    padding: 0 48px;
    text-align: left;
    font-size: $largest;
    line-height: 46px;

    @include breakpoint(1500px) {
      font-size: 1.38888rem;
      margin-top: 20px;
      line-height: 1.5;
      padding: 0;
    }
  }

  .box {
    .button {
      a {
        font-size: 16px;
        padding: 20px 16px;
        color: #ffffff;
        display: block;
        text-decoration: none;
        background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, #100a0f), to(#100a0f));
        background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, #100a0f 50%, #100a0f);
        background-size: 201% 100%;
        background-repeat: no-repeat;
        background-position: 100% 0;
        -webkit-transition: 0.3s background-position ease-out;
        transition: 0.3s background-position ease-out;
        border: 1px solid $black;
      
        &:hover {        
          background-position: 0 0;
          color: #100a0f;
        }
      }
    }
  }
  
  .yellow {
    background: $yellow;
  }
}



.view-inhouse-info {
  .field--custom-strong-title {
    font-size: $super;
    font-family: $libre;
    line-height: 60px;
    margin-bottom: 70px;
    width: span(7 of 9);
    @include breakpoint(1024px) {
      width: 100%;
    }
    @include breakpoint(600px) {
      font-size: 2.01666rem;
      line-height: 1.3;
    }
  }
  .field--name-body {
    line-height: $line40;
    font-size: $ptext2;
    margin-bottom: 90px;
    width: span(7 of 9);
    @include breakpoint(1024px) {
      width: 100%;
    }
    @include breakpoint(600px) {
      font-size: $normal;
      line-height: 1.8;
    }
  }
}

.node-type-services-content,
.node-type-blog,
.node-type-advanced-page,
.node-type-public-workshops,
.node-type-page {
    #block-views-56988818e5dad1bfb43137cef0925cf9,
    #block-views-d501b9c211e362b75675678db578e59d {
      display: none;
    }
}

.node-type-in-house-training {
  #block-views-56988818e5dad1bfb43137cef0925cf9,
  #block-views-d501b9c211e362b75675678db578e59d {
     display: block;

    .block__title {
      font-size: $box;
      line-height: 50px;
      margin-left: span(1 wide of 12);

      @include breakpoint(700px){
        width: 83%;
        margin: 0 auto;
        line-height: 1.3;
        margin-bottom: 40px;
      }
    }
  }
  .block--views {
    clear: both;
  }
}


  .view-display-id-inhouse_training_for_managers {
    .block__title {
      font-size: $box;
      line-height: 50px;
      margin-left: span(1 wide of 12);
    }

    .view-content {
      width: span(9 of 12);
      margin-left: span(2 wide of 12);
      margin-right: span(1 wide of 12);
    }
  }

  .view-related-inhouse-training {
    @include clearfix;
    //margin-bottom: $gdelimit;

   &.view-display-id-inhouse_training_for_design_teams {
     .view-content {
       @include breakpoint(1100px) {
        width: span(10 of 12);
        margin-left: span(2 of 12);
        margin-right: 0;
       }
       @include breakpoint(1024px) {
         margin-left: 0;
         margin: 0 auto;
       }
     }
   }

    .view-content {
      width: span(9 of 12);
      margin-left: span(2 wide of 12);
      margin-right: span(1 wide of 12);

      @include breakpoint(700px) {
        width: 83%;
        margin-left: 0;
        margin-right: 0;
        margin: 0 auto;
      }
    }
  }

  .view-display-id-inhouse_training_for_design_teams {
    .views-row {
      margin-bottom: 150px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .views-row {
      width: span(3 of 9);
      margin-right: gutter(1 of 9);
      float: left;
      overflow: hidden;      

      //@include breakpoint()
      
      &:nth-child(3n) {
        margin-right: 0;
        float: right;
        //margin-bottom: gutter(of 9);
      }

      &:nth-child(3n+1) {
        clear: both;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include breakpoint(1024px) {
        width: span(5 of 10);
        margin-right: gutter(1 of 10);
        margin-bottom: 20px;
        &:nth-child(2n+1) {
          clear: left;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(3n+1) {
          clear: none;
        }
        &:nth-child(3n) {
          float: left;
        }
      }

      @include breakpoint(680px) {
        width: span(10 of 10);
        margin-right: 0;
        margin-bottom: 20px;
        
        &:nth-child(2n+1) {
          clear: left;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(3n+1) {
          clear: none;
        }
        &:nth-child(3n) {
          float: left;
        }
      } 

    }

    


    .request_quote {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 32px;
    }
  
}

.activew-circle {
  // right: 5px !important;
  @include breakpoint(1435px) {
    right: 5px !important;
  }
  @include breakpoint(1300px) {
    right: -5px !important;
  }
  @include breakpoint(800px) {
    right: 50% !important;
    top: 18px;
    transform: translateX(60px);
  }

}

// .sf-depth-2 {
//   .activew-circle {
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }
.slicknav_menu .slicknav_nav {
  .training-ulitem-inside {
    .activew-circle { 
      // z-index: 1;
      // right: 1px;
      @include breakpoint(1024px) {
        right: 0 !important;
        top: 30px;
        transform: none !important;
      }    
    }
  }  
}
.training-ulitem-inside {
  .activew-circle { 
    z-index: 1;
    right: 1px;
    @include breakpoint(1435px) {
      right: -10px !important;
    }
  }
}

.node--services-content--full,
.node--blog--full {
  > .node__content {
     padding-top: 200px;
     @include breakpoint(1024px) {
       width: 100% !important;
       padding-top: 100px;
     }
   }


   @include breakpoint(1024px) {
     width: 100%;
     .group-content-bounds {
       width: 83%;
       margin: 0 auto;
     }
   }
   
   

   .addthis-mobile {
     margin-top: -10px;
     margin-bottom: 60px;
   }

   width: span(10);
   margin: 0 auto;
   line-height: 1.65;

   .field--name-field-intro-text {
     margin-bottom: $gdelimit / 2;
   }

   .group-pricing-wrapper {
     display: block;
     @include breakpoint(1024px) {      
        margin-top: 0 !important;
        display: table-footer-group;      
     }
   }

   .group-content-bounds {
     @include clearfix;
     @include breakpoint(1024px) {
       display: table;
     }
   }


   .field--name-field-body-4 {
     margin-bottom: $gdelimit / 2;
   }

   .bg-image {
     height: 618px;
     background-position: center;
     background-size: cover;
     @include breakpoint(1024px) {
       height: 480px;
     }
     @include breakpoint(680px) {
      height: 370px;
     }
   }

   .group-image-wrapper {
    @include breakpoint(580px) {
      margin-bottom: 0;
    }
     .field--name-field-image {
       //min-height: 618px;
       position: relative;
       overflow: hidden;

      //  @include breakpoint(1024px) {
      //   min-height: 480px;
      //  }
      //  @include breakpoint(680px) {
      //   min-height: 370px;
      //  }
       

      //  &:after {
      //    content: "";
      //    display: block;
      //    position: absolute;
      //    top: 0;
      //    bottom: 0;
      //    left: 0;
      //    right: 0;
      //    opacity: 0.7;
      //    background-color: $black;
      //  }

      //  img {
      //   //  min-height: 100%;
      //   //  position: absolute;
      //   //  top: 0;
      //   //  left: 50%;
      //   //  max-width: none;
      //   //  transform: translateX(-50%);
      //   //   width: 100%;
      //   //   display: block;
      //  }
     }
   }

   .group-right-wrapper {
     .field {
       &__label {
         margin-bottom: 30px;
         font-weight: 700;
         font-size: $slight;
       }
     }
   }

   .field-collection-view {
     @include clearfix;
     margin-bottom: 10px;
     padding-bottom: 0;
     border-bottom: none;

     &-final {
       margin-bottom: 0;
     }

     .field--name-field-collection-title {
       color: $black;
       float: none;
       width: 100%;
       padding: 10px 40px;
       background: $bgray;
       margin-left: 0;
       font-weight: 300;
       font-family: $mont;
       font-size: $small;
     }

     .field--name-field-collection-body,
     .field--name-field-body {
       line-height: 1.65;
       padding: 20px;
       background: $lgray;
       font-size: $small;
       font-weight: 400;
     }

     

     .field--name-field-collection-body {
       width: 100%;
       float: none;
       margin-left: 0;

     }
   }

   .field--name-body,
   .field--name-field-body-2,
   .field--name-field-body-3 {
     margin-bottom: $gdelimit / 2;
     border-bottom: 1px solid $collg;
     padding-bottom: $gdelimit / 2;
     font-weight: 400;

     @include breakpoint(1024px) {
       padding-bottom: 60px;
       margin-bottom: 60px;
     }

    }

   .field--name-field-intro-text {
     font-size: $ptext2;
     color: $gray;
     font-weight: 400;
   }

   .like-this {
     font-weight: 400;
     font-size: $slight;
     margin-bottom: 10px;

     .lk-2 {
       font-weight: 700;
     }
   }

   // Susy Context 10 columns
   .group-meta {
     width: span(6 of 10);
     margin-left: span(2 wide of 10);
     text-align: center;
     margin-bottom: 130px;
   }

   .custom-title {
     font-size: $super;
     line-height: 65px;
     text-align: center;
    //  padding-bottom: 70px;
     width: span(6 of 12);
     margin: 0 auto;
     @include breakpoint(1024px) {
      font-size: 2.01666rem;
      line-height: 1.3;
      width: 100%;
     }
     @include breakpoint(500px) {
      margin-bottom: 25px;
      font-size: 1.66666rem;
     }
   }


   blockquote {
    //  color: $yellow;
      color: #747474;

     font-size: $larger;
     line-height: 54px;
     border-top: 1px solid $yellow;
     border-bottom: 1px solid $yellow;
     padding: 30px 0;
     margin: 60px 0;
     @include breakpoint(550px) {
       line-height: 1.4;
       font-size: 22px;
     }
   }

   .field--name-field-date {
     font-size: $slight;
     margin-bottom: 10px;
     text-align: center;

     .field__label,
     .field__items {
       display: inline-block;
       float: none;
       font-weight: 300;
     }
   }

   .group-left-wrapper {
     width: span(3 of 10);
     margin-right: gutter(of 10);
     float: left;
     text-align: left;

     @include breakpoint(1024px) {
      width: 100%;
      margin-right: 0;
      display: table-footer-group;
      float: none;
     }

   }

   .group-right-wrapper {
    // margin-left: gutter(of 10);
     float: left;
     width: span(6 of 10);

     @include breakpoint(1024px) {
      width: 100%;
      margin-right: 0;
      margin-top: 40px;
     }
   }

   .group-pricing-wrapper {
     legend {
       display: block;
       margin-bottom: 10px;
       font-size: $slight;
       font-weight: 700;
     }

     .field--type-number-integer {
       margin-bottom: 25px;

       &:last-child {
         margin-bottom: 0;
       }
     }
   }

   .addthis-wrapper {
     margin-top: 0;
   }

   .group-image-wrapper {
     text-align: center;
     position: relative;
     margin-bottom: 80px;
     overflow: hidden; // ie fix

     .group-image-align {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       z-index: 10;
       color: $white;
      // max-width: span(8 of 10);
       width: span(8 of 10);
       font-size: $newsletter;

       .field__label,
       .field__items {
         display: inline-block;
         float: none;
       }

       .field__label {
         font-weight: 400;
       }

       .field__items {
         font-weight: 700;
       }
     }
   }

   &.node-archived-workshop {

     .custom-title {
       font-size: $super;
       color: $black;
     }
   }
 }


 /* Blog Related Styling */

 .view-blog {
   &.view-display-id-page {
     padding: 150px 0 150px 0;
   }

     .node--blog--teaser {
       margin-bottom: 60px;
       border: 1px solid #e1e1e1;
       min-height: 600px;
       width: 100%;
       position: relative;
       
       img { 
         width: 100%;
       }

      .custom-title {
        padding: 0 30px;
        margin-top: 10px;
        font-size: $ptext;
        line-height: 32px;

        a {
          &:link, &:visited, &:active {
            text-decoration: none;
            color: $black;
          }
      
          &:hover, &:focus {
            color: $black;
          }
        }

      }

       .read-more {
         position: absolute;
         bottom: 30px;
         left: 30px;
         a {
           border: 1px solid #e1e1e1;
           background: none;
         }
         &:hover {
           a {
             border: 1px solid $black;
             background: none;
           }
         }
       }
     }

     .views-row {
      float: left;
      width: span(4 of 12);
      margin-right: gutter();

       &:nth-child(3n) { 
        margin-right: 0;
       }
       &:nth-child(3n+1) {
         clear: left;
       }

       @include breakpoint(1024px) {
          width: span(6 of 12);
          margin-right: gutter();
          &:nth-child(3n+1) {
            margin-right: gutter();
            clear: none;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(2n+1) {
            margin-right: gutter();
            clear: left;
          }
      }

      @include breakpoint(580px) {
        width: 100%;
        margin-right: 0;

        .node--blog--teaser {
          min-height: 650px;
        }

        &:nth-child(2n+1) {
          clear: none;
          margin-right: 0;
        }
        &:nth-child(3n+1) {
          margin-right: 0;
        }
      } 

      @include breakpoint(360px) {
        .node--blog--teaser {
          min-height: 580px;

        }
      }
     }
   
 }

 #block-views-blog-block {
   .block__title {
     margin-left: span(1 of 12);
   }
 }


 /* Front Page Box links fix */
.box {
  .button a {
    border: 1px solid $black;
    background-color: none;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    background-size: 204% 100%;
    background-image: -webkit-gradient(linear,left top,right top,from(#fbc31e),color-stop(50%,#fbc31e),color-stop(50%,transparent),to(transparent));
    background-image: linear-gradient(to right,#fbc31e,#fbc31e 50%,transparent 50%,transparent);
    background-position: 100% 0;

    &:hover {
      background-position: 0 0;
      border-color: #fbc31e;
    }
  }
}
#block-gtweaks-what-we-do{
  .box-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
}
%styling-for-cards{
  padding-top: 0px;
  @include breakpoint(560px) {
    padding-top: 0px;
  }
  .box-image{
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    img {
      display: block;
      width: 100%;
    }
  }
  .box-meta{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  h2{
    text-align: left;
    padding: 0 35px !important;
    margin-bottom: 20px !important;
  }
  .text{
    padding: 0 35px;
    text-align: left;
    font-size: 16px;
    margin-bottom: 35px;
  }
  .box-align{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .button{
    margin-top: auto;
    padding: 0 35px;
    a{
      min-width: unset;
      width: 100%;
    }
  }
  @include breakpoint(1420px) {
    h2{
      padding: 0 20px !important;
    }
    .text{
      padding: 0 20px;
    }
    .button{
      padding: 0 20px;
    }
  }
  @include breakpoint(1260px) {
    h2{
      padding: 0 10px !important;
    }
    .text{
      padding: 0 10px;
    }
    .button{
      padding: 0 10px;
    }
    .read-more-arrow a::after{
      margin-left: 10px;
    }
  }
  @include breakpoint(930px) {
    .box-align{
      width: 100% !important;
      height: auto !important;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .box-meta{
      width: 50%;
      -webkit-transform: translateY(0) !important;
      transform: translateY(0) !important;
    }
    .box-image{
      width: 50%;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    h2{
      padding-right: 0px !important;
      margin-bottom: 15px !important;
    }
    .text{
      padding-right: 0px !important;
      margin-bottom: 25px !important;
    }
    .button {
      padding:0 0 0 10px;
      a{
        min-width: unset;
      }
    }
  }
  @include breakpoint(650px) {
    padding: 0px 0px 30px 0px !important;
    .box-align {
      width: 100%;
      flex-direction: column;
    }
    .box-meta {
      width: 100%;
    }
    .box-image {
      width: 100%;
      margin-bottom: 30px;
    }
    h2 {
      padding:0 20px !important;
    }
    .text {
      padding:0 20px !important;
    }
    .button {
      padding:0px;
      display: flex;
      justify-content: center;
      a{
        width: 80%;
      }
    }
  }
  @include breakpoint(460px) {
    .button {
      a{
        width: 70%;
      }
    }
  }
}
.block--gtweaks-what-we-do .view-content{
  .box-item{
    &-1,&-2,&-3{
      margin-bottom: gutter(of 9);
      @include breakpoint(560px) {
        .button{
          padding: 0 20px !important;
          a{
            width: 80%;
          }
        } 
      }
      @include breakpoint(460px) {
        .button {
          a{
            width: 70%;
          }
        }
      }
    }
    &-4{
      @extend %styling-for-cards;
      .button {
        a {
          color: white;
          border: 0px;
          font-weight: 600;
          background-image: -webkit-gradient(linear, left top, right top, from(#133a5f), color-stop(50%, #133a5f), color-stop(50%, #0c4f8e), to(#0c4f8e));
          background-image: linear-gradient(to right, #133a5f, #133a5f 50%, #0c4f8e 50%, #0c4f8e);
          &:hover {        
            background-position: 0 0;
            border: 0px;
          }
          &::after{
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    &-5{
      @extend %styling-for-cards;
      .button {
        a {
          color: white;
          border: 0px;
          font-weight: 600;
          background-image: -webkit-gradient(linear, left top, right top, from(#A22E8B), color-stop(50%, #A22E8B), color-stop(50%, #7D2B6D), to(#7D2B6D));
          background-image: linear-gradient(to right, #A22E8B, #A22E8B 50%, #7D2B6D 50%, #7D2B6D);
          &:hover {        
            background-position: 0 0;
            border: 0px;
          }
          &::after{
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    &-6{
      @extend %styling-for-cards;
      .button {
        a {
          border: 0px;
          font-weight: 600;
          background-image: -webkit-gradient(linear, left top, right top, from(#100a0f), color-stop(50%, #100a0f), color-stop(50%, #fbc31e), to(#fbc31e));
          background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
          &:hover {        
            background-position: 0 0;
            color:white;
            border: 0px;
            &::after{
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  } 
}



 .block--views-training-block-3 .read-more.rmore a {
  border: 1px solid #fbc31e;
  background-color: none;
  -webkit-transition: 0.3s background-position ease-out;
  transition: 0.3s background-position ease-out;
  background-size: 204% 100%;
  background-image: -webkit-gradient(linear,left top,right top,from(#fbc31e),color-stop(50%,#fbc31e),color-stop(50%,transparent),to(transparent));
  background-image: linear-gradient(to right,#fbc31e,#fbc31e 50%,transparent 50%,transparent);
  background-position: 100% 0;
  &:hover {
    background-position: 0 0;
    color: $black;

    &:after {
      @include sprite($sprite-read-more-black);
    }
  }
 }



 /* NUMBERS Vertical - Fixes for each ones height */
 #block-gtweaks-what-we-do {
  .vertical-number .yellow-title .text {
    top: 120px;
    @include breakpoint(1024px) {
      top: auto;
      line-height: 38px;
    }
  }
 }
 
 
 #block-gtweaks-why-ux,
 #block-views-testimonials-block,
 #block-views-companies-block {
  .vertical-number .yellow-title .text {
    top: 135px;
    @include breakpoint(1024px) {
      top: auto;
      line-height: 38px;
    }
  }
 }

 #block-views-training-block-3 {
  top: 135px; 
}

 .block--views-companies-block .block__title.companies-title {
   padding-top: 40px;
 }


 // What we do image body fix 
 .nnode-118 {
  .field--name-body {
    img {
      display: block;
      margin: 0 auto;
    }
  }
 }

 .node-type-page,
 .node--advanced-page {
   .field--name-field-strong-title {
    padding-top: 30px;
   }
 }

 .metrics-extra {
   display: none;
 }

 #block-gtweaks-testimonials-our-work {
   margin-bottom: 100px;
   @include breakpoint(1024px) {
     margin-bottom: 0;
     max-height: 600px;
   }
   @include breakpoint(600px) {
    max-height: unset;
   }

   .node--testimonials {
    .field--name-body {
      padding: 0 70px;
      @include breakpoint(600px) {
        padding: 0;
      }
    }
   }
   .button a {
     padding: 17px 22px;
   }
 }

 .node--in-house-training {
  .button a {
    padding: 17px 22px;
  }
 }


 .menu {
   .last {
     &.leaf {
       margin-right: 40px;
       @include breakpoint(1300px) {
         margin-right: 10px;
       }
     }
   }
 }

.node-archived-workshop {
  &.node--public-workshops--full {
    .request_quote {
      display: block;
    }
  } 
}

 .node--public-workshops--full {
   .request_quote {
     display: none;
   }
 }


 .sold-out {
  padding: 12px 24px;
  background: black;
  color: white;  
  font-weight: 500;
 }



 .view-related-inhouse-training {
  .node--in-house-training--teaser {
    @include breakpoint(1024px) {
      margin-bottom: 40px;
    }
   }
 }
 

 // WHo we are responsive fix
 .nnode-118 {
  .vertical-number .yellow-title {
    .text {
      @include breakpoint(350px) {
        display: inline;
        line-height: 38px;
      }
    }
    .number {
      @include breakpoint(350px) {
        margin-left: -20px;
      }
    }
  }
 }
 

 .inhouse-legend {
   legend {
     display: none !important;
    }
 }

//  .view-navigation-boxes {
//    .read-more {
//      &.rmore {
//        a {
//         border: 1px solid $yellow;
//         padding: 22px;
//         &:hover {
//           background-position: 0 0;
//           border-color: $yellow;
//           color: $black;
//         }
//        }
//      }
//    }
//  }

.service-arrow-down {
  border-color: black transparent;
	border-style: solid;
	border-width: 5px 5px 0px 5px;
	height: 0px;
  width: 0px;
  display: inline-block;
}

.select-repl {
  border: 1px solid $black;
  padding: 13px;
  width: span(10 of 12);
  margin-left: span(1 wide of 12);

  &:hover {
    cursor: pointer;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 10px);
    padding-right: 40px;
  }
}


.modal-is-active {
  background: rgba(0,0,0,0.4);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99999999;
  top: 0;
}

.services-options {
  background: white;
  width: 80vw;
  margin: 0 auto;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 80vh;
  overflow-y: scroll;

}

.option-wrapper {
  padding: 10px 25px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  &:hover {
    cursor: pointer;
  }
}

.service-option {
  width: calc(100% - 12px);
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;

}

.circle-input {
  width: 12px;
  height: 12px;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 50%;
  display: inline-block;
}

.hidden {
  display: none;
}

.selected {
  .circle-input {
    background: $yellow;
  }
}


#pgwModal {
  min-height: 600px;
  input {
    -webkit-appearance: none;
  }
}




// blog
.node-author-group-teaser {
  padding: 15px 30px;
  font-size: 12px;
  @include clearfix;
  font-family: $mont;

  .group-left {
    float: left;
    @include breakpoint(1120px) {
      float: none;
    }
    @include breakpoint(1024px) {
      float: left;
    }
    @include breakpoint(768px) {
      float: none;   
    }
  }
  .group-right {
    float: right;
    @include breakpoint(1120px) {
      float: none;
      display: block;
      margin-top: 3px;
    }
    @include breakpoint(1024px) {
      float: right;
    }
    @include breakpoint(768px) {
      float: none;
      display: block;
      margin-top: 3px;
      
    }
  }

  a {
    font-size: 12px;
    font-weight: 600;
    color: $black;
    text-decoration: none;
    
    &:visited,
    &:active {
      color: $black;
      text-decoration: none;
    }

    &:hover {      
      color: $yellow;
    }
  }

}



.node-author-group-full {
  @include clearfix;
  padding-bottom: 50px;
  margin-bottom: 60px;
  border-bottom: 1px solid $yellow;
  @include breakpoint(1300px) {
    padding-bottom: 20px;
  }
  @include breakpoint(650px) {
    text-align: center;
  }

  .group-left {
    float: left;
    font-size: 18px;
    @include breakpoint(1300px) {
      display: block;
      float: none;
    }
    @include breakpoint(1024px) {
      display: inline;
      float: left;
    }
    @include breakpoint(770px) {
      display: block;
      float: none;
    }
    @include breakpoint(650px) {
      margin-bottom: 10px;
    }
  }
  .group-right {
    float: right;
    font-size: 16px;
    line-height: 30px;
    @include breakpoint(1300px) {
      display: block;
      float: none;
      margin-bottom: 10px;
    }
    @include breakpoint(1024px) {
      display: inline;
      float: right;
    }
    @include breakpoint(770px) {
      display: block;
      float: none;
      margin-bottom: 10px;
    }
  }

  .follow-btn {
    @include breakpoint(650px) {
      margin-top: 10px;
      display: block;
      margin-right: 0;
    }
    a {
      font-size: 12px;
      font-weight: 600;
      padding: 10px 30px;
      border: 1px solid $black;
      border-radius: 30px;
      color: $black;
      text-decoration: none;
      display: inline-block;
      &:visited,
      &:active {
        color: $black;
        text-decoration: none;
      }

      &:hover {
        background: $black;
        color: $white;
      }
    }
  }

  .author-name {
    font-weight: 600;
    margin-right: 10px;
    @include breakpoint(360px) {
      margin-right: 0;
    }
  }
}



.page-node-119 {
  .tabs--primary {
    position: relative;
    top: 150px;
  }
}

.page-node {
  &.node-type-blog {
    .block--views-navigation-boxes-block {
      @include breakpoint(1024px) {
      margin-top: 60px;
      }
    }
  }
}

#block-views-training-block-5 {
  
  .deactivated-price {
    display: none;
  }
}

//Commerce
.pgwModal {

  .pm-body{
    padding: 0 70px 0 0;

    @include breakpoint(950px) {
      padding-right: 0;
    }
    @include breakpoint(560px) {
      padding: 0 !important;
    }

    .pm-content {
      display: flex;
      @include breakpoint(950px) {
        flex-direction: column;
      }

      .pay-now {
        background-color: $yellow;
        width: 35%;
        padding: 0 45px;

        @include breakpoint(950px) {
          width: 100%;
          padding: 45px;
        }
        
        .pay-now-title {
          margin-bottom: 45px;
          margin-top: 100px;
          font-size: 24px;

          @include breakpoint(950px) {
            margin-top: 0;
          }
          @include breakpoint(400px) {
            margin-top: 40px;
          }
        }

        .pay-now-subtitle {
          text-transform: uppercase;
          font-size: 12px;
          margin-bottom: 10px;
        }

        a {
          text-decoration: none;
          width: 100%;
          text-align: center;
          display: block;
          color: white;
          font-weight: bold;
          background-color: black;
          padding: 10px;
        }
      }
    }
  }
}



body {
  overflow-x: hidden;
}
// Import header styling
@import "components/header";
@import "components/write_myux";
@import "components/training_block";
@import "components/blog";
@import "components/footer";
@import "components/coupon_webform";
@import "components/webinars";
@import "components/checkout";
@import "components/checkout_complete";
@import "components/services_page";


.group-content-bounds {
  .group-left-wrapper {
    position: sticky;
    // top: 80px;
    top: 100px;
    bottom: 0;
    margin-bottom: 130px;
    @include breakpoint(1024px) {
      position: relative;
      top: unset;
      bottom: unset;
    }
    
  }  
}


// .field-collection-item-field-methods-techniques {
//   position: relative;
//   .field--name-field-page-link {
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//     a {
//       position: absolute;
//       top: 0;
//       right: 0;
//       left: 0;
//       bottom: 0;      
//     }
//   }
// }

// Field collection links
.is-linked {
  .field-collection-item-field-methods-techniques {
    position: relative;
    .field--name-field-collection-title {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        @include sprite($sprite-arrow);
      }

      &:hover {
        background-image: linear-gradient(to right, $black, $black 50%, $yellow 50%, $yellow);
        background-size: 200% 100%;
        background-repeat: no-repeat;
        background-position: 100% 0;
        cursor: pointer;
      }
    }    
  }  
  .field--name-field-page-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    text-indent: -9999px;
  }
}


.group-right-wrapper {
  .product-link {
    margin-bottom: 25px;
  }
}

.node--page--full {
  padding-top: 70px;
}


.field--name-field-early-product-ref {
  margin-bottom: 30px;
  @include breakpoint(450px) {
    margin-bottom: 10px;
  }
}

.reserve-your-seat {
  text-decoration: none;
}

.deactivated-price,
.activated-price {
  padding: 16px 0 !important;
  line-height: 1;
}



.node--public-workshops--minimal {
  .group-right-wrapper {
    
    width: auto !important;
    display: flex;
    flex-direction: column;

    .field--name-field-early-product-ref {
      margin-top: 30px;
    }
  }
}

.node--plain-page--full {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 190px 0 150px 0;
  @include breakpoint(600px) {
    padding: 100px 0 100px 0;
  }

  .custom-title {
    font-family: "Libre Baskerville";
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 70px;
    @include breakpoint(700px) {
      font-size: 35px;
    }
    @include breakpoint(400px) {
      font-size: 28px;
    }
  }
  .field--name-body {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;

    @include breakpoint(400px) {
      font-size: 16px;
    }
  }
}


.field--name-field-agenda {
  margin-bottom: 100px;
}


// Remove all hover effects on touch devices
@media (pointer:coarse) {
  .services-cta .button a:hover,
  .button.main-banner-button a:hover {
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
      background: #fbc31e;     
      color: #100a0f; 
  }
  .box .button a:hover {
    border-color: #100a0f;
    background-position: none;
    background: white;
  }
  .node--webinars--box .read-more.rmore.button a:hover,   
  .node--public-workshops--box .read-more.rmore.button a:hover,
  #block-views-training-block-3 .group-buttons .button a:hover {
    border: 1px solid white;
    color: white;
    background: none;
  }
  .node--case-studies--teaser .read-more a:hover {
    color: #100a0f;
    background-position: none;
    background: #fbc31e;
  }
  .view-navigation-boxes .read-more.rmore a:hover {
    border: 1px solid #fbc31e;
    color: #fbc31e;
    background: none;
  }
  .node--public-workshops--box .request_quote a:hover, .node--webinars--box .request_quote a:hover,
  #block-views-training-block-4 .node--public-workshops--box .request_quote a:hover {
    color: #100a0f;
    background-position: none;
    background: #fbc31e;
  }
  .read-more.rmore a:hover {
    background-position: none;
    border-color: #100a0f;
    background: none;
  }
  .commerce-checkout-form-complete > div .grouper .checkout-buttons .checkout-continue:hover, .commerce-checkout-form-checkout > div .grouper .checkout-buttons .checkout-continue:hover,
  .field--name-field-pw-product-reference .activated-price:hover,
  .button a:hover {
    background-position: none;
    color: #100a0f;
    background: #fbc31e;
  }
  .custom-row .box .button a:hover,
  .block--gtweaks-header-lets-talk.block a:hover {
    color: white;
    background-position: none;
    background: black;
  }

  .webinar-link--copy-link a:hover {
    background: none;
  }
  #edit-buttons .checkout-gofrontpage {
    color: #100a0f;
    background-position: none;
    background: #fbc31e;
  }
}


.slicknav_menu {
  .training-ulitem-inside {  
    font-size: 1.38888rem !important;
  }
}


.node--blog--full .custom-title {
  margin-bottom: 60px;
}

.nnode-33 {
  position: relative;
  top: 60px;
  @include breakpoint(1024px) {
    top: 0;
  }
}





a, button, input,textarea,select,area {
  &:focus {
    outline: auto !important;
  }
}



.node--webinars--minimal .custom-title {
 @include breakpoint(400px) {
  width: 90%; 
 }
}