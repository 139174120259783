#block-views-blog-block-1 {
  margin-top: 220px;

  .view-blog {
    display: flex;
    position: relative;
    
    .views-row:nth-child(2n+1) {
      @include breakpoint(1024px) {
        clear: none;
      }
    }

    @include breakpoint(1024px) {
      flex-direction: column;
    }

    .vertical-number .yellow-title .text {
      top: 125px;
      @include breakpoint(1024px) {
        top: 10px;
      }
    }

    .view-content {
      margin-top: 85px;
      margin-left: span(1 wider);
      @include breakpoint(450px) {
        margin-left: 35px;
      }
    }    

    .views-row {
      max-width: 512px !important;
      margin-right: 30px;

      &:nth-child(3n+1) {
        clear: none;
      }

      @include breakpoint(700px) {
        max-width: 285px !important;
        height: 330px;
        margin-bottom: 330px;

        .node--blog--teaser {
          min-height: 420px;
        }
        .node-author-group-teaser {
          font-size: 9px;
          padding: 10px 10px 0 10px;
        }
        .custom-title {
          font-size: 13px;
          line-height: 22px;
          padding: 10px;
        }
      }      
      @include breakpoint(700px) {
        margin-bottom: 180px;
      }
      @include breakpoint(450px) {
        margin-right: 5px;        
      }
    }    
  }

  .slick-dots {
 
      list-style-type: none;
      display: flex;
      justify-content: center;
      padding-left: 0;
      max-width: 1330px;
    
    li {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #C4C4C4;
      margin-right: 10px;
      &:hover {
        cursor: pointer;
      }
      &.slick-active {
        background: #fbc31e;
      }
      button {
        opacity: 0;
      }
    }
  }

  .view-footer {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
  }
  .slick-arrows-blog {
    display: flex;
    justify-items: center;
    height: 100%;
    @include breakpoint(550px) {
      display: none;
    }

    .blog-prev {
      @include sprite($sprite-read-more-black);
      transform: rotate(180deg);
      margin-right: 30px;
      margin-top: auto;
      margin-bottom: auto;

      @include breakpoint(410px) {
        @include sprite($sprite-next);
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }
    .blog-next {
      @include sprite($sprite-read-more-black);          
      margin-top: auto;
      margin-bottom: auto;
      @include breakpoint(410px) {
        @include sprite($sprite-next);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}