// The services page inherits blog's styling from the styles.scss file.
// This only overwrites certain elements's styling.

.node--services-content--full {
  .custom-title {
    width: 100%;    
  }
  .group-meta {
    @include breakpoint(600px) {
      margin-bottom: 30px;
    }
  }
  .group-image-wrapper {
    @include breakpoint(600px) {
      margin-bottom: 30px;
    }
  }
}