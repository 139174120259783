.full-size-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &-node {
   // min-height: 684px;
    min-height: 400px;
    height: 684px;
    position: relative;
    max-height: calc(100vh - 185px);
  }
}

// scrollbar stuff
.mCSB_inside > .mCSB_container {
  margin-right: 40px;
}

.mCSB_scrollTools {

    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";

  .mCSB_draggerRail {
    background-color: rgba(16, 10, 15, 1);
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
    width: 8px;
  }

  .mCSB_dragger {

    &:hover {
      .mCSB_dragger_bar {
        background-color: #fff;
        background-color: rgba(255, 255, 255, 1);
        filter: "alpha(opacity=100)";
        -ms-filter: "alpha(opacity=100)";
      }
    }

    .mCSB_dragger_bar {
      background-color: rgba(255, 255, 255, 1);
      filter: "alpha(opacity=100)";
      -ms-filter: "alpha(opacity=100)";
      width: 8px;
      border-radius: 0;
    }

    &:hover {
      .mCSB_dragger_bar {
        background-color: rgba(255, 255, 255, 1);
      }
    }


    &:active .mCSB_dragger_bar,
    &.mCSB_dragger_onDrag .mCSB_dragger_bar {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 1);
      filter: "alpha(opacity=100)";
      -ms-filter: "alpha(opacity=100)";
    }
  }
}

.field_delimiter {
  margin-bottom: $gdelimit / 2;
  border-bottom: 1px solid $collg;
  padding-bottom: $gdelimit / 2;
}

.external-associate {
  padding: 12px 22px;
  color: $black;
  font-size: $smaller;
  text-align: center;
  text-decoration: none;
  background: $yellow;
  font-weight: 300;
  
  // position: absolute;
  // top: 0;
  // right: 0;
  min-width: 168px;
  float: right;

  @include breakpoint(550px) {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  &-mobile {
    display: none;
  }
}

.use-ajax {
  text-decoration: none;
}

.has-accordion-item {

  .field--type-text {
    position: relative;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &:after {
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -3px;
      content: "";
      border-top: 6px solid $black;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    &.close-accit {
      &:after {
        border-top: none;
        border-bottom: 6px solid $black;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
    }

  }

  .field--type-text-long {
    display: none;
  }
}

.grid-wrapper {
  position: relative;
 // height: 0;
}

.slicknav_menu {
  .slicknav_menutxt {
    background-color: $bgray;
  }
}

.pgwloaderModal {

  img {
    max-width: 200px;
  }

  .pm {
    &-body {
      background: transparent;
    }

    &-content {
      text-align: center;
    }
  }
}

.custom-title-box {
  width: span(3);
  padding: 52px 32px;
  background-color: $yellow;
  height: 376px;
  // exp
  min-width: 367px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
 // position: relative;

  &:after {
    content: "";
    @include sprite($sprite-box-rectangles-white);
    position: absolute;
    right: 32px;
    bottom: 32px;
  }

  .title-text {
    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 0.2em;
    font-size: $larger;
    font-weight: 300;
    font-family: $mont;
    // color: $white;
    color: #000000;

  }

      .number {
        margin-bottom: 32px;
        width: 70px;
        height: 45px;
        background-size: contain;

        &-01 {
          background: url('../images/chapterwhite/chapter-01.svg') 0 0 no-repeat;
        }

        &-02 {
          background: url('../images/chapterwhite/chapter-02.svg') 0 0 no-repeat;
        }

        &-03 {
          background: url('../images/chapterwhite/chapter-03.svg') 0 0 no-repeat;
        }

        &-04 {
          background: url('../images/chapterwhite/chapter-04.svg') 0 0 no-repeat;
        }

        &-05 {
          background: url('../images/chapterwhite/chapter-05.svg') 0 0 no-repeat;
        }
      }

  &-nav {
    background-color: $black;
    transform: none;
    position: relative;

    .title-text {
      text-transform: uppercase;
    }

    &:after {
      content: "";
      @include sprite($sprite-box-rectangles-yellow);
    }
  }
}

.close-btn-wrapper {
  text-align: right;
  margin: 50px 0 80px;
}

.activew-bubble {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  img {
    max-width: 206px;
    margin-left: 8rem;
  }
}

.activew-circle {
  width: 22px !important;
  height: 22px !important;
  text-align: center;
  line-height: 22px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: $yellow;
  font-weight: 700;
  font-size: $smallest !important;
  display: block;
  position: absolute !important;
  z-index: 1;
  top: 22px;
  right: 22px;
  padding: 0 !important;
}

.training-ulitem-inside {
  position: relative;
  z-index: 2;
}

.mobile-only {
  display: none;
  &.universal-buttons-wrap { 
    .deactivated-price {
      border: none;
      border-left: 1px solid #c0c0c0;
    }
    .field--name-field-early-product-ref {
      @include breakpoint(450px) {
        .activated-price {
          width: 100%;
        }            
      }
    }    
  }
}

.universal-buttons-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: $lgray;
  text-align: center;

  @include breakpoint(1024px) {
    z-index: 2147483642;
  }

  .field--name-field-early-bird-price .deactivated-price,
  .field--name-field-standard-price .deactivated-price {
    border: none;
  }
}

.close-btn {
  width: 105px;
  display: inline-block;

  &.close-btn-nots {
    color: #000000;
    &:active,
    &:visited,
    &:hover {
      color: #000000;
    }
    text-decoration: none;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }

  .close-md {
    display: flex;
    font-weight: 400;
    font-size: $small;
    vertical-align: middle;
    @include clearfix;
    line-height: 40px;
  }

  .x-close-modal {
    @include sprite($sprite-close-modal);
    display: block;
    float: right;
  }

  .x-close-text {
    margin-right: 20px;
    vertical-align: middle;
  }
}

.block__title {
  font-family: $libre;
  font-size: $box;
  font-weight: 400;
  margin-bottom: 40px;
}

.pgwModal {

  .pm-content {
    padding: 0;
    border-radius: none;
    background: none;

    .messages {
      font-size: $smaller;
      margin-bottom: 20px;
    }
  }

  .close-md {
    display: flex;
    font-weight: 400;
    font-size: $small;
    vertical-align: middle;
    @include clearfix;
    line-height: 40px;
  }

  .x-close-modal {
    @include sprite($sprite-close-modal);
    display: block;
    float: right;
  }

  .x-close-text {
    margin-right: 20px;
    vertical-align: middle;
  }

  .pm-body {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 0 70px;
  }

  .pm-close {
    top: 20px;
    right: 20px;
  }
}

#webform_drawer-1 {
 @include clearfix;
  padding: 70px 0;
  max-width: 700px;
  margin: 0 auto;
}

#webform_drawer-2 {
  @include clearfix;
  padding: 70px 0;
  max-width: 460px;
  margin: 0 auto;
}

#webform_drawer-36 {
  @include clearfix;
  padding: 70px 0;
  max-width: 460px;
  margin: 0 auto;
}

.metrics-wrapper {
@include clearfix;

  .metrics {
    float: left;
    width: span(2 of 8);
    margin-right: gutter(of 8);
    position: relative;

    display: flex;
    flex-direction: column;

    &-desc {
      font-weight: 400;
      font-size: $smallest;
      color: $gray;
    }

    &-extra {
      font-size: $metrics;
      font-weight: 300;
      color: $yellow;
      line-height: 1.1;
    //  position: absolute;
      margin-left: 5px;
    //  top: 50%;
     // transform: translateY(-50%);
    }

    &:last-child {
      margin-right: 0;
    }

    &-label {
      font-weight: 400;
      font-size: $smallest;
    }

    &-animate {
      position: relative;
      font-size: $metrics;
      font-weight: 300;
      color: $yellow;
      line-height: 1.1;
    }

    .training-animate {
      float: left;
    }
  }
}

.webform-confirmation {
  text-align: center;
  min-height: 628px;
  position: relative;

  .webconf {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
  }

  .tickbox {
    @include sprite($sprite-tickbox);
    margin: 0 auto;
  }

  .webform-success-txt {
    margin-top: 40px;
    text-transform: uppercase;
  }
}

.webform-client-form,
.user-login {

  .form-actions {
    margin-bottom: 0;
  }

  .form-item {
 //   margin-bottom: 32px;
    margin-bottom: 20px;
  }

  label {
    display: block;
    font-family: $mont;
    font-size: $smallest;
    margin-bottom: 3px;
    font-weight: 300;
    text-transform: uppercase;
  }

  input,
  textarea,
  select {
    width: 100%;
    border: none;
    box-shadow: none;
    background: $mgray;
    padding: 12px;
    font-size: $small;
    font-family: $mont;
    line-height: 1.13;
    font-weight: 300;

    &[type="submit"] {
      background: $yellow;
      color: $black;
      font-family: $mont;
      font-weight: 700;
      text-align: center;
      font-size: $smaller;
      padding: 13px;

      &:hover,
      &:focus {
        cursor: pointer;
      }
    }
  }

  textarea {
    line-height: 1.4;
  }
}

// Slick Arrow
.slick-arrow {
  text-align: center;
  width: 40px;

  .carousel-arrow {
    &-left,
    &-right {
      border: solid $yellow;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 6px;
    }

    &-left {
      transform: rotate(135deg);
    }

    &-right {
      transform: rotate(-45deg);
    }
  }
}

.arrow-wrapper {
  z-index: 30;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &--right {
    position: absolute;
    top: 60px;
    right: 24%;
  }

  &--left {
    position: absolute;
    top: 60px;
    left: 24%;
  }
}

.applies-for {
  font-size: $smaller;
  text-align: center;
  margin-top: 5px;
  width: 214px;
  padding: 0 8px;
}

.button {
  a {
    display: inline-block;
    padding: 22px;
    color: $black;
    font-size: $smaller;
    text-align: center;
    text-decoration: none;
    font-weight: 300;
    // min-width: 214px;
    min-width: 225px;

    background-image: linear-gradient(to right, $black, $black 50%, $yellow 50%, $yellow);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    transition: 0.3s background-position ease-out;

    &:hover,
    &.active {
      background-position: 0 0;
      color: $yellow;
    }
  }

  &.request_quote {
    a {
      font-weight: 400;
    }
  }
}

.field--name-field-pw-product-reference,
.field--name-field-early-product-ref,
.field--name-field-early-bird-price,
.field--name-field-standard-price {
  display: inline-block;

  @include breakpoint(450px) {
    display: block;
    width: 100%;
  }

  .product-active,
  .activated-price {
    padding: 16px 22px;
    color: $black;
    font-size: $smaller;
    width: 225px;
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to right, $black, $black 50%, $yellow 50%, $yellow);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    font-weight: 400;
    transition: 0.3s background-position ease-out;

    @include breakpoint(450px) {
      width: 100%;
      a {
        width: 100%;
      }
    }


    &:hover,
    &:focus {
      background-position: 0 0;
      color: $yellow;
    }
  }

  .product-disabled,
  .product-active {
    padding: 0 !important;
    a {
      background-image: none;    
      transition: none;
    }    
  }

  .product-active {
    margin-bottom: 25px;
  }

  .product-disabled,
  .deactivated-price {
    padding: 16px 22px;
    color: $dprice;
    font-size: $smaller;
    width: 225px;
    text-align: center;
    border: 1px solid $dprice;
    text-decoration: none;
    background: transparent;
    font-weight: 400;

    @include breakpoint(450px) {
      width: 100%;
    }

    .price {
      margin-right: 5px;
    }
    a { 
      padding: 16px 20px;
      color: $dprice;
      font-size: $smaller;
      width: 225px;
      text-align: center;      
      text-decoration: none;
      background: transparent;
      font-weight: 400;
      &:hover {
        cursor: default;
      }
    }
  }
}

.read-more {

   &-black {
     a {
       &:after {
         @include sprite($sprite-read-more-black);
       }

       &:hover,
       &:focus {
         color: $black;
       }
     }
   }

  &-yellow {
    a {
      &:after {
        @include sprite($sprite-read-more-yellow);
      }
    }
  }

  &-arrow {
    a {
      &:after {
        content: "";
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
      }
    }
  }

  &.rmore {

    &.read-more-black {
      a {
        border: 1px solid $black;
      }
    }

    &.read-more-yellow {
      a {
        border: 1px solid $yellow;
      }
    }

    a {
      background-color: none;
      transition: 0.3s background-position ease-out;
      background-size: 204% 100%;
      background-image: linear-gradient(to right, $yellow, $yellow 50%, transparent 50%, transparent);
      background-position: 100% 0;

      &:hover {
        background-position: 0 0;
        border-color: $yellow;

        &.read-more-yellow {
          a {
            color: $black;

            &:after {
              @include sprite($sprite-read-more-black);
            }
          }
        }
      }
    }
  }
}

// fix nav...
#ux-design-section,
#ux-evaluation-section,
#ux-research-section {
  &:before {
    display: block;
    content: "";
    margin-top: -$gdelimit / 2;
    height: $gdelimit / 2;
    visibility: hidden;
  }
}

.vertical-number {
  // color: $yellow;
  color: #747474;  
  width: span(1);
  float: left;
  margin-right: gutter();
  position: relative;
  text-align: center;

  .yellow-title {
    position: relative;
    // transform: rotate(-90deg);
    // transform-origin: right bottom;
    // font-size: $slight;
    // white-space: nowrap;
    // display: inline-block;
    // margin-left: -100%;

 /*   position: absolute;
    top: 0;
    left: 0;*/

    .text {
      // margin-right: 45px;
      // margin-bottom: -25px;
      font-weight: 400;
      letter-spacing: 3.2px;
      position: relative;
      left: 35%;
      top: 180px;
      transform: rotate(-90deg) translateX(-50%);
      transform-origin: left;
      min-width: 200px;      
      margin-bottom: -25px;
      text-align: right;
    }

    .number {
      // transform: rotate(90deg);
      // transform-origin: right bottom;
      width: 70px;
      height: 45px;
      // background-size: contain;
      // float: right;

      &-01 {
        background: url('../images/chapter-01.svg') 0 0 no-repeat;
      }

      &-02 {
        background: url('../images/chapter-02.svg') 0 0 no-repeat;
      }

      &-03 {
        background: url('../images/chapter-03.svg') 0 0 no-repeat;
      }

      &-04 {
        background: url('../images/chapter-04.svg') 0 0 no-repeat;
      }

      &-05 {
        background: url('../images/chapter-05.svg') 0 0 no-repeat;
      }

      &-06 {
        background: url('../images/chapter-06.svg?1231') 0 0 no-repeat;
        background-size: contain;
      }
    }
  }
}

.group-social-media {
  @include clearfix;
  margin-top: 40px;

  .field--type-link-field {
    margin-right: 10px;
    float: left;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    float: left;
    overflow: hidden;
    text-indent: -9999px;
  }

  .field--name-field-linkedin {
    a {
      @include sprite($sprite-linkedin);
    }
  }

  .field--name-field-dribbble {
    a {
      @include sprite($sprite-dribble);
    }
  }

  .field--name-field--facebook {
    a {
      @include sprite($sprite-facebook);
    }
  }

  .field--name-field-twitter {
    a {
      @include sprite($sprite-twitter);
    }
  }
}