.block--gtweaks-what-we-do {
  .view-content {
    .box {
      &.full-width {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0;        

        @include breakpoint(930px) {
          padding: 0 !important;
        }
        @include breakpoint(850px) {
          flex-direction: column-reverse;
        }
  
        .col {          
          &:first-child {
            width: 510px;
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            padding-top: 40px;

            @include breakpoint(1200px) {
              width: 300px;
            }
            @include breakpoint(850px) {
              width: 100%;
            }

            img {
              margin-bottom: 80px;
              display: block;

              @include breakpoint(560px) {
                margin-bottom: 40px;
              }
            }
          }
          &:last-child {
            width: 60%;

            @include breakpoint(1200px) {
              margin-left: auto;
            }
            @include breakpoint(850px) {
              width: 100%;
            }

            img {
              margin-bottom: 70px;
              display: block;
              margin-left: auto;

              @include breakpoint(850px) {
                margin-bottom: 0;
              }
            }
          }
        }

        .text {
         
            br {
              @include breakpoint(700px) {
                display: none;
              }
           }
        }

        a {
          padding: 25px 40px;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          background-color: #23c9ff;
          display: block;
          text-decoration: none;
          margin-top: 40px;
          margin-bottom: 40px;
          
          &:hover {
            cursor: pointer;
            background: #0facdf;
          }

          @include breakpoint(600px) {
            padding: 15px;
          }
          @include breakpoint(400px) {
            padding: 10px;
          }
  
          &:active,
          &:visited {
            color: white;
          }
        }
        h2 {
          margin-top: 0;
          text-align: left;
          margin-bottom: 20px;
          line-height: 1;
          padding-left: 0;

          @include breakpoint(560px) {
            margin-bottom: 20px !important;
          }
        }
        .content {
          width: 400px;
          margin: 0 auto;  
          text-align: left;     
          margin-left: 30px;
          @include breakpoint(850px) {
            margin-left: auto;
          }
          @include breakpoint(600px) {
            width: 300px;
          }
          @include breakpoint(430px) {
            width: 250px;
          }
          @include breakpoint(400px) {
            width: 180px;
          }

          .cta {
            @include breakpoint(600px) {
              text-align: center;
            }
            @include breakpoint(400px) {
              font-size: 12px;
              text-align: center;                            
            }
          }
        }  
      }          
    }
  }
}