// Override default variables of susy

$container: 1600px;
// $gdelimit: 250px;
$gdelimit: 200px;

$susy: (
  columns: susy-repeat(12),
  gutters: 0.30769230769231, // or 32px
  spread: 'narrow',
  container-spread: 'narrow',
);

@include breakpoint-set('default feature', 'max-width');