.block--gtweaks-footer-lets-talk.block {
  height: 420px;
  @include breakpoint(400px) {
    height: 480px;
  }

  &> .block__content {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    
    @include breakpoint(750px) {
      padding: 0 !important;
    }
  }
  .newsletter-intro {
    margin-top: auto;

    a {
      color: black;
      &:hover,
      &:visited {
        color: black;
      }      
    }

    @include breakpoint(750px) {
      margin-top: 40px;
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
  #block-mailchimp-signup-theuxprodigy-newsletter {
    margin-bottom: auto;
  }
  .form-actions {
    display: flex;
    position: absolute;
    top: 30%;
    right: 0;    
    padding-bottom: 14px;
    transform: translateY(-50%);
    margin-bottom: 0;
    padding-bottom: 0;

    @include breakpoint(750px) {
      top: 25%;
    }
    @include breakpoint(425px) {
      top: 22%;
    }

    &:before {
      content: "send";
      display: inline;
      letter-spacing: 0.8px;
      font-size: 16px;
      margin-right: 10px;
      @include breakpoint(700px) {
        font-size: 12px;
      }
    }    
    input[type="submit"] {
      position: relative !important;
      top: initial !important;
      right: initial !important;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}


.social-media-icons {
  display: flex;
  align-items: center;

  @include breakpoint(950px) {
    padding-top: 30px;
  }

  span {
    margin-right: 10px;
  }
  a {
    float: none;
  }
}

.gdpr-menu {
  display: flex;
  position: relative;
  top: 6px;

  @include breakpoint(950px) {
    padding-top: 30px;    
  }
  @include breakpoint(600px) {
    padding-bottom: 30px;
  }

  @include breakpoint(450px) {
    display: flex;
    flex-direction: column;
    a {
      padding-bottom: 5px;
      margin: 0 !important;
    }  
  }

  a {
    margin-right: 15px;
    font-weight: normal;
    text-decoration: underline;
    &:first-child {
      margin-left: 15px;
    }
  }
}


#mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form {
  @include breakpoint(750px) {
    margin: 10px auto 0;
    input[type="text"] {
      font-size: 12px !important;
      padding: 8px 64px 8px 14px;
    }
  }
  .form-item-mergevars-EMAIL {
    margin-bottom: 10px;
  }
  .form-type-checkbox {
    @include breakpoint(700px) {
      padding-left: 0;
    }
    @include breakpoint(425px) {
      max-width: 240px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 0;
    }
  }
}
