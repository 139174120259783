#commerce-checkout-form-complete {
  .block-gtweaks-notification-bar-top {
    display: none;
  }
  #edit-cart-contents,
  #edit-grouper {
    & > .fieldset-wrapper {
      // height: 100vh;
      min-height: 980px;
      padding-top: 190px;

      @include breakpoint(1160px) {
        min-height: auto;
      }
      @include breakpoint(500px) {
        padding-top: 60px;
      }
    }
  }
  
  .title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  & > div .grouper {
    max-width: 510px;
    margin-right: auto;
  }
  .message {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-top: 60px;

    &:before {
      content: "";
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url('/sites/all/themes/theuxprodigy/images/checkout-check.png');
      background-size: cover;
      background-repeat: no-repeat;      
    }
    p {
      width: calc(100% - 40px);
      padding-left: 20px;
    }
  }
  .help {
    font-size: 16px;
    margin-top: 130px;
    a {
      color: #2b78b9;      
      text-decoration: underline;
    }
  }
}

#edit-buttons {
  margin-top: 30px;

  .fieldset-wrapper {
    height: auto;
    padding-top: 0;
  }
  .checkout-gofrontpage {
    max-width: 241px;
    line-height: 64px;
    text-align: center;
    text-decoration: none;    
    // min-width: 214px;
    min-width: 225px;
    background-image: linear-gradient(to right, #100a0f, #100a0f 50%, #fbc31e 50%, #fbc31e);
    background-size: 200% 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    color: black;

    &:hover {
      background-position: 0 0;
      color: #fbc31e;
    }
  }
}


#edit-webinar-link {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.webinar-link--description {
  a {
    color: #1089f4;
    display: block;
    margin-top: 15px;
    word-break: break-all;
    
    &:visited {
      color: #1089f4;
    }
  }
}
.webinar-to-calendar--description {
  margin-bottom: 15px;
  display: inline-block;
}
.webinar-link--copy-link {
  a {
    color: #100a0f;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    line-height: 49px;
    width: 240px;
    display: inline-block;
    text-align: center;
    border: 1px solid #acacac;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 40px;

    background-color: none;
    -webkit-transition: 0.3s background-position ease-out;
    transition: 0.3s background-position ease-out;
    background-size: 204% 100%;
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc31e), color-stop(50%, #fbc31e), color-stop(50%, transparent), to(transparent));
    background-image: linear-gradient(to right, #fbc31e, #fbc31e 50%, transparent 50%, transparent);
    background-position: 100% 0;

    &:hover {
      cursor: pointer;
      background-position: 0 0;
    }
  }
  
}


.commerce-checkout-form-complete > div > .cart_contents, .commerce-checkout-form-checkout > div > .cart_contents {
  margin-right: 0;
}


.webinar-to-calendar--button {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  &:last-child {
    margin-bottom: 0;
  }

  &.microsoft {
    width: 240px;
    height: 48px;
    background: #25baff;
    line-height: 49px;
    text-align: center;   
    &:before {
      content: "";
      background: url('/sites/all/themes/theuxprodigy/images/microsoft_icon.png');
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px;
    }
  }

  &.google {
    width: 240px;
    height: 48px;
    background-color: #1089f4;
    line-height: 49px;
    text-align: center;    
    &:before {
      content: "";
      background: url('/sites/all/themes/theuxprodigy/images/google_icon.png');
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px;
    }
  }
  &.apple {
    width: 240px;
    height: 48px;
    background-color: #f35b58;
    line-height: 49px;
    text-align: center;
    &:before {
      content: "";
      background: url('/sites/all/themes/theuxprodigy/images/apple_icon.png');
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 12px;
    }
  }

  a {
    color: #fefefe;
    text-decoration: none;    
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    &:visited,
    &:hover {
      color: #fefefe;
    }
  }
}



.copied {
  background: #fbc31e;
}